.glasses-history {
	.glasses-history-title {
		text-align: center;
		padding: 29px 0;
		border-top: 2px solid #dfdfdf;
	}
	.glasses-history-box-01 {
		.inner {
			padding: 20px 0;
			overflow: hidden;
		}
		.btn {
			float: left;
			width: 215px;
			text-align: center;
		}
		p {
			line-height: 20px;
			font-family: $font-mincho;
			float: right;
			font-size: fz(15);
		}
	}
	.history-list {
		overflow: hidden;
		padding: 20px 0 50px;
		li {
			background: #fff;
			margin-bottom: 10px;
			overflow: hidden;
			.item-img {
				float: left;
				width: 485px;
				height: 215px;
				padding: 20px;
				line-height: 175px;
				vertical-align: middle;
				border-right: 1px dotted #dfdfdf;
				box-sizing: border-box;
				img {
					max-width: 100%;
					max-height: 100%;
					height: auto;
					vertical-align: middle;
				}
			}
			.item-remove {
				float: left;
				width: 72px;
				min-height: 215px;
				background: #e4e3e5 url(/img/glasses/history/btn_remove.png) no-repeat center center;
				a {
					display: block;
					width: 100%;
					height: 215px;

				}
			}
			.item-contents {
				width: 980px - 485px - 72px;
				box-sizing: border-box;
				overflow: hidden;
				float: left;
				font-size: 12px;
				.item-brand-logo {
					float: left;
					height: 70px;
					width: 139px;
					box-sizing: border-box;
					padding: 5px;
					line-height: 60px;
					border-right: 1px dotted #dfdfdf;
					text-align: center;
					img {
						max-width: 100%;
						max-height: 100%;
						height: auto;
					}
				}
				.item-model {
					padding: 10px;
					height: 70px;
					box-sizing: border-box;
					color: $c-murasaki;
					float: left;

					.item-model-cat {
						font-size: fz(18);
						font-family: $font-mincho;
						line-height: 1.1;
					}
					.item-model-name {
						font-size: fz(33);
						line-height: 1.2;
						font-family: $font;
						font-weight: bold;
					}
				}
				.item-contents-bottom {
					clear: both;
					border-top: 1px dotted #dfdfdf;
					font-size: 13px;
					padding: 8px 14px;
				}
			}
		}
	}
	.history-contents-bottom {
		padding: 48px 0;
		text-align: center;
		.btn {
			text-align: center;
			width: 255px;
			line-height: 20px
		}
	}

}