.glasses-vintage {
	.main-visual {
		&__summary {
			text-align: center;
			background: #000;
		}
		&__summary-inner {
			color: #fff;
			font-size: fz(20);
			line-height: 2.1;
			background-image: url(/img/glasses/brand/vintage/bg_main_visual_summary.png), url(/img/glasses/brand/vintage/bg_main_visual_summary2.png), url(/img/glasses/brand/vintage/bg_main_visual_summary3.png);
			background-position: center bottom, left bottom, left top;
			background-repeat: no-repeat, repeat-x, repeat;
			padding: 40px 0 75px;
		}

		&__detail {
			background: #000;
			padding: 61px;
			margin-bottom: 25px;
		}
		&__detail-inner {
			background: url(/img/glasses/brand/vintage/bg_title.png) left top repeat-x;
			.title {
				text-align: center;
				padding-top: 40px;
				span {
					width: 100%;
					color: #fff;
					font-size: fz(30);
					font-family: $font-mincho;
					border-bottom: 1px solid #e18d2a;
					padding-bottom: 10px;
				}
			}

			.clearfix {
				margin-top: 45px;
			}
			.first-img {
				float: left;
			}
			.first-text {
				float: right;
			}
			.second-img {
				float: right;
			}
			.second-text {
				float: left;
			}
			.first-text, .second-text {
				width: 330px;
				color: #fff;
				line-height: 1.85;
				margin-top: 55px;
			}

			.wop-box {
				background: #fff;
				padding: 8px;
				margin-top: 25px;
				&-inner {
					border: 1px solid #a3a3a3;
					padding: 58px 48px;
					overflow: hidden;
					.img {
						float: left;
					}
					.summary {
						width: 506px;
						float: right;
						&__title {
							font-size: fz(25);
							margin-bottom: 23px;
						}
						&__text {
							span {
								color: #54288a;
							}
						}
					}

				}
			}
		}
	}
}
