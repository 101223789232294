@charset "utf-8";
.sunglasses-pro-shop {
  .main-visual {
    height: 216px;
    background: url(/img/sunglasses/pro_shop/bg_main_visual.jpg) center top no-repeat;
  }
  .main-visual-title {
    height: auto;
    color: $c-murasaki;
    font-size: fz(26);
    font-weight: bold;
    font-family: $font-mincho;
    margin-bottom: 20px;
    padding-top: 50px;
	  line-height: 1.6;
  }
  .main-visual-sub-title {
    font-size: fz(12);
    font-weight: bold;
    text-align: left;
    padding-left: 42px;
  }
  .contents__caption.contents__caption--is-balloon {
    color: $c-murasaki;
    font-size: fz(24);
    font-family: $font-mincho;
    font-weight: bold;
    background: #fff;
  }
  .list {
    padding: 0 0 0 95px;
    margin: 30px 0;
    li {
      color: $c-murasaki;
      font-size: fz(18);
      font-family: $font-mincho;
      margin-bottom: 20px;
    }
  }
  .lead-box {
    width: 100%;
    padding: 32px 36px;
    text-align: center;
    box-sizing: border-box;
    background-image: url(/img/sunglasses/pro_shop/bg_contents_top.png),url(/img/sunglasses/pro_shop/bg_contents_bottom.png),url(/img/sunglasses/pro_shop/bg_contents_middle.png);
    background-repeat: no-repeat,no-repeat,repeat-y;
    background-size: contain;
    background-position: left top,left bottom,left;
    h4 {
      color: $c-murasaki;
      font-size: fz(18);
      font-family: $font-mincho;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .att {
    font-size: fz(10);
    text-align:left;
    margin-top: 10px;
  }
  .list-02 {
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-top: 2px;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .lead-box__title {
    margin-top: -50px;
    margin-bottom: 50px;
  }
  .lead-box__note {
    padding: 15px 20px;
    border-bottom: 1px dashed #959595;
  }
  .lead-box-detail {
    background: #f9f9f7;
    padding: 15px 20px;
    margin-top: 2px;
    text-align: left;
    .btn-08 {
      background: #fafafa url(/img/common/icon_circle_01.png) no-repeat left center;
    }
  }
  .textarea {
    display: table;
  }
  .textarea-inner {
    display: table-cell;
    height: 188px;
    vertical-align: middle;
  }
  .lead-box-detail__name {
    dt, dd {
      display: inline-block;
      font-weight: bold;
    }
    dt {
      padding-left: 30px;
    }
    dd {
      margin-left: 20px;
    }
  }
  .btn-top {
    width: 375px;
    margin: 35px auto;
  }
}
