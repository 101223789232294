@charset "UTF-8";
.area06 {
	.main-visual {
    width: 100%;
    height: 424px;
    background-image: url(/img/shop_list/area06/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top 20px;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
}
