.quality-omotenashi {
	.main-visual {
		height: 339px;
		background: url(/img/quality/omotenashi/bg_main.jpg) no-repeat center top;
		border-bottom: 1px solid #dfdfdf;
		.main-visual-title {
			font-size: 33px;
			line-height: 1.57;
			@include mincho-murasaki;
			padding-top: 123px;
			width: auto;
			padding-left: 19px;
			height: auto;
		}
	}

	/* sec01
	  ---------------------------------- */
	.quality-lead-area {
		.inner {
			.quality-lead {
				font-size: fz(20);
				span {
					border-bottom: 2px solid #c7e1bd;
					padding: 7px 0;
				}
			}
		}
	}

	/* sec02
	  ---------------------------------- */
	.sec-02 {
		margin-bottom: -20px;
		padding-bottom: 60px;
		.inner {
			padding: 40px 0 25px 0;
			background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
		}
		ul {
			width: 1000px;
			overflow: hidden;
			li {
				background: #fff;
				margin: 0 20px 20px 0;
				float: left;
				  box-sizing: border-box;
				width: 480px;
				padding: 0 37px 23px;
				border: 3px solid #f2f2f2;
				h3 {
					border-top: 7px solid $c-murasaki;
					border-bottom: 2px solid $c-murasaki;
					text-align: center;
					height: 61px;
					line-height: 61px;
					font-family: $font-mincho;
					font-size: fz(30);
					margin-bottom: 20px;
				}
				p {
					margin-bottom: 1em;
				}
				.img-wrapper {
					border: 3px solid $c-murasaki;
					padding: 7px 6px;
					img {
						max-width: 100%;
						height: auto;
						border: 1px solid $c-murasaki;
					}
				}
				&:last-child {
					width: 100%;
					clear: both;
					overflow: hidden;
					h3 {
						text-align: left;
					}
					p {
						width: 495px;
						float: left;
					}
					.img-wrapper {
						float:right;
					}
				}
			}
		}
	}
	.sec-03 {
		background: url(/img/quality/omotenashi/bg_section_03.png) repeat-x left top;
		height: 264px;
		.inner {
			height: 100%;
			background: url(/img/quality/omotenashi/bg_section_01.png) no-repeat center top;
			color: #fff;
			h2 {
				font-size: fz(24);
				font-family: $font-mincho;
				padding: 10px 0 0 45px;
			}
			p {
				font-family: $font-mincho;
				font-size: fz(27);
				padding: 50px 0 0 327px;
			}
			a {
				margin: 15px 0 0 712px;
				color: #fff;
				border: 3px solid #fff;
				width: 270px;
				height: 50px;
				text-align: center;
				line-height: 44px;
				box-sizing: border-box;
				display: block;
				transition: all 0.3s;
				&:hover {
					text-decoration: none;
					opacity: 0.44;
				}
				span {
					color: #fff;
					font-size: fz(18);
					font-family: $font-mincho;
					&:before {
						content: url(/img/quality/omotenashi/icon_circle_white.png);
						padding-right: 10px;
						padding-top: 4px;
						display: inline-block;
						vertical-align: middle;
						line-height: 44px;
					}
				}
			}
		}
	}
}