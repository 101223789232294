@charset "UTF-8";

.border-box{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.hearing-aid{
  .cf:after {
    content: "."; 
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
  .cf {min-height: 1px;}
  * html .cf {
    height: 1px;
    /*¥*//*/
    height: auto;
    overflow: hidden;
    /**/
  }
  .pc-mode{
    display: block;
  }
  .sp-mode{
    display: none;
  }

  @mixin opacity($opacity){
    opacity: $opacity;
    filter: alpha(opacity=$opacity);
    -ms-filter: "alpha(opacity=#{$opacity*100})";
    -moz-opacity:$opacity;
    -khtml-opacity: $opacity;
  }

  @mixin transition($value) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
      #{$prefix}transition: $value;
    }
  }
  .op{
    @include transition(0.1s);
    &:hover{
      @include opacity(0.7);
    }
  }
  .hearing_aid_sheet{
    .main-visual-sub{
      .inner{
        position: relative;
        width: 965px;
        margin: 0 auto;
        text-align: left;
        
      }
      .main-visual-sub-title-02{
        font-size: 27px;
        padding-top: 86px;
        padding-left: 15px;
        line-height: 1;
        color: #453288;
        height: 113px;
        font-weight: bold;
        font-family: $font-mincho;
      }
      .main-visual-sub-icon{
        position: absolute;
        top: 46px;
        right: 53px;
      }
    }

    .announce-wrap{
      background: #fff;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #f7f7f7;
      .inner{
        width: 980px;
        margin: 0 auto;
        background: url(/img/common/bg_line_01.png) left top repeat-x;
        padding: 27px 0 24px;
        .announce-txt{
          font-size: 20px;
          font-family: $font-mincho;;
          color: $c-murasaki;
          font-weight: bold;
          line-height: 1.6;
        }
      }
    }

    .checksheets-wrap{
      padding: 32px 0 78px;
      .inner{
        width: 874px;
        margin: 0 auto;
      }

      .type-box-a-wrap{
        border: 2px solid #dfdfdf;
        background: #fff;
        padding-bottom: 6px;
        margin-bottom: 48px;
        .type-box-a-desc-txt{
          line-height: 1.6;
        }
        .type-box-a-desc{
          width: 858px;
          height: auto;
          padding: 10px 26px;
          @extend .border-box;
          .detail-btn{
            right: 16px;
            left: auto;
            bottom: 15px;
            a{
              padding: 0px 22px;
              height: 31px;
              line-height: 31px;
              border: 1px solid #e1e1e1;
              span{
                padding-right: 0;
                padding-left: 24px;
                background: url(/img/common/icon_circle_01.png) left 7px no-repeat;
                display: inline-block;
              }
            }
          }
        }
      }

      .checkitem-wrap{
        .checkitem-wrap-head{
          text-align: center;
          width: 278px;
          margin: 0 auto 24px;
          position: relative;
          @extend .border-box;
          &:before{
            content: url("/img/hearing_aid/sheet/bg_quotation_left.png");
            position: absolute;
            left: -120px;
            top: 16px;
          }
          &:after{
            content: url("/img/hearing_aid/sheet/bg_quotation_right.png");
            position: absolute;
            right: -120px;
            top: 16px;
          }
          span{
            font-size: 24px;
            font-family: $font-mincho;;
            color: $c-murasaki;
            font-weight: bold;
            line-height: 1.6;
            padding-left: 57px;
            display: inline-block;
          }
        }
        &.checkitem-wrap-talk{
          .checkitem-wrap-head{
            span{
              background: url(/img/hearing_aid/icn_voice.png) left top no-repeat;
            }
          }
        }
        &.checkitem-wrap-madia{
          .checkitem-wrap-head{
            span{
              background: url(/img/hearing_aid/sheet/icon_media.png) left top no-repeat;
            }
          }
        }
        &.checkitem-wrap-house{
          .checkitem-wrap-head{
            span{
              background: url(/img/hearing_aid/sheet/icon_house.png) left top no-repeat;
            }
          }
        }
        &.checkitem-wrap-goout{
          .checkitem-wrap-head{
            span{
              background: url(/img/hearing_aid/sheet/icon_goout.png) left top no-repeat;
            }
          }
        }

        .checkitem-box{
          padding: 55px 70px 43px;
          box-shadow:4px 4px 0px -2px #dad9d9;
          -moz-box-shadow: 4px 4px 0px -2px #dad9d9;
          -webkit-box-shadow: 4px 4px 0px -2px #dad9d9;
          margin-bottom: 62px;
          @extend .border-box;
          &:last-child{
            margin-bottom: 86px;
          }
          .checkitem-list{
            .checkitem-list-item{
              background: url(/img/hearing_aid/sheet/icon_sheet_check.png) 1px 17px no-repeat;
              line-height: 1.3;
              padding: 28px 0 10px 66px;
              border-top: 1px solid #dfdfdf;
              margin-bottom: 17px;
              font-size: 22px;
              font-family: $font-mincho;;
              color: $c-murasaki;
              font-weight: bold;
              &:first-child{
                border-top: none;
                padding-top: 8px;
                background: url(/img/hearing_aid/sheet/icon_sheet_check.png) 1px top no-repeat;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .btn-check{
        width: 502px;
        height: 119px;
        margin: 0 auto;
        @extend .border-box;
        &.btn-check-self{
          background: url(/img/hearing_aid/sheet/btn_checksheet_self.png) left top no-repeat;
        }
        &.btn-check-family{
          background: url(/img/hearing_aid/sheet/btn_checksheet_family.png) left top no-repeat;
        }
        a{
          color: #fff;
          display: block;
          padding-top: 25px;
          &:hover{
              text-decoration: none;
          }
        }
        .btn-txt{
          font-size: 33px;
          font-family: $font-mincho;
          color: #fff;
          margin-left: 221px;
          line-height: 1.3;
          background: url(/img/common/icon_circle_white.png) right center no-repeat;
          margin-right: 15px;
          span{
            font-size: 22px;
            display: block;
            font-family: $font-mincho;
            color: #fff;
          }
        }
      }
    }
  }
}
