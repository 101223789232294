@charset "utf-8";
.glasses-about-faq {
  .bg-pattern-01 {
    padding: 50px 0;
  }
  .contents__ttl-bg-outer {
    margin-bottom: 30px;
  }
  .list {
    background: #fff;
  }
  .list-inner {
    padding: 0 45px;
  }
  .list-item {
    border-top: 1px solid #eae7ec;
    a {
      display: inline-block;
      padding: 15px 0 15px 30px;
    }
  }
  .list--02 {
    margin-top: 50px;
  }
}
