@charset "utf-8";
.glasses-casual {
	.main-visual__textarea {
		position: relative;
		/* .attr {
			width: 190px;
			position: absolute;
			right: 0;
			bottom: 10px;
			text-align: left;
			font-size: 10px;
		} */
	}
	.gc-contents-box {
		.row, .col-6 {
			padding: 0;
			margin: 0;
		}
		&:nth-child(2) {
			.col-6 {
				&:nth-child(1) {
					float: left;
				}
				&:nth-child(2) {
					float: right;
				}
			}
		}
		&:nth-child(3) {
			.col-6 {
				&:nth-child(1) {
					float: right;
				}
				&:nth-child(2) {
					float: left;
				}
			}
		}
		&:nth-child(4) {
			.col-6 {
				&:nth-child(1) {
					float: left;
				}
				&:nth-child(2) {
					float: right;
				}
			}
		}
		&:nth-child(5) {
			border-bottom: 2px solid #593f7f;
			.col-6 {
				&:nth-child(1) {
					float: right;
				}
				&:nth-child(2) {
					float: left;
				}
			}
		}
	}
	.gc-contents-box__textarea-outer {
		display: table;
		min-height: 490px;
		*height: 490px;
	}
	.gc-contents-box__textarea {
		display: table-cell;
		vertical-align: middle;
		height: 490px;
	}
	.gc-contents-box__title, .gc-contents-box__text {
		width: 400px;
		margin: 0 auto;
	}
	.gc-contents-box__title {
		font-size: fz(30);
		font-weight: bold;
		color: $c-murasaki;
		background: url(/img/glasses/set/casual/icon_arrow.png) left center no-repeat;
		padding-left: 16px;
		margin-bottom: 20px;
	}
	.gc-contents-separate {
		padding-bottom: 78px;
	}
	.gc-contents-separate--01 {
		background: url(/img/glasses/set/casual/img_separate.png) left bottom no-repeat;
	}
	.gc-contents-separate--02 {
		background: url(/img/glasses/set/casual/img_separate_02.png) left bottom no-repeat;
	}
	.gc-contents-separate--03 {
		background: url(/img/glasses/set/casual/img_separate_03.png) left bottom no-repeat;
	}
}
