@charset "UTF-8";
.blog {
	//.main-visual-blog{
	//	background: url(/img/blog/shinjuku/main_visual.png) center top no-repeat;
	//	height: 277px;
	//	margin-top: 30px;
	//	overflow: hidden;
	//	.main-visual-blog-title{
	//		width: 648px;
	//		background: $c-murasaki;
	//		color: #fff;
	//		box-sizing: border-box;
	//		padding-left: 25px;
	//		font-size: 42px;
	//		height: 130px;
	//		line-height: 1.4;
	//		span{
	//			font-size: 26px;
	//		}
	//	}
	//	.main-visual-blog-txt{
	//		float: right;
	//		margin-top: 107px;
	//		width: 310px;
	//		a{
	//			display: block;
	//			width: 310px;
	//			@include vertical-align-middle(40px);
	//			background: $c-murasaki;
	//			color: #fff;
	//			box-sizing: border-box;
	//			text-align: right;
	//			padding-right: 15px;
	//			font-weight: bold;
	//			&:hover{
	//				text-decoration: none;
	//			}
	//		}
	//	}
	//}

	/**
	投稿記事のレイアウト：デフォルト
	*/
	strong { font-weight: bold; }
	em { font-style: italic; }
	blockquote {
		display: block;
		-webkit-margin-before: 1em;
		-webkit-margin-after: 1em;
		-webkit-margin-start: 40px;
		-webkit-margin-end: 40px;
	}
	.aligncenter {
	display: block;
	margin: 0 auto;
}
	.alignright { float: right; }
	.alignleft { float: left; }

	img[class*="wp-image-"],
	img[class*="attachment-"] {
		max-width: 100%;
		height: auto;
	}
	.clearfix {
		overflow: hidden;
		zoom: 1;
	}
	.clearfix:after {
		content: "";
	display: block;
		clear: both;
	}


	.main-visual-wrapper {
		height: 410px;
		width: 980px;
		margin: 0 auto 15px;
		position: relative;
		.main-visual-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: url(/img/blog/common/overlay_mv.png) no-repeat center center;
		}
		.main-visual-ribon {
			position: absolute;
			top: 112px;
			left: -24px;
			width: 660px;
			height: 170px;
			box-sizing: border-box;
			color: #fff;
			background: url(/img/blog/common/ribon_mv.png) no-repeat left top;
			p {
				padding: 25px 68px 5px;
				span {
					padding: 0 17px;
					border-left: 1px solid #fff;
					border-right: 1px solid #fff;
					font-size: 27px;
					font-weight: bold;
					background: #453288;
				}
			}
			h2 {
				font-size: 43px;
				font-weight: bold;
				padding: 0 0 0 48px;
			}
			a {
				color: #fff;
			}
		}
		.main-visual-btn {
			position: absolute;
			right: 20px;
			bottom: 18px;
		}
	}
	.contents{
		overflow: hidden;
		background: url(/img/blog/common/bg_blog.png) left top repeat;
		padding-bottom: 69px;
		.main{
			width: 648px;
			float: left;
			box-sizing: border-box;
			.main-inner{
				border: 1px solid #dfdfdf;
				background: #fff url(/img/common/bg_line_01.png) left top repeat-x;
				margin-bottom: 22px;
			}
			.title{
				font-size: 26px;
				font-weight: bold;
				color: $c-murasaki;
				width: 615px;
				margin: 0 auto 10px;
				border-bottom: 2px solid $c-murasaki;
				padding: 20px 0 5px;
			}
			.category{
				text-align: right;
				margin-right: 17px;
				font-weight: bold;
				font-size: fz(14);
				a{
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
			}
			.main-contents{
				font-size: fz(14);
				width: 615px;
				margin: 10px auto;
				p{
					line-height: 1.7;
					margin-bottom: 10px;
				}
			}
			.under-category{
				height: 40px;
				background: #e5e5e5;
				.category{
					line-height: 40px;
					a{
						text-decoration: none;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}


			.form-allowed-tags{display:none; }

			.says{ display:none; }
			//これでコメントがあった時の名前と日付の間の文字を消し

			.reply{display:none; }
			//これで返信リンクを消し

			.comment-author{
				position:relative;
			}
			.comment-meta{

			}
			.comment-author a{text-decoration:none; color:#3C3C3C; cursor:default; }
			.comment-meta a{text-decoration:none; color:#3C3C3C; cursor:default; }
			//この辺で名前と日付の位置を決め

			.fn{font-style:normal; }
			//これで名前のフォントを斜体から普通に戻し

			//.commentlist{list-style:none; margin:0 0 40px; padding:0; }
			//.commentlist li{margin:0; padding:0; }
			//.comment-body{clear:both; font-size:0.8em; line-height:1.9; position:relative; overflow:hidden; margin:0 0 -50px; }
			//.comment-body p{width:345px; padding:0 0 0 20px; border-left:solid 1px #C1C1C1; position:relative; top:-75px; left:95px; }
			///これらでフォントサイズやらマージンやらコメントの位置やらを調整し

			#commentform label{font-size:12px; }
			//これでフォームのラベルサイズを調整して


			#comments-count {
				//color: $c-murasaki;
				font-size: 16px;
				font-weight: bold;
				//border-bottom: 1px solid $c-murasaki;
				margin-bottom: 20px;
			}
			.comment-lists-wrap {  /* コメント一覧全体のスタイル指定 */
			}
			.comment-lists-wrap ol { /* olタグのスタイル（数字のスタイルを変えたいときに指定） */
				list-style-position: inside;
			}
			.comment-lists-wrap ol .comment { /* 1コメントを囲む要素全体のスタイル */
			}
			.comment-lists-wrap ol .children { /* コメントに返信があった場合の子要素全体のスタイル（左に余白を入れるなど） */
			}
			.comment-lists-wrap ol .children li { /* コメントへの各返信のスタイル */
				overflow: hidden;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			.comment-meta a, .reply a { /* コメントの日付リンクおよび返信リンクのスタイル */
			}
			.comment-notes {
				margin-bottom: 15px;
			}
			.fn { /* コメントした人の名前のスタイル（デフォルトでは斜体なので変更したい場合はこの部分で指定） */
				font-size: 15px;
				font-weight: bold;
				font-style: normal;
				float: left;
				line-height: 17px;
				display: inline-block;
			}
			.says {
				display: none;
			}
			.comment-meta.commentmetadata {
				display: inline-block;
				line-height: 17px;
				padding-left: 10px;
			}
			.comment-body p {
				clear: both;
				font-size: 14px;
				padding-top: 1em;
			}
			.comment-body p:last-child {
				margin-bottom: 0;
			}
			#respond { /* コメント投稿フォーム全体のスタイル指定 */
			}

			.form-submit #submit { /* コメント投稿ボタンのスタイル指定 */
			}
			.form-submit #submit:hover { /* コメント投稿ボタンにマウスが乗ったときのスタイル指定 */
			}

			#reply-title {
				display: none;
			}
			#comments-list {
				margin-bottom: 30px;
				li {
					margin-bottom: 20px;
					&:last-child {
						margin: 0;
					}
				}
			}

			.comment-title{
				color: $c-murasaki;
				font-size: 18px;
				font-weight: bold;
				border-bottom: 1px solid $c-murasaki;
				margin-bottom: 20px;
			}
			.comment-list{

				li {
					overflow: hidden;
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.comment-form {

				.comment-form-author,.comment-form-email,.comment-form-comment {
					overflow: hidden;
					margin-bottom: 20px;
				}
				label{
					width: 180px;
					float: left;
					font-size: 15px;
					font-weight: bold;
					padding-top: 9px;
				}
				input[type=text],textarea {
					width: 450px;
					float: right;
					font-size: fz(15);
					outline: none;
					margin-bottom: 20px;
					border: 1px solid $c-murasaki;
				}
				input[type=text] {
					box-sizing: border-box;
					height: 40px;
				}
				textarea{
					box-sizing: border-box;
					height: 200px;
				}

			}
			.comment-form-url {
				display: none;
			}
			.comment-form-comment {

			}

			.comment-desc{
				width: 450px;
				float: right;
				font-size: fz(15);
				input{
					width: 100%;
					box-sizing: border-box;
					height: 40px;
				}
				textarea{
					width: 100%;
					box-sizing: border-box;
					height: 200px;
				}
			}
		}
		.aside{
			width: 310px;
			float: right;
			box-sizing: border-box;
			.shop{
				margin-bottom: 22px;
			}
			.shop-name{
				color: $c-murasaki;
				font-size: 18px;
				font-weight: bold;
				border-bottom: 4px solid $c-murasaki;
				margin-bottom: 10px;
			}
			.shop-image{
				margin-bottom: 7px;
				img {
					max-width: 100%;
					height: auto;
				}
			}
			.store-info{
				margin-bottom: 10px;
			}
			.anc{
				background: url(/img/common/icon_circle_01.png) no-repeat left center;
				a{
					padding-left: 21px;
					color: #3000e2;
				}
			}
			.title{
				@include vertical-align-middle(40px);
				font-size: 18px;
				font-weight: bold;
				padding-left: 10px;
				color: #fff;
				background: $c-murasaki;
				margin-bottom: 13px;
			}
			.latest-post{
				.latest-post-list{
					margin-bottom: 24px;
				}
				.latest-post-item {
					padding-left: 24px;
					background: url(/img/blog/common/icn01.png) 5px 4px no-repeat;
					margin-bottom: 8px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.past-post{
				border: 5px solid $c-murasaki;
				text-align: center;
				margin-bottom: 20px;
				a{
					color: $c-murasaki;
					font-size: 17px;
					font-weight: bold;
					display: block;
					@include vertical-align-middle(40px);
				}
				span{
					display: inline-block;
					background: url(/img/blog/common/icn02.png) right center no-repeat;
					padding-right: 17px;
				}
			}
			.archive-post{
				border: 5px solid $c-murasaki;
				text-align: center;
				margin-bottom: 30px;
				a{
					color: $c-murasaki;
					font-size: 17px;
					font-weight: bold;
					display: block;
					@include vertical-align-middle(70px);
				}
				span{
					display: inline-block;
					background: url(/img/common/icon_circle_01.png) left center no-repeat;
					padding-left: 17px;
				}
			}
			.category-post-list{
				margin-bottom: 24px;
			}
			.category-list {
				li {
					padding-left: 24px;
					background: url(/img/blog/common/icn01.png) 5px 4px no-repeat;
					margin-bottom: 8px;
					font-size: fz(14);
					a{
						color: #3000e2;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}

			.list{
				.list-item{
					margin-bottom: 4px;
					a{
						display: block;
						@include vertical-align-middle(57px);
						font-size: 18px;
						font-weight: bold;
						padding-left: 24px;
						color: #fff;
						background: $c-murasaki;
						&:hover{
							text-decoration: none;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.blog-tyep-01{
	.contents{
		.main{
			.title{
				color: $c-murasaki;
			}
		}
		.aside{
		}
	}
}
.common-contents-blog{
	.common-store-guide{
		.inner{
			overflow: hidden;
			margin-bottom: 48px;
		}
	}
}

.store-blog-inner{
	width: 980px;
	margin: 0 auto;
	background: url(/img/common/bg_line_01.png) left top repeat-x;
}
.store-blog-name{
	text-align: center;
	width: 418px;
	margin: 0 auto;
	@include vertical-align-middle(38px);
	color: #fff;
	font-size: fz(16);
	font-weight: bold;
	background: url(/img/blog/common/bg_footer_store_title.png) left top no-repeat;
	position: relative;
	top: -17px;
}
.store-block-top{
	margin: 25px 0 33px;
}
.store-block-bottom{
	margin-bottom: 27px;
}
.store-top{
	width: 860px;
	margin: 0 auto;
}
.store-bottom{
	width: 560px;
	margin: 0 auto;
}
.store-blog{
	text-align: center;
	width: 260px;
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #d2d2d2;
	p{
		border-bottom: 2px solid #453288;
		margin-bottom: -1px;
	}
	a{
		@include vertical-align-middle(44px);
		color: $c-murasaki;
		font-weight: bold;
		font-size: fz(15);
		display: block;
		&:hover{
			text-decoration: none;
		}
	}
	span{
		padding-left: 21px;
		background: url(/img/common/icon_circle_01.png) no-repeat left center;
	}
}

/*****************************
*カレンダーに枠を付ける
*****************************/
#wp-calendar {
	border-collapse: collapse;
	border-top-width: 1px;
	border-right-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-top-color: #999;
	border-right-color: #999;
	width: 100%;
	margin-bottom: 30px;
}
#wp-calendar thead tr th {
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-bottom-style: solid;
	border-left-style: solid;
	border-bottom-color: #999;
	border-left-color: #999;
	text-align: center;
}

/*土曜日のヘッダーセル*/
#wp-calendar thead tr th:nth-child(6){
	background-color: #C0DDF9;
}

/*日曜日のヘッダーセル*/
#wp-calendar thead tr th:nth-child(7){
	background-color: #F5D0D3;
}

#wp-calendar td {
	text-align: center;
	padding: 0px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-bottom-style: solid;
	border-left-style: solid;
	border-bottom-color: #999;
	border-left-color: #999;

}
#wp-calendar td a{
	text-decoration: underline;
}
#wp-calendar caption {
	font-weight: bold;
	text-align: left;
}
#wp-calendar tbody tr #today {
	background-color: #CCC;
}

/*
Default style for WP-PageNavi plugin

http://wordpress.org/extend/plugins/wp-pagenavi/
*/

.wp-pagenavi {
	clear: both;

	/* センター寄せ */
	text-align:center;
}

.wp-pagenavi a, .wp-pagenavi span {
	text-decoration: none;
	border: 1px solid #BFBFBF;
	padding: 5px 8px;
	margin: 4px;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
	border-color: #000;
}

.wp-pagenavi span.current {
	font-weight: bold;
}
