.quality-item{
  .main-visual{
    border-bottom: 1px solid #dfdfdf;
  }
  .main-visual-inner{
    position: relative;
    .main-visual-img{
      position: absolute;
      right: 107px;
      top: 58px;
    }
    .main-visual-txt{
      position: absolute;
      width: 189px;
      @include vertical-align-middle(31px);
      @include mincho-s(17);
      text-align: center;
      background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
      color: #fff;
      top: 247px;
      right: 77px;
    }
  }
  .main-visual{
    background: url(/img/common/bg_main.png) center top no-repeat;
    height: 339px;
    .main-visual-title{
      font-size: 33px;
      line-height: 1.57;
      @include mincho-murasaki;
      padding-top: 123px;
      width: auto;
      padding-left: 19px;
      height: auto;
    }
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 40px 0 25px 0;

    .main-contens-box{
      background: #fff;
      border: 3px solid #f2f2f2;
      padding: 20px 24px 14px;

      /* --- タイトル部分 --------------- */
      .main-contens-title-area{
        margin-bottom: 25px;
      }
      .main-contens-title-deep{
        border-left: 5px solid $c-murasaki;
        padding-left: 19px;
        p{
          min-height: 35px;
          font-size: 16px;
        }
      }
      .main-contens-title-light{
        border-left: 5px solid #a78dc3;
        padding-left: 19px;
      }
      .main-contens-title-02{
        font-size: 40px;
        @include mincho-murasaki;
        line-height: 1.4;
      }
      .main-contens-title-03{
        font-size: 16px;
        font-weight: bold;
      }
    }
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
    }
    .main-contens-clms-left{
      width: 539px;
      float: left;
      .main-contens-btn{
        background: #fff;
        border: 4px solid $c-murasaki;
        font-family: $font-mincho;
        font-weight: bold;
        width: 252px;
        @include vertical-align-middle(33px);
        font-size: 16px;
        text-align: center;
        a{
          display: inline-block;
          color: $c-murasaki;
          padding-left: 22px;
          background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .main-contens-txt{
        padding-left: 19px;
      }
    }
    .main-contens-clms-right{
      width: 368px;
      float: right;
      position: relative;
      .main-contens-img{
        border: 3px solid $c-murasaki;
        background: #fff;
        padding: 6px;
        img{
          display: block;
          border: 2px solid $c-murasaki;
        }
      }
      .tag{
        position: absolute;
        left: -21px;
        top: -9px;
      }
    }
  }
}
