@charset "UTF-8";
/* =================================

================================= */

.contact_faq_tbl {

	tr {

		span {
			color: #f00;
		}
		a {
			color: $c-murasaki;
		}
		&:nth-child(2n+1) {
			td,th {
				background: #e9e9e9;
			}
		}
		&:nth-child(2n) {
			td:first-child,th:first-child{
				background: #e8e2ec;
			}
		}
		&:nth-child(1) {
			td,th {
				background: #ccc;
			}
		}
	}
}
.contact_faq_tbl td { border: 1px solid #000000; padding: 5px;} .contact_faq_tbl td.td1 { width: 150px;} .contact_faq_tbl td.td2,.contact_faq_tbl td.td3 { text-align: center;}

/* =================================
お問い合わせ
================================= */
.contact {
	.contents-lead {
		text-align: center;
		margin-top: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid #f7f7f7;
	}
	.bg-pattern-01 {
		padding: 40px 0;
	}
	.contents {
		border-right: 3px solid #f2f2f2;
		border-bottom:  3px solid #f2f2f2;
		border-left:  3px solid #f2f2f2;
		background: #fff;
	}
	.contents__caption-outer {
		background: url(/img/contact/img_caption_border.png) left top repeat-x;
	}
	.contents__caption {
		color: $c-murasaki;
		font-weight: bold;
		font-size: fz(23);
		font-family: $font-mincho;
	}
	.contents__list {
		width: 828px;
		margin: 0 auto 40px;
		.btn-05 {
			border: 1px solid #d2d2d2;
		}
		span {
			display: inline-block;
			line-height: 17px;
			height: 17px;
			font-size: 83.3%;
		}
	}
	.contents__list .col-4 {
		margin-top: 30px;
	}
	.btn-outer {
		width: 376px;
		margin: 40px auto;
	}
}
/* =================================
フォーム
================================= */
.contact-form {
	padding-bottom: 120px;
	background: url(/img/contact/form/bg_bottom.png) repeat-x left bottom,
	url(/img/common/bg_pattern_01.png) repeat;
	.form-step {
		background: url(/img/contact/form/bg_step_border.png) left center repeat-x;
		overflow: hidden;
		margin: 50px 0;
	}
	.main-header {
		margin-top: 20px;
		background: url(/img/contact/form/bg_header.png) repeat-x left top,
	}
	.main-header .inner {
		background: none;
	}
	.main-header .page-title {
		padding: 20px 0;
	}
	.form-step__list {
		width: 282px;
		float: left;
		margin: 0 20px;
	}
	.title {
		text-align: center;
		font-weight: bold;
		font-size: fz(24);
		font-family: $font-mincho;
		color: $c-murasaki;
		margin-bottom: 40px;
	}
	.att {
		font-weight: bold;
		text-align: center;
		margin-bottom: 40px;
	}
	.error {
		color: $c-murasaki;
		font-size: fz(20);
		font-weight: bold;
		text-align: center;
		border: 4px solid $c-murasaki;
		padding: 19px 0;
		margin-bottom: 30px;
	}
	// form
	form {
	}
	.form-inner {
		border: 3px solid #f2f2f2;
		padding: 50px 54px;
		background: #fff;
	}
	.form-item__name-outer {
		display: table;
		background: #f6f3f9;
	}
	.form-item__name {
		display: table-cell;
		height: 100px;
		vertical-align: middle;
		font-weight: bold;
		color: $c-murasaki;
		text-align: right;
		border-bottom: 1px solid #dcd1e7;
		padding-right: 20px;
		&.form-item__name--textarea {
			height: 176px;
		}
	}
	.form-item__value-outer {
		display: table;
	}
	.form-item__value {
		display: table-cell;
		height: 100px;
		vertical-align: middle;
		border-bottom: 1px solid #e6e6e6;
		padding-left: 60px;
		input,textarea {
//			-webkit-appearance: none;
			border: 1px solid #dfdfdf;
			outline: 0;
		}
		input {
			min-height: 35px;
			line-height: 35px;
		}
		input[type="email"] {
			width: 100%;
			box-sizing: border-box;
		}
		textarea {
			width: 100%;
			height: 112px;
			box-sizing: border-box;
		}
		&.form-item__value--textarea {
			height: 176px;
		}
		&.form-item__value--tel {
			input[type="tel"] {
				width: 30%;
				box-sizing: border-box;
			}
			span {
				margin-right: 5px;
			}
		}
	}
	.form-item__value-label {
		label {
			&:nth-child(2) {
				margin-left: 45px;
			}
			span {
				margin-right: 10px;
			}
		}
	}
	.form-item__value-exp,
	.form-item__value-error {
		margin-top: 10px;
	}
	.form-item__value-error {
		color: #840b0b;
	}
	.att-privacy {
		border: 1px solid $c-murasaki;
		padding: 38px;
		margin-top: 55px;
	}
	.att-privacy__title {
		font-size: fz(16);
		font-weight: bold;
		color: $c-murasaki;
		margin-bottom: 30px;
	}
	.submitarea {
		width: 375px;
		margin: 48px auto 0;
		&:hover {
			cursor: pointer;
		}
		&.submitarea--confirm {
			width: 545px;
		}
		&.submitarea--complete {
			width: 200px;
		}
		input {
			border: none;
			color: $c-murasaki;
			padding-left: 26px;
			background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
			background-size: 17px;
			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}
}
