@charset "utf-8";
/*
 *  3.ボックス
 *  5.ライン
 *  7.画像
 * 10.ブランド表
 * 11.レンズ詳細表
 * 12.店舗詳細表
 * ラベル??

 **********************************************/

.area-name {
	height: 40px;
	line-height: 40px;
	padding: 0 20px;
	color: #fff;
	font-size: 20px;
	background: #453288;
	margin-bottom: 40px;
	font-family: Roboto Condensed,A1 Mincho,"ヒラギノ明朝 ProN W6",HiraMinProN-W6,"HG明朝E","ＭＳ Ｐ明朝",MS PMincho,"MS 明朝",serif;
}

 // =====================================
 // 	ブランドリスト？
 // =====================================
 .list_national_brand {
 	overflow: hidden;
 	box-sizing: border-box;
 	margin-bottom: -1px;
 	background: #fff !important;
 	li {
 		&.current {
 			background: #dfdfdf;
 		}
 		width: 16.66666666%;
 		height: 80px;
 		box-sizing: border-box;
 		line-height: 80px;
 		border-right: 1px solid #e9e9e9;
 		border-bottom: 1px solid #e9e9e9;
 		a {
 			display: block;
 			width: 100%;
 			height: 100%;
 			img {
 				max-width: 60%;
 				max-height: 60%;
 				width: auto;
 				height: auto;
 			}
 		}
 	}
 }
 .lists_private_brand {
 	overflow: hidden;
 	img {
 		max-width: 100%;
 		height: auto;
 	}
 	.list{
 		border: 1px solid #d1d1d1;
 		float: left;
 		width: 314px;
 		margin: 0 16px 32px 0;
 		background: #fff;
 		position: relative;
 		.txt{
 			height: 54px;
 			padding-top: 12px;
 			font-size: 14px;
 			text-align: center;
 		}
 		.txt02{
 			position: absolute;
 			right: 8px;
 			bottom: 40px;
 			font-size: 12px;
 			color: #dfdfdf;
 		}
 		.img02{
 			height: 83px;
 			line-height: 83px;
 			text-align: center;
 			img{
 				max-height: 80%;
 				width: auto;
 				vertical-align: middle;
 			}
 		}
 		.list-btn{
 			a{
 				background: #fafafa;
 				display: block;
 				border-top: 1px solid #d1d1d1;
 				text-align: center;
 				span{
 					height: 33px;
 					line-height: 33px;
 					font-family: $font-mincho;
 					font-weight: bold;
 					font-size: fz(14);
 					padding-left: 33px;
 					display: inline-block;
 					background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
 				}
 			}
 		}
 		&:nth-child(3n){
 			margin-right: 0;
 		}
 	}
 }
 // =====================================
 // 	ラベル？
 // =====================================
 .label-01 {
 	min-width: 43px;
 	color: #fff;
 	font-size: fz(11);
 	height: 16px;
 	line-height: 16px;
 	background: $c-murasaki;
 	display: inline-block;
 	text-align: center;
 	vertical-align: middle;
 }
