@charset "UTF-8";
/* セカンドナビ
------------------------------------------*/
.hearing-sec-nav{
	background: #f7f7f7;
	border-top: 1px solid #dfdfdf;
	height: 63px;
	.hearing-sec-nav-area{
		text-align: center;
		.hearing-sec-nav-list{
			display: inline-block;
			font-size: 14px;
		  padding-left: 24px;
		  margin-right: 41px;
		  background: url(/img/common/icon_circle_01_bottom.png) no-repeat left 1px;
		  margin-top: 23px;
		  &.last{
		  	margin-right: 0;
		  }
			a{
				color: #4e3c93;
				padding-bottom: 20px;
				&:hover{
					border-bottom: 3px solid #01c1b8;
					text-decoration: none;
				}
			}
			&.active a{
				border-bottom: 3px solid #01c1b8;
			}
		}
	}
}

.guarantee-lead{
	height: 49px;
	p{
		line-height: 49px;
		font-size: 14px;
		color: #333;
	}
	&.guarantee-lead-02{
		p{
			text-align: center;
			a{
				display: inline-block;
				color: #4e3c93;
				background: url(/img/common/icon_circle_01_left.png) no-repeat left 15px;
				padding-left: 23px;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}

/* サードナビ
------------------------------------------*/
.hearing-third-nav{
	background: #f7f7f7;
	height: 63px;
	.hearing-third-nav-area{
		margin-left: 170px;
		.hearing-third-nav-list{
			float: left;
			font-size: 14px;
		  padding-left: 24px;
		  margin-right: 84px;
		  background: url(/img/common/icon_circle_01_bottom.png) no-repeat left 1px;
		  margin-top: 23px;
		  &.last{
		  	margin-right: 0;
		  }
			a{
				color: #4e3c93;
				padding-bottom: 20px;
				&:hover{
					border-bottom: 3px solid #01c1b8;
					text-decoration: none;
				}
			}
			&.active a{
				border-bottom: 3px solid #01c1b8;
			}
		}
	}
}

/* メーカーリスト
------------------------------------------*/
.hearing-maker{
	margin-bottom: 28px;
	.hearing-maker-list{
		background: #fff;
		padding: 30px 50px;
		margin-bottom: 40px;
		&.last{
			margin-bottom: 0;
		}
	}
	.hearing-maker-title{
		font-weight: bold;
		font-size: 24px;
		font-family: $font-mincho;
		color: #4e3c93;
		line-height: 1;
		padding-bottom: 15px;
		background: url(/img/hearing_aid/line_guarantee_01.gif) left bottom no-repeat;
		margin-bottom: 18px;
	}
	.deatil-inner{
		width: 870px;
		margin: 0 auto;
	}
	.hearing-maker-subhead{
		font-size: 16px;
		font-weight: bold;
		color: #333;
		line-height: 1;
		margin-bottom: 16px;
	}
}

/* テーブル
------------------------------------------*/
.table-guarantee{
	margin-bottom: 38px;
	.table-guarantee-track{
		.table-guarantee-head{
			padding: 14px;
			background: #bbb1c1;
			font-size: 16px;
			color: #fff;
			font-weight: bold;
			line-height: 1.37;
			vertical-align: middle;
			text-align: center;
			border-bottom: 1px solid #fff;
			width: 122px;
		}
		.table-guarantee-desc{
			padding: 14px 20px;
			font-size: 14px;
			line-height: 1.85;
			vertical-align: middle;
			background: #fafafa;
			border-bottom: 1px solid #c9c9c9;
			&.cell-2{
				width: 320px;
				border-right: 1px solid #c9c9c9;
			}
			&.last{
				border-right: none;
			}
		}
		&.last{
			.table-guarantee-head,
			.table-guarantee-desc{
				border-bottom: none;
			}
		}
	}
	&.type-row{
		.table-guarantee-track{
			.table-guarantee-head{
				border-bottom: none;
				border-right: 1px solid #fff;
				&.last{
					border-right: none;
				}
			}
			.table-guarantee-desc{
				border-right: 1px solid #c9c9c9;
				&.last{
					border-right: none;
				}
			}
		}
	}
	&.cell-3{
		.table-guarantee-track{
			.table-guarantee-head{
				width: 261px;
			}
			.table-guarantee-desc{
			}
		}
	}
}
