.glasses-children-guarantee {
	.main-visual {
		background: #fff265 url(/img/glasses/children/guarantee/bg_main_01.png) no-repeat center top;
		p {
			font-size: fz(20);
			padding-top: 53px;
			margin-top: 0;
			&.desc{
				margin-top: 6px;
			}
		}
		h1 {
			margin-top: 5px;
			font-size: fz(40);
		}
	}
	.main-conents{
		.contents01-inner{
			overflow: hidden;
		}
		.contents01-box{
			height: 132px;
			border: 6px solid #ef4492;
			background: #fff;
			position: relative;
			.num{
				border-radius: 50%;
				@include mincho-s(24);
				@include vertical-align-middle(42px);
				width: 42px;
				text-align: center;
				border: 6px solid #ef4492;
				background: #fff;
				position: absolute;
				top: -17px;
				left: -17px;
				z-index: 10;
				color: #ef4492;
			}
			.title{
				text-align: center;
				background: #ef4492;
				position: relative;
				span{
					padding-left: 71px;
					color: #fff;
					@include vertical-align-middle(55px);
					@include mincho-s(28);
					display: inline-block;
				}
				&:after{
				  content: "";
				  position: absolute;
				  bottom: -10px; left: 50%;
				  margin-left: -10px;
				  display: block;
				  width: 0px;
				  height: 0px;
				  border-style: solid;
				  border-width: 10px 10px 0 10px;
				  border-color: #ef4492 transparent transparent transparent;
				}
			}
			.desc{
				text-align: center;
				@include vertical-align-middle(77px);
				font-size: 28px;
				color: #ef4492;
				font-weight: bold;
			}
		}
		.clms{
			width: 740px;
			margin: 0 auto 17px;
		}

		.box-01{
			width: 728px;
			margin: 50px auto 30px;
			.title{
				span{
					background: url(/img/glasses/children/guarantee/icon_01.png) left 12px no-repeat;
				}
			}
			.desc{
				background: url(/img/glasses/children/guarantee/bg_arrow.png) 705px 54px no-repeat;
			}
		}
		.box-02{
			width: 348px;
			float: left;
			margin: 0 auto;
			.title{
				span{
					background: url(/img/glasses/children/guarantee/icon_02.png) left 12px no-repeat;
				}
			}
			.desc{
				background: url(/img/glasses/children/guarantee/bg_arrow.png) 325px 54px no-repeat;
				position: relative;
				&:after{
					content: '※1';
					font-size: 11px;
					position: absolute;
					top: -17px;
					right: 65px;
					color: #333;
				}
			}
		}
		.box-03{
			width: 348px;
			float: right;
			margin: 0 auto;
			.title{
				span{
					background: url(/img/glasses/children/guarantee/icon_03.png) left 12px no-repeat;
				}
			}
			.desc{
				background: url(/img/glasses/children/guarantee/bg_arrow.png) 325px 54px no-repeat;
				position: relative;
				&:after{
					content: '※1';
					font-size: 11px;
					position: absolute;
					top: -17px;
					right: 65px;
					color: #333;
				}
			}
		}
		.attr{
			width: 740px;
			margin: 0 auto 46px;
			dt{
				display: inline-block;
				margin-right: 4px;
				vertical-align: top;
			}
			dd{
				display: inline-block;
				width: 701px;
			}
		}
		.contents02{
			.inner{
				width: 740px;
				height: 284px;
				background: url(/img/glasses/children/guarantee/bg_img.png) 495px top no-repeat;
				position: relative;
				.contents03-title{
					@include mincho-murasaki-s(26px);
					line-height: 35px;
					margin-bottom: 13px;
				}
				.contents03-desc{
					font-size: 14px;
					line-height: 24px;
					margin-bottom: 32px;
				}
				.contents03-txt{
					@include mincho-s(40px);
					background: url(/img/glasses/children/guarantee/bg_line.png) left 41px repeat-x;
					display: inline-block;
					margin-left: 176px;
					color: #ef4492;
				}
				.contents03-arrow{
					position: absolute;
					bottom: -27px;
					left: 0;
					right: 0;
					width: 93px;
					margin: 0 auto;
				}
			}
		}
		.contents03.bg-pattern-06{
			padding: 0;
			.contents03-bg-top{
				background: url(/img/glasses/children/guarantee/bg_jaggy.png) left top repeat-x;
				padding: 50px 0;
			}
		}
	}
	.contents03-box{
		border: 3px solid #ef4492;
		width: 948px;
		margin: 0 auto 20px;
		background: #ef4492;
		display: table;
		.contents03-box-left{
			width: 275px;
			position: relative;
			background: #ef4492;
			display: table-cell;
			font-size: 13px;
			vertical-align: middle;
			.num{
				border-radius: 50%;
				@include mincho-s(24px);
				@include vertical-align-middle(42px);
				width: 42px;
				text-align: center;
				border: 6px solid #ef4492;
				background: #fff;
				position: absolute;
				top: -17px;
				left: -17px;
				z-index: 10;
				color: #ef4492;
			}
			.title{
				text-align: center;
				position: relative;
				span{
					color: #fff;
					@include mincho-s(22px);
					display: inline-block;
				}
			}
			.desc{
				text-align: center;
				@include mincho-s(22px);
				color: #fff;
			}
		}
		.contents03-box-right{
			width: 640px;
			display: table-cell;
			background: #fff;
			padding: 20px 0 20px 30px;
			.txt-01{
				@include mincho-s(18px);
			}
			.txt-02{
				@include mincho-s(22px);
				color: #ef4492;
				line-height: 0.9;
				margin-bottom: 7px;
				span{
					font-size: 30px;
				}
			}
			.txt-03{
				font-size: 14px;
			}
			.txt-04{
				font-size: 12px;
			}
		}
		&.contents03-box-01{
			.contents03-box-left{
				.title{
					span{
						padding-left: 68px;
						background: url(/img/glasses/children/guarantee/icon_small_01.png) left 3px no-repeat;
					}
				}
			}
		}
		&.contents03-box-02{
			.contents03-box-left{
				.title{
					span{
						padding-left: 62px;
						background: url(/img/glasses/children/guarantee/icon_small_02.png) left 9px no-repeat;
					}
				}
			}
		}
		&.contents03-box-03{
			.contents03-box-left{
				.title{
					span{
						padding-left: 58px;
						background: url(/img/glasses/children/guarantee/icon_small_03.png) left 2px no-repeat;
					}
				}
			}
		}
	}
	.contents03-box-04{
		width: 954px;
		box-sizing: border-box;
		border: 1px solid #ef4492;
		padding: 10px;
		background: #fff;
		margin: 0 auto;
		overflow: hidden;
		.contents03-box-04-box{
			border: 3px solid #ef4492;
			height: 99px;
			margin-bottom: 17px;
			.contents03-box-04-box-left,
			.contents03-box-04-box-right{
				display: table-cell;
				vertical-align: middle;
				height: 99px;
			}
			.contents03-box-04-box-left{
				width: 263px;
				border-right: 1px solid #ef4492;
				.title{
					@include mincho-s(22px);
					color: #ef4492;
					text-align: center;
				}
			}
			.contents03-box-04-box-right{
				padding-left: 30px;
				.txt-01{
					@include mincho-s(18px);
				}
				.txt-02{
					@include mincho-s(22px);
					color: #ef4492;
				}
				.txt-04{
					font-size: 12px;
				}
			}
		}
		.contents03-box-04-txt{
			width: 875px;
			margin: 0 auto;
			font-size: 13px;
			line-height: 1.7;
			padding-left: 1em;
			text-indent: -1em;
		}
	}
}
