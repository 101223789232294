.hearing-aid-comparison {
    .main-visual-sub {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dfdfdf;
    }
    .inner {
        padding-top: 40px;
    }
    table {
        border: 2px solid #e9e9e9;
        width: 100%;
        margin-bottom: 115px;
        background: #fff;
    }
    th, td {
        border: 1px solid #cac3e0;
    }
    thead {
        th {
            padding-top: 5px;
            text-align: center;
        }
    }
    tbody {
        th {
            vertical-align: middle;
            text-align: center;
        }
        td {
            width: 210px;
            text-align: center;
            vertical-align: middle;
            padding: 24px 0;

        }
        p {
            padding: 0 10px;
            text-align: left;
        }
        ul {
            text-align: left;
            padding: 0 10px;
        }
        li {
            padding: 0 0 0 14px;
            background: url(/img/hearing_aid/comparison/icon_dot_01.png) no-repeat 4px 6px;
        }
        dl {
            background: #f5f4f6;
            padding: 0 5px 5px;
            margin-bottom: -24px;
        }
        dt {
            text-align: center;
            padding: 10px 0;
            @include mincho-murasaki-s(14);
        }
        dd {
            text-align: center;
            width: 100%;
            padding: 5px 0;
            background: #fff;
            margin-bottom: 5px;
        }
    }
}