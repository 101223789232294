@charset "UTF-8";
/* セカンドナビ
------------------------------------------*/
.sec-nav{
  background: #f7f7f7;
  border-top: 1px solid #dfdfdf;
  height: 63px;
  .sec-nav-area{
    margin-left: 10px;
    .sec-nav-list{
      float: left;
      font-size: 14px;
      padding-left: 24px;
      margin-right: 41px;
      background: url(/img/common/icon_circle_01_bottom.png) no-repeat left 1px;
      margin-top: 23px;
      &:last-child {
        margin-right: 0;
      }
      a{
        color: #4e3c93;
        padding-bottom: 20px;
        &:hover{
          border-bottom: 3px solid #01c1b8;
          text-decoration: none;
        }
      }
      &.active a{
        border-bottom: 3px solid #01c1b8;
      }
    }
  }
  &.contact-lens-shop{
    .sec-nav-area{
      margin-left: 57px;
    }
    .sec-nav-list{
      margin-right: 53px;
    }
  }
}

.shop-section-01{
  padding-top: 17px;
}
.title-02{
  &.contact-lens-shop{
    padding-bottom: 8px;
    margin-bottom: 23px;
  }
}

/* 取扱店一覧
------------------------------------------*/
.type-lists{
  .type-list{
    text-align: right;
    .type-list-name{
      background: url(/img/contact_lens/icn_my_small.gif) left top no-repeat;
      padding-left: 30px;
      font-size: 14px;
      display: inline-block;
    }
    .type-list-link{
      color: $c-murasaki;
      display: block;
      background: url(/img/common/icon_external_link_01.png) right top no-repeat;
      padding-right: 21px;
    }
  }
}


/* ラインリスト
------------------------------------------*/
$w_01: 165px;
$w_02: 452px;
$w_03: 235px;
$w_04: 104px;


/* ヘッド */
.line-list-head{
  border-bottom: 1px solid $c-murasaki;
  padding-bottom: 5px;
  .line-list-head-title{
    line-height: 1;
    font-size: 14px;
    font-weight: bold;
    color: $c-murasaki;
    float: left;
    &.line-list-head-title-01{
      width: $w_01;
      margin-left: 2px;
    }
    &.line-list-head-title-02{
      width: $w_02;
    }
    &.line-list-head-title-03{
      width: $w_03;
    }
  }
}


/* ラインリスト
------------------------------------------*/
.line-lists{
  .line-list{
    border-bottom: 1px solid #d9d9d9;
    &.last{
      border-bottom: none;
    }
    &:hover{
      background: #f5f5f5;
    }
    a{
      display: block;
      &:hover{
        text-decoration: none;
        background: url(/img/common/bg_line_arrow.gif) right top repeat-y;
      }
    }
    .arrow{
      background: url(/img/common/line_arrow_off.png) 966px 50% no-repeat;
      &:hover{
        background: url(/img/common/line_arrow_on.png) 966px 50% no-repeat;
      }
    }
    .line-list-item{
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      height: 60px;
      line-height: 1.3;
      &.line-list-item-01{
        width: $w_01 - 12px;
        font-weight: bold;
        padding-left: 12px;

      }
      &.line-list-item-02{
        width: $w_02;
      }
      &.line-list-item-03{
        width: $w_03;
        font-weight: bold;
        font-size: 26px;
      }
      &.line-list-item-04{
        width: $w_04;
      }
    }
  }
}
