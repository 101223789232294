@charset "utf-8";
/*------------------------------------*\
		Base
\*------------------------------------*/
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,object,iframe,pre,code,p,blockquote,form,fieldset,legend,table,th,td,caption,tbody,tfoot,thead,article,aside,figure,footer,header,menu,nav,section,audio,video,canvas,main {
	margin: 0;
	padding: 0;
}
article,aside,figure,figcaption,footer,h1,h2,h3,h4,h5,h6,header,menu,nav,section,main {
	display: block;
}
html {
	overflow-y: scroll;
}
html,body {
	width: 100%;
	height: 100%;
}
body {
	color: $font-color;
	background: $bg-color;
	font-family: $font;
	font-size: 13px;
	*font-size: 82%;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	word-wrap: break-word;
}
a {
	&:link {
		color: $font-color;
		text-decoration: none;
	}
	&:visited {
		color: $font-color;
		text-decoration: none;
	}
	&:hover {
		color: $font-color;
		text-decoration: underline;
	}
	&:active {
		color: $font-color;
		text-decoration: none;
	}
}
h1,h2,h3,h4,h5,h6 {
	font-size: 100%;
}
p {
	line-height: 1.5;
}
li {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
	font-family: inherit;
}
caption,th,td {
	text-align: left;
	vertical-align: top;
}
fieldset {
	border: 0;
}
input,textarea,select,label {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 100%;
	font-family: inherit;
	vertical-align: middle;
}
label {
	cursor: pointer;
}
textarea {
	overflow: auto;
}
legend {
	display: none;
}
img {
	border: 0;
	vertical-align: middle;
}
object {
	vertical-align: middle;
	outline: 0;
}
em {
	font-style: italic;
}
strong {
	font-weight: 700;
}
small {
	font-size: 100%;
}
abbr,acronym {
	border: 0;
	font-variant: normal;
}
q:before,q:after {
	content:''
}
address,caption,cite,code,dfn,var {
	font-weight: 400;
	font-style:normal;
}
code,pre {
	font-family: monospace;
}
sup {
	vertical-align: text-top;
}
sub {
	vertical-align: text-bottom;
}
hr {
	height:2px;
	background-color: none;
	border:1px dotted #333333;
}
input[type=text]:hover, textarea {
	cursor: text;
}
input[type=text]:focus, textarea {
	outline: none;
}