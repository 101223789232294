@charset "UTF-8";
.bg-contents1 {
	background: url(/img/company/top/bg_section01.png) left top repeat;
}
.bg-contents2 {
	background: #f3f3f3;
}
.bg-contents3 {
	background:	url(/img/company/top/bg_section02.png) left top repeat;
}
.article-list__title {
	font-size: fz(24);
	font-weight: bold;
	color: $c-murasaki;
	border-bottom: 3px solid #5a3e80;
	padding-bottom: 10px;
	&:after {
		content: "";
		display: block;
		width: 90%;
		float: right;
		clear: right;
		border-bottom: 3px solid #bcb1c1;
		margin-bottom: -3px;
		padding-bottom: 10px;
	}
}
.company {
	.main-header {
		.inner {
			position: relative;
		}
		.page-nav {
			position: absolute;
			top: 0;
			right: 0;
			li {
				margin-left: 40px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}
.company-top {
	.main-visual {
		background-size: 100%;
		background: url(/img/company/top/bg_main_visual.png) center center no-repeat;
		padding: 60px 0;
	}
	.main-visual__title {
		text-align: left;
	}
	.main-visual__text {
		font-size: fz(18);
		color: #fff;
		font-family: $font-mincho;
		line-height: 2;
	}
	.main-visual__btn {
		margin-top: 45px;
	}
	.section01 {
		background: url(/img/company/top/bg_section01.png) left top repeat;
		padding: 45px 0;
		border-bottom: 1px solid #e7e7e7;
	}
	.section01__text {
		text-align: center;
		margin: 45px 0 0;
	}
	.section01__title {
		text-align: center;
		font-size: fz(46);
		font-family: $font-mincho;
		color: $c-murasaki;
	}
	.section01__btn, .section02__btn {
		width: 375px;
		margin: 45px auto 0;
	}
	.section01__list {
		img {
			width: 100%;
			border-bottom: 1px solid #dcdcdc;
		}
	}
	.section02 {
		background: url(/img/company/top/bg_section02.png) left top repeat;
		padding: 45px 0;
	}
	.section02__title {
		width: 100%;
		color: $c-murasaki;
		font-size: 37px;
		font-weight: bold;
		text-align: center;
	}
	.row.legacy-clearfix {
		overflow: visible;
	}
	.news-list {
	}
	.news-list-item-inner {
		display: block;
		height: 100%;
		position: relative;
		background: #fff;
		border: 2px solid #dfdfdf;
		padding: 20px 10px;
	}
	.icon-category {
		position: absolute;
		top: -28px;
		left: -28.5px;
	}
	.news-header {
		padding-left: 70px;
		margin-bottom: 10px;
	}
	.news-title {
		color: $c-murasaki;
//		color: #c7e1bd;
	}
	.news-main {
		.article__item-imgarea {
			min-height: 200px;
		}
	}
	.news-main-img {
		max-width: 100%;
		height: auto;
	}
	.news-note {
		margin-top: 10px;
	}
	.section02__bnr {
		background: url(/img/company/top/bg_section02_bnr.jpg) center top no-repeat;
		background-size: 100%;
		padding: 70px 0;
		margin: 45px 0;
	}
	.section02__bnr-title {
		width: 100%;
		color: $c-murasaki;
		font-size: 37px;
		font-family: $font-mincho;
		letter-spacing: 0.3em;
		font-weight: bold;
	}
	.section02__bnr-text, .section02__bnr-btn {
		display: inline-block;
		margin-top: 15px;
	}
	.section02__bnr-text {
		font-size: fz(18);
		font-family: $font-mincho;
		font-weight: bold;
	}
	.section03__ttl {
		background: url(/img/company/top/bg_section03_ttl.png) center center no-repeat;
		background-size: 100%;
		img {
			width: 273px;
			display: block;
			margin: 0 auto;
			padding: 15px 0;
		}
	}
	.section03__text {
		margin: 25px 0;
		text-align: center;
	}
	.section01__list,
	.section03__list {
		margin: 0 0 45px;
		overflow: hidden;
		li {
			margin-right: 47px;
			width: 295px;
			float: left;
			text-align: center;
			&:last-child {
				margin-right: 0;
			}
			p {
				background: url(/img/company/top/bg_list_01.png) no-repeat center bottom;
				height: 28px;
				line-height: 28px;
				color: $c-murasaki;
				font-size: fz(20);
				font-family: $font-mincho;
				margin-bottom: 8px;
			}
			a {
				border: 1px solid #dfdfdf;
				background: #fafafa;
				display: block;
				div {
					height: 35px;
					line-height: 35px;
					font-size: fz(17);
					font-family: $font-mincho;
					font-weight: bold;
					text-align: center;
					span {
						background: url(/img/common/icon_circle_01.png) no-repeat left center;
						padding-left: 23px;
					}
				}
			}
		}
	}
	.section01__list {
		margin: 40px 0;
		img {
			max-width: 100%;
			height: auto;
			border-bottom: 1px solid #dcdcdc;
		}
	}
}

.company-remarks {
	.contents-wrapper {
		width: 850px;
		margin: 0 auto 160px;
	}
	.title-01 {
		position: relative;
		font-size: fz(25);
		margin-top: 125px;
		color: #01c1b8;
		&:after {
			display: inline-block;
			content: "";
			width: 416px;
			height: 1px;
			position: absolute;
			top: 15px;
			border-bottom: 1px solid #c9c9c9;
		}
	}
	.contents__text {
		font-size: fz(15);
	}
}

.company-philosophy {
	.pc-mode{
		display: block;
	}
	.sp-mode{
		display: none;
	}
	
	.contents-wrapper {
		width: 851px;
		margin: 0 auto;
	}
	.ttl-01 {
		margin: 50px 0 0;
	}
	.list-01 {
		width: 500px;
		margin: 50px auto;
		li {
			font-size: fz(18);
			font-family: $font-mincho;
			text-align: left;
		}
	}
	.text-box-01 {
		width: 100%;
		padding-top: 15px;
		margin: 50px 0;
		background: url(/img/company/philosophy/bg_text_box_01_top.png) center top no-repeat;
		.text-box-01-inner {
			padding: 0 15px;
			background: url(/img/company/philosophy/bg_text_box_01_bottom.png) center bottom no-repeat;
		}
		.logo {
			text-align: center;
			margin-top: 70px;
		}
		.ttl-02 {
			font-size: fz(24);
			font-family: $font-mincho;
			color: $c-murasaki;
			margin-top: 35px;
			border-bottom: 1px solid #dfdfdf;
		}
		p {
			margin: 20px 0 45px;
		}
	}
	.text-box-02 {
		height: 309px;
		box-sizing: border-box;

		border-top: 1px solid #dfdfdf;
		padding: 94px 0 0;
		width: 100%;
		background: url(/img/company/philosophy/bg_text_box_02.png) center top no-repeat;
		.inner {
			width: 842px;
		}
		p {
			text-align: left;
			font-size: fz(15);
			font-family: $font-mincho;
			padding-top: 20px;
			line-height: 1.7;
		}
	}
	.bnr {
		margin: 60px 0;
	}
}

.company-history {
		.history-line__body {
			position: relative;
			width: 915px;
			margin: 45px auto;
		}
		.history-line__btn1 {
			position: absolute;
			top: 151px;
			right: 20px;
		}
		.history-line__btn2 {
			position: absolute;
			top: 170px;
			right: 100px;
		}
		.history-line__btn3 {
			position: absolute;
			bottom: 0;
			right: 0;
		}
		.history-line__article {
			width: 840px;
			margin: 0 auto;
		}
		.history-line__article-item {
			color: #4e3c93;
			border-top: 1px solid #4e3c93;
			padding: 25px 20px 0 0;
			margin-top: 25px;
			&:nth-child(1) {
				border-top: none;
				margin-top: 0;
				padding: 0 20px 0 0;
			}
		}
		.history-line__article-item2 {
			border-top: 1px solid #bbb1c1;
			padding-top: 25px;
			margin-top: 25px;
			&:nth-child(2) {
				border-top: none;
				margin-top: 0;
				padding-top: 0;
			}
		}

	#lean_overlay{
		position: fixed; z-index:100;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: #000;
		display: none;
	}
	#modal-contents {
		width: 930px;
		height: 644px;
		#btn-close-wrapper {
			text-align: right;
		}
		#modal-contents-inner {
			width: 930px;
			height: 618px;
			background: url(/img/company/history/bg_modal.png) no-repeat center center;
		}
		#section-01 {
			overflow: hidden;
			.left {
				padding: 77px 0 0 77px;
			}
			.right {
				width: 365px;
				padding: 134px 69px 0 0;
			}
		}
		#section-02 {
			overflow: hidden;
			display: none;
			.left {
				width: 365px;
				padding: 134px 0 0 69px;
			}
			.right {
				padding: 59px 69px 0 0;
			}
		}
		.section-title {
			text-align: center;
			color: $c-murasaki;
			font-family: $font-mincho;
			font-size: fz(30);
			border-bottom: 2px solid $c-murasaki;
			padding-bottom: 8px;
			margin-bottom: 35px;
		}
		.section-contents {
			font-size: fz(16);
			font-family: $font-mincho;
			border-bottom: 2px solid $c-murasaki;
			padding-bottom: 35px;
			margin-bottom: 20px;
		}
		.btn {
			margin: 0 auto;
			width: 215px;
		}
	}
}

.company-profile {
	.contents {
		margin: 20px 0 45px;
	}
	.contents__list {
		&.contents__list--is-border {
			border-top: 1px solid #d9d9d9;
			margin: 25px 0 0;
		}
	}
	.contents__list-item {
		border-top: 1px solid #d9d9d9;
		padding: 25px 0 0 0;
		margin: 25px 0 0 0;
		&.contents__list-item--is-no-border {
			border-top: none;
			padding: 25px 0 0;
			margin: 0;
		}
	}
	.contents__list-item-name {
		font-weight: bold;
		color: #4e3c93;
	}
	.contents__list-item-detail-img,
	.contents__list-item-detail-text {
		margin: 15px 0 0;
	}
}

.company-csr {
	background: #f7f7f7;
	padding-bottom: 100px;
	.main-visual {
		background: url(/img/company/top/bg_section02_bnr.jpg) center top no-repeat;
		background-size: 100%;
		padding: 70px 0;
		margin: 0 0 45px;
	}
	.main-visual__title {
		font-size: fz(28);
		font-weight: bold;
		font-family: $font-mincho;
		color: $c-murasaki;
		text-align: left;
	}
	.main-visual__text {
		text-align: left;
		margin: 20px 0 0;
		span {
				color: $c-murasaki;
				font-weight: bold;
		}
	}
	.contents {
		background: #fff;
		padding: 0 40px;
		margin-bottom: 50px;
	}
	.contents-inner {
		padding-bottom: 40px;
	}
	.contents__list {
		padding-top: 50px;
		.block {
			margin-top: 20px;
		}
	}
	.contents__list-title {
		font-size: fz(24);
		font-weight: bold;
		color: $c-murasaki;
		border-bottom: 3px solid #5a3e80;
		padding-bottom: 10px;
		&:after {
			content: "";
			display: block;
			width: 90%;
			float: right;
			clear: right;
			border-bottom: 3px solid #bcb1c1;
			margin-bottom: -3px;
			padding-bottom: 10px;
		}
	}
	.contents__list-text {
		.contents__list-text-paragraph {
			margin-top: 15px;
			&:first-child {
				margin-top: 0;
			}
			&.contents__list-text-paragraph--right {
				float: right;
				clear: right;
			}
		}
		.contents__list-text-paragraph-link {
			color: $c-murasaki;
		}
	}
}

.company-gallery {
	.main-visual {
		background: url(/img/company/gallery/top/bg_main_visual.jpg) center top no-repeat;
		padding: 108px 0;
	}
	.main-visual__description {
		width: 476px;
		padding: 33px 0 0;
		margin: 0 0 0 50px;
		background: url(/img/company/gallery/top/bg_main_visual_description.png) center top no-repeat;
	}
	.main-visual__description-inner {
		background: url(/img/company/gallery/top/bg_main_visual_description02.png) center bottom no-repeat;
		padding: 0 25px 15px;
	}
	.main-visual__description-title {
		color: $c-murasaki;
		font-weight: bold;
		font-family: $font-mincho;
		font-size: fz(30);
		&:after {
			display: block;
			content: "";
			height: 15px;
			background: url(/img/company/gallery/top/bg_main_visual_description_title.png) center bottom no-repeat;;
		}
	}
	.main-visual__description-text {
		font-size: fz(12);
		margin-top: 15px;
		text-align: left;
	}
	.list {
		padding: 45px 0;
	}
	.list__img, .list__note, .list__link {
		border-right: 1px solid #dddddd;
		border-left: 1px solid #dddddd;
	}
	.list__title {
		text-align: center;
		font-size: fz(20);
		font-weight: bold;
		font-family: $font-mincho;
		color: $c-murasaki;
		border-bottom: 3px solid #4e3c93;
		padding-bottom: 10px;
	}
	.list__img {
		img {
			width: 100%;
		}
	}
	.list__note {
		padding: 15px;
		background: #fff;
		border-bottom: 1px solid #dddddd;
	}
	.list__link {
		padding: 15px;
		text-align: center;
		background: #fafafa;
		border-bottom: 1px solid #dddddd;
	}
	.list__link-anc {
		display: inline-block;
		font-weight: bold;
	}
	.bg-contents2 {
	}
	.contents2 {
		width: 880px;
		margin: 0 auto;
		padding: 45px 0;
	}
	.contents2__title {
		font-size: fz(24);
		font-weight: bold;
		color: $c-murasaki;
		border-bottom: 3px solid #5a3e80;
		padding-bottom: 10px;
		&:after {
			content: "";
			display: block;
			width: 90%;
			float: right;
			clear: right;
			border-bottom: 3px solid #bcb1c1;
			margin-bottom: -3px;
			padding-bottom: 10px;
		}
	}
	.contents2__text {
		margin-top: 20px;
	}
	.contents2__list {
		background: #fff;
		padding-bottom: 20px;
	}
	.contents2__list--2 {
		background: #e4e3e5;
	}
	.contents2__list-inner {
		padding: 0 30px;
	}
	.contents2__list-title {
		background: #4e3c93;
		font-size: fz(18);
		font-weight: bold;
		font-family: $font-mincho;
		text-align: center;
		color: #fff;
		height: 40px;
		line-height: 40px;
		margin-bottom: 8px;
		&:after {
			display: block;
			content: '';
			background: url(/img/common/bg_flagship_shop_04.png) no-repeat center bottom;
			height: 6px;
		}
	}
	.contents2__list-title--2 {
		font-size: fz(18);
		font-weight: bold;
		font-family: $font-mincho;
		text-align: center;
		color: #4e3c93;
		height: 40px;
		line-height: 40px;
		margin-bottom: 8px;
	}
	.contents2__list-text {
		text-align: center;
		margin: 10px 0;
	}
	.contents2__list-text--2 {
		height: 190px;
		overflow-y: scroll;
		background: #fff;
		padding: 5px 15px;
	}
	.contents2__list-tel {
		background: url(/img/company/gallery/top/bg_contents_list_tel.png) center top no-repeat;
		padding-top: 4px;
	}
	.contents2__list-tel-inner {
		background: url(/img/company/gallery/top/bg_contents_list_tel.png) center bottom no-repeat;
		padding-bottom: 4px;
	}
	.contents2__list-tel-inner2 {
		background: url(/img/company/gallery/top/bg_contents_list_tel2.png) center top repeat-y;
		padding: 18px 50px;
		dt, dd {
			font-size: fz(17);
			font-weight: bold;
			margin-bottom: 10px;
		}
		dt {
			float: left;
			clear: left;
			width: 100px;
		}
		dd {
			color: #4e3c93;
		}
	}
}

.company-gallery-schedule1 {
	.main-visual {
		background: url(/img/company/gallery/schedule_01/bg_main_visual.png) center top no-repeat;
		border-bottom: 1px solid #dfdfdf;
	}
	.main-visual__title {
		font-size: fz(26);
		font-family: $font-mincho;
		font-weight: bold;
		color: $c-murasaki;
		padding: 80px 0;
		text-align: left;
	}
	.article-list {
		margin-top: 110px;
	}
	.article-list-name {
		width: 350px;
		overflow: hidden;
		*zoom: 1;
		margin-bottom: 25px;
		li {
			float: left;
			margin-right: 45px;
			margin-bottom: 5px;
		}
	}
	.article-list-wrap {
		width: 880px;
		margin: 0 auto 240px;
	}
	.article-list__note {
		text-align: right;
		font-size: fz(15);
		font-weight: bold;
		margin-top: 10px;
	}
	.article-list__img {
		img {
			margin-top: 20px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	.article-list__text {
		margin-bottom: 50px;
	}
	.article-list__profile-outer {
		width: 588px;
		background: url(/img/company/gallery/schedule_01/bg_profile_border_01.png) center top no-repeat;
		padding-top: 5px;
	}
	.article-list__profile {
		padding-bottom: 5px;
		background: url(/img/company/gallery/schedule_01/bg_profile_border_01.png) center bottom no-repeat;
	}
	.article-list__profile-inner {
		padding: 25px 30px;
		background: url(/img/company/gallery/schedule_01/bg_profile_border_02.png) center top repeat-y;
	}
	.article-list__profile-title {
		font-size: fz(18);
		font-weight: bold;
		color: $c-murasaki;
		margin-bottom: 20px;
	}
	.article-list__profile-history {
		li {
			text-indent: -1.3em;
			margin-left: 1.3em;
			margin-bottom: 10px;
		}
	}
}

.company-news {
	.popup {
		background: url(/img/common/bg_arrow_01.png) center bottom no-repeat;
		padding-bottom: 14px;
		margin: 25px 0;
	}
	.popup-inner {
		background: #fff0f0;
		padding: 30px;
	}
	.popup__title {
		font-weight: bold;
		color: #ff6e6e;
		margin-bottom: 10px;
	}
	.menu {
		background: #fafafa;
		padding: 28px 0;
	}
	.menu-inner {
		text-align: center;
		padding: 0 30px;
	}
	.menu__list {
		display: inline-block;
		color: #5a3e80;
		font-weight: bold;
		margin-right: 63px;
	}
	.article-outer {
		background: url(/img/company/top/bg_section02.png) left top repeat;
		padding-top: 35px;
	}
	.article__item {
		position: relative;
		padding: 20px 20px 20px 82px;
		margin-top: 35px;
		border: 2px solid #dfdfdf;
		background: #fff;
	}
	.article__item-icon {
		position: absolute;
		top: -20px;
		left: -20px;
	}
	.article__item-textarea {
		float: left;
		width: 562px;
	}
	.article__item-date {
		font-size: fz(15);
		font-weight: bold;
	}
	.article__item-title {
		color: #01c1b9;
		font-size: fz(20);
		font-weight: bold;
		border-bottom: 1px solid #cbcbcb;
		margin-bottom: 10px;
		display: block;
	}
	.article__item-link {
		text-align: right;
		margin: 15px 30px 0 0;
		.btn-06 {
			display: inline-block;
			font-family: $font-mincho;
			padding-right: 10px;
		}
	}
	.article__item-link--right {
		text-align: left;
	}
	.article__item-link--center {
		text-align: center;
		margin: 62px 0 0;
		padding: 0 0 62px;
	}
	.article__item-list-title {
		color: $c-murasaki;
		font-weight: bold;
		margin-top: 30px;
	}
	.article__item-list {
		margin-top: 10px;
		li {
			background: url(/img/common/bg_list_01.png) left center no-repeat;
			padding-left: 13px;
			margin-top: 10px;
		}
	}
	.article__item-imgarea {
		float: right;
		width: 308px;
	}
	.article__item-img {
		margin-top: 20px;
		&:first-child {
			margin-top: 0;
		}
	}
	.paginate {
		text-align: center;
		margin: 38px 0 0;
		padding: 0 0 38px;
	}
	.paginate-prev,
	.paginate-next {
		display: inline-block;
	}
	.paginate-inner {
		display: inline-block;
	}
	.paginate-link {
		display: inline-block;
		width: 15px;
		height: 15px;
		line-height: 15px;
		border: 1px solid #bfbfbf;
		border-radius: 15px;
		padding: 7px;
		margin: -5px 35px 0 0;
		&:first-child {
			margin-left: 35px;
		}
	}
}
