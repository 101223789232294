@charset "UTF-8";
.area05 {
	.main-visual {
    height: 424px;
    background-image: url(/img/shop_list/area05/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
  .anc-01 {
    position: absolute;
    top: 120px;
    left: 380px;
  }
  .anc-02 {
    position: absolute;
    top: 205px;
    left: 335px;
  }
  .anc-03 {
    position: absolute;
    top: 260px;
    left: 320px;
  }
  .anc-04 {
    position: absolute;
    top: 315px;
    left: 290px;
  }
  .anc-05 {
    position: absolute;
    top: 355px;
    left: 240px;
  }
  .anc-06 {
    position: absolute;
    top: 290px;
    left: 170px;
  }
  .anc-07 {
    position: absolute;
    top: 235px;
    left: 198px;
  }
}
