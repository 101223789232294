@charset "utf-8";
.sunglasses-quad {
  background: #f7f7f7;
  .main-visual {
    .main-visual-img{
      height: auto;
      overflow: hidden;
      //width: 1280px;
      margin: 0 auto 30px;;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .main_lead{
    width: 873px;
    margin: 0 auto 20px;
  }
  .main_desc{
    font-weight: bold;
    font-size: 14px;
    width: 980px;
    margin: 0 auto 47px;
  }
  .lead{
    width: 980px;
    margin: 25px auto 20px;
    font-weight: normal;
  }
  .k-box-area{
  }
  .k-box{
    width: 980px;
    background: #fff;
    background-image: url(/img/sunglasses/quad/kakko_top.png),url(/img/sunglasses/quad/kakko_bottom.png);
    background-position: left top, left bottom;
    background-repeat: no-repeat,no-repeat;
    margin: 0 auto;
    padding: 30px 0;
    .k-box-title{
      width: 920px;
      margin: 0 auto;
    }
    .k-box-contentes{
      width: 920px;
      margin: 0 auto 30px;
    }
  }
  .price{
    @include mincho-murasaki-s(16px);
    text-align: right;
    width: 920px;
    margin: 0 auto;
    line-height: 1;
    .txt01{
      font-size: 44px;
      font-family: $font;
    }
  }
  .sec02{
    background: #fff;
    padding: 50px 0;
  }
  .sec-inner{
    width: 980px;
    margin: 0 auto;

  }
  .sec02-area{
    width: 980px;
    margin: 0 auto;
  }
  .bg-kagikakko{
    width: 920px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .sec02-inner{
    background-image: url(/img/sunglasses/quad/kakko_top.png),url(/img/sunglasses/quad/kakko_bottom.png);
    background-position: left top, left bottom;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
  }
  .sec02_ex{
    width: 918px;
    margin: 0 auto;
  }
  .underbar_title{
    @include mincho-murasaki-s(24px);
    font-family: $font-mincho;
    padding-bottom: 5px;
    background: url(/img/sunglasses/quad/line_bar.png) left bottom no-repeat;
    margin-bottom: 10px;
  }
  .white-inner{
    background: #fff;
    padding: 30px 0;
    margin-bottom: 20px;
    .txt-01{
      @include mincho-murasaki-s(24px);
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .block01{
    width: 817px;
    margin: 0 auto;
    .txt{
      font-size: 14px;
    }
  }
  .frame{
    width: 920px;
    box-sizing: border-box;
    border: 4px solid $c-murasaki;
    text-align: center;
    @include mincho-murasaki-s(18px);
    margin-bottom: 45px;
    padding: 15px 0;
  }
  .white-inner-02{
    padding: 0;
  }
  .col-inner{
    position: relative;
    p{
      position: absolute;
      width: 405px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 292px;
    }
  }
  .col-inner-02{
    p{
      top: 266px;
    }
  }
  .caution{
    font-size: 10px;
    float: right;
    text-indent: -1em;
    padding-left: 1em;
  }
  .frame-btn{
    clear: both;
    margin: 0 auto;
    @include frame-btn(504px,5px,$c-murasaki,57px,20px);
    a{
      margin: 0 auto;
      span{
        color: $c-murasaki;
        background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
      }
    }
  }
  .position{
    position: relative;
    p{
      position: absolute;
      top: 310px;
      left: 20px;
    }
  }
  .custom{
    font-size: 14px;
    width: 408px;
  }
  .quad-title-01 {
    @include mincho-murasaki-s(20px);
    background: url(/img/sunglasses/quad/bnr_01.png) no-repeat left bottom;
    padding-bottom:  10px;
    margin-bottom: 10px;
  }
}
