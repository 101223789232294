.quality-skill{
  .main-visual{
    border-bottom: 1px solid #dfdfdf;
  }
  .main-visual-inner{
    position: relative;
    .main-visual-img{
      position: absolute;
      right: 91px;
      top: 58px;
    }
    .main-visual-txt{
      position: absolute;
      width: 189px;
      @include vertical-align-middle(31px);
      @include mincho-s(17);
      text-align: center;
      background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
      color: #fff;
      top: 247px;
      right: 77px;
    }
  }
  .main-visual{
    background: url(/img/common/bg_main.png) center top no-repeat;
    height: 339px;
    .main-visual-title{
      font-size: 33px;
      line-height: 1.57;
      @include mincho-murasaki;
      padding-top: 123px;
      width: auto;
      padding-left: 19px;
      height: auto;
    }
  }

  /* sec01
  ---------------------------------- */
  .quality-lead-area{
    .inner{
      .quality-lead-title{
        font-size: 28px;
        @include mincho-murasaki;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: -13px;
        background: #fff;
        position: relative;
        z-index: 10;
        width: 459px;
        text-align: center;
      }
      .quality-lead{
        &.quality-lead-01{
          padding-top: 54px;
          border-top: 1px solid $c-murasaki;
        }
        span{
          border-bottom: 2px solid #c7e1bd;
          padding: 7px 0;
        }
      }
      .quality-lead-sub{
        padding-left: 24px;
        font-size: 14px;
        line-height: 1.7;
      }
    }
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 40px 0 25px 0;

    .main-contens-box{
      background: #fff;
      border: 3px solid #f2f2f2;
      padding: 20px 24px 14px;

      /* --- タイトル部分 --------------- */
      .main-contens-title-area{
        margin-bottom: 25px;
      }
      .main-contens-title-deep{
        border-left: 5px solid $c-murasaki;
        padding-left: 19px;
        p{
          @include vertical-align-middle(35px);
          @include mincho-murasaki;
          font-size: 38px;
          font-weight: bold;
        }
      }
      .main-contens-title-light{
        border-left: 5px solid #a78dc3;
        padding-left: 19px;
        padding-top: 8px;
      }
      .main-contens-title-02{
        font-size: 14px;
      }
    }
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
    }
    .main-contens-clms-left{
      width: 539px;
      float: left;
      .main-contens-btn{
        background: #fff;
        border: 4px solid $c-murasaki;
        font-family: $font-mincho;
        font-weight: bold;
        width: 252px;
        @include vertical-align-middle(33px);
        font-size: 16px;
        text-align: center;
        a{
          display: inline-block;
          color: $c-murasaki;
          padding-left: 22px;
          background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .main-contens-txt{
        padding-left: 19px;
      }
    }
    .main-contens-clms-right{
      width: 349px;
      float: right;
      position: relative;
      
      .main-contens-img{
        border: 3px solid $c-murasaki;
        background: #fff;
        padding: 6px;
        img{
          display: block;
          border: 2px solid $c-murasaki;
        }
      }
    }
  }
  .main-contens-box-02{
    .main-contens-clms-right{
      width: 244px;
    }
  }
	.main-contens-box-03{
		.main-contens-clms-left {
			width: 545px;
		}
		.main-contens-clms-right{
			width: 228px;
		}
	}
	.main-contens-box-04{
		.main-contens-clms-left {
			width: 539px - 55px;
		}
		.main-contens-clms-right{
			width: 399px;
		}
	}

  /* sec03
  ---------------------------------- */
  .sec-03{
    .inner{
      width: 980px;
      height: 210px;
      background: url(/img/quality/skill/bg_banner.jpg) left top no-repeat;
    }
    .banner-area{
      width: 662px;
      margin-left: 33px;
      overflow: hidden;
      .banner-title{
        text-align: center;
        color: #fff;
        margin-top: 35px;
        @include mincho-s(29);
        line-height: 1;
        margin-bottom: 13px;
      }
      .banner-lead{
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        color: #fff;
        margin-bottom: 17px;
      }
      .banner-btn{
        border: 4px solid #fff;
        margin: 0 auto;
        @include mincho-s(16);
        width: 290px;
        @include vertical-align-middle(32px);
        text-align: center;
        a{
          display: inline-block;
          color: #fff;
          padding-left: 22px;
          background: url(/img/quality/icon_circle_quality_banner.gif) left 8px no-repeat;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }

}