/* タイトル部分
------------------------------------------*/
.special-sec-01{
  padding-top: 45px;
  padding-bottom: 54px;
  border-top: 1px solid #dfdfdf;
  .inner{
    background: #fff;
    position: relative;
    padding: 46px 61px 40px;
  }
  .title{
    @include mincho-murasaki;
    font-size: 35px;
    line-height: 1;
    margin-bottom: 44px;
    background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
    padding-top: 31px;
  }
}

.special-sec-02 {
  .balloon-01 {
    margin-bottom: 30px;
  }
  .title {
    @include vertical-align-middle(78px);
  }
}
/* タイトル以下のレイアウト
------------------------------------------*/
.special-clms{
  .special-clms-left{
    float: left;
  }
  .special-clms-right{
    float: right;
    .special-clms-title{
      color: $c-murasaki;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 14px;
    }
    .special-clms-txt{
      line-height: 1.79;
      font-size: 14px;
    }
    .special-price-area{
      margin-top: 24px;
      border-top: 1px solid $c-murasaki;
      padding-top: 20px;
      text-align: right;
      .item-name{
        display: inline-block;
        @include mincho-murasaki;
        vertical-align: top;
        font-size: 14px;
        margin-top: 19px;
        font-weight: bold;
        line-height: 1.3;
      }
      .price-area{
        display: inline-block;
        color: $c-murasaki;
        line-height: 1.2;
        margin-left: 40px;
        vertical-align: top;
        .price{
          font-weight: bold;
          font-size: 35px;
          span {
            font-size: 17px;
          }
        }
        .sup{
          font-size: 12px;
          display: block;
        }
      }
    }
  }
}

/* 一覧に戻るボタン
------------------------------------------*/
.special-detail-btn,
.select-detail-btn {
  width: 214px;
  text-align: center;
  margin: 39px auto 0;
  border: 1px solid #ccc;
  &.detail-btn {
    display: block;
  }
  a{
    display: block;
  }
}

/* ライン付きタイトル
------------------------------------------*/
.type-title-03{
  font-weight: bold;
  font-size: 22px;
  font-family: $font-mincho;
  color: #4e3c93;
  line-height: 1;
  padding-bottom: 14px;
  background: url(/img/hearing_aid/line_guarantee_01.gif) left bottom no-repeat;
  margin-bottom: 30px;
}

/* 特殊機能ページでのデフォルトテキスト
------------------------------------------*/
.special-txt-01{
  font-size: 14px;
  line-height: 1.79;
}

/* 特殊機能ページでのリスト
------------------------------------------*/
.special-lists{
  border: 1px solid #a0a0a0;
  padding: 20px;
  .special-list{
    padding-left: 21px;
    background: url(/img/glasses/lens/special/special_dot.gif) left 7px no-repeat;
    line-height: 1.64;
    font-size: 14px;
    margin-bottom: 5px;
    &.last{
      margin-bottom: 0;
    }
  }
}

/* =================================
 特殊機能レンズトップページ
 ================================= */
@import "special/top";

/* =================================
 CCPレンズトップページ
 ================================= */
@import "special/ccp";

/* =================================
 Color Viewレンズトップページ
 ================================= */
@import "special/color_view";

/* =================================
 ネットペックレンズトップページ
 ================================= */
@import "special/netspec";

/* =================================
 TELEXレンズトップページ
 ================================= */
@import "special/talex";
