@charset "utf-8";
/*------------------------------------*\
    バックグラウンド
\*------------------------------------*/

// 格子
.bg-pattern-01 {
  background: url(/img/common/bg_pattern_01.png) repeat;
}

// ノート
.bg-pattern-02 {
  background: url(/img/common/bg_pattern_02.png) repeat;
}

// 斜線
.bg-pattern-03,
.bg-slash {
  background: url(/img/hearing_aid/bg_slash.gif) left top repeat;
}

// 薄いタイル
.bg-pattern-04,
.bg-tile {
  background: url(/img/common/bg_corner_tile.png) left top repeat;
}



// 水色ドット
.bg-pattern-06 {
  background: url(/img/common/bg_pattern_06.png) repeat;
  padding: 50px 0;
}

.bg-pattern-07 {
  background: url(/img/hearing_aid/list/bg_01.png) repeat-x left top;
}