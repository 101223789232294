@charset "utf-8";
/*------------------------------------*\
		Settings Variable
\*------------------------------------*/
// =====================================
// Width
// =====================================
$smp-width: 768px;
$inner-width: 980px;



// =====================================
// Bg Color
// =====================================102
$bg-color: #fff;



// =====================================
// Font Style
// =====================================
$font: "Roboto Condensed", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "ヒラギノ角ゴ W3 JIS2004",'メイリオ', Meiryo,'Lucida Grande', sans-serif;
$font-mincho: "Roboto Condensed", "A1 Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;



// =====================================
// Font Color
// =====================================
$font-color: #333;
$c-murasaki: #453288;



// =====================================
// Other Settings
// =====================================
// # Support
$support-ie6: false;
$support-ie7: false;
$support-ie8: true;
$support-ie9: true;
$support-mozilla: true;
$support-webkit : true;
$support-opera  : true;

// # Vendor-prefixed CSS Property
$use-prefix-webkit: true;
$use-prefix-moz   : true;
$use-prefix-ms    : true;
$use-prefix-o     : true;

// # IE
$use-ie-filter    : false;
$use-ie-expression: false;

// # Path
$path-pj : "";
$path-img: "#{$path-pj}/img";
$path-sprite: "#{$path-img}/img/sprite.png";

// # DataURI
$dataURI-btn: "data:image/png;base64,*****";
