.glasses-sportyluxe{
  .main-visual{
    width: 980px;
    height: 1499px;
    background: url(/img/glasses/brand/sportyluxe/bg_main_visual.png) left top no-repeat;
    margin-bottom: 30px;
    overflow: hidden;
  }

  /* メインコンテンツ
  ----------------------------*/
  .main-contents{
    width: 843px;
    height: 752px;
    margin: 629px auto 0;
    .main-contents-list{
      width: 421px;
      height: 376px;
      &.main-contents-list-01,
      &.main-contents-list-03{
        float: left;
        margin-left: -34px;
      }
      &.main-contents-list-02,
      &.main-contents-list-04{
        float: right;
        margin-right: -34px;
      }
      &.main-contents-list-01{
        .main-contents_img{
          width: 205px;
        }
      }
      &.main-contents-list-02{
        .main-contents_img{
          width: 390px;
        }
      }
      &.main-contents-list-03{
        margin-top: 52px;
        .main-contents_title{
          margin-top: 34px;
        }
        .main-contents_img{
          width: 379px;
          margin-left: -2px;
        }
      }
      &.main-contents-list-04{
        margin-top: 52px;
        .main-contents_title{
          margin-top: 34px;
        }
        .main-contents_img{
          width: 403px;
        }
      }
    }
    .main-contents_title{
      width: 116px;
      margin: 0 auto 30px auto;
    }
    .main-contents_lead{
      text-align: center;
      line-height: 1.75;
      font-size: 12px;
      margin-bottom: 34px;
    }
    .main-contents_img{
      margin: 0 auto;
    }
  }
  .contents-list {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .contents-list__title {
    height: 22px;
    line-height: 30px;
    background: url(/img/glasses/brand/sportyluxe/icon_circle.png) left center no-repeat;
    padding-left: 26px;
    padding-bottom: 8px;
    margin-bottom: 10px;
    font-size: 18px;
    border-bottom: 1px solid #e7e7e7;
  }
  .contents-list__img {
    margin-bottom: 10px;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #cccccc;
  }
  .contents-list__name {
    font-weight: bold;
    background: url(/img/common/icon_circle_01.png) left center no-repeat;
    padding-left: 23px;
    margin-bottom: 10px;
  }
  .contents-list{
    margin-bottom: 50px;
  }
}