@charset "UTF-8";
/* =================================
 コンタクトレンズトップページ
 ================================= */
@import "contact_lens/top";

/* =================================
 お手入れ
 ================================= */
@import "contact_lens/care";

/* =================================
 取扱店一覧ページ
================================= */
@import "contact_lens/shop";

/* =================================
 店舗隣接コンタクトクリニック
================================= */
@import "contact_lens/clinic";

/* =================================
 おすすめコンタクトレンズ
================================= */
@import "contact_lens/recommend";

/* =================================
 ご購入の流れ
================================= */
@import "contact_lens/flow";

/* =================================
 1日使い捨てコンタクトレンズ
================================= */
@import "contact_lens/disposable";

/* =================================
 ポイント
================================= */
@import "contact_lens/point";

/* =================================
 定期検査
================================= */
@import "contact_lens/inspection";

// 共通キャンペーン
.contact-lens-campaign {
	padding: 40px;
}
// リンク
.contact-lens-link {
	padding: 30px;
	ul {
		text-align: center;
		font-size: 0;
	}
	li {
		display: inline-block;
		font-size: 14px;
		max-width: 238px;
		margin-right: 9px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.maker_link {
	text-align: right;
}

.main-visual-type-img {
	font-size: 50px;
}

.main-visual-type-img img {
	margin-right: 20px;
}

.disposable_contact_lens .contents01 li div {
	border: 0;
}

.type-area-left .type-area-desc {
	margin: 150px 0px 133px 34px;
}

.type-area-right .type-area-img {
	margin: 18px 0px 0px 42px;
	text-align: center;
}

.type-area-price {
	clear: both;
	padding: 10px 0;
	text-align: center;
}

.type-area-img2 {
	float: left;
	width: 230px;
	margin-top: 18px;
}

.type-area-img2 p {
	font-size: 12px;
}

.type-area-left .type-area-img {
	text-align: center;
}
