.inner.special-lens-top{
  padding: 50px 0 0 0;
  .special-lens-box{
    border: 2px solid #dfdfdf;
    width: 471px;
    position: relative;
    &.special-lens-box-01,
    &.special-lens-box-02{
      margin-bottom: 30px;
    }
    &.special-lens-box-01,
    &.special-lens-box-03{
      float: left;
    }
    &.special-lens-box-02,
    &.special-lens-box-04{
      float: right;
    }
    .special-lens-box-title {
        color: $c-murasaki;
	    font-family: $font-mincho;
      text-align: center;
      @include vertical-align-middle(45px);
      font-size: 20px;
    }
    .special-lens-box-clms{
      width: 444px;
      border-top: 3px solid #c6dfbf;
      padding: 11px 7px;
      margin: 0 auto 31px auto;
      .special-lens-box-clms-left{
        float: left;
        width: 200px;
        height: 130px;
	      img {
		      max-width: 100%;
		      height: auto;
	      }
      }
      .special-lens-box-clms-right{
        width: 226px;
        float: right;
        font-size: 14px;
        line-height: 1.79;
      }
    }
    .special-lens-box-btn-area{
      background: #f5f4f6;
      width: 460px;
      margin: 0 auto 6px auto;
      padding: 5px 0;
      .detail-btn{
        text-align: center;
        a {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }
    .special-lens-box-price{
      position: absolute;
      right: 10px;
      bottom: 63px;
      line-height: 1;
    }
  }
}

.special-lens-top-banner.inner{
  .bnr-lens-lists{
    margin-top: 77px;
  }
}
