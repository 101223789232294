@charset "UTF-8";
/* =================================
サイトのご利用にあたって
================================= */
.links {
	.heading-title{
		background: url(/img/privacy/purple_belt.png) left top no-repeat;
		font-size: 18px;
		color: #fff;
		width: 980px;
		box-sizing: border-box;
		@include vertical-align-middle(50px);
		padding-left: 10px;
		margin-bottom: 15px;
	}
	.lead{
		font-size: 15px;
	}
	.inner-title{
		font-size: 16px;
		font-weight: bold;
		color: $c-murasaki;
	}
	.title-02.title-02-purple {
	  padding-bottom: 10px;
	  margin-bottom: 10px;
	}
	.desc{
		font-size: 14px;
		line-height: 1.7;
	}
	.title-02.title-02-purple {
	  padding-bottom: 10px;
	  margin-bottom: 10px;
	}

	.terms-list-01{
		li{
			text-indent: -1em;
			padding-left: 1em;
			font-size: 14px;
		}
	}
	.terms-list-02{
		dt{
			font-size: 16px;
			font-weight: bold;
		}
		dd{
			font-size: 14px;
		}
	}
	.terms-list-03{

	}
	.box{
		overflow: hidden;
		@include box(1px,$c-murasaki);
		padding: 20px;
		margin-bottom: 30px;
		.inner-anc-list{
			li{
				float: left;
			  font-size: 14px;
			  padding-left: 24px;
			  margin-right: 41px;
			  background: url(/img/common/icon_circle_01_bottom.png) 0% 3px no-repeat;
			  line-height: 1.8;

			  a{
			  	color: $c-murasaki;

			  }
			}
		}
	}
	.detail-btn{
	  a{
	    border: 1px solid #e1e1e1;
	  }
	}
}
