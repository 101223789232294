@charset "utf-8";
.sunglasses-history {
  .main-visual {
    height: 418px;
    //width: 1280px;
    margin: 0 auto;
    background: url(/img/sunglasses/history/bg_main_visual.png) center top no-repeat;
    .main_title{
      font-size: 14px;
      color: #fff;
      padding-top: 22px;
      line-height: 1.2;
      margin-bottom: 21px;
      text-align: left;
    }
    .inner-white{
      width: 999px;
      background: #fff;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 10px;
    }
    .main-visual-table{
      tr{
        &.head{
          background: #4e3c93 url(/img/sunglasses/history/bg_belt.png) left top no-repeat;
          height: 37px;
        }
        &.content{
          height: 230px;
          background: #f5f5f5;
        }
        th{
          width: 163px;
          border-right: 1px solid #644db1;
          text-align: center;
          color: #fff;
          font-family: $font;
          font-size: 24px;
          vertical-align: middle;
          border-bottom: 3px solid #01c1b7;
          &.last{
            border-right: none;
          }
        }
        td{
          width: 163px;
          border-right: 1px solid #b4aab9;
          text-align: center;
          &.last{
            border-right: none;
          }
          .txt{
            font-size: 14px;
            margin-top: 25px;
            line-height: 1.6;
            font-weight: bold;
            padding-left: 22px;
            text-align: center;
            background: url(/img/common/icon_circle_01.png) left 2px no-repeat;
            display: inline-block;
            &.txt-06{
              letter-spacing: -0.1em;
            }
          }
        }
      }

    }
  }

  .contents{
    padding: 10px 0 68px;
    .first-inner{
      width: 976px;
      margin: 0 auto;
      overflow: hidden;
      .first-inner-box{
        overflow: hidden;
      }
      .box-updown{
        width: 471px;
        box-sizing: border-box;
        padding: 0 0 11px 0;
        height: 547px;
        background-image: url(/img/sunglasses/history/box_top.png), url(/img/sunglasses/history/box_bottom.png);
        background-position: left top, left bottom;
        background-repeat: no-repeat, no-repeat;
        .title{
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          margin-top: 4px;
          @include vertical-align-middle(47px);
          margin-bottom: 2px;
        }
      }
      .box-middel{
        background: url(/img/sunglasses/history/box_middle.png) left top repeat-y;
        position: relative;
        height: 483px;
        .item{
          height: 227px;
          line-height: 227px;
          vertical-align: middle;
          text-align: center;
        }
        .desc{
          font-size: 14px;
          line-height: 1.2;
          width: 434px;
          margin: 0 auto;
        }
        .under-box{
          position: absolute;
          bottom: 0;
          left: 4px;
          width: 471px - 4px - 4px;
          border-top: 1px solid #dfdfdf;
          height: 122px;
          box-sizing: border-box;
          .under-box-left{
            float: left;
            border-right: 1px solid #dfdfdf;
          }
          .under-box-right{
            float: right;
          }
          .under-box-left,
          .under-box-right{
            width: 231px;
          }
          .under-box-title{
            font-size: 18px;
            margin: 8px auto 8px;
            width: 214px;
            font-weight: bold;
            border-bottom: 1px solid #dfdfdf;
            color: $c-murasaki;
            span{
              padding-left: 10px;
              border-left: 4px solid #01c1b7;
            }
          }
          .under-box-list{
            li{
              font-size: 14px;
              line-height: 1.2;
              padding-left: 22px;
            }
          }
        }
      }
      .topic-box{
        border: 1px solid #dfdfdf;
        margin-bottom: 15px;
        .topic-box-title{
          font-size: 18px;
          color: #fff;
          text-align: center;
          font-weight: bold;
          background: url(/img/sunglasses/history/bg_belt_02.png) left top no-repeat;
          @include vertical-align-middle(39px);
          position: relative;
          /* 下吹き出し */
          &:after{
            content: "";
            position: absolute;
            bottom: -10px; left: 50%;
            margin-left: -10px;
            display: block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: #4e3c93 transparent transparent transparent;
          }
        }
        .topic-box-desc{
          background: #fff;
          padding: 22px 19px;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .detail-btn{
        a{
          border: 1px solid #e1e1e1;
          background: rgba(237,237,237,0.29);
          filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#33ededed,EndColorStr=#33ededed);/*IE8以下用*/
        }
      }
    }
  }


}
