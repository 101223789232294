/* TALEX カスタム
------------------------------------------*/
.color-view.special-clms{
  .special-clms-left{
    width: 217px;
    margin-left: 48px;
    padding-top: 39px;
    .talex-phrase{
      padding-left: 6px;
      line-height: 1.5;
      margin-bottom: 50px;
      color: #034a3b;
      @include mincho-s(25);
    }
  }
  .special-clms-right{
    width: 490px;
    .item-name-01{
      margin-top: 2px;
    }
    .item-name-02{
      margin-top: 2px;
    }
  }
}

/* 紫吹き出し（スモール）
------------------------------------------*/
.purple-belt-balloon-s{
  background: url(/img/glasses/lens/special/purple_belt_balloon_s.png) left top no-repeat;
  width: 980px;
  margin: 0 auto;
  @include vertical-align-middle(59px);
  @include mincho-s(20);
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 10;
  &:after{
    content: "";
    position: absolute;
    bottom: -10px; left: 50%;
    margin-left: -10px;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #453288 transparent transparent transparent;
  }
}

/* ライン付きタイトル
------------------------------------------*/
.type-title-03.color-view{
  margin-bottom: 15px;
}


.color-view-clms-01{
  .color-view-clms-left{
    float: left;
    width: 287px;
  }
  .color-view-clms-right{
    float: right;
    width: 547px;
    .color-view-clms-inner-left{
      float: left;
      width: 188px;
      &.custom-01{
        margin-right: 60px;
      }
      &.custom-02{
        width: 75px;
        margin-right: 36px;
        margin-top: 32px;
      }
      .fig{
        width: 71px;
        float: none;
        margin: 0;
        text-align: center;
      }
    }
  }
}
.fig-txt-color-view{
  line-height: 1.5;
  text-align: center;
  font-size: 12px;
}


.special-sec-01{
  .inner.color-view-sec-box-01{
    padding: 0 61px 49px;
  }
}


.intro{
  margin-bottom: 52px;
  .intro-left{
    float: left;
    width: 223px;
    margin: -42px 0 0 -7px;
    position: relative;
    z-index: 20;
  }
  .intro-right{
    float: right;
    width: 613px;
    margin: 45px 0 60px 0;
  }
}

/* カラービューの使用場面
------------------------------------------*/
.inner.color-view-sec-box-02{
  .use-situation-box{
    width: 393px;
    border: 1px solid #a0a0a0;
    &.use-situation-01,
    &.use-situation-02{
      margin-bottom: 40px;
      height: 445px;
    }
    &.use-situation-03,
    &.use-situation-04{
      height: 373px;
    }
    .use-situation-title{
      border-top: 2px solid $c-murasaki;
      width: 380px;
      text-align: center;
      font-size: 20px;
      @include vertical-align-middle(46px);
      @include mincho-murasaki;
    }
    .special-lists{
      border: none;
      width: 370px;
      margin: 0 auto;
      border-top: 1px solid $c-murasaki;
      padding: 20px 0 0 0;
      .special-list{
        padding-left: 21px;
        margin-left: 12px;
      }
    }
    &.use-situation-01{
      .special-lists{
        margin-bottom: 37px;
      }
    }
    &.use-situation-02{
      .special-lists{
        margin-bottom: 28px;
      }
    }



    .use-situation-img-01{
      float: left;
      margin-right: 25px;
      margin-left: 23px;
      font-size: 12px;
      text-align: center;
    }
    .use-situation-img-02{
      float: left;
      font-size: 12px;
      text-align: center;
    }
    .use-situation-img-03{
      width: 126px;
      margin: 0 auto;
      font-size: 12px;
      text-align: center;
    }
    .fig-use-situation-03{
      width: 126px;
      margin: 0 auto;
    }

    .use-situation-img-04{
      width: 160px;
      margin: 0 auto 20px auto;
    }
    .use-situation-img-05{
      width: 160px;
      float: left;
      margin: 0 25px 0 12px;
    }
    .use-situation-img-06{
      width: 160px;
      float: left;
    }

    .use-situation-04-box{
      width: 370px;
      margin: 0 auto;
      padding: 20px 0 0 0;
      border-top: 1px solid $c-murasaki;
    }



  }
}



