@charset "UTF-8";
.area03 {
	.main-visual {
    height: 424px;
    background-image: url(/img/shop_list/area03/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
	.anc-01,.anc-02,.anc-03,.anc-04,.anc-05,.anc-06,.anc-07,.anc-08,.anc-09,.anc-10,.anc-11,.anc-12,.anc-13,.anc-14,.anc-15,.anc-16,.anc-17,.anc-18{
		position: absolute;
	}
  .anc-01 {
    top: 260px;
    left: 0;
  }
  .anc-02 {
    top: 135px;
    left: 75px;
  }
  .anc-03 {
    top: 70px;
    left: 220px;
  }
  .anc-04 {
    top: 55px;
    left: 350px;
  }
  .anc-05 {
    top: 85px;
    left: 470px;
  }
  .anc-06 {
    top: 150px;
    left: 500px;
  }
  .anc-07 {
    top: 205px;
    left: 438px;
  }
  .anc-08 {
    top: 255px;
    left: 400px;
  }
  .anc-09 {
    top: 165px;
    left: 360px;
  }
	.anc-10 {
    top: 120px;
    left: 325px;
  }
	.anc-11 {
    top: 140px;
    left: 220px;
  }
	.anc-12 {
    top: 200px;
    left: 150px;
  }
	.anc-13 {
    top: 290px;
    left: 140px;
  }
	.anc-14 {
    top: 200px;
    left: 260px;
  }
	.anc-15 {
    top: 250px;
    left: 310px;
  }
	.anc-16 {
    top: 320px;
    left: 240px;
  }
}
