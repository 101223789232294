@charset "UTF-8";

.flow-txt-01{
	font-size: 14px;
	line-height: 1.7;
	padding: 18px 0 20px;
}
.flow-box{
	padding: 25px;
	background: #fff;
	border: 2px solid #dfdfdf;
	position: relative;
	margin-bottom: 48px;
	&:after{
		content: url(/img/hearing_aid/flow_arrow.png);
		position: absolute;
		bottom: -42px;
		left: 488px;
	}
	&.last{
		margin-bottom: 32px;
		&:after{
			content: '';
			position: static;
		}
		.flow-right-box-desc{
			margin-bottom: 48px;
		}
		.detail-btn-05{
			left: 207px;
			bottom: 18px;
			right: static;
			a {
				padding-top: 7px;
				padding-bottom: 7px;
			}
		}
	}
	.area{
		overflow: hidden;
	}
	.flow-icn{
		float: left;
		width: 140px;
		height: 140px;
		background: url(/img/hearing_aid/bg_flow.png) left top no-repeat;
		text-align: center;
		line-height: 140px;
		font-size: 24px;
		font-family: $font;
		color: #fff;
		font-weight: bold;
	}
	.flow-right-box{
		width: 745px;
		float: right;
		.flow-right-box-title{
			@include mincho-murasaki;
			font-size: 24px;
			border-bottom: 1px solid #bbb1c1;
			margin-bottom: 10px;
		}
		.flow-right-box-desc{
			font-size: 14px;
			line-height: 1.7;
		}
		&.flow-right-box-02{
			float: left;
			margin-left: 43px;
			width: 534px;
		}
	}
	.flow-right-box-img{
		float: right;
	}

	.detail-btn{
		position: absolute;
		bottom: 10px;
		right: 17px;
		a{
			border: 1px solid #e1e1e1;
			background: #fafafa;
		}
		&.detail-btn-01{
			bottom: 10px;
			right: static;
			left: 211px;
		}
		&.detail-btn-02{
			bottom: 10px;
			right: static;
			left: 382px
		}
		&.detail-btn-03{
			bottom: 10px;
			right: static;
			left: 539px;
		}
		&.detail-btn-04{
			bottom: 10px;
			right: static;
			left: 747px;
		}
	}
}
