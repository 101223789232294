.glasses-biside{
  .main-visual{
    width: 980px;
    margin-bottom: 90px;
  }

  /* メインコンテンツ
  ----------------------------*/
  .contents-list {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .contents-list__name {
    font-weight: bold;
    background: url(/img/common/icon_circle_01.png) left center no-repeat;
    padding-left: 23px;
    margin-bottom: 10px;
  }
  .contents-list{
    margin-bottom: 50px;
  }
}