@charset "UTF-8";
/* =================================
検索結果
================================= */
.search {
	.heading-title{
		background: url(/img/privacy/purple_belt.png) left top no-repeat;
		font-size: 18px;
		color: #fff;
		width: 980px;
		box-sizing: border-box;
		padding-left: 10px;
		@include vertical-align-middle(50px);
		margin-bottom: 30px;
	}

	.search-result{
		padding-left: 18px;
		min-height: 300px;
		.title-06{
			margin-bottom: 20px;
			a{
				font-size: 18px;
				font-weight: bold;
				color: $c-murasaki;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
			&:last-child{
				margin-bottom: 100px;
			}
		}
	}
}