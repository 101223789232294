.recommend-contact-lens {
	.bg-contents1 {
		padding: 0 0 33px;
		.inner {
			padding: 50px 0 0;
		}
		ul {
			overflow: hidden;
		}
		li {
			float: left;
			padding: 0 10px;
			margin-bottom: 17px;
		}
	}
	&--list {
		padding: 50px 0;
		border-top: 1px solid #ccc;
		ul {
			overflow: hidden;
			margin-bottom: 50px;
		}
		li {
			float: left;
			box-sizing: border-box;
			min-height: 398px;
			width: 237px;
			border: 1px solid #ccc;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
		}
		p {
			padding: 0 10px;
		}
	}

	// 定期検査のすすめボタン
	.contact-lens-top-box-02{
		display: none;
	}
}
