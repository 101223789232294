.quality-fashion{

  /* 紫格子
  ---------------------------------- */
  .bg-net-purple{
    background: url(/img/quality/fashion/bg_net_purple.png) left top repeat;
  }

  .bg-net{
    overflow: hidden;
  }

  /* sec01
  ---------------------------------- */
  .sec-01{
    height: 365px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 35px;
    .inner{
      position: relative;
      height: 365px;
      background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
    }
    .sec-01-img-01{
      position: absolute;
      width: 250px;
      height: 316px;
      top: 87px;
      left: 5px;
    }
    .sec-01-img-02{
      position: absolute;
      width: 265px;
      height: 265px;
      top: -33px;
      right: -52px;
    }
    .sec-01-img-txt-01{
      position: absolute;
      width: 545px;
      text-align: center;
      @include vertical-align-middle(99px);
      @include border-radius(15px);
      @include mincho-murasaki;
      top: 37px;
      left: 190px;
      font-size: 24px;
      background: #fff;
      &:after{
        content: url(/img/quality/fashion/fashion_arrow_top.png);
        line-height: 1;
        width: auto;
        height: auto;
        position: absolute;
        top: 99px;
        left: 35px;
      }
    }
    .sec-01-img-txt-02{
      position: absolute;
      width: 608px;
      text-align: center;
      @include vertical-align-middle(100px);
      @include border-radius(15px);
      @include mincho-murasaki;
      top: 205px;
      left: 302px;
      font-size: 24px;
      background: #fff;
      &:after{
        content: url(/img/quality/fashion/fashion_arrow_bottom.png);
        position: absolute;
        top: -59px;
        right: 116px;
      }
    }
    .sec-01-item-01{
      position: absolute;
      width: 82px;
      height: 74px;
      top: 21px;
      left: 129px;
    }
    .sec-01-item-02{
      position: absolute;
      width: 58px;
      height: 82px;
      top: 199px;
      right: 35px;
    }
    .sec-01-item-03{
      position: absolute;
      bottom: -18px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  .fashion-txt-01{
    font-size: 26px;
    width: 972px;
    border: 4px solid #453288;
    text-align: center;
    @include vertical-align-middle(85px);
    @include mincho-murasaki;
    margin: 0 auto 35px;
  }




  /* sec02
  ---------------------------------- */
  .sec-02{
    padding: 40px 0 0 0;
    overflow: hidden;
    .sec-02-box{
      background: #fff;
      border: 3px solid #f2f2f2;
      padding: 20px 24px 14px;
      .two-color-title-deep{
        border: 2px solid $c-murasaki;
        padding: 1px;
        .two-color-title-01{
          text-align: center;
          background: $c-murasaki;
          font-weight: bold;
          color: #fff;
        }
      }
      .two-color-title-light{
        border-bottom: 3px solid $c-murasaki;
        text-align: center;
        .two-color-title-02{
          font-size: 35px;
          font-weight: bold;
          @include mincho-murasaki;
        }
      }

    }
    .sec-02-title-area{
      background: url(/img/quality/fashion/bg_belt_purple_l.png) center top no-repeat;
      height: 100px;
      margin-bottom: 25px;
      .sec-02-title{
        width: 980px;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        @include mincho-s(22);
        line-height: 1.6;
        z-index: 0;
        position: relative;
        height: 79px;
        padding-top: 15px;
        &.sec-02-02-title{
          height: 86px;
          line-height: 1.6;
          padding-top: 14px;
        }
        &:after{
          content: "";
          position: absolute;
          bottom: -10px; left: 50%;
          margin-left: -10px;
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: $c-murasaki transparent transparent transparent;
        }
        .sec-02-title-01{
          display: block;
          font-size: 20px;
        }
        .sec-02-title-02{
          display: inline-block;
          position: relative;
          font-size: 25px;
          &:before{
            position: absolute;
            content: url(/img/quality/fashion/bg_bright_01.png);
            left: -44px;
            top: 0;
          }
          &:after{
            position: absolute;
            content: url(/img/quality/fashion/bg_bright_02.png);
            right: -38px;
            top: 0;
          }
        }
      }
    }
    .sec-02-box-style-area{
      border-top: 1px solid $c-murasaki;
      text-align: center;
      margin-top: 27px;
      margin: 27px 0 36px;
      .sec-02-box-style{
        font-family: $font;
        background: url(/img/quality/fashion/bg_style.png) left top no-repeat;
        width: 171px;
        color: #fff;
        font-size: 20px;
        @include vertical-align-middle(31px);
        margin: -15px auto 0;
      }
    }

    .sec-02-txt{
      font-size: 14px;
      line-height: 1.7;
    }
    .sec-02-clms{
      margin-bottom: -10px;
      overflow: hidden;
      .sec-02-clms-left{
        float: left;
        min-width: 320px;
        &.sec-02-01-clms-left{
          width: 517px;
        }
        &.sec-02-02-clms-left{
          width: 313px;
        }
        .sec-02-txt{
          padding-left: 19px;
        }
      }
      .sec-02-clms-right{
        float: right;
        position: relative;
        &.sec-02-02-clms-right{
          width: 465px;
          height: 60px;
          padding: 15px 30px;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.6;
          background: url(/img/quality/fashion/bg_balloon.png) left center no-repeat;
        }
        .sec-02-img{
          border: 3px solid $c-murasaki;
          background: #fff;
          padding: 6px;
          img{
            display: block;
            border: 2px solid $c-murasaki;
          }
        }
      }
    }
    .sec-02-compare-img-area{
      width: 808px;
      margin: 0 auto 15px;
      position: relative;
      .sec-02-compare-img-area-clms{
        width: 369px;
        position: relative;
      }
      .sec-02-compare-img-area-clms-left{
        float: left;
      }
      .sec-02-compare-img-area-clms-right{
        float: right;
      }
      .sec-02-compare-img{
        display: table-cell;
        height: 322px;
        vertical-align: middle;
      }
      .sec-02-compare-txt{
        width: 367px;
        min-height: 42px;
        font-size: 14px;
        margin-top: 10px;
        padding-bottom: 10px;
        padding-left: 60px;
        border-bottom: 1px solid $c-murasaki;
        background: url(/img/quality/fashion/icon_before_text.png) left top no-repeat;
      }
      .sec-02-compare-right-txt{
        background: url(/img/quality/fashion/icon_after_text.png) left top no-repeat;
      }
      .sec-02-compare-arrow {
        position: absolute;
        left: 288px;
        top: 110px;
      }
      .img-before{
        position: absolute;
        top: 36px;
        left: -41px;
      }
      .img-after{
        position: absolute;
        top: 36px;
        right: -41px;
      }
    }
  }


  /* sec03
  ---------------------------------- */
  .sec-03{
    overflow: hidden;
    padding: 30px 0;
    .sec-03-clms-area{
      width: 980px;
      margin: 0 auto;
      padding-bottom: 55px;
      border-bottom: 1px solid $c-murasaki;
      .sec-03-clms{
        width: 480px;
        a{
          display: block;
          width: 464px;
          height: 86px;
          border: 2px solid #dfdfdf;
          padding: 5px;
          background: #fff;
          .inner{
            background: #f5f4f6;
            display: block;
            width: auto;
            text-align: center;
            @include vertical-align-middle(86px);
            .txt{
              @include mincho-murasaki;
              position: relative;
              text-align: center;
              display: inline-block;
              font-size: 24px;
              &:after{
                content: url(/img/quality/fashion/bg_item_01.png);
                position: absolute;
                top: 0;
                left: 30px;
              }
            }
          }
        }
        &.sec-03-clms-left{
          float: left;
          .inner{
            .txt{
              padding-left: 156px;
              background: url(/img/common/icon_circle_01.png) 128px 35px no-repeat;
              &:after{
                content: url(/img/quality/fashion/bg_item_01.png);
                position: absolute;
                top: 17px;
                left: 0;
              }
            }
          }
        }
        &.sec-03-clms-right{
          float: right;
          .inner{
            .txt{
              padding-left: 103px;
              background: url(/img/common/icon_circle_01.png) 75px 35px no-repeat;
              &:after{
                content: url(/img/quality/fashion/bg_item_02.png);
                position: absolute;
                top: 16px;
                left: 0;
              }
            }
          }
        }
      }
    }

    .sec-03_btn{
      margin: -21px auto 0;
      background: #fff;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
