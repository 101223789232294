@charset "utf-8";
// bnr bottom-contents
.gl-double-border {
    background: url(/img/common/gl_footer_contents/bg_border_double.png) left top repeat-x;
}
.bg-faq-title {
  background: url(/img/common/bg_title_faq.png) no-repeat center top;
  height: 69px;
  color: #fff;
  font-family: $font-mincho;
  font-size: fz(26);
  text-align: center;
  line-height: 58px;
}
.bnr-about-gl {
  padding-bottom: 40px;
  .ttl-01 {
    text-align: center;
    padding: 40px 0;
  }
  .btn--about-lens {
    font-size: fz(30);
    font-weight: bold;
    font-family: $font-mincho;
    height: 120px;
    line-height: 120px;
    &.btn-05 {
      &:hover {
        background: none;
        color: $c-murasaki;
      }
    }
    img {
      margin-left: 20px;
    }
  }
  .list--about-lens {
    overflow: hidden;
  }
  .list--about-lens__item {
    float: left;
    width: 33.33333%;
    padding: 35px 20px;
    border-left: 1px solid #dfdfdf;
    box-sizing: border-box;
    .btn-01 {
      font-size: fz(22);
      font-weight: bold;
      font-family: $font-mincho;
      color: $c-murasaki;
      background: #fff url(/img/common/icon_circle_01.png) 7px 14px no-repeat;
    }
  }
  .list--about-lens__item--01 {
    background: url(/img/common/gl_footer_contents/img_list_04.jpg) 200px center no-repeat;
  }
  .list--about-lens__item--02 {
    background: url(/img/common/gl_footer_contents/img_list_05.jpg) 200px center no-repeat;
  }
  .list--about-lens__item--03 {
    background: url(/img/common/gl_footer_contents/img_list_06.jpg) 230px center no-repeat;
      border-right: 1px solid #dfdfdf;
  }
  .list--about-lens__item--04 {
    clear: both;
    height: 99px;
    line-height: 99px;
    text-align: center;
    border: 1px solid #dfdfdf;
    background: url(/img/common/gl_footer_contents/img_list_07.jpg) right center no-repeat;
    .btn {
      padding: 0 0 0 30px;
    }
    .btn-01 {
      font-size: fz(22);
      font-weight: bold;
      font-family: $font-mincho;
      color: $c-murasaki;
      background: #fff url(/img/common/icon_circle_01.png) 7px center no-repeat;
    }
  }
}
// suv navの部分
.glasses-common {
  .sec-nav .sec-nav-area.lens .sec-nav-list {
    margin-right: 35px;
  }
}
// basic
.glasses-basic {
  .contents__img {
    text-align: center;
    margin: 15px 0;
  }
  .list-outer {
    width: 895px;
    margin: 0 auto;
    padding: 20px 0 40px;
  }
  .list-item {
    position: relative;
    min-height: 115px;
    border-top: 1px solid $c-murasaki;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    background: #fff url(/img/glasses/basic/bg_list_num.png) left top no-repeat;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .list-item__num {
    position: absolute;
    top: 0;
    left: 12px;
    color: $c-murasaki;
    font-family: $font;
    font-size: fz(27);
  }
  .list-item__title {
    text-align: center;
    color: $c-murasaki;
    font-family: $font-mincho;
    font-size: fz(24);
    border-bottom: 1px solid #dfdfdf;
    margin: 10px 0;
    padding-bottom: 8px;
  }
  .list-item__text {
    text-align: center;
  }
}
