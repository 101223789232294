@charset "utf-8";
// step bar
.step-bar {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 40px 0 18px;
  padding: 0;
  overflow: hidden;
  &.step2 {
    .step {
      width: 50%;
    }
  }
  &.step3 {
    .step {
      width: 33.333%;
    }
  }
  &.step4 {
    .step {
      width: 25%;
    }
  }
  &.step5 {
    .step {
      width: 20%;
    }
  }
}
.step {
  display: inline-block;
  position: relative;
  float: left;
  color: #fff;
  font-weight: bold;
  font-size: fz(22);
  text-decoration: underline;
  padding: 25px 65px 25px 60px;
  background-color: #a298c3;
	box-sizing: border-box;
  &:before,
  &:after {
    display: block;
    content: '';
    width: 40px;
    height: 58px;
    position: absolute;
    left: -30px;
    border-left: 20px solid #fff;
    background-color: #a298c3;
  }
  &:after {
    top: 0;
	  transform: skew(45deg);
  }
  &:before {
    bottom: 0;
	  transform: skew(-45deg);
  }
  &:first-child {
    &:before,
    &:after {
      content: none;
    }
  }
  &.current {
    background-color: #453288;
    &:before,
    &:after {
      background-color: #453288;
    }
  }
}

// 似合うレンズの選び方
.glasses-choice {
	p {
		font-size: fz(14);
	}
  .main-visual {
    text-align: center;
  }
  .main-visual__text {
    font-family: $font-mincho;
    font-weight: bold;
    font-size: fz(22);
    color: #453288;
  }
  .main-visual__note {
	  font-size: fz(16);
    margin-top: 50px;
  }
  // index
  .contents-lead {
    background: #f9f9f9;
    border: 5px solid #453288;
    border-radius: 5px;
    padding: 16px 0;
    margin-top: 30px;
  }
  .contents-lead__img {
    img {
      display: block;
      margin: 0 auto;
    }
    .img--2 {
      display: block;
      margin: 60px auto 0;
    }
  }
  .contents-lead__title {
    font-size: fz(40);
    font-weight: bold;
    font-family: $font-mincho;
    color: #6e6e6e;
    border-left: 5px solid #453288;
    margin-top: 35px;
    padding-left: 15px;
  }
  .contents-lead__text {
    padding-top: 5px;
    border-left: 5px solid #a78ec3;
    padding-left: 15px;
  }
  .contents-lead__note {
    width: 426px;
    margin-top: 20px;
    padding: 25px;
    box-sizing: border-box;
    background-image: url(/img/common/bg_flagship_shop_01.png),url(/img/common/bg_flagship_shop_03.png),url(/img/common/bg_flagship_shop_02.png);
    background-repeat: no-repeat, no-repeat, repeat-y;
    background-position: left top, left bottom, left;
  }
  .contents-lead__note-list-item {
    color: $c-murasaki;
    font-size: 16px;
    font-weight: bold;
  }
  .bg-contents-2 {
    background: #f9f9f9;
  }
  .contents-2__title {
    font-size: fz(30);
    font-family: $font-mincho;
    color: $c-murasaki;
    border-bottom: 3px solid $c-murasaki;
    padding-left: 170px;
    padding-top: 90px;
    margin-left: 5px;
  }
  .index03-contents-2__title {
    padding-left: 0;
  }
  .index03-contents-2__text {
    font-size: fz(16);
    padding-left: 3px;
    margin-top: 20px;
  }
  .contents-2__title-bg-1 {
    &:after {
      content: "";
      display: block;
      background: url(/img/glasses/choice/img_point_01.png) left center no-repeat;
      width: 170px;
      height: 170px;
      margin-top: -100px;
    }
  }
  .contents-2__title-bg-2 {
    &:after {
      content: "";
      display: block;
      background: url(/img/glasses/choice/img_point_02.png) left center no-repeat;
      width: 170px;
      height: 170px;
      margin-top: -100px;
    }
  }
  .contents-2__title-bg-3 {
    &:after {
      content: "";
      display: block;
      background: url(/img/glasses/choice/img_point_03.png) left center no-repeat;
      width: 170px;
      height: 170px;
      margin-top: -100px;
    }
  }
  .contents-2__img {
    margin-top: 30px;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  // next prev　ステップボタン
  .contents-pager {
    width: 800px;
    margin: 60px auto 0;
  }
  .contents-pager--short {
    width: 390px;
  }
  .b-next, .b-prev {
    display: inline-block;
    overflow: hidden;
    padding-bottom: 3px;
    border-bottom:1px solid #453288;
  }
  .step-num {
    position:relative;
    float: left;
    width: 85px;
    height: 32px;
    line-height: 32px;
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    -o-box-sizing:border-box;
    background: #4e3c93;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-align:center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -32px;
      width: 0;
      height: 0;
      border-top: solid 16px transparent;
      border-right: solid 16px transparent;
      border-bottom: solid 16px transparent;
      border-left: solid 16px #4e3c93;
      z-index: 10;
    }
  }
  .step-num-2 {
    position:relative;
    float: left;
    width: 85px;
    height: 32px;
    line-height: 32px;
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    -o-box-sizing:border-box;
    background: #4e3c93;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-align:center;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -32px;
      width: 0;
      height: 0;
      border-top: solid 16px transparent;
      border-left: solid 16px transparent;
      border-bottom: solid 16px transparent;
      border-right: solid 16px #4e3c93;
      z-index: 10;
    }
  }
  .step-go {
    float: left;
    height: 32px;
    line-height: 32px;
    margin-left: 20px;
    color: $c-murasaki;
    font-size: 24px;
    font-family: $font-mincho;
  }
  .step-go-2 {
    float: left;
    height: 32px;
    line-height: 32px;
    margin-right: 20px;
    color: $c-murasaki;
    font-size: 24px;
    font-family: $font-mincho;
  }
/*   .next-step {
  background: url(/img/glasses/choice/btn_next_step.png) left top no-repeat;
  margin: 90px auto 0;
  padding-left: 116px;
    display: inline-block;
  border-bottom: 1px solid $c-murasaki;
  a {
    font-size: fz(23);
    font-weight: bold;
    color: $c-murasaki;
    text-decoration: none;
  }
} */
  .next-step-2 {
      background: url(/img/glasses/choice/btn_next_step_02.png) left top no-repeat;
  }
  .contents-advice-outer {
    margin-top: 60px;
    padding-top: 30px;
    border-top: 1px solid #d2d2d2;
  }
  .contents-advice {
    border: 2px solid #d2d2d2;
    padding: 6px;
  }
  .contents-advice__title {
    color: #fff;
    font-size: fz(20);
    font-weight: bold;
    text-align: center;
    font-family: $font-mincho;
    background: $c-murasaki;
    padding: 10px 0;
    span {
      font-size: 90%;
    }
  }
  .contents-advice-inner {
    background: #f5f4f6;
  }
  .contents-advice__textarea {
    float: left;
    width: 305px;
    padding: 0 20px;
    text-align: center;
    color: $c-murasaki;
  }
  .contents-advice__text {
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .contents-advice__img {
    float: left;
    padding-top: 5px;
  }
  .note-anc {
    margin-top: 20px;
    padding-bottom: 50px;
    a {
      display: block;
      font-weight: bold;
      color: $c-murasaki;
      margin-top: 10px;
    }
  }
  .btn-app {
    width: 470px;
    margin: 48px auto;
    border: 2px solid #dfdfdf;
    padding: 5px;
    a {
      font-size: fz(24);
      background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
      font-family: $font-mincho;
      color: $c-murasaki;
    }
  }
  .btn-app-inner {
    background: #f5f4f6;
    padding: 10px 0;
  }
  .btn-app-bg {
    height: 50px;
    line-height: 50px;
    background: url(/img/glasses/choice/bg_btn_app.png) left center no-repeat;
    padding-left: 75px;
    margin-left: 70px;
  }
  // 02
  .contents__caption {
    font-size: fz(23);
    color: $c-murasaki;
  }
  .contents__lead {
    font-size: fz(22);
    color: $c-murasaki;
    font-family: $font-mincho;
    text-align: center;
    margin: 20px 0 40px;
  }
  .index02-contents-2__list-title {
    border-bottom: 1px solid $c-murasaki;
    p {
      color: $c-murasaki;
      font-size: fz(18);
      font-weight: bold;
    }
  }
  .index02-contents-2__text {
    font-size: fz(15);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .index02-contents-2__list-item {
    padding: 30px 0;
    border-bottom: 1px solid #d2d2d2;
  }
  .index02-contents-2__list-anc {
    a {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}
