.contact-lens-inspection {
	.pc-mode{
		display: block;
	}
	.sp-mode{
		display: none;
	}
	
	padding-bottom: 49px;
	.title {
		border-bottom: 1px solid #dcdcdc;
		.inner {
			padding-top: 55px;
			padding-bottom: 50px;
			text-align: center;
			@include mincho-murasaki-s(36);
			background: url('/img/contact_lens/inspection/bg_01.png') repeat-x left top;
			border-bottom: 2px solid $c-murasaki;
			margin-bottom: -2px;
		}
	}
	.box-01 {
		padding: 28px;

		h3 {
			@include mincho-murasaki-s(24);
			margin-bottom: 0.6em;
		}
		p {

		}
	}
	.doctor {
		padding: 0 115px;
		margin-bottom: 40px;
		overflow: hidden;
		p {
			width: 315px;
			padding-top: 40px;
			float: left;
		}
		img {
			float: right;
		}
	}
	.bnr {
		position: relative;
		a {
			position: absolute;
			bottom: 26px;
			left: 505px;
		}
	}
}