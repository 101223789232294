@charset "utf-8";
/*------------------------------------*\
メインビジュアル
\*------------------------------------*/
/* 1.メインビジュアル
--------------------------------- */
.main-visual {
	position: relative;
	.main-visual-title{
		display: table-cell;
		height: 340px;
		@include mincho-s(40px);
		vertical-align: middle;
		padding-left: 42px;
	}
}

/* 1-1.メインビジュアル下層
--------------------------------- */
.sub-main-visual{
	.sub-main-visual-title{
		width: 938px;
		height: 190px;
		padding-top: 149px;
		padding-left: 42px;
		line-height: 1;
		margin: 0 auto;
		@include mincho-murasaki-s(41);
		text-align: left;
	}
}

/* メインビジュアル
------------------------------------------*/
.main-visual-sub{
	background: url(/img/common/bg_main_sub.png) 50% top no-repeat;
	border-bottom: 1px solid #e0e0e0;
	.main-visual-sub-title{
		width: 965px;
		height: 113px;
		font-size: 27px;
		padding-top: 86px;
		padding-left: 15px;
		line-height: 1;
		margin: 0 auto;
		color: $c-murasaki;
		font-weight: bold;
		text-align: left;
		font-family: $font-mincho;
	}
}