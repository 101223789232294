/* 調整サポートレンズ カスタム
------------------------------------------*/
.support_lens.select-clms{
  .select-clms-left{
    width: 360px;
  }
  .select-clms-right{
    width: 450px;
    .item-name{
      margin-top: 3px;
    }
  }
}
