.contact-lens-flow {
	.pc-mode{
		display: block;
	}
	.sp-mode{
		display: none;
	}
	.contents01 .inner {
		padding-bottom: 60px;
		background: url(/img/contact_lens/flow/bg_01.png) no-repeat center 578px;
	}
	.box-01 {
		border: 4px solid #f2f2f2;
		border-top: none;
		padding-bottom: 20px;
		background: #fff url('/img/contact_lens/inspection/bg_01.png') repeat-x left top;
		text-align: center;
		p {
			padding: 27px 0 18px;
		}
	}

	.flow-list {

		li {
			margin-top: 20px;
			width: 645px;
			box-sizing: border-box;
			border: 2px solid #dfdfdf;
			background: #fff;
			overflow: hidden;
			padding: 15px 10px 10px 20px;
			&:nth-child(1), &:last-child {
				width: 100%;
				p, h3 {
					width: 660px;
				}
				h3 {
					float: left;
				}
			}
			&:last-child {
				margin-top: 110px;
			}
		}
		.step {
			background: url(/img/contact_lens/flow/bg_step_01.png) no-repeat left top;
			display: inline-block;
			width: 101px;
			height: 31px;
			float: left;
			color: #fff;
			line-height: 31px;
			padding: 0 0 0 11px;
			font-size: fz(22);
			text-decoration: none;
		}
		.title {
			float: left;
			line-height: 31px;
			font-size: fz(24);
			color: $c-murasaki;
			padding-left: 12px;
			font-family: $font-mincho;
		}
		h3 {
			overflow: hidden;
			border-bottom: 1px solid $c-murasaki;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}
		p {
			float: left;
			width: 330px;
		}
		img {
			float: right;
		}
	}


	// モーダル
	.step-consul-body{
		display: none;
	}

}