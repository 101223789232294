@charset "UTF-8";
/* =================================
プライバシーポリシー
================================= */
.privacy {
	.heading-title{
		background: url(/img/privacy/purple_belt.png) left top no-repeat;
		font-size: 18px;
		color: #fff;
		width: 980px;
		box-sizing: border-box;
		padding-left: 10px;
		@include vertical-align-middle(50px);
	}
	.date{
		font-size: 12px;
		text-align: right;
	}
	.inner-title{
		font-size: 16px;
		font-weight: bold;
		color: $c-murasaki;
	}
	.desc{
		font-size: 14px;
		line-height: 1.7;
	}
	.title-02.title-02-purple {
	  padding-bottom: 10px;
	  margin-bottom: 10px;
	}
	.mail{
		background: url(/img/privacy/common_ic13.gif) left 8px no-repeat;
		padding-left: 15px;
	}

}