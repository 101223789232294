@charset "utf-8";
/*------------------------------------*\
    ボックス
\*------------------------------------*/
/* 紫ボックス */
//.purple-frame-btn{
//  @include frame-btn(504px,5px,$c-murasaki,57px,20px);
//}

.box-corner {
    padding: 20px 30px;
    box-sizing: border-box;
    background-image: url(/img/common/bg_corner_01.png),
        url(/img/common/bg_corner_02.png),
        url(/img/common/bg_corner_03.png),
        url(/img/common/bg_corner_04.png),
        url(/img/common/bg_corner_tile.png);
    background-position: top left,
        top right,
        bottom left,
        bottom right,
        top left;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat;
    &__title {
        @include mincho-murasaki-s(18);
        text-align: left;
    }
}
