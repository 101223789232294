@charset "utf-8";
.glasses-set-lower {
  .main-visual-inner {
    display: table;
    min-height: 215px;
  }
  .main-visual__title, .main-visual__text {
    display: table-cell;
    height: 215px;
    vertical-align: middle;
  }
  .contents-list__item {
    .col-4 {
      margin-top: 40px;
    }
  }
  .icon-arrow {
    display: inline-block;
    font-weight: bold;
    margin: 5px 0;
    background: url(/img/common/icon_circle_01.png) left center no-repeat;
    padding-left: 32px;
  }
  .bg-glass {
    font-size: fz(24);
    font-weight: bold;
    font-family: $font-mincho;
    color: $c-murasaki;
    background: url(/img/glasses/set/lower/icon_glass.png) left center no-repeat;
    padding-left: 63px;
  }
}
