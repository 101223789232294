@charset "utf-8";
.single-glasses {
	.bg-pattern-01 .inner {
		padding: 20px 0;
	}
	.contents {
    background: #fff;
	}
  // utility
  .is-hide {
    display: none;
  }
  .is-show {
    display: inline-block!important;
  }
  // item header
	.item {
	}
	.item-header {
		height: 40px;
    line-height: 40px;
		border-bottom: 1px solid #efefef;
    padding: 12px;
    overflow: hidden;
	}
  .item-header-left {
    float: left;
  }
  .item-header-right {
    float: right;
    overflow: hidden;
  }
	.item-header__color {
    font-size: fz(24);
    font-weight: bold;
    border-bottom: 1px solid #000;
    span {
      font-family: $font-mincho;
      margin-left: 6px;
    }
	}
  .item-header__use-outer {
    float: right;
    margin-right: 10px;
  }
	.item-header__use {
    font-size: fz(24);
    font-weight: bold;
    font-family: $font-mincho;
	}
	.item-header__made-in {
		float: right;
	}
  .item-contents__pic {
    height: 520px;
    line-height: 520px;
    text-align: center;
  }
  // item btn
  .item-contents__btn {
    width: 960px;
    margin: 0 auto 8px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    box-sizing: border-box;
    overflow: hidden;
  }
  .item-contents__btn-textarea,
  .item-contents__btn-imgarea {
    float: left;
    overflow: hidden;
  }
  .item-contents__btn-textarea {
    width: 600px;
  }
  .item-contents__btn-imgarea {
    width: 360px;
  }
  // li is-active
  .item-contents__btn-text.is-active,
  .item-contents__btn-img.is-active {
    background: #593687;
    a {
      background: #ddd8e0;
    }
  }
  // li
  .item-contents__btn-text,
  .item-contents__btn-img {
    float: left;
    border-left: 1px solid #dcdcdc;
    padding-top: 5px;
    &:last-child {
      border-right: 1px solid #dcdcdc;
      a {
        width: 118px;
      }
    }
    a {
      display: table-cell;
      width: 119px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      border-top: 1px solid #dcdcdc;
    }
  }
  .item-contents__btn-text {
  }
  .item-contents__btn-img {
    img {
      width: 80%;
    }
    //上書き
    &.is-active {
      a {
        background: #fff;
      }
    }
  }

  // item description
	.item-contents__description {
		overflow: hidden;
		border-top: 1px solid #dbdbdb;
		border-bottom: 1px solid #dbdbdb;
	}
  .item-contents__description-logoarea {
    float: left;
    box-sizing: border-box;
  }
	.item-contents__description-logo {
    width: 220px;
		min-height: 200px;
		line-height: 200px;
		vertical-align: middle;
		text-align: center;
		img {
			max-width: 100%;
			height: auto;
		}
	}
  .item-contents__description-textarea {
    position: relative;
    float: right;
    width: 760px;
    min-height: 164px;
    padding: 18px 30px;
    box-sizing: border-box;
    border-left: 1px solid #dbdbdb;
  }
	.item-category {
		font-size: fz(18);
		font-family: $font-mincho;
		color: #4e4e4e;
	}
	.item-name {
		color: rgb(159,140,192);
		font-family: $font;
		font-size: 50px;
		@include fontBold;
	}
	.item-description {
		font-size: fz(14);
		color: #333;
	}
	.item-copy {
		position: absolute;
		top: 18px;
		right: 30px;
		color: #000;
		font-family: $font-mincho;
		font-size: fz(18);
		border-bottom: 2px solid #000;
	}
	.item-contents__detail {
		padding: 13px;
		border: 1px solid #dbdbdb;
    box-sizing: border-box;
		margin: 19px 17px 27px;
    overflow: hidden;
    .label-01 {
      float: left;
    }
	}
  .item-contents__detail-left {
    float: left;
    width: 420px;
  }
  .item-contents__detail-right {
    float: right;
    width: 498px;
    min-height: 154px;
    box-sizing: border-box;
    border-left: 1px solid #dbdbdb;
    padding-left: 15px;
  }
	.item-contents__detail-element {
		color: $c-murasaki;
		padding-bottom: 10px;
		border-bottom: 1px solid #dbdbdb;
		margin-bottom: 10px;
    overflow: hidden;
    dd {
      padding-left: 67px;

    }
	}
	.item-contents__detail-color {
    overflow: hidden;
    dd {
      padding-left: 67px;
    }
	}
	.item-contents__detail-size {
    overflow: hidden;
	}
  .item-contents__detail-size-img {
    margin-top: 40px;
  }
  .item-contents__detail-size-block {
    float: left;
    width: 40%;
  }
  .item-contents__detail-size-block--2 {
//    float: right;
    width: 25%;
	  dd {
		  margin-left: 2em;
	  }
  }
  .item-contents__detail-size-block--3 {
    clear: both;
    text-align: right;
  }
  .icon-num {
    display: inline-block;
    width: 17px;
    height: 17px;
    color: #fff;
    background: #000;
//    margin-left: -2em;
    text-align: center;
  }
  .first-value {
    margin-left: 0.5em;
  }
  // 値段
	.item-price__value {
		color: $c-murasaki;
		font-weight: bold;
		text-align: right;
		border-bottom: 1px solid $c-murasaki;
		padding-top: 25px;
		padding-bottom: 10px;
		.value01 {
			font-family: $font-mincho;
			font-size: fz(20);
		}
		.value02 {
			font-size: fz(30);
		}
		.value03 {
			font-family: $font;
			font-size: fz(40);
		}
		.value04 {
			font-size: fz(19);
		}
	}
	// 種類一覧 title
	.bg-flat-price {
		background: url(/img/glasses/detail/bg_flat_price.png) center top no-repeat;
		text-align: center;
		padding-top: 52px;
		margin-top: 50px;
		font-size: fz(20);
		color: $c-murasaki;
		font-family: $font-mincho;
	}
	.bg-flat-price-bottom {
		background: url(/img/glasses/detail/bg_flat_price_bottom.png) center bottom no-repeat;
		padding-bottom: 20px;
		margin-bottom: 30px;
		overflow: hidden;
	}
	// end 種類一覧
	.btnarea {
		padding: 20px 50px;
		background: #f7f7f7;
		a {
			width: 400px;
			margin: 0 auto;
		}
	}
	.btn--detail{
        text-align: right;
		margin-bottom: 20px;
	}
	.btn--back {
    text-align: right;
		padding: 20px 0;
		margin-right: 10px;
	}
}
.item-header__color,
.item-contents__pic-product,
.item-contents__pic-product img,
.item-contents__btn-imgarea {
	display: none;
}
.item-contents__pic-product {
	text-align: center;
	img {
		max-width: 630px;
		height: auto;
	}
}


.flat-price-title {
	text-align: center;
	font-size: fz(18);
}