.glasses-brand-common {
	.contents-list__img {
		margin-bottom: 10px;
		box-sizing: border-box;
		overflow: hidden;
		border: 1px solid #cccccc;
		padding: 10px;
		vertical-align: middle;
		height: 190px;
		line-height: 190px;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.contents-list__name {
		font-weight: bold;
		background: url(/img/common/icon_circle_01.png) left center no-repeat;
		padding-left: 23px;
		margin-bottom: 10px;
	}
}
