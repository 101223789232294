/* リード文
---------------------------------- */
.quality-lead-area{
  border-bottom: 2px solid #e9e9e9;
  .inner{
    padding:37px 0 28px 0;
    background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
    .quality-lead{
      @include mincho-murasaki-s(24);
      line-height: 1.8;
      padding-left: 24px;
    }
  }
}

/* 紫太枠画像
---------------------------------- */
.felt-purple-frame-img{
  border: 3px solid $c-murasaki;
  background: #fff;
  padding: 6px;
  img{
    display: block;
    border: 2px solid $c-murasaki;
  }
}

/* 下部メニュー
---------------------------------- */
.under-menu{
  border: 2px solid #e9e9e9;
  padding: 42px 0 39px 0;
  .inner-under-menu{
    width: 959px;
    margin: 0 auto;
    background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
    position: relative;
    padding: 58px 0 60px 0;
    border-bottom: 1px solid $c-murasaki;
  }

  .under-menu-title{
    position: absolute;
    background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
    width: 190px;
    text-align: center;
    @include vertical-align-middle(31px);
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    font-family: $font;
    margin: 0 auto;
    top: -14px;
    left: 0;
    right: 0;
  }

  .under-menu-list{
    text-align: center;
    a{
      display: block;
    }
    .under-menu-list-item{
      display: inline-block;
      width: 167px;
      margin-right: 46px;
      &.last{
        margin-right: 0;
      }
      .under-menu-list-item-img{
        display: block;
        width: 114px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      .under-menu-list-item-title{
        display: inline-block;
        text-align: center;
        padding-left: 22px;
        @include mincho-murasaki-s(18);
        background: url(/img/common/icon_circle_01.png) left 5px no-repeat;
      }
    }
  }

  .under-menu-to-top-btn {
    margin: 0 auto;
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 0;
    background: #fff;
    border: 4px solid $c-murasaki;
    font-family: $font-mincho;
    font-weight: bold;
    width: 252px;
    @include vertical-align-middle(33px);
    font-size: 16px;
    text-align: center;
    a{
      display: inline-block;
      color: $c-murasaki;
      padding-left: 22px;
      background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

/* =================================
 トップページ
================================= */
@import "quality/top";

/* =================================
 データ蓄積ページ
================================= */
@import "quality/data";

/* =================================
 商品ページ
================================= */
@import "quality/item";

/* =================================
 知識・技術ページ
================================= */
@import "quality/skill";

/* =================================
 アフターサービスページ
================================= */
@import "quality/after";

/* =================================
 深視力測定ページ
================================= */
@import "quality/depthperception";

/* =================================
 アイメック測定ページ
================================= */
@import "quality/eyemec";

/* =================================
 ロービジョンケアページ
================================= */
@import "quality/lowvision";

/* =================================
 アフターケアページ
================================= */
@import "quality/care";

/* =================================
 和真の店づくりページ
================================= */
@import "quality/store";

/* =================================
 和真のメガネ保証ページ
================================= */
@import "quality/guarantee";

/* =================================
 ファッションアドバイスページ
================================= */
@import "quality/fashion";

/* =================================
 おもてなし
================================= */
@import "quality/omotenashi";

/* =================================
 隣接クリニック
================================= */
@import "quality/contact_lens_store";
