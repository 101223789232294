.lead{
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  margin-bottom: 43px;
}

/* 中近両用レンズ カスタム
------------------------------------------*/
.blue_light.select-clms{
  .select-clms-left{
    width: 360px;
  }
  .select-clms-right{
    width: 450px;
  }
}