@charset "UTF-8";

.pl16{padding-left: 16px!important;}
.pt27{padding-top: 27px!important;}
.mb13{margin-bottom: 13px!important;}
.mb34{margin-bottom: 34px!important;}
.mb43{margin-bottom: 43px!important;}

.border-box{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.hearing-aid {

    .cf:after {
      content: "."; 
      display: block;
      clear: both;
      height: 0;
      visibility: hidden;
    }
    .cf {min-height: 1px;}
    * html .cf {
      height: 1px;
      /*¥*//*/
      height: auto;
      overflow: hidden;
      /**/
    }
    .main-visual {
        background: url(/img/hearing_aid/bg_main_01.png) center top no-repeat;
        height: 340px;
        //padding-top: 82px;
        @extend .border-box;
        &-title {
            color: $c-murasaki;
            @include mincho-murasaki-s(41);
        }
        &__text {
            @include mincho-s(20);
            margin-top: 1em;
        }
    }








    .section-01 {
        ul {
            width: 50%;
            padding-left: 148px;
            display: inline-block;
        }
        li {
            font-size: 16px;
            min-height: 3em;
            background: url(/img/common/icon_checkbox_01.png) no-repeat left top;
            padding-left: 27px;
        }
        span {
            border-bottom: 1px solid $c-murasaki;
        }
    }



    /* お客さまの声
    ------------------------------------------*/
    .balloon-customer-voice {
        height: 101px;
    }
    .balloon-belt-01 {
        border-top: 2px solid #ededed;
        border-bottom: 1px solid #e1e1e1;
        background: #fff;
    }
    .inner-customer-voice {
        position: relative;
        width: 980px;
        margin: -2px auto 0;
        border-top: 2px solid $c-murasaki;
        text-align: center;
    }
    .balloon-customer-voice-title {
        @include mincho-murasaki-s(24);
        margin-bottom: 11px;
        margin-top: 18px;
    }
    .balloon-customer-voice-triangle {
        position: absolute;
        top: 95px;
        left: 50%;
    }

    .customer-voice-area{
        .inner{
            position: relative;
            height: 788px;
        }
    }
    .balloon-belt-02 {
        height: 60px;
        background: url(/img/hearing_aid/balloon-belt-02.png) 50% top no-repeat;
    }
    .balloon-belt-02 {
        padding-top: 12px;
        text-align: center;
        @include mincho-s(24);
        color: #fff;
    }
}


/* お客さま配置
------------------------------------------*/
.person-area{
    position: absolute;
    .thum{
        float: left;
        .thum-img{
            width: 147px;
        }
        .post{
            text-align: center;
            @include mincho-s(15px);
            margin-top: -24px;
            color: #fff;
        }
    }
    .post-txt{
        display: none;
    }
    .sp-mode-i{
        display: none;
    }
    .voice{
        float: left;
        position: relative;
        display: table-cell;
        padding: 18px 20px;
        box-sizing: border-box;
        width: auto;
        z-index: 0;
        line-height: 1.4;
        font-size: 14px;
        border-radius: 5px;
        &:after{
            content: "";
            position: absolute;
            bottom: -15px;
            left: 16px;
            margin-left: 0;
            display: block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 15px 20px 0 0;
            z-index: -1;
        }
    }
}





.person-01{
    width: 452px;
    top: 29px;
    left: 2px;
    .thum{
        margin: 5px 7px 0 0;
        .thum-img{
            width: 154px;
        }
    }
    .voice{
        width: 290px;
        background: #dddae5;
        &:after{
            border-color: #dddae5 transparent transparent transparent;
        }
    }
}


.person-02{
    width: 458px;
    top: 17px;
    left: 508px;
    .thum{
        margin: 17px 16px 0 0;
        .thum-img{
            width: 142px;
        }
        .post{
            margin-top: -21px;
        }
    }
    .voice{
        width: 290px;

        background: #f7ecec;
        &:after{
            border-color: #f7ecec transparent transparent transparent;
        }
    }
}


.person-03{
    width: 290px;
    top: 231px;
    left: 5px;
    .thum{
        float: none;
        .thum-img{
            width: 154px;
        }
    }
    .voice{
        float: none;
        width: 290px;
        background: #f4f4f4;
        &:after{
            border-color: #f4f4f4 transparent transparent transparent;
            left: 111px;
        }
    }
}



.person-04{
    width: 641px;
    top: 199px;
    left: 329px;
    .thum{
        float: right;
        .thum-img{
            width: 326px;
        }
        .post{
            margin-top: -21px;
        }
    }
    .voice{
        float: right;
        width: 293px;
        height: 159px;
        margin: 21px 22px 0 0;
        background: #dcefee;
        padding-top: 60px;
        &:after{
            border-color: transparent #dcefee transparent transparent;
            left: auto;
            right: 16px;
            border-width: 0 20px 15px 0;
        }
    }
}

.person-silhouette-01{
    width: 472px;
    clear: both;
    top: 560px;
    left: 22px;
    .thum{
        width: 29px;
        margin: 41px 13px 0 0;
    }
    .voice{
        width: 430px;
        background: #dddae5;
        &:after{
            border-color: #dddae5 transparent transparent transparent;
        }
    }
}
.person-silhouette-02{
    width: 403px;
    top: 436px;
    left: 228px;
    .thum{
        width: 21px;
        margin: 29px 14px 0 0;
    }
    .voice{
        width: 335px;
        background: #f5e9f3;
        &:after{
            border-color: #f5e9f3 transparent transparent transparent;
        }
    }
}
.person-silhouette-03{
    width: 425px;
    clear: both;
    top: 560px;
    left: 532px;
    .thum{
        width: 29px;
        margin: 20px 14px 0 0;
    }
    .voice{
        width: 382px;
        background: #f0f0f0;
        &:after{
            border-color: #f0f0f0 transparent transparent transparent;
        }
    }
}


.person-silhouette-04{
    width: 433px;
    top: 676px;
    left: 48px;
    .thum{
        width: 30px;
        margin: 18px 16px 0 0;
    }
    .voice{
        width: 387px;
        background: #e7f4f3;
        &:after{
            border-color: #e7f4f3 transparent transparent transparent;
        }
    }
}



.person-silhouette-05{
    width: 430px;
    top: 676px;
    left: 531px;
    .thum{
        float: right;
        width: 31px;
        margin-right: 24px;
    }
    .voice{
        float: right;
        width: 375px;
        background: #f8eeee;
        box-sizing: border-box;
        padding-left: 66px;
        &:after{
            border-color: transparent #f8eeee transparent transparent;
            left: auto;
            right: 16px;
            border-width: 0 20px 15px 0;
        }
    }
}

/* BG（ネット）
------------------------------------------*/
.bg-net{
    background: url(/img/hearing_aid/bg_net.gif) left top repeat;
    .net-inner-top{
        padding-top: 34px;
        &.net-inner-01-top{
            background: url(/img/hearing_aid/bg_net_inner_01_top.png) left top no-repeat;
        }
        &.net-inner-02-top{
            background: url(/img/hearing_aid/bg_net_inner_02_top.png) left top no-repeat;
        }
        &.net-inner-04-top{
            background: url(/img/hearing_aid/bg_net_inner_03_top.png) left top no-repeat;
        }
        &.net-inner-05-top{
            background: url(/img/hearing_aid/bg_net_inner_04_top.png) left top no-repeat;
        }
    }
    .net-inner-bottom{
        margin-top: -19px;
        padding-bottom: 34px;
        &.net-inner-01-bottom{
            background: url(/img/hearing_aid/bg_net_inner_01_bottom.png) left bottom no-repeat;
        }
        &.net-inner-02-bottom{
            background: url(/img/hearing_aid/bg_net_inner_02_bottom.png) left bottom no-repeat;
        }
        &.net-inner-04-bottom{
            background: url(/img/hearing_aid/bg_net_inner_03_bottom.png) left bottom no-repeat;
        }
        &.net-inner-05-bottom{
            background: url(/img/hearing_aid/bg_net_inner_04_bottom.png) left bottom no-repeat;
        }
    }
    .net-inner-middle{
        margin-bottom: -19px;
        padding: 0 18px;
        &.net-inner-04-middle{
            height: 274px;
        }
        &.net-inner-05-middle{
            height: 274px;
        }
    }
}

/* 3つのサービス
------------------------------------------*/
.hear-service{
    margin-bottom: 50px;
}
.hear-service-inner{
    width: 980px;
    margin: 0 auto;
    .box-corner{
        padding: 13px 18px;
        background-image: url(/img/common/bg_corner_purple_01.png), url(/img/common/bg_corner_purple_02.png), url(/img/common/bg_corner_purple_03.png), url(/img/common/bg_corner_purple_04.png),url(/img/common/bg_corner_tile.png);
        background-position: top left, top right, bottom left, bottom right,top left;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat,repeat;
    }
}
.hear-service-list-01{
    margin-bottom: 20px;
}
.hear-service-list-02{
    width: 480px;
    float: left;
    margin-right: 20px;
}
.hear-service-list-03{
    width: 480px;
    float: left;
}

.hear-part{
    width: 134px;
    height: 99px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 1;
    padding-top: 35px;
    float: left;
    margin-right: 19px;
    font-weight: bold;
    &.hear-part-01{
        background: url(/img/hearing_aid/bg_hear_part_01.png) left top no-repeat;
    }
    &.hear-part-02{
        margin-right: 9px;
        background: url(/img/hearing_aid/bg_hear_part_02.png) left top no-repeat;
    }
    &.hear-part-03{
        margin-right: 9px;
        background: url(/img/hearing_aid/bg_hear_part_03.png) left top no-repeat;
    }
}
.hear-part-txt{
    padding-bottom: 9px;
    border-bottom: 3px solid #01c1b7;
    margin-top: 30px;
    float: left;
    display: inline-block;;
    .sub{
        font-weight: bold;
        font-size: 21px;
        line-height: 1;
        display: block;
        padding-bottom: 17px;
        color: #333;
        .net-inner-02-middle &{
            font-size: 19px;
        }
        .net-inner-03-middle &{
            font-size: 20px;
            display: inline-block;
            padding-bottom: 0;
        }
    }
    .main{
        font-size: 37px;
        line-height: 1;
        font-weight: bold;
        font-family: $font-mincho;
        color: #4e3c93;
        .net-inner-02-middle &{
            font-size: 23px;
        }
        .net-inner-03-middle &{
            font-size: 39px;
        }
    }
}
.reserve-btn{
    float: right;
    width: 96px;
    margin-right: 55px;
    margin-top: 78px;
    a{
        width: 96px;
        height: 31px;
        line-height: 31px;
        display: block;
        color: #fff;
        font-family: $font-mincho;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        background: url(/img/hearing_aid/bg_reserve_btn.png) left top no-repeat;
        &:hover{
            text-decoration: none;
        }
    }
}

/* お試しの流れ
------------------------------------------*/
.trial-flow{
    width: 929px;
    height: 140px;
    border: 3px solid #bbb1c1;
    background: #fff;
    margin-bottom: 7px;
    .flow-img{
        width: 929px;
        height: 140px;
        position: relative;
        background: url(/img/hearing_aid/trial_flow_img.png) 17px 10px no-repeat;
    }
    .flow-txt{
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        position: absolute;
        top: 74px;
        &.flow-01{
            left: 57px;
        }
        &.flow-02{
            left: 186px;
        }
        &.flow-03{
            left: 315px;
        }
        &.flow-04{
            left: 673px;
        }
        &.flow-05{
            left: 841px;
        }
        &.flow-06{
            top: 22px;
            left: 526px;
        }
        &.flow-07{
            top: 54px;
            left: 473px;
            color: #333;
            width: 142px;
            line-height: 1.3;
        }
        &.flow-08{
            top: 40px;
            left: 201px;
            color: #333;
            background: #fff;
        }
        &.flow-09{
            top: 40px;
            left: 761px;
            color: #333;
            background: #fff;
        }
        &.flow-10{
            top: -13px;
            left: 9px;
            color: #4e3c93;
            background: #fff;
            line-height: 1;
            padding: 4px 5px;
            font-size: 16px;
        }

    }
}

.txt-desc-02{
    font-size: 13px;
    line-height: 1.7;
}
.white-box{
    padding: 11px 18px;
    background: #fff;
}

.service-02-left{
    float: left;
    width: 248px;
}
.service-02-right{
    float: right;
    margin-top: -20px;
    width: 151px;
}

.service-03-left{
    float: left;
    width: 268px;
}
.service-03-right{
    float: right;
    margin-top: -34px;
    width: 151px;
}


/* その他のサービス
------------------------------------------*/
.type-box-a-list{
    width: 468px;
    //height: 176px;
    border: 2px solid #dfdfdf;
    background: #fff;
    padding-bottom: 6px;
    &.type-box-a-left{
        float: left;
    }
    &.type-box-a-right{
        float: right;
    }
    &.type-box-a-list-custom{
        height: auto;
        .type-box-a-desc{
            height: 47px;
            .detail-btn{
                right: 16px;
                left: auto;
            }
        }
    }
}
.type-box-a-title{
    @include vertical-align-middle(44px);
    @include mincho-murasaki-s(20);
    padding-left: 28px;
    background: url(/img/hearing_aid/bg_type_box_a.gif) left 12px no-repeat;
}
.type-box-a-desc{
    background: #f5f4f6;
    width: 424px;
    height: 107px;
    margin: 0 auto;
    padding: 10px 16px;
    position: relative;
    .type-box-a-desc-left{
        float: left;
        width: 248px;
    }
    .type-box-a-desc-txt{
        font-size: 14px;
    }
    .detail-btn{
        position: absolute;
        bottom: 10px;
        left: 16px;
        a{
            display: block;
        }
    }
    .type-box-a-desc-right{
        float: right;
        width: 171px;
    }
}


/* おすすめ補聴器
------------------------------------------*/
.type-box-b{
    margin-bottom: 46px;
}
.type-box-b-list{
    width: 458px;
    padding: 5px;
    border: 1px solid #dfdfdf;
    position: relative;
    &.type-box-b-left{
        float: left;
    }
    &.type-box-b-right{
        float: right;
    }
    .type-box-b-title{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-mincho;
        color: #333;
        line-height: 36px;
        height: 36px;
        text-align: right;
        border-bottom: 2px solid #f5f5f5;
        padding-right: 6px;
    }
    .type-box-b-thum{
        width: 456px;
        text-align: center;
    }
    .type-box-b-detail-area{
        background: #f5f4f6;
        padding: 7px 0;
        text-align: center;
        .detail-btn{
            display: inline-block;
            a{
                height: 33px;
                line-height: 33px;
                background: #fff;
                font-family: $font-mincho;
                display: block;
                font-size: 14px;
                color: #333;
                &:hover{
                    text-decoration: none;
                }
                span{
                    padding-left: 34px;
                    padding-right: 16px;
                    background: url(/img/common/icon_circle_01.png) 12px 7px no-repeat;
                    display: block;
                }
            }
        }
    }
    .type-box-b-type{
        position: absolute;
        width: 85px;
        height: 52px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        padding-top: 32px;
        line-height: 1.29;
        background: url(/img/hearing_aid/bg_recommend_type.png) left top no-repeat;
        top: -27px;
        left: -15px;
        &.sec-row{
            height: 58px;
            padding-top: 26px;
        }
    }
}

.type-box-c{
    .type-box-c-list{
        width: 463px;
        &.type-box-c-left{
            float: left;
            margin-left: 5px;
        }
        &.type-box-c-left{
            float: right;
            margin-right: 5px;
        }
        .type-box-c-title{
            font-size: 16px;
            font-weight: bold;
            font-family: $font-mincho;
            color: #4e3c93;
            line-height: 1;
            border-bottom: 2px solid #4e3c93;
            padding-bottom: 6px;
            margin-bottom: 3px;
        }
        .type-box-c-desc{
            font-size: 14px;
        }
        .detail-btn{
            float: right;
            a{
                height: 33px;
                line-height: 33px;
                font-family: $font-mincho;
                display: block;
                font-size: 14px;
                color: #333;
                &:hover{
                    text-decoration: none;
                }
                span{
                    padding-left: 34px;
                    padding-right: 12px;
                    background: url(/img/common/icon_circle_01.png) 12px 7px no-repeat;
                    display: block;
                }
            }
        }
    }
}

.hearing-aid{
    .hearing_aid_top{
        .main-visual{
            .main-visual-txt-wrap{
                display: table-cell;
                height: 340px;
                vertical-align: middle;
            }
            .main-visual-title-02{
                color: #453288;
                font-family: "Roboto Condensed", "A1 Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
                font-weight: bold;
                font-size: 308%;
                padding-left: 42px;
            }
            .main-visual-sub-02{
                font-family: "Roboto Condensed", "A1 Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
                font-weight: bold;
                font-size: 20px;
                color: #333;
                padding-left: 42px;
            }
        }
        .exp-section-01 .exp-info .exp-txt span{
            border-bottom: 2px solid #a78dc3;
        }
        .exp-section-02{
            padding: 39px 0 35px;
            .exp-section-txt-02{
                border: 9px solid #c7e1bd;
                .exp-arrow{
                    top: -20px;
                }
            }
        }
        .balloon-01{
            border-top: 2px solid #ededed;
        }
        .checksheet-wrap{
            margin-bottom: 49px;
            border-top: 1px solid #dfdfdf;
            border-bottom: 1px solid #dfdfdf;
            @extend .border-box;
            padding: 25px 0 34px;
            .inner{
                width: 988px;
                margin: 0 auto;
            }
            .checksheet-title{
                text-align: center;
            }
            .checksheet-desc{
                font-size: 14px;
                width: 705px;
                margin: 0 auto;
                text-align: center;
                margin-top: -26px;
                margin-bottom: 35px;
            }
            .btn-checksheet-wrap{
                width: 982px;
                margin: 0 auto;
            }
            .btn-family,.btn-self{
                width: 472px;
                height: 120px;
                @extend .border-box;
                a{
                    color: #fff;
                    &:hover{
                        text-decoration: none;
                    }
                }
                .btn-txt{
                    font-size: 33px;
                    font-family: $font-mincho;
                    color: #fff;
                    margin-left: 260px;
                    margin-top: 25px;
                    line-height: 1.3;
                    background: url(/img/common/icon_circle_white.png) right center no-repeat;
                    margin-right: 18px;
                    span{
                        font-size: 22px;
                        display: block;
                        font-family: $font-mincho;
                        color: #fff;
                    }
                }
            }
            .btn-family{
                float: left;
                background: url(/img/hearing_aid/index/btn_checksheet_family.png) left top no-repeat;
            }
            .btn-self{
                float: right;
                background: url(/img/hearing_aid/index/btn_checksheet_self.png) left top no-repeat;
            }
        }
        .hear-part-txt{
            text-align: center;
            float: none;
            margin-top: 7px;
            .sub{
                padding-bottom: 23px;
            }
            .main{
                font-size: 44px;
                .main-02{
                    font-size: 30px;
                }
            }
        }
        .trial-flow-wrap{
            position: relative;
            text-align: center;
            .reserve-btn{
                margin-right: 250px;
                position: absolute;
                top: 0;
                right: 0;
                float: none;
                margin-top: 2px;
            }
        }
        
        .trial-icon{
            position: absolute;
            top: -21px;
            left: 0;
            margin-left: 278px;
        }

        .banner-wrap{
            background: $c-murasaki;
            padding: 21px 0 25px;
            margin-bottom: 40px;
            .inner{
                width: 987px;
                margin: 0 auto;
            }
            .banner-big{
                float: left;
                width: 475px;
            }
            .banner-small{
                float: right;
                width: 472px;
                &.banner-small-01{
                    margin-bottom: 30px;
                }
            }
        }
        .person-04 .voice{
            font-size: 16px;
        }

        .hear-service-wrap{
            padding-top: 44px;
            border-top: 1px solid #dfdfdf;
            .type-box-a{
                margin-bottom: 55px;
            }
        }

        .compare-wrap{
            height: 323px;
            background: url(/img/hearing_aid/index/bg_compare.png) center top no-repeat;
            text-align: center;
            margin-bottom: 52px;
            .copytxt{
                display: inline-block;
                margin-top: 50px;
                font-size: 25px;
                font-weight: bold;
                color: #fff;
                padding-bottom: 5px;
                border-bottom: 3px solid #c7e1bd;
                font-family: $font-mincho;
            }
            .compare-btn{
                text-align: center;
                margin-top: 150px;
                a{
                    width: 252px;
                    height: 40px;
                    line-height: 40px;
                    border: 4px solid #fff;
                    display: inline-block;
                    background: #453288;
                    span{
                        color: #fff;
                        font-size: 15px;
                        padding-left: 28px;
                        background: url(/img/common/icon_circle_white.png) no-repeat 5px center;
                    }
                }
            }
        }

        .aftercare-box-wrap{
            width: 985px;
            margin: 0 auto 62px;
            text-align: center;
            position: relative;
            .box-corner{
                background-image: url(/img/common/bg_corner_purple_01.png), url(/img/common/bg_corner_purple_02.png), url(/img/common/bg_corner_purple_03.png), url(/img/common/bg_corner_purple_04.png);
                background-position: top left, top right, bottom left, bottom right;
                background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
                background-color: #f6f5f7;
                height: 172px;
                padding: 45px 0 0;
            }
            .aftercare-box-head{
                font-size: 31px;
                font-family: $font-mincho;
                font-weight: bold;
                color: $c-murasaki;
                padding-top: 13px;
                border-bottom: 4px solid #c6b6d7;
                display: inline-block;
                position: relative;
                &:before{
                    content: url(/img/hearing_aid/index/icon_aftercare_box_01.png);
                    position: absolute;
                    left: -80px;
                    top: 0;
                    z-index: 10;
                }
                &:after{
                    content: url(/img/hearing_aid/index/icon_aftercare_box_02.png);
                    position: absolute;
                    right: -83px;
                    top: 0;
                    z-index: 10;
                }
            }
            .aftercare-box-btn{
                text-align: center;
                position: absolute;
                bottom: -17px;
                left: 0;
                right: 0;
                a{
                    width: 145px;
                    height: 33px;
                    line-height: 33px;
                    border: 1px solid #c4b3d6;
                    display: inline-block;
                    span{
                        font-size: 14px;
                        display: inline-block;
                        padding-top: 2px;
                    }
                }
            }
        }

        .trial-flow{
            margin: 0 auto 7px;
            .flow-txt.flow-06{
                top: 19px;
            }
        }

        .other-wrap{
            padding-top: 40px;
            border-top: 1px solid #dfdfdf;
            .type-box-c-list-01{
                float: left;
            }
            .type-box-c-list-02{
                float: right;
            }
        }

        .balloon-01{
            .title{
                padding: 22px 0 20px;
                display: inline-block;
                padding-left: 55px;
                background: url(/img/common/icon_comment_01.png) left 19px no-repeat;
            }
        }


    }
}