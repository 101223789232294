/* ネッツペックコートレンズ カスタム
------------------------------------------*/
.netspec.special-clms{
  .special-clms-left{
    width: 358px;
    margin-left: 2px;
    .special-sub-img-01{
      margin-bottom: 24px;
    }
    .special-sub-img-02{
      margin-left: 11px;
    }
  }
  .special-clms-right{
    width: 450px;
    .special-clms-txt.type-list{
      padding-left: 1em;
      text-indent: -1em;
    }
    .price.custom{
      padding-right: 1em;
    }
    .price-area {
      .sup {
        text-align: left;
      }
    }
  }
}