@charset "utf-8";
.glasses-index {
	.gindex-inner {
		width: 668px;
		margin: 0 auto;
	}
	.gindex-contents-title {
		position: relative;
		height: 69px;
		line-height: 69px;
		font-size: fz(24);
		font-weight: bold;
		font-family: $font-mincho;
		color: $c-murasaki;
		padding-top: 40px;
		&:after {
			position: absolute;
			bottom: 10px;
			left: 0;
			content: "";
			display: block;
			width: 100%;
			height: 3px;
			border-bottom: 3px solid $c-murasaki;
		}
	}
	.gindex-contents-title--bg-01 {
		width: 255px;
		margin: 0 auto;
		background: url(/img/glasses/lens/choose/bg_contents_title.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.gindex-contents-title--bg-02 {
		background: url(/img/glasses/lens/choose/bg_contents_title_02.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.gindex-contents-title--bg-03 {
		background: url(/img/glasses/lens/choose/bg_contents_title_03.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.gindex-contents-title--bg-04 {
		width: 400px;
		margin: 0 auto;
		background: url(/img/glasses/lens/choose/bg_contents_title_04.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.gindex-contents-title--bg-05 {
		width: 340px;
		margin: 0 auto;
		background: url(/img/glasses/lens/choose/bg_contents_title_05.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.gindex-contents-title--bg-06 {
		width: 400px;
		margin: 0 auto;
		background: url(/img/glasses/lens/choose/bg_contents_title_06.png) left bottom no-repeat;
		padding-left: 60px;
	}
	.list--short {
		width: 316px;
		margin: 0 auto;
	}
	.list-title {
		height: 40px;
		line-height: 40px;
		color: #fff;
		font-size: fz(17);
		font-weight: bold;
		text-align: center;
	}
	.list-title--short {
		background: url(/img/glasses/lens/choose/bg_list_title.png) left top no-repeat;
	}
	.list-title--long {
		width: 598px;
		background: url(/img/glasses/lens/choose/bg_list_title_long.png) left top no-repeat;
	}
	.list-inner {
		border-left: 1px solid #e5e5e5;
		border-right: 1px solid #e5e5e5;
	}
	.list-img {
		border-bottom: 1px solid #e5e5e5;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.list-text {
		text-align: center;
		padding: 24px 0;
		min-height: 38px;
		background: #fff;
		border-bottom: 1px solid #e5e5e5;
	}
	.list-anc {
		height: 32px;
		line-height: 32px;
		font-weight: bold;
		font-family: $font-mincho;
		text-align: center;
		background: #fafafa url(/img/common/icon_circle_01.png) no-repeat 96px center;
		padding-left: 30px;
		border-bottom: 1px solid #e5e5e5;
	}
	.list-anc--long {
		width: 597px;
		background: #fafafa url(/img/common/icon_circle_01.png) no-repeat 40% center;
		border-left: 1px solid #e5e5e5;
		border-right: 1px solid #e5e5e5;
	}
	.note {
		padding-top: 10px;
	}
}
.select-clms {
	margin-bottom: 60px;
	&:last-of-type {
		margin-bottom: 0;
	}
	.item-name {
		padding-top: 15px;
		span {
			padding-left: 1em;
		}
	}
}