@charset "UTF-8";

.border-box{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.hearing-aid{
  .cf:after {
    content: "."; 
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
  .cf {min-height: 1px;}
  * html .cf {
    height: 1px;
    /*¥*//*/
    height: auto;
    overflow: hidden;
    /**/
  }
  .pc-mode{
    display: block;
  }
  .sp-mode{
    display: none;
  }
  .hearing-aid-aftercare{
    .after-section-01{
      padding: 13px 0 33px 0;
    }
    .lead-box {
      width: 100%;
      padding: 32px 36px;
      margin-bottom: 50px;
      text-align: center;
      background-image: url(/img/sunglasses/pro_shop/bg_contents_top.png),url(/img/sunglasses/pro_shop/bg_contents_bottom.png),url(/img/sunglasses/pro_shop/bg_contents_middle.png);
      background-repeat: no-repeat,no-repeat,repeat-y;
      background-size: contain;
      background-position: left top,left bottom,left;
      @extend .border-box;
    }
    .lead-title {
      width: 100%;
      font-size: 24px;
      font-family: $font-mincho;
      color: #4e3c93;
      background: url(/img/hearing_aid/aftercare/line_guarantee_03.png) left bottom no-repeat;
      padding-bottom: 12px;
      margin: 0 auto 17px;
      text-align: left;
      @extend .border-box;
    }
    .lead-desc {
      float: left;
      font-size: 14px;
      text-align: left;
      line-height: 1.6;
      width: percentage(633/908);
      margin-left: percentage(14/908);
      @extend .border-box;
    }
    .lead-img{
      float: right;
      width: percentage(193/908);
      margin-right: percentage(20/908);
      @extend .border-box;
    }
    .care-head-wrap{
      position: relative;
      margin-bottom: 40px;
      .care-num{
        position: absolute;
        top: 0;
        left: 0;
        width: 94px;
        height: 94px;
        font-size: 60px;
        text-align: center;
        line-height: 1.6;
        color: #fff;
        background: url(/img/hearing_aid/aftercare/bg_care_head.png) left bottom no-repeat;
      }
      .care-head{
        font-size: 32px;
        font-family: $font-mincho;
        color: #4e3c93;
        padding: 20px 0 8px 93px;
        border-bottom: 2px solid #453288;
        text-align: left;
        margin-bottom: 26px;
        margin-left: 30px;
        @extend .border-box;
      }
      .care-desc{
        font-size: 14px;
        line-height: 1.6;
        padding-left: 123px;
      }
    }
    .type-care-01{
      margin-bottom: 30px;
    }
    .tab-head-wrap{
      border-bottom: 5px solid #453288;
      @extend .border-box;
      .tab-head{
        font-size: 18px;
        display: inline-block;
        font-family: $font-mincho;
        color: #fff;
        text-align: center;
        background: #453288;
        padding: 10px 53px 5px;
        line-height: 1;
        border-radius: 13px 13px 0 0;
        -moz-border-radius: 13px 13px 0 0;
        -webkit-border-radius: 13px 13px 0 0;
        @extend .border-box;
      }
    }
    .type-case{
      margin-bottom: 37px;
      &.type-case-01-03{
        margin-bottom: 71px;
      }
      &.type-case-02-01{
        margin-bottom: 0;
      }
    }
    .type-case-img-wrap{
      margin-top: 4px;
      background-image: url(/img/hearing_aid/aftercare/bg_type_case_img_left.png),url(/img/hearing_aid/aftercare/bg_type_case_img_right.png);
      background-repeat: no-repeat,no-repeat;
      background-size: contain;
      background-position: left top,right top;
      background-color: #fff;
      text-align: center;
      padding: 16px 0 19px;
      font-size: 0;
      @extend .border-box;
      .type-case-img{
        display: inline-block;
        margin-left: 26px;
        &:first-child{
          margin-left: 0;
        }
      }
    }

    .care-step-wrap{
      border: 3px solid #e9e9e9;
      padding: 16px 17px;
      background: #fff;
      @extend .border-box;
      .care-step-list{
        font-size: 0;
        .care-step-list-item{
          position: relative;
          display: inline-block;
          margin-left: 10px;
          width: 227px;
          height: 270px;
          padding-top: 28px;
          background: url(/img/hearing_aid/aftercare/bg_care_step_list.png) left top repeat;
          vertical-align: top;
          @extend .border-box;
          &:first-child{
            margin-left: 0;
          }
          &:after{
            content: url(/img/hearing_aid/aftercare/care_step_arrow_right.png);
            position: absolute;
            top: 44%;
            right: -17px;
            z-index: 10;
          }
          &.last{
            &:after{
              content: normal;
            }
          }
          .care-step-num{
            position: absolute;
            top: 15px;
            left: 15px;
            width: 54px;
            height: 54px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            line-height: 52px;
            background: url(/img/hearing_aid/aftercare/bg_care_step_num.png) left top repeat;
          }
          .care-step-list-item-img{
            text-align: center;
            margin-bottom: 18px;
          }
          .care-step-list-item-desc{
            font-size: 14px;
            line-height: 1.2;
            width: 75.6%;
            margin: 0 auto;
            .caution{
              display: block;
              font-size: 11px;
            }
          }
        }
      }
      .care-step-list-02{
        .care-step-list-item{
          background: url(/img/hearing_aid/aftercare/bg_care_step_list.png) left top repeat;
          position: relative;
          width: 464px;
          height: 270px;
          @extend .border-box;
          &.care-step-list-item-01{
            float: left;
            height: 495px;
            position: relative;
            padding: 28px 30px 0;
            &:after{
              content: url(/img/hearing_aid/aftercare/care_step_arrow_right.png);
              position: absolute;
              top: 20%;
              right: -17px;
              z-index: 10;
            }
          }
          &.care-step-list-item-02,&.care-step-list-item-03{
            float: right;
            height: 244px;
          }
          &.care-step-list-item-02{
            position: relative;
            margin-bottom: 7px;
            .care-step-list-item-img{
              float: left;
              margin-left: 79px;
              margin-top: 47px;
            }
            .care-step-list-item-desc{
              float: right;
              width: 181px;
              margin-right: 25px;
              margin-top: 37px;
            }
            .care-step-num{
              position: absolute;
              top: 16px;
              left: 14px;
              width: 54px;
              height: 54px;
              text-align: center;
              color: #fff;
              font-size: 20px;
              line-height: 52px;
              background: url(/img/hearing_aid/aftercare/bg_care_step_num.png) left top repeat;
            }
            &:after{
              content: url(/img/hearing_aid/aftercare/care_step_arrow_down.png);
              position: absolute;
              bottom: -17px;
              left: 214px;
              z-index: 10;
            }
          }
          &.care-step-list-item-03{
            position: relative;
            .care-step-list-item-img{
              float: left;
              margin-left: 82px;
              margin-top: 14px;
            }
            .care-step-list-item-desc{
              float: right;
              width: 190px;
              margin-right: 9px;
              margin-top: 37px;
            }
            .care-step-num{
              position: absolute;
              top: 16px;
              left: 14px;
              width: 54px;
              height: 54px;
              text-align: center;
              color: #fff;
              font-size: 20px;
              line-height: 52px;
              background: url(/img/hearing_aid/aftercare/bg_care_step_num.png) left top repeat;
            }
          }
        }
        .care-step-list-item-list-item{
          width: 173px;
          margin-bottom: 18px;
          position: relative;
          &.care-step-list-item-list-item-01{
            float: left;
          }
          &.care-step-list-item-list-item-02{
            float: right;
          }
          .care-step-list-item-list-item-img{
            margin-bottom: 9px;
          }
          .care-step-num{
            position: absolute;
            top: -10px;
            left: -15px;
            width: 54px;
            height: 54px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            line-height: 52px;
            background: url(/img/hearing_aid/aftercare/bg_care_step_num.png) left top repeat;
          }
        }
        .example-wrap{
          margin-bottom: 17px;
          .correct-wrap{
            float: left;
            width: 173px;
            position: relative;
          }
          .incorrect-wrap{
            float: right;
            width: 173px;
            position: relative;
          }
          .correct-mark,.incorrect-mark{
            position: absolute;
            top: -9px;
            left: -13px;
            z-index: 10;
          }
        }
        .caution-txt-01{
          margin-bottom: 15px;
        }
        .caution-txt-02{
          font-size: 14px;
          line-height: 1.2;
        }
      }

      .care-detail-list{
        float: left;
        width: 464px;
        .care-detail-list-item{
          background: url(/img/hearing_aid/aftercare/bg_care_step_list.png) left top repeat;
          position: relative;
          width: 464px;
          padding: 17px 30px 0;
          @extend .border-box;
          .care-detail-list-item-head{
            text-align: center;
            font-family: $font-mincho;
            font-size: 14px;
            background: #a78dc3;
            line-height: 1;
            color: #fff;
            padding: 8px 0 10px;
            margin-bottom: 13px;
          }
          .care-detail-list-item-img{
            margin-bottom: 13px;
          }
          .care-detail-list-item-desc{
            font-size: 14px;
            line-height: 1.2;
          }
          &.care-detail-list-item-01{
            margin-bottom: 7px;
            height: 261px;
          }
          &.care-detail-list-item-02{
            height: 280px;
            .care-detail-type-open-01{
              float: left;
              width: 173px;
            }
            .care-detail-type-open-02{
              float: right;
              width: 173px;
            }
            .type-open-img{
              margin-bottom: 13px;
            }
          }
        }
      }
      .care-detail-right{
        float: right;
        width: 464px;
        height: 548px;
        position: relative;
        padding: 17px 35px 0;
        background: url(/img/hearing_aid/aftercare/bg_care_step_list.png) left top repeat;
        @extend .border-box;
        .care-detail-top{
          margin-bottom: 40px;
          .care-detail-top-01{
            width: 173px;
            float: left;
          }
          .care-detail-top-02{
            width: 173px;
            float: right;
          }
          .care-detail-top-img{
            margin-bottom: 13px;
          }
        }
        .care-detail-list-item-desc-01{
          margin-bottom: 23px;
        }
      }
      .care-example-list{
        font-size: 0;
        .care-example-list-item{
          width: 178px;
          display: inline-block;
          margin-left: 10px;
          height: 238px;
          padding-top: 17px;
          background: url(/img/hearing_aid/aftercare/bg_care_step_list.png) left top repeat;
          vertical-align: top;
          .care-example-list-item-img{
            text-align: center;
            margin-bottom: 25px;
          }
          .care-example-list-item-desc{
            width: 123px;
            margin: 0 auto;
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }

    .after-section-02{
      .balloon-belt-02.balloon-belt-02-title{
        margin-bottom: 40px;
      }
      .after-service-wrap{
        width: 980px;
        margin: 0 auto 53px;
        .hear-service-list{
          padding: 16px 17px;
          height: 338px;
          @extend .border-box;
          .hear-part{
            font-family: Roboto Condensed,Hiragino Kaku Gothic ProN,"ヒラギノ角ゴ ProN W3","ヒラギノ角ゴ W3 JIS2004","メイリオ Meiryo",Lucida Grande,sans-serif;
          }
          .hear-part-txt{
            font-size: 19px;
          }
          &.hear-service-list-03{
            .hear-part-txt{
              margin-top: 37px;
              .sub{
                display: inline;
              }
              .main{
                font-size: 39px;
              }
            }
          }
        }
      }
    }

    .security-wrap{
      border-top: 1px solid #e0e0e0;
      padding: 44px 0 41px;
      .security-box{
        width: 980px;
        height: 211px;
        margin: 0 auto;
        //background: url(/img/hearing_aid/aftercare/bg_security.png) left bottom no-repeat;
        background: #4b398f url(/img/hearing_aid/aftercare/bg_security_02.png) left top no-repeat;
        @extend .border-box;
        padding: 7px 10px;
        &-inner{
          border: 2px solid #fff;
        }
        .security-head{
          font-size: 24px;
          float: left;
          font-family: $font-mincho;
          color: #fff;
          font-weight: bold;
          line-height: 1;
          padding: 20px 0 15px 74px;
          margin: 66px 0 20px 44px;
          background: url(/img/hearing_aid/aftercare/icon_security.png) left bottom no-repeat;
        }
        .security-desc{
          float: right;
          font-size: 17px;
          margin-right: 60px;
          width: 378px;
          margin: 45px 50px 37px 0;
          color: #fff;
        }
        .security-btn{
          float: right;
          width: 298px;
          margin-right: 50px;
          margin-bottom: 19px;
          .btn-05{
            border: 5px solid #fff;
            background: #4b398f;
            height: 32px;
            line-height: 32px;
            span{
              color: #fff;
              font-size: 15px;
              padding-left: 28px;
              background: url(/img/common/icon_circle_white.png) no-repeat 5px center;
            }
          }
        }
      }
    }
    .footer-txt-link-wrap{
      text-align: center;
      padding: 17px 0;
      border-top: 1px solid #e0e0e0;
      .link-btn a span {
        font-size: 14px;
        padding-left: 33px;
        color: #4e3c93;
        display: inline-block;
        background: url(/img/common/icon_circle_01_left.png) no-repeat 7px center;
      }
      .link-btn{
        a{
          color: #4e3c93;
          span{
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
