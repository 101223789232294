/* TALEX カスタム
------------------------------------------*/
.talex.special-clms{
  .special-clms-left{
    width: 326px;
    margin-left: 20px;
    .talex-phrase{
      padding-left: 6px;
      line-height: 1.5;
      margin-bottom: 50px;
      color: #034a3b;
      @include mincho-s(25);
    }
  }
  .special-clms-right{
    width: 450px;
    .item-name-01{
      margin-top: 2px;
    }
    .item-name-02{
      margin-top: 2px;
    }
  }
}

/* 白吹き出し
------------------------------------------*/
.white-belt-balloon{
  border-top: 1px solid #ededed;
  .inner,
  .inner-white-belt-balloon{
    border: none;
    position: static;
    margin-top: 0;
  }
}

.special-sec-02{
  background: #f7f7f7;
  padding-bottom: 39px;
  .inner{
    &.inner.white-box{
      background: #fff;
      padding: 31px 17px;
    }
  }
  /* ラインリスト
  ------------------------------------------*/
  $w_01: 186px;
  $w_02: 431px;
  $w_03: 300px;
  $pr: 12px;

  /* ヘッド */
  .line-list-head{
    .line-list-head-title{
      &.line-list-head-title-01{
        width: $w_01;
      }
      &.line-list-head-title-02{
        width: $w_02;
      }
      &.line-list-head-title-03{
        width: $w_03;
      }
    }
  }
  .line-lists{
    .line-list{
      .arrow{
        background: url(/img/common/line_arrow_off.png) 933px 50% no-repeat;
        &:hover{
          background: url(/img/common/line_arrow_on.png) 933px 50% no-repeat;
        }
      }
      .line-list-item{
        &.line-list-item-01{
          width: $w_01 - 12px - $pr;
          padding-right: $pr;
        }
        &.line-list-item-02{
          width: $w_02 - $pr;
          padding-right: $pr;
        }
        &.line-list-item-03{
          width: $w_03;
          font-size: 22px;
        }
      }
    }
  }
}