@charset "UTF-8";

.main-glasses {
	.main-visual {
		background: url(/img/glasses/bg_main_01.png) no-repeat center top;
	}
	.box-02 {
		height: 385px;
		background: #efeef1 url(/img/glasses/index/bg_box_02.jpg) no-repeat center top;
		.inner {
			padding-top: 65px;
			.box-02-contents {
				width: 377px;
				h2 {
					font-family: $font-mincho;
					font-weight: bold;
					font-size: fz(30);
					color: $c-murasaki;
					margin-bottom: 25px;
				}
				img {
					margin-bottom: 25px;
				}
				p {
					font-size: fz(14);
					font-weight: bold;
					margin-bottom: 10px;
				}
			}
		}
	}
}

/* ご紹介
------------------------------------------*/
.glasses-introduction {
  .main-visual-sub {
    background: url(/img/glasses/lens/introduction/bg_main_visual.jpg) center top no-repeat;
  }
  .bg-pattern-02 {
    padding-top: 40px;
  }
  .gi-contents-title {
    text-align: center;
    font-family: $font-mincho;
    font-weight: bold;
    font-size: fz(30);
    color: $c-murasaki;
    border: 7px solid #c6dfbf;
    padding: 22px 0;
    margin-bottom: 40px;
    &:after {
      content: "";
      display: block;
      height: 44px;
      margin-bottom: -45px;
      background: url(/img/glasses/lens/introduction/icon_arrow_balloon.png) center bottom no-repeat;
    }
  }
  .list-item {
    .col-6 {
      margin-top: 20px;
    }
  }
  .list-item-anc {
    display: table;
    width: 472px;
    height: 107px;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
    a {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $c-murasaki;
      font-size: fz(22);
      font-family: $font-mincho;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .list-item-anc-01 {
    background: url(/img/glasses/lens/introduction/btn_list_01.png) left top no-repeat;
  }
  .list-item-anc-02 {
    background: url(/img/glasses/lens/introduction/btn_list_02.png) left top no-repeat;
  }
  .list-item-anc-03 {
    background: url(/img/glasses/lens/introduction/btn_list_03.png) left top no-repeat;
  }
  .list-item-anc-04 {
    background: url(/img/glasses/lens/introduction/btn_list_04.png) left top no-repeat;
  }
  .list-item-anc-05 {
    background: url(/img/glasses/lens/introduction/btn_list_05.png) left top no-repeat;
  }
  .list-item-anc-06 {
    background: url(/img/glasses/lens/introduction/btn_list_06.png) left top no-repeat;
  }
  .title-area{
    text-align: center;
    .txt{
      @include mincho-murasaki-s(24px);
      display: inline-block;
      position: relative;
      padding-bottom: 8px;
      &:before{
        position: absolute;
        content: url(/img/glasses/brand/top/bg_kakko_left.png);
        bottom: 0;
        left: -108px;
      }
      &:after{
        position: absolute;
        content: url(/img/glasses/brand/top/bg_kakko_right.png);
        bottom: 0;
        right: -108px;
      }
      span{
        color: $c-murasaki;
      }
    }
  }
}
