@charset "utf-8";
/*------------------------------------*\
    grid
\*------------------------------------*/

.row {
	margin-top: 40px;
	margin-left: -20px;
	margin-right: -20px;
	box-sizing: border-box;
	overflow: hidden;
	&:first-child {
		margin-top: 0;
	}
}
.col-12, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
	width: 100%;
	float: left;
	padding: 0 20px;
	margin-top: 0;
	box-sizing: border-box;
}
.col-10 {
	width: 87.5%;
}
.col-9 {
	width: 75%;
}
.col-8 {
	width: 66.66666667%;
}
.col-7 {
	width: 58.33333331%;
}
.col-6 {
	width: 50%;
}
.col-5 {
	width: 41.66666665%;
}
.col-4 {
	width: 33.33333333%;
}
.col-3 {
	width: 25%;
}
.col-2 {
	width: 16.66666666%;
}
.col-1 {
	width: 8.33333333%;
}
