@charset "utf-8";
/*------------------------------------*\
    button
\*------------------------------------*/

.btn {
	display: inline-block;
	background: #fff;
	transition: opacity 0.3s;
	&:hover {
		text-decoration: none;
		opacity: 0.55;
	}
}

// 灰色 右矢印
.btn-01 {
	display: inline-block;
	background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
	background-size: 17px;
	padding-left: 30px;
}

.btn-02, a.btn-02 {
	color: #fff;
	box-sizing: border-box;
	width: 150px;
	margin: 0 6px 6px;
	padding: 5px 11px;
	background: $c-murasaki url(/img/common/icon_external_link_02.png) no-repeat 95% center;
	font-size: fz(13);
}
.btn-03 {
	padding-right: 24px;
	background: #fff url(/img/common/icon_external_link_01.png) no-repeat 95% center;
	font-size: fz(14);
}
.btn-04{
	height: 37px;
	line-height: 37px;
	text-align: center;
	display: block;
	border: 1px solid #dfdfdf;
	border-radius: 0;
	padding: 0;
	span {
		padding-right: 24px;
		background: #fff url(/img/common/icon_external_link_01.png) no-repeat right center;
	}
}

// ボタン: 紫
.btn-05 {
	display: block;
	border: 5px solid #7a6599;
	border-radius: 0;
	text-align: center;
	@include vertical-align-middle(50px);
	@include mincho-murasaki-s(18);
	padding: 0;
	transition: all 0.3s;
	span {
		padding-left: 26px;
		background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
		background-size: 17px;
	}
	&:hover {
		color: #fff;
		opacity: 1;
		background: #7a6599;
	}
}

.detail-btn{
	display: block;
	a{
		@include vertical-align-middle(33px);
		@include mincho-s(14);
		background: #fff;
		color: #333;
		padding-left: 12px;
		&:hover{
			text-decoration: none;
		}
		span{
			padding-right: 16px;
		}
	}
}

.btn-06 {
	background: #fafafa url(/img/common/icon_circle_01.png) no-repeat 7px center;
	background-size: 17px;
	padding: 7px 7px 7px 30px;
	border: 1px solid #dbdbdb;
	border-radius: 4px;
}
.btn-07 {
	background: url(/img/common/icon_external_link_03.png) right center no-repeat;
	padding-right: 20px;
}
//btn-06と同じ
.btn-08 {
	display: inline-block;
	padding-left: 30px;
	background: #fafafa url(/img/common/icon_circle_01.png) no-repeat 7px center;
	background-size: 17px;
}
.btn-09, a.btn-09 {
	display: block;
	border: 1px solid #dbdbdb;
	background: #fafafa;
	border-radius: 0;
	text-align: center;
	color: $c-murasaki;
	height: 33px;
	line-height: 33px;
	font-family: $font-mincho;
	font-size: fz(15);
	padding: 0;
	transition: all 0.3s;
	span {
		padding-left: 26px;
		background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
		background-size: 14px;
	}
	&:hover {
		color: #fff;
		opacity: 1;
		background: #7a6599;
	}
}

.btn-10 {
	font-weight: bold;
	display: inline-block;
	color: $c-murasaki;
	border: 2px solid $c-murasaki;
	padding: 5px 8px;
}

.btn-11 {
	background: #fff url(/img/common/icon_circle_01.png) no-repeat 7px center;
	background-size: 17px;
	padding: 7px 7px 7px 30px;
	border-radius: 4px;
	@include mincho-s(14);
}