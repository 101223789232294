@charset "UTF-8";
/*
 * ローカルナビバックグラウンド
 *********************************/
.lnav-glasses {
	background: #453288 url(/img/common/bg_lnav_megane.png) right 100px top no-repeat;
}
/*
 * 対象ブランド上書き
 *********************************/
.common-brand {
	text-align: center;
	padding: 55px 0;
	h2, .brand-contents__note {
		margin-bottom: 20px;
	}
	ul {
		background: none;
		border: 3px solid #e9e9e9;
	}
	li {
		width: 16.6666666%;
		height: 80px;
		line-height: 80px;
		border-right: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
		box-sizing: border-box;
		&:nth-child(6n) {
			border-right: none;
		}
		img {
			max-width: 80%;
			max-height: 80%;
			width: auto;
			height: auto;
		}
	}
}

.box-select-from-set {
	text-align: center;
	background: url(/img/glasses/index/bg_01.png) no-repeat center bottom;
	margin-bottom: 32px;
	width: 981px;
	h2 {
		margin-bottom: 9px;
	}
	ul {
		width: 1000px;
		overflow: hidden;
	}
	li {
		float: left;
		position: relative;
		a {
			display: block;
			width: auto;
			box-sizing: border-box;
			position: absolute;
			bottom: 18px;
			left: 98px;
		}
		@media all and (-ms-high-contrast:none) { // IE 10以上
			a {
				bottom: 9px;
				span {
					display: block;
					width: 100%;
					padding: 7px 7px 7px 20px;
				}
			}
		}
	}
	div {
		height: 58px;
		a {
			color: $c-murasaki;
			font-family: $font-mincho;
			font-weight: bold;
			margin-top: 14px;
		}
	}
}
.box-select-from-figure {
	width: 471px;
	float: left;
	text-align: center;
	h2 {
		margin-bottom: 10px;
	}
	ul {
		overflow: hidden;
	}
	li {
		float: left;
	}
}
.box-select-from-brand {
	width: 471px;
	float: right;
	text-align: center;
	background: url(/img/glasses/index/bg_02.png) no-repeat center bottom;
	h2 {
		margin-bottom: 10px;
	}
	ul {
		overflow: hidden;
		background: url(/img/glasses/bg_glasses_brand.png) repeat-y center top;
	}
	li {
		width: 235px;
		padding: 6px;
		text-align: center;
		float: left;
		box-sizing: border-box;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	div {
		border-top: 1px solid #dfdfdf;
		padding: 10px 0 12px;
		a {
			color: $c-murasaki;
			font-family: $font-mincho;
			font-weight: bold;
		}
	}
}
.search-items {
	padding: 45px 10px;
	overflow: hidden;
	form {
		width: 100%;
		input {
			height: 60px;
			line-height: 60px;
			float: left;
			border: none;
			box-sizing: border-box;
			&:focus {
				box-shadow: 0 0 10px $c-murasaki;
			}
		}
		input[type=text] {
			width: 853px;
			padding: 0 60px;
			font-size: fz(18);
			border-radius: 8px 0 0 8px;
			border: 1px solid #dbdbdb;
			outline: none;
			background: #fff url(/img/common/bg_search.png) no-repeat 20px center;

		}
		input[type=submit] {
			background-color: $c-murasaki;
			color: #fff;
			border: none;
			width: 117px;
			font-size: fz(20);
			font-family: $font-mincho;
			border-radius:  0 8px 8px 0;
			cursor: pointer;
		}
	}
}
.link-glasses {
	border-top: 1px solid #dbdbdb;
	ul {
		overflow: hidden;
	}
	li {
		float: left;
		height: 89px;
		width: 25%;
		text-align: center;
		box-sizing: border-box;
		border-right: 1px solid #dbdbdb;
		&:first-child {
			border-left: 1px solid #dbdbdb;
		}
		a {
//			display: block;
			width: 100%;
			height: 100%;
			display: table;
			span {
				display: table-cell;
				vertical-align: middle;
				background: url(/img/common/icon_circle_01.png) no-repeat 35px center;
				font-size: fz(17);
				font-family: $font-mincho;
				line-height: 17px;

			}
			&:hover {
				color: $c-murasaki;
				text-decoration: none;
			}
		}
	}
}
.main-glasses {
	.main-visual-title {
		color: $c-murasaki;
	}
	.box-01 {
		border: 1px solid #dbdbdb;
		padding: 44px 0 0;
	}
}

.list-glasses-item {
	overflow: hidden;
	padding: 11px 0 28px;
	min-height: 312px;
	li {
		float: left;
		margin: 0 10px 10px 0;
		background: #fff;
		text-align: center;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	a {
		display: block;
		width: 100%;
		height: 100%;
	}
	.glasses-img {
		position: relative;
		height: 227px;
		width: 485px;
		box-sizing: border-box;
		padding: 10px;
		border-bottom: 1px solid #dbdbdb;
		line-height: 227px;
		overflow: hidden;
		img {
			max-height: 200px;
			max-width: 100%;
			height: auto;
		}
	}
	.glasses-brand-icon {
		width: 138px;
		height: 75px;
		text-align: center;
		border-right: 1px solid #dbdbdb;
		box-sizing: border-box;
		float: left;
		line-height: 55px;
		padding: 10px;
		img {
			vertical-align: middle;
			max-width: 80%;
			max-height: 80%;
			height: auto;
			width: auto;
		}
	}
	.glasses-name {
		float: left;
		width: 485px - 138px;
		height: 75px;
		color: $c-murasaki;
		box-sizing: border-box;
		padding: 10px;
		font-weight: bold;
		p {
			line-height: 1.2;
		}
		.glasses-brand-name {
			font-family: $font-mincho;
			font-size: fz(18);
		}
		.glasses-number {
			font-size: fz(36);
		}
	}
}

/* =================================
 商品詳細
================================= */
@import "glasses/single";

.glasses-set-index {
	.main-visual {
		background: url(/img/glasses/set/index/bg_01.jpg) no-repeat center top;
		text-align: center;
		height: 340px;
		padding-top: 19px;
		box-sizing: border-box;
	}
	.link-glasses-set {
		padding-top: 40px;
		li {
			text-align: center;
			margin-bottom: 40px;
		}
		h2 {
			margin-bottom: 14px;
		}
	}
}

// tenmu
.glasses-tenmu {
	.main-visual {
		min-height: 631px;
		background: #000000 url(/img/glasses/brand/tenmu/bg_main_visual.jpg) left top no-repeat;
		padding: 15px 20px;
	}
	.main-visual__icon {
		text-align: left;
	}
	.main-visual__lead {
		text-align: right;
		margin-top: -80px;
	}
	.main-visual__title {
		text-align: right;
		margin-top: 320px;
		margin-bottom: 35px;
	}
	.main-visual__item {
		min-height: 500px;
		padding: 31px 35px;
		box-sizing: border-box;
		background-image: url(/img/glasses/brand/tenmu/bg_main_visual_item_top.png),url(/img/glasses/brand/tenmu/bg_main_visual_item_bottom.png),url(/img/glasses/brand/tenmu/bg_main_visual_item_middle.png);
		background-repeat: no-repeat, no-repeat, repeat-y;
		background-position: left top, left bottom, left top;
	}
	.main-visual__item-outer {
		background: #fff;
	}
	.main-visual__item-title {
		font-size: fz(35);
		font-weight: bold;
		font-family: $font-mincho;
		margin-bottom: 20px;
	}
	.main-visual__item-lead {
		margin-bottom: 20px;
		text-align: left;
		text-indent: 1em;
	}
	.main-visual__item-title2 {
		text-align: left;
	}
	.main-visual__item-pic {
		text-align: center;
		margin-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #a30000;
	}
	.main-visual__item-list-outer {
		padding-top: 15px;
	}
	.main-visual__item-list {
		border-left: 1px dashed #a30000;
		text-align: left;
		&:first-child {
			border-left: none;
		}
	}
	.main-visual__item-list-title {
		color: #a30000;
		font-weight: bold;
		margin-bottom: 15px;
	}
	.att {
		background: #a30000;
		padding: 5px 0;
	}
	.att-inner {
		color: #fff;
		font-size: 27px;
		font-weight: bold;
		font-family: $font-mincho;
		border-top: 2px solid #d39090;
		border-bottom: 2px solid #d39090;
		text-align: center;
	}
	.contents-list {
		margin-top: 35px;
		margin-bottom: 100px;
	}
	.contents-list__title {
		height: 22px;
		line-height: 30px;
		font-weight: bold;
		background: url(/img/glasses/brand/tenmu/icon_circle.png) left center no-repeat;
		padding-left: 26px;
		padding-bottom: 8px;
		margin-bottom: 10px;
		border-bottom: 1px solid #e7e7e7;
	}
	.contents-list__img {
		border: 1px solid #cccccc;
		margin-bottom: 10px;
		box-sizing: border-box;
		overflow: hidden;
	}
	.contents-list__name {
		font-weight: bold;
		background: url(/img/glasses/brand/tenmu/icon_contents_list_name.png) left center no-repeat;
		padding-left: 23px;
		margin-bottom: 10px;
	}
}
/* =================================
 商品検索
================================= */
@import "glasses/shape";
/* =================================
 補聴器メーカー補償ページ
================================= */
@import "glasses/flat_price";

/* =================================
 こどものメガネの選び方
================================= */
@import "glasses/children";

/* =================================
 レンズを選ぶページ
 ================================= */
@import "glasses/select";
/* =================================
 レンズのご紹介ページ
 ================================= */
@import "glasses/index";
/* =================================
 カジュアルセット
 ================================= */
@import "glasses/casual";
/* =================================
 特殊機能レンズページ
 ================================= */
@import "glasses/special";
/* =================================
 よくあるご質問ページ
 ================================= */
@import "glasses/faq";
/* =================================
 ブランドページ
 ================================= */
@import "glasses/brand";
@import "glasses/junior";
/* =================================
 似合うメガネの選び方
 ================================= */
@import "glasses/choice";
/* =================================
 メガネの基礎知識
 ================================= */
@import "glasses/economy";

/* =================================
 ブランドセット
 ================================= */
/* オリジナルブランドモジュール */
.contents__orig-brand {
	padding: 60px 0 0;
	.contents__orig-brand-inner {
		padding-bottom: 60px;
		border-bottom: 1px solid #4e3c93;
	}
	.contents__orig-brand-title.title-05 {
		width: 100%;
		color: $c-murasaki;
		font-size: fz(24);
		font-weight: bold;
		font-family: $font-mincho;
		text-align: center;
	}
	.contents__orig-brand-sub-title {
		font-size: 20px;
		font-weight: bold;
		font-family: $font-mincho;
		text-align: center;
	}
	.contents__orig-brand-img {
		background: #fff;
		padding: 5px;
		img {
			width: 100%;
		}
	}
}
.glasses-brand {
	.main-visual {
		min-height: 340px;
	}
	.brand-contents__title {
		padding-top: 50px;
		padding-bottom: 12px;
	}
}
/* =================================
 レンズのご紹介
 ================================= */
@import "glasses/lens/index";
/* =================================
 メガネの基礎知識
 ================================= */
@import "glasses/basic";
/* =================================
 フレームの基礎知識
 ================================= */
@import "glasses/frame";
/* =================================
 レンズの基礎知識
 ================================= */
@import "glasses/type";
/* =================================
 目とメガネについて
 ================================= */
@import "glasses/faq_glasses";
/* =================================
 ブランドセットページ 下層
 ================================= */
@import "glasses/set/lower";
/* =================================
 閲覧履歴
 ================================= */
@import "glasses/history";
/* =================================
 オーダーメイドメガネと既製老眼鏡について
 ================================= */
@import "glasses/reading_glass";