.glasses-shop_list {
	.main-contents {
		border-top: 1px solid #dfdfdf;
		padding-top: 55px;
	}
	#area-01 {
		overflow: hidden;
//		padding-bottom: 15px;
		margin-bottom: 25px;
		position: relative;
		min-height: 40px;
//		background: url(/img/glasses/brand/shop_list/bnr_01.png) no-repeat left bottom;
		.logo {
			float: left;
		}
		.btn {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}

	.line-list-head .line-list-head-title.line-list-head-title-02,
	.line-lists .line-list .line-list-item.line-list-item-02 {
		width: 430px;
	}
	.line-lists .line-list .line-list-item {
		font-size: 13px;
	}
	.line-list-head .line-list-head-title.line-list-head-title-03 {
		width: 205px;
	}
	.line-lists .line-list .line-list-item.line-list-item-03 {
		width: 205px;
		font-size: 20px;
	}
	.line-list-head-title.line-list-head-title-04 {
		float: left;
	}
	.line-lists .line-list .line-list-item.line-list-item-04 {
		width: 60px;
	}
}