@charset "UTF-8";
.page-home {
	/**
	 * Main
	 */
	.main-img {
		width: 100%;
		position:  relative;
		height: 380px;
		overflow: hidden;
		background: url(/img/home/pic_main_01.jpg) no-repeat center top;
		background-size: cover;
		.lead {
			width: 830px;
			margin: 0 auto;
			padding-top: 100px;
		}
		&__bnr {
			position: relative;
			z-index: 11;
			width: 830px;
			margin: 48px auto 0;
			div {
				padding: 15px 70px 0 0;
				display: inline-block;
				border-top: 1px solid #fff;
			}
		}
	}
	.main-img-cover {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		background: url(/img/home/bg_main.png) no-repeat center top;
	}
	/* ******************************
	 * box-01
	 ****************************** */
	.box-01 {
		padding: 36px 0 57px;
		overflow: hidden;
		.left {
			width: 555px;
			color: $c-murasaki;
			text-align: center;
			overflow: hidden;
			h2 {
				@include mincho-murasaki-s(27);
			}
			h3 {
				font-size: fz(14);
				letter-spacing: 4px;
				margin-top: 8px;
			}
			ul {
				margin-top: 22px;
				position: relative;
				left: 50%; /* リスト全体であるul要素を右に50%移動。つまり先頭を中央に持ってくる感じです。 */
				float: left;
			}
			li {
				position: relative;
				left: -50%; /* 各リスト項目になるli要素を左に50%移動。 */
				float: left;
				font-size: fz(12);
				min-width: 74px;
				min-height: 95px;
				margin-right: 48px;
				&:last-child {
					margin-right: 0;
				}
				&.list-01 {
					background: url(/img/home/icon_01.png) no-repeat center top;
				}
				&.list-02 {
					background: url(/img/home/icon_02.png) no-repeat center top;
				}
				&.list-03 {
					background: url(/img/home/icon_03.png) no-repeat center top;
				}
				&.list-04 {
					background: url(/img/home/icon_04.png) no-repeat center top;
				}
				a {
					display: block;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					padding-top: 85px;
					color: $c-murasaki;
				}
			}
		}
		.right {
			min-width: 375px;
			p {
				font-size: fz(14);
				line-height: 26px;
				margin-bottom: 10px;
			}
		}
	}
	/* ******************************
	 * box-02
	 ****************************** */
	.box-02 {
		overflow: hidden;
		.box-02-01 {
			overflow: hidden;
		}
		h2 {
			height: 78px;
			line-height: 78px;
			@include mincho-murasaki-s(20);
			border-top: 2px solid #ededed;
			border-bottom: 1px solid #dbdbdb;
			text-align: center;
			.inner {
				border-top: 2px solid $c-murasaki;
				margin-top: -2px;
			}
		}
		.left {
			//width: 647px;
			overflow: hidden;
			padding-bottom: 59px;
			float: none;
			margin: 0 auto;
			text-align: center;
			h3 {
				margin: 24px 0 14px;
			}
			li {
				float: left;
			}
		}
		.right {
			margin-top: 75px;
			width: 308px;
			height: 362px;
			background: url(/img/home/bg_kaoshindan.png) no-repeat left top;
			box-sizing: border-box;
			padding: 251px 21px 0 25px;
			h3, p {
				text-indent: 100%;
				overflow: hidden;
				white-space: nowrap;
			}
		}
		.box-02-02 {
			clear: both;
			padding-top: 60px;
			h3 {
				span {
					color: #000;
				}
			}
			ul {
				margin-top: 15px;
			}
		}
	}
	/* ******************************
	 * box-03
	 ****************************** */
	.box-03 {
		border-top: 1px solid #dfdfdf;
		border-bottom: 1px solid #dfdfdf;
	}
	/* ******************************
	 * box-04
	 ****************************** */
	.box-04 {
	}
	/* ******************************
	 * box-05
	 ****************************** */
	.box-05 {
		border-top: 2px solid #dfdfdf;
		.inner {
			border-top: 2px solid #4e2f76;
			position: relative;
			margin-top: -2px;
		}
		ul {
			box-sizing: border-box;
			border-right: 1px solid #dfdfdf;
			overflow: hidden;
		}
		li {
			box-sizing: border-box;
			float: left;
			width: 33.333333%;
			border-left: 1px solid #dfdfdf;
			padding: 36px 0 23px;
			text-align: center;
		}
		h2 {
			margin-bottom: 7px;
		}
		p {
			margin-bottom: 22px;
		}
	}
	/* ******************************
	 * box-06
	 ****************************** */
	.box-06 {
		border-top: 1px solid #dfdfdf;
		padding: 25px 0 32px;
		.inner {
			overflow: hidden;
		}
		.left {
			margin-top: 35px;
			width: 316px;
		}
		.right {
			width: 636px;
		}
		.icon-category {
			height: 15px;
			line-height: 15px;
			padding: 0 7px;
			display: inline-block;
			box-sizing: border-box;
			color: #fff;
			font-size: fz(9);
			min-width: 90px;
			text-align: center;
			margin-left: 15px;
		}
		.icon-category-01 { background: #ff6e6e;}
		.icon-category-02 { background: #5a3e7f;}
		.icon-category-03 { background: #c5b90f;}
		.news-head {
			overflow: hidden;
			h2 {
				float: left;
			}
			a {
				float: right;
				font-weight: bold;
				padding-left: 9px;
				background: url(/img/common/icon_arrow_01.png) no-repeat left center;
				line-height: 36px;
			}
		}
		.news-list {
			border-top: 1px solid #dfdfdf;
			li {
				overflow: hidden;
				padding: 13px 0;
				border-bottom: 1px solid #dfdfdf;
			}
		}
		.news-header,
		.news-main {
			float: left;
			width:  465px;
		}
		.news-header {
			line-height: 15px;
			margin-bottom: 4px;
		}
		.news-title {
			font-size: fz(14);
			line-height: 22px;
			a {
				color: #01c1b8;
			}
		}
		.news-thumbnails {
			float: right;
			width: 150px;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		.link-bnr {
			li {
				margin-bottom: 17px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.link-text {
			li {
				background: url(/img/common/icon_circle_01.png) no-repeat 0 center;
				background-size: 17px;
				line-height: 18px;
				padding-left: 23px;
				font-size: fz(18);
				font-weight: bold;
				font-family: $font-mincho;
				margin-top: 42px;
			}
		}
	}
}
