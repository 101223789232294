.quality-care{

  .inner.main-visual-sub-inner{
    position: relative;
  }
  .main-visual-sub-img{
    position: absolute;
    right: 80px;
    top: 25px;
  }
  .main-visual-sub-txt{
    position: absolute;
    right: 41px;
    top: 144px;
    width: 189px;
    @include vertical-align-middle(31px);
    @include mincho-s(17);
    text-align: center;
    background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
    color: #fff;
  }

  /* sec01
  ---------------------------------- */
  .quality-lead-area{
    border-top: 1px solid #dfdfdf;
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 46px 0;
    .main-contens-list-area{
      .main-contens-list{
        position: relative;
        .main-contens-list-img{
          position: absolute;
          top: -14px;
          right: 30px;
        }
        .main-contens-list-img-02{
          position: absolute;
          top: 51px;
          right: 336px;
        }
        .main-contens-list-box{
          border: 3px solid #f2f2f2;
	        border-radius: 6px;
          overflow: hidden;
          box-sizing: border-box;
          background: #fff;
          .main-contens-list-box-title{
            display: table-cell;
            width: 157px;
            height: 148px;
            vertical-align: middle;
            @include mincho-s(16);
            color: #fff;
            line-height: 1.63;
            position: relative;
            text-align: center;
            background: url(/img/quality/after/care/bg_care_title.png) left top no-repeat;
            z-index: 10;
            &:after{
              content: "";
              position: absolute;
              top: 50%;
              right: -10px;
              margin-top: -10px;
              display: block;
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 10px 0 10px 10px;
              border-color: transparent transparent transparent #453288;
            }
          }
          .main-contens-list-box-desc{
            display: table-cell;
            width: 817px;
            height: 148px;
            vertical-align: middle;
            span{
              display: block;
              @include mincho-murasaki;
              font-size: 16px;
              line-height: 1.44;
              margin-left: 54px;
            }
          }
        }
        &.under-arrow{
          z-index: 10;
          &:after{
            content: "";
            position: absolute;
            bottom: -28px;
            left: 50%;
            margin-left: -10px;
            display: block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 16px 12px 0 12px;
            border-color: #a78dc3 transparent transparent transparent;  
          }
        }
      }
    }
  }
  .sec-nav{
    margin-top: 6px;
    .inner {
      position: relative;
      .sec-nav-area{
        float:left;
        left:50%;
        position:relative;
        margin-left: 0;
        padding-left: 0;
      }
      .sec-nav-list{
        float:left;
        left:-50%;
        position:relative;
        margin-right: 84px;
        &.last{
          margin-right: 0;
        }
      }
    }
  }

  .main-contens-colorview{
    padding: 38px 0 59px;
    .inner-main-contens-colorview{
      width: 952px;
    }
    .main-contens-colorview-clms-left{
      width: 523px;
      float: left;
    }
    .main-contens-colorview-clms-right{
      width: auto;
      float: right;
    }
    .main-contens-colorview-lead{
      width: 338px;
      height: 31px;
      @include vertical-align-middle(31px);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #fff;
      background: url(/img/quality/after/lowvision/bg_sec_02_img.png) left top no-repeat;
      margin-bottom: 26px;
    }
    .main-contens-colorview-title{
      @include mincho-murasaki;
      font-size: 30px;
      line-height: 1;
      margin-bottom: 20px;
    }
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 32px;
    }
    .main-contens-colorview-detail-btn{
      background: #fff;
      margin-left: 138px;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }




  .sec-03.shop-list-area{
    position: relative;
    margin-bottom: 80px;
    .inner-shop-list{
      border-bottom: 1px solid $c-murasaki;
    }
    .area-name{
      width: 961px;
      background: url(/img/quality/after/bg_purple_belt.png) left top no-repeat;
      margin-bottom: 45px;
      @include mincho-murasaki;
      @include vertical-align-middle(50px);
      font-size: 20px;
      color: #fff;
      padding-left: 19px;
    }
    
    .shoplist-area-to-top-btn{
      margin: 0 auto;
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      background: #fff;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .white-belt-balloon{
    border-top: 1px solid #dfdfdf;
    .inner{
      .title{
        background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
      }
    }
  }
  

}