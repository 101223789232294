.disposable_contact_lens {
	.main-visual {
		background: url(/img/contact_lens/disposable_contact_lens/bg_01.png) no-repeat center top;
		height: 200px;
		h1 {
			text-align: left;
			line-height: 200px;
			font-size: fz(27);
			color: $c-murasaki;
			font-family: $font-mincho;
		}
	}
	.contents01 {
		padding: 35px 0 85px;
		ul {
			overflow: hidden;
			padding-bottom: 30px;
		}
		li {
			border-top: 1px solid #dfdfdf;
			border-left: 1px solid #dfdfdf;
			.row {
				height: 100%;
				border-bottom: 1px solid #dfdfdf;
				border-right: 1px solid #dfdfdf;
				padding: 15px 0;
				overflow: hidden;
				.contents01-img-outer {
					display: table;
					height: 100%;
				}
				.mb15 {
					img {
						max-width: 100%;
					}
				}
				.contents01-img {
					display: table-cell;
					height: 100%;
					text-align:center;
					vertical-align: middle;
					.number {
						display: block;
						font-size: smaller;
					}
				}
			}
		}
	}
}

.disposable_contact_lens .local-nav {
	height: auto;
	padding-bottom: 15px;

}
.disposable_contact_lens .local-nav li{
	margin-bottom: 15px;
}
.disposable_contact_lens .local-nav li a:hover {
	border: none;
	text-decoration: underline;
}
