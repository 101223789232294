.hearing-aid-list {
    .main-visual__list {
        overflow: hidden;
        padding: 20px 0;
        li {
            float: left;
            width: 25%;
            text-align: center;
            margin-right: 0;
        }
        .img {
            height: 115px;
            padding-bottom: 20px;
        }
        img {
            max-height: 115px;
            width: auto;
            vertical-align: bottom;
        }
        a {
            background: url(/img/common/icon_circle_01.png) no-repeat left center;
            padding: 3px 24px;
            color: $c-murasaki;
        }
        .img {
            a {
                background: none;
                padding: 0;
            }
        }
    }
    .item-list {
        padding-top: 42px;
        li {
            border: 2px solid #e9e9e9;
            padding: 22px;
            margin-bottom: 45px;
            background: #fff;
            overflow: hidden;
            li {
                border: none;
                padding: 0;
                margin: 0;
            }
        }
        .img {
            float: left;
            height: 300px;
            width: 475px;
            text-align: center;
            line-height: 300px;
            vertical-align: middle;
            img {
                max-height: 100%;
                width: auto;
            }
        }
        .detail {
            margin-left: 475px;
            &__logo {
                overflow: hidden;
                padding-bottom: 12px;
                margin-bottom: 15px;
                background: url(/img/hearing_aid/list/line_01.png) no-repeat left bottom;
                img {
                    vertical-align: middle;
                }
            }
            .logo {
                float: left;
            }
            .kind {
                float: right;
            }
            &__text {
                margin-bottom: 18px;
            }
            &__link {
                background: #f5f4f6;
                padding: 12px 14px;
                overflow: hidden;
                text-align: center;
                li {
                    display: inline-block;
                }
            }
            .btn {
                border: 1px solid #e9e9e9;
                background-color: #fff;
                padding-top: 8px;
                padding-bottom: 8px;
                &.btn-01 {
                    float: left;
                    padding-right: 10px;
                }
                &.btn-03 {
                    float: right;
                    padding-left: 10px;
                }
            }
        }
        li#item-01{
            .logo{
                margin-top: 13px;
            }
        }
    }
    .bg-pattern-hearing-aid-01 {
        background: url(/img/hearing_aid/list/bg_02.png) no-repeat center center;
        background-size: cover;
        text-align: center;
        position: relative;
        overflow-x: hidden;
        a {
            position: absolute;
            bottom: 25px;
            left: 50%;
            margin-left: -130px;
        }
    }
}