.hearing_aid_confirm {
	padding: 34px 0 70px;
	.confirm-wrapper {
		width: 800px;
		background-image:
			url(/img/hearing_aid/bg_01.png),
			url(/img/hearing_aid/bg_03.png);
		background-repeat:
			no-repeat,
			no-repeat;
		background-position:
			center top,
			center bottom;
		box-sizing: border-box;
		padding: 13px 0 11px;
		margin: 0 auto;
	}
	.confirm-contents {
		padding: 40px 70px;
		background: url(/img/hearing_aid/bg_02.png) repeat-y center top;
	}
	h1 {
		background: url(/img/hearing_aid/bg_title_01.png);
		width: 660px;
		height: 60px;
		text-align: center;
		color: #fff;
		font-size: fz(24);
		font-family: $font-mincho;
		line-height: 50px;
		margin-bottom: 20px;
	}
	h2 {
		color: #453288;
		font-size: fz(24);
		text-align: center;
		margin-bottom: 25px;
	}
	h3 {
		text-align: center;
		height: 41px;
		background: url(/img/hearing_aid/bg_confirm_01.png) no-repeat center top;
		line-height: 41px;
		font-family: $font-mincho;
		color: #453288;
		font-size: fz(20);
		margin-bottom: 7px;
	}
	ul {
		margin-bottom: 25px;
		li {
			line-height: 50px;
			border-bottom: 1px solid #dfdfdf;
			.number {
				color: #453288;
				font-size: 24px;
				padding-left: 55px;
			}
			.text {
				padding-left: 0.5em;
				font-size: 18px;
			}
		}
	}
	.box-01 {
		border: 3px solid #dfdfdf;
		padding: 2px;
		.box-01-inner {
			background: #edf5ea;
			font-size: 18px;
			color: #453288;
			padding: 20px 0 20px 112px;
		}
	}
}