@charset "UTF-8";
.area02 {
	.main-visual {
    height: 424px;
    background-image: url(/img/shop_list/area02/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
  .anc-01 {
    position: absolute;
    top: 30px;
    left: 320px;
  }
  .anc-02 {
    position: absolute;
    top: 70px;
    left: 265px;
  }
  .anc-03 {
    position: absolute;
    top: 110px;
    left: 200px;
  }
  .anc-04 {
    position: absolute;
    top: 175px;
    left: 300px;
  }
  .anc-05 {
    position: absolute;
    top: 295px;
    left: 260px;
  }
  .anc-06 {
    position: absolute;
    top: 300px;
    left: 50px;
  }
  .anc-07 {
    position: absolute;
    top: 265px;
    left: 428px;
  }
	.anc-08 {
		position:absolute;
		top: 70px;
		left: 430px;
	}
}
