.lead{
  font-size: 14px;
  line-height: 1.57;
  margin-bottom: 43px;
  font-weight: normal;
}

/* サングラスレンズ カスタム
------------------------------------------*/
.sunglasses.select-clms{
  .select-clms-left{
    width: 360px;
  }
  .select-clms-right{
    width: 450px;
    .select-clms-txt.type-list{
      padding-left: 1em;
      text-indent: -1em;
    }
    .price.custom{
      padding-right: 1em;
    }
  }
  .select-clms .select-clms-right .select-clms-txt {
    line-height: 1.5;
  }
}
.select-main-visual.sunglasses{
  margin-bottom: 20px;
  .select-main-visual-img{
    width: 276px;
    margin-right: 15px;
    float: left;
    &.last{
      margin-right: 0;
    }
  }
}