@charset "utf-8";
/*------------------------------------*\
		Global Nav
\*------------------------------------*/
#global-nav {
	float: right;
	overflow: hidden;
	padding-top: 30px;
	ul {
		width: 650px;
	}
	li {
		box-sizing: border-box;
		float: left;
		@include fontBold;
		font-size: fz(14);
		/* padding-left: 20px; */
		margin-right: 50px;
	}
	a:hover {
		text-decoration: none;
		color: #7a6599;
	}
}

// Home
.page-home {
	#header {
		padding-top: 40px;
	}
	.header-main {
		position: relative;
	}
	.header-logo {
		width: auto;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -63px;
	}
	#global-nav {
		float: none;
		overflow: hidden;
		padding-top: 0;
		li {
			height: 85px;
			line-height: 85px;
			&:last-child {
				margin-right: 0;
			}
		}
		.global-left-nav,
		.global-right-nav {
			float: left;
			width: 50%;
			overflow: hidden;
		}
		.global-left-nav {
			li {
				margin-right: 25px;
			}
		}
		.global-right-nav {
			float: right;
			text-align: right;
			li {
				float: none;
			}
		}
	}
}
