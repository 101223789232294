/* 中近両用レンズ カスタム
------------------------------------------*/
.dual_lens_02.select-clms{
  .select-clms-left{
    width: 360px;
  }
  .select-clms-right{
    width: 450px;
    .item-name-02{
      margin-top: 3px;
    }
  }
}