@charset "utf-8";

.search-filter {
	border-top: 1px solid #dbdbdb;
	border-bottom: 1px solid #dbdbdb;
	height: 133px;
	overflow: hidden;
}
.select-figure {
	float: left;
	width: 280px;
	height: 133px;
	box-sizing: border-box;
	border-left: 1px solid #dbdbdb;
	border-right: 1px solid #dbdbdb;
	.slide {
		width: 280px;
		height: 133px;
	}
}
.serach-results {
	.glasses-img-units img {
		display: none;
	}
	.glasses-img-units img.is-show {
		display: inline;
	}
	#item-wrapper {
		min-height: 351px;
		position: relative;
	}
}
.filter-contents {
	border-right: 1px solid #dbdbdb;
	overflow: hidden;
	float: left;
	height: 133px;
	width: 698px;
	padding: 6px 0 6px 12px;
	box-sizing: border-box;
	p {
		width: 17px;
		float: left;
	}
	.left {
		width: 667px;
	}
	dl {
		float: left;
		overflow: hidden;
		margin-left: 10px;
	}
	dt {
		font-weight: bold;
		display: block;

	}
	dd {
		width: 70px;
		height: 30px;
		box-sizing: border-box;
		float: left;
		border: 1px solid #dbdbdb;
		border-right: none;
		text-align: center;
		line-height: 30px;
		font-size: fz(10);
		overflow: hidden;
		&:last-child {
			border: 1px solid #dbdbdb;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			&:hover,&.active {
				background: $c-murasaki;
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.filter-direction {
		dd {
			width: 86px;
			position: relative;
			a {

				&:hover,&.active {
					border: 2px solid $c-murasaki;
					background: none;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}
.filter-color {
	a {
		border-bottom: 2px solid #01c1b7;
		&#red { border-color: #a30000;}
		&#pink {  border-color: #e3007f;}
		&#purple { border-color: $c-murasaki;}
		&#blue { border-color: #1400d5;}
		&#gold { border-color: #c5b90f;}
		&#brown { border-color: #81511c;}
		&#black { border-color: #000;}
		&#silver { border-color: #dbdbdb;}
	}
}
.filter-top-contents {
	overflow: hidden;
	padding-bottom: 15px
}
.filter-color {
	overflow: hidden;

}
.filter-bottom-contents {

}

.select-figure {
	position: relative;
	background: url(/img/glasses/shape/bg_select_shape.gif) repeat left top;
	p {
		position: absolute;
		top: 2px;
		left: 0;
		z-index: 500;
		width: 100%;
		text-align: center;
		color: $c-murasaki;
		font-family: $font-mincho;
	}
	.bxslider {
		width: 100%;
		text-align: center;

		li {
			line-height: 133px;
			height: 133px;
			text-align: center;
			padding: 0 15px;
			box-sizing: border-box;
			p, img {
				display: none;
			}
			img {
				display: inline!important;
				max-width: 70%;
			}
			p {
				top: auto;
				bottom: 2px;
				font-size: fz(22);
			}
		}
	}
}
#modal-overlay{
	z-index: 99999;
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background: rgba(0,0,0,0.75) url(/img/glasses/shape/loading.svg) no-repeat center center;
}

.result {
	position: absolute;
	top: 10px;
	left: 970px;
	z-index: 100;
	font-weight: bold;
	&__number {
		font-size: 160px;
		color: #a78dc3;
		line-height: 1.1;
	}
	&__text {
		background: #a78dc3;
		color: #fff;
		text-align: center;
		line-height: 1.5;
		font-size: fz(16);
		margin-top: -10px;
		font-family: $font-mincho;
	}
}
