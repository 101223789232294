@charset "UTF-8";
.main-header a{
	color: #fff;
}

.quality-clinic__contact-lens, {
  .border-box{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .cf:after {
    content: "."; 
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
  .cf {min-height: 1px;}
  * html .cf {
    height: 1px;
    /*¥*//*/
    height: auto;
    overflow: hidden;
    /**/
  }
  .announce-wrap{
    background: #fff;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #f7f7f7;
    .inner{
      width: 980px;
      margin: 0 auto;
      background: url(/img/common/bg_line_01.png) left top repeat-x;
      padding: 27px 0 24px;
      .announce-txt{
        font-size: 24px;
        padding-left: 19px;
        font-family: $font-mincho;
        color: $c-murasaki;
        font-weight: bold;
        line-height: 1.6;
      }
    }
  }

  .sec01{
    padding: 40px 0;
    .box{
      background: #fff;
      border: 3px solid #f2f2f2;
      padding: 32px;
      @extend .border-box;
      &-head{
        font-size: 34px;
        font-family: $font-mincho;
        color: $c-murasaki;
        font-weight: bold;
        line-height: 1.6;
      }
      &-desc{
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
      }
    }
    .box-head-deep{
      border-left: 5px solid #453288;
      padding-left: 20px;
      .box-head{
        font-size: 36px;
      }
    }
    .box-head-desc{
      border-left: 5px solid #a78dc3;
      padding: 9px 0 9px 20px
    }
    .box-01{
      margin-bottom: 20px;
      .box-desc{
        width: 860px;
      }
    }
    .box-02{
      .box-img{
        float: left;
        border: 3px solid #453288;
        background: #fff;
        padding: 6px;
        img{
          display: block;
          border: 2px solid #453288;
        }
      }
      .box-right{
        float: right;
        width: 521px;
      }
      .box-head-deep{
        .box-head{
          font-size: 35px;
        }
      }
    }
  }

  .contents__caption-outer {
      margin-bottom: 38px;
  }
  .contents__caption {
    padding: 40px 0 0;
      .inner{
        width: 980px;
        margin: 0 auto;
        background: url(/img/common/bg_line_01.png) left top repeat-x;
        padding: 21px 0 18px;
      }
      .contents__caption-head{
        font-size: 24px;
        font-family: $font-mincho;
        color: $c-murasaki;
        font-weight: bold;
        line-height: 1.6;
      }
      .contents__caption-desc{
        font-size: 14px;
        line-height: 1.6;
      }
  }
  .contents__caption--is-balloon:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -9px;
    width: 17px;
    height: 9px;
    background: url(/img/glasses/set/economy/bg_ttl_arrow_02.png) center bottom no-repeat;
  }
  .sec-nav.contact-lens-shop{
    margin-bottom: 20px;
    .sec-nav-area{
      margin-left: 0;
    }
  }

  .area{
    &-head{
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      font-family: $font-mincho;
      color: #fff;
      font-weight: bold;
      padding-left: 17px;
      background: #4e3c93 url(/img/quality/clinic_store/bg_area_head.png) left top no-repeat;
      margin-bottom: 40px;
      @extend .border-box;
    }
    &-list{
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      li{
        margin-top: 37px;
        &:first-child{
          margin-top: 0;
        }
        .area-list-store-name{
          font-size: 14px;
          font-family: $font-mincho;
          color: $c-murasaki;
          font-weight: bold;
          float: left;
        }
        .area-list-store-contact{
          float: right;
          font-size: 14px;
          font-family: $font-mincho;
          color: $c-murasaki;
          font-weight: bold;
          span{
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }

    .area-list-head-wrap{
      margin-bottom: 16px;
    }
    .timetable{
      border: 1px solid #eee;
      th{
        width: 139px;
        background: #bbb1c1;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        height: 24px;
        border-right: 1px solid #eee;
      }
      td{
        height: 60px;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #eee;
      }
    }

  }
  .menu-to-top-wrap{
    position: relative;
    border-bottom: 1px solid #453288;
    margin: 70px auto;
  }
  .under-menu-to-top-btn{
    margin: 0 auto;
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 0;
    background: #fff;
    border: 4px solid #453288;
    font-family: "Roboto Condensed", "A1 Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    font-weight: bold;
    width: 252px;
    height: 33px;
    line-height: 33px;
    font-size: 16px;
    text-align: center;
    a{
      display: inline-block;
      color: #453288;
      padding-left: 22px;
      background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
