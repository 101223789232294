.quality-after{
  .main-visual{
    border-bottom: 1px solid #dfdfdf;
  }
  .main-visual-inner{
    position: relative;
    .main-visual-img{
      position: absolute;
      right: 111px;
      top: 58px;
    }
    .main-visual-txt{
      position: absolute;
      width: 189px;
      @include vertical-align-middle(31px);
      @include mincho-s(17);
      text-align: center;
      background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
      color: #fff;
      top: 247px;
      right: 77px;
    }
  }
  .main-visual{
    background: url(/img/common/bg_main.png) center top no-repeat;
    height: 339px;
    .main-visual-title{
      font-size: 33px;
      line-height: 1.57;
      @include mincho-murasaki;
      padding-top: 123px;
      width: auto;
      padding-left: 19px;
      height: auto;
    }
  }

  /* sec01
  ---------------------------------- */
  .quality-lead-area{
    .inner{
      .quality-lead{
        span{
          border-bottom: 2px solid #c7e1bd;
        }
      }
    }
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 66px 0 96px 0;
    background: $c-murasaki;
    overflow: hidden;
    .inner-main-contens{
      width: 711px;
      margin: 0 auto;
    }
    .main-contens-box{
      width: 295px;
      .main-contens-title{
        @include mincho-s(20);
        text-align: center;
        color: #fff;
        line-height: 1;
        padding-bottom: 7px;
        background: url(/img/quality/after/bg_kakko.png) left bottom no-repeat;
        margin-bottom: 13px;
        span{
          display: inline-block;
          background: $c-murasaki;
          padding: 0 10px; 

        }
      }
      .main-contens-img{
        border: 5px solid #fff;
        margin-bottom: 15px;
      }
      .main-contens-txt{
        font-size: 14px;
        line-height: 1.7;
        color: #fff;
        margin-bottom: 15px;
      }
      .main-contens-btn{
        border: 4px solid #fff;
        margin: 0 auto;
        @include mincho-s(16);
        @include vertical-align-middle(32px);
        width: 287px;
        text-align: center;
        a{
          display: inline-block;
          color: #fff;
          padding-left: 22px;
          background: url(/img/quality/icon_circle_quality_banner.gif) left 8px no-repeat;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .main-contens-clms-left{
      float: left;
    }
    .main-contens-clms-right{
      float: right;
    }
    
  }

}