@charset "utf-8";
/*------------------------------------*\
		Utility (Helper)
\*------------------------------------*/
.inner {
	width: 100%;
	box-sizing: border-box;
}
.list-wrapper {
	width: 100%;
	overflow: hidden;
	*zoom: 1;
}
.left {
	float: left;
}
.right {
	float: right;
}
.clear {
	clear: both;
}
.t-left {
	text-align: left;
}
.t-right {
	text-align: right;
}
.t-center {
	text-align: center;
}

.underline {
	text-decoration: underline!important;
}
.clearfix {
//@include clearfix;
	overflow: hidden;
}
.cf:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.cf {display: inline-block;}
/* Hides from IE-mac \*/
* html .cf {height: 1%;}
.cf {display: block;}
/* End hide from IE-mac */

.legacy-clearfix {
	@include legacy-pie-clearfix;
}
.fs-half {
	font-size: 50%;
}
.bold {
	font-weight: bold;
}
.hidden {
	display: none;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed{
	position: fixed;
}
.hidden-text {
	@include hiddenText;
}
.smp-show {

}
.smp-hide {
	display: none!important;
}
.sp-hide {
	display: block;
}
strong {
	@include fontBold;
}
.color-01 {
	color: $c-murasaki;
}
.font-mincho {
	font-family: $font-mincho!important;
}
.no-link {
	a:hover {
		img {
			opacity: 1;
		}
	}
}
@media (min-width: $smp-width) {
	.inner {
		width: $inner-width;
		margin: 0 auto;
	}
	.smp-show {
		display: none;
	}
	.smp-hide {
		display: block;
	}
	.sp-hide {
		display: block;
	}
	.sp-br {
		display: none;
	}
}
@media screen and (max-width: $smp-width) {
	.inner {
		padding: 0 15px;
	}
	.smp-inner {
	  width: 94.8%;
	  margin: 0 auto;
	  a{
	    display: block;
	  }
	}
	.smp-t-right {
		text-align: right;
	}
	.smp-t-center {
		text-align: center;
	}
	.smp-t-left {
		text-align: left;
	}

}



// ===================================================================
// Margin/Padding
// ===================================================================
$space: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
@each $prefix in $space{
	// Margin
	.mt#{$prefix} {
		margin-top: #{$prefix}px!important;
	}
	.mb#{$prefix} {
		margin-bottom: #{$prefix}px!important;
	}
	.ml#{$prefix} {
		margin-left: #{$prefix}px!important;
	}
	.mr#{$prefix} {
		margin-right: #{$prefix}px!important;
	}

	// Padding
	.pt#{$prefix} {
		padding-top: #{$prefix}px!important;
	}
	.pb#{$prefix} {
		padding-bottom: #{$prefix}px!important;
	}
	.pl#{$prefix} {
		padding-left: #{$prefix}px!important;
	}
	.pr#{$prefix} {
		padding-right: #{$prefix}px!important;
	}
}

// ===================================================================
// Font size
// ===================================================================
$fontSizeList: 62%, 70%, 77%, 85%, 93%, 100%, 108%, 116%, 124%, 131%, 139%, 147%, 154%, 162%, 170%, 177%, 185%, 193%, 200%, 208%, 216%, 224%, 231%, 239%, 247%, 254%, 262%, 270%, 277%, 285%, 293%, 300%, 308%, 316%, 324%, 331%, 339%, 347%, 354%, 362%,	370%, 377%, 385%, 393%, 400%, 408%, 416%, 424%, 431%, 439%, 447%;

@function fz($size) {
	@if $size < 8 {
		$size: 8;
	}
	@if $size > 40 {
		$size: 40;
	}
	@return nth($fontSizeList, $size - 7);
}
