@charset "UTF-8";
/* メインビジュアル
------------------------------------------*/
.main-visual-sub{
	.main-visual-type-inner{
		position: relative;
		height: 199px;
		.main-visual-type-title{
			font-size: 20px;
			padding-top: 44px;
			padding-left: 15px;
			line-height: 1;
			color: $c-murasaki;
			font-weight: bold;
			text-align: left;
			font-family: $font-mincho;
		}
		.main-visual-type-img{
			position: absolute;
			top: 80px;
			left: 12px;
		}
		.main-visual-type-type{
			position: absolute;
			top: 58px;
			right: 61px;
			width: 84px;
			height: 52px;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			padding-top: 32px;
			line-height: 1.29;
			background: url(/img/hearing_aid/bg_recommend_type.png) left top no-repeat;
			&.sec-row{
				height: 58px;
				padding-top: 26px;
			}
		}
	}
}

/* メインコンテンツ
------------------------------------------*/
.type-main-content{
	width: 950px;
	margin: 0 auto 42px auto;
}
.type-area-left{
	float: left;
	width: 476px;
	.type-area-img{
		margin: 18px 0 0 42px;
	}
	.type-area-price{

	}
}
.type-area-right{
	float: right;
	width: 469px;
	margin-right: 5px;
	.type-area-desc{
		margin: 150px 0 133px 34px;
	}
	.btn-area{
		background: #f5f4f6;
		width: 470px;
		height: 60px;
		.detail-btn{
			width: 214px;
			a{
				width: 214px;
				display: block;
				background: none;
				padding-left: 0;
				span{
					display: block;
					background: #fff url(/img/common/icon_circle_01.png) 17px center no-repeat;
					padding-left: 0;
					padding-right: 0;
				}
			}
			&.detail-btn-left{
				float: left;
				margin: 12px 0 0 16px;
				span{
					padding-left: 39px;
				}
			}
			&.detail-btn-right{
				float: right;
				margin: 12px 16px 0 0;
				span{
					padding-left: 34px;
					padding-right: 34px;
					background: #fff url(/img/common/icon_external_link_01.png) 168px 10px no-repeat;
				}
			}
		}
	}
}
.type-main-title{
	width: 950px;
	font-size: 24px;
	font-family: $font-mincho;
	font-weight: bold;
	color: #4e3c93;
	line-height: 1;
	background: url(/img/hearing_aid/line_guarantee_02.gif) left bottom no-repeat;
	padding-bottom: 12px;
	margin: 0 auto 17px auto;
}

.bg-net{
	&.merit{
		width: 300px;
		float: left;
		margin-right: 20px;
	}
	&.demerit{
		width: 250px;
		float: left;
	}
}
.net-title-b{
	margin-bottom: 9px;
	text-align: center;
	.sub{
		font-weight: bold;
		font-size: 16px;
		color: #333;
		display: inline-block;
		vertical-align: top;
		line-height: 1;
		margin-top: 3px;
	}
	.main{
		font-weight: bold;
		font-size: 21px;
		color: $c-murasaki;
		font-family: $font-mincho;
		border-bottom: 3px solid #01c1b8;
		line-height: 1;
	}
}
.net-title-b-list-area{
	.net-title-b-list{
		line-height: 1.86;
		font-size: 14px;
		margin-bottom: 10px;
		padding-left: 11px;
		background: url(/img/hearing_aid/icn_list_dot.png) left 10px no-repeat;
	}
}
.fig{
	float: right;
	margin-right: 23px;
}
