.main-visual-sub.lens{
	.inner{
		position: relative;
		.caution{
			position: absolute;
			right: 0;
			bottom: 15px;
			@include mincho-murasaki;
			font-size: 12px;
			line-height: 1;
		}
	}
}

.flat-price-section-box-01 {
	padding-top: 39px;
}
.flat-price-section-box-02{
	border-top: 1px solid #dfdfdf;
}
// カラムのラッパー
.flat-price-three-clms-outer {
	width: 936px;
	margin: 0 auto;
}
.flat-price-two-clms-outer {
	width: 614px;
	margin: 0 auto;
}
.flat-price-one-clms-outer {
	width: 292px;
	margin: 0 auto;
}

.inner-box-three-clms {
	float: left;
	width: 292px;
	margin-right: 30px;
	box-sizing: border-box;
	&.last{
		margin-right: 0;
	}
	.title{
		width: 292px;
		position: relative;
		display: inline-block;
		text-align: center;
		font-size: 18px;
		@include mincho-murasaki;
		@include vertical-align-middle(32px);
		background: #fff;
		border: 1px solid #dfdfdf;
		z-index: 0;
		margin-bottom: 9px;
		&:after{
			content: "";
			position: absolute;
			bottom: -10px; left: 50%;
			margin-left: -10px;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #dfdfdf transparent transparent transparent;
		}
	}
}
.three-clms-list-area{
	.three-clms-list{
		height: 35px;
		border-bottom: 1px solid #dfdfdf;
		font-size: 15px;
		font-weight: bold;
		padding-left: 7px;
		.num{
			display: table-cell;
			vertical-align: middle;
			height: 35px;
			line-height: 1;
			width: 43px;
			padding-right: 7px;
		}
		.name{
			display: table-cell;
			vertical-align: middle;
			height: 35px;
			line-height: 1;
			width: 157px;
			font-weight: normal;
			padding-right: 7px;
		}
	}
}
//　四角いボックス
.three-clms-list-tile {
	float: left;
	width: 292px;
	border-top: 2px solid #453287;
	border-left: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	margin-right: 30px;
	margin-bottom: 30px;
	box-sizing: border-box;
	&:nth-child(3n) {
		margin-right: 0;
	}
}
.three-clms-list-tile__title {
	display: table;
	width: 100%;
	border-right: 1px solid #dfdfdf;
	box-sizing: border-box;
	span {
		display: table-cell;
		width: 100%;
		height: 55px;
		font-family: $font-mincho;
		color: $c-murasaki;
		font-size: fz(16);
		font-weight: bold;
		vertical-align: middle;
		text-align: center;
	}
}
.three-clms-list-tile__text-area {
	overflow: hidden;
}
.three-clms-list-tile__text-left,
.three-clms-list-tile__text-right,
.three-clms-list-tile__text {
	border-right: 1px solid #dfdfdf;
	border-top: 1px solid #dfdfdf;
	box-sizing: border-box;
}
.three-clms-list-tile__text-left,
.three-clms-list-tile__text-right {
	float: left;
	min-height: 28px;
	font-size: fz(11);
	padding-left: 5px;
}
.three-clms-list-tile__text-left {
	width: 99px;
}
.three-clms-list-tile__text-right {
	width: 192px;
	span {
		margin-right: 5px;
	}
}
.three-clms-list-tile__text {
	width: 100%;
	clear: both;
	background: #e3f0de;
	padding: 5px 10px;
	text-align: center;
	.first-note {
		font-weight: bold;
	}
	.second-note {
		font-size: fz(11);
	}
}

.flat-price-box-01{
	background: #fff;
	border: 1px solid #eee;
	width: 654px;
	margin: 0 auto 44px auto;
//	@include border-radius(5px);
	border-radius: 5px;
	padding: 17px 27px;
	.tag{
		display: block;
		float: left;
		width: 34px;
		text-align: center;
		color: #fff;
		margin-right: 15px;
		@include vertical-align-middle(23px);
		&.tag-green{
			background: #01c1b7;
		}
		&.tag-purple{
			background: #7800ff;
		}
	}
	.desc{
		@include vertical-align-middle(23px);
	}
}
.detail-btn{
	&.flat-price-detail-btn{
		text-align: center;
		margin-bottom: 48px;
		a{
			border: 1px solid #ccc;
			width: 214px;
			span{
				padding: 0 0 0 29px;
				display: inline-block;
				background-position: left 7px;
			}
		}
	}
}
.tags{
	display: table-cell;
	vertical-align: middle;
	height: 35px;
	width: 77px;
	overflow: hidden;
	.tag{
		width: 34px;
		text-align: center;
		display: inline-block;
		color: #fff;
		font-size: 13px;
		@include vertical-align-middle(23px);
		&.tag-green{
			background: #01c1b7;
		}
		&.tag-purple{
			float: right;
			background: #7800ff;
		}
	}
}

.bnr-lens-lists{
	margin: 59px 0 90px 0;
	.bnr-lens-list{
		float: left;
		margin-right: 20px;
		&.bnr-lens-list-01{
			margin: 0 auto 40px auto;
			float: none;
		}
		&.bnr-lens-list-02{
			clear: both;
		}
		&:last-child {
			margin: 0;
		}

	}
}
.bnr-lens-lists-02 {
	overflow: hidden;
	width: 1000px;
	margin-bottom: 40px;
	text-align: center;
	h3 {
		font-size: fz(20);
		color: $c-murasaki;
		font-family: $font-mincho;
	}
	li {
		float: left;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
		p {
			font-family: $font-mincho;
			color: $c-murasaki;
			font-size: 145%;
			background: $c-murasaki;
			color: #fff;
		}
		div {
			width: 230px;
			box-sizing: border-box;
			border-top: none;
			border: 1px solid #e1e1e1;
			background: #fafafa;
			height: 31px;
			line-height: 29px;
			span {
				padding-left: 23px;
				background: url(/img/common/icon_circle_01.png) no-repeat left center;
			}
		}
	}
}

.sec-nav{
	.sec-nav-area{
		&.lens{
			text-align: center;
			.sec-nav-list{
				display: inline-block;
				float: none;
				margin-right: 40px;
				&:last-child {
					margin-right: 0;
				}
				a{
					&:hover{
						border-bottom: 3px solid #a78ec3;
						text-decoration: none;
					}
				}
				&.active a{
					border-bottom: 3px solid #a78ec3;
				}
			}
		}
	}
}
