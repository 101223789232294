.quality-depthperception {
  /* sec01
  ---------------------------------- */
  .quality-lead-area{
    border-top: 1px solid #dfdfdf;
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 40px 0 25px 0;

    .main-contens-box{
      background: #fff;
      border: 3px solid #f2f2f2;
      padding: 20px 24px 14px;

      /* --- タイトル部分 --------------- */
      .main-contens-title-area{
        margin-bottom: 25px;
      }
      .main-contens-title-deep{
        border-left: 5px solid $c-murasaki;
        padding-left: 19px;
        p{
          @include vertical-align-middle(35px);
          @include mincho-murasaki;
          font-size: 38px;
          font-weight: bold;
        }
      }
      .main-contens-title-light{
        border-left: 5px solid #a78dc3;
        padding-left: 19px;
        padding-top: 8px;
      }
      .main-contens-title-02{
        font-size: 14px;
        font-weight: bold;
      }
    }
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
    }
    .main-contens-clms-left{
      width: 544px;
      float: left;
      .main-contens-btn{
        background: #fff;
        border: 4px solid $c-murasaki;
        font-family: $font-mincho;
        font-weight: bold;
        width: 252px;
        @include vertical-align-middle(33px);
        font-size: 16px;
        text-align: center;
        a{
          display: inline-block;
          color: $c-murasaki;
          padding-left: 22px;
          background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .main-contens-txt{
        padding-left: 19px;
      }
    }
    .main-contens-clms-right{
      width: 368px;
      float: right;
      position: relative;
      
      .main-contens-img{
        border: 3px solid $c-murasaki;
        background: #fff;
        padding: 6px;
        img{
          display: block;
          border: 2px solid $c-murasaki;
        }
      }
    }
  }


  .sec-03.shop-list-area{
    position: relative;
    margin-bottom: 80px;
    .inner-shop-list{
      border-bottom: 1px solid $c-murasaki;
    }
    .area-name{
      width: 961px;
      background: url(/img/quality/after/bg_purple_belt.png) left top no-repeat;
      margin-bottom: 45px;
      @include mincho-murasaki;
      @include vertical-align-middle(50px);
      font-size: 20px;
      color: #fff;
      padding-left: 19px;
    }
    
    .shoplist-area-to-top-btn{
      margin: 0 auto;
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      background: #fff;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .white-belt-balloon{
    .inner{
      .title{
        background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
      }
    }
  }
  /* ラインリスト
  ------------------------------------------*/
  $w_01: 186px;
  $w_02: 431px;
  $w_03: 300px;
  $pr: 12px;

  .title-02.contact-lens-shop{
    margin-left: 9px;
  }
  /* ヘッド */
  .line-list-head{
    margin-left: 9px;
    .line-list-head-title{
      &.line-list-head-title-01{
        width: $w_01;
      }
      &.line-list-head-title-02{
        width: $w_02;
      }
      &.line-list-head-title-03{
        width: $w_03;
      }
    }
  }
  .line-lists{
    margin-left: 9px;
    .line-list{
      .arrow{
        background: url(/img/common/line_arrow_off.png) 957px 50% no-repeat;
        &:hover{
          background: url(/img/common/line_arrow_on.png) 957px 50% no-repeat;
        }
      }
      .line-list-item{
        &.line-list-item-01{
          width: $w_01 - 12px - $pr;
          padding-right: $pr;
        }
        &.line-list-item-02{
          width: $w_02 - $pr;
          padding-right: $pr;
        }
        &.line-list-item-03{
          width: $w_03;
          font-size: 22px;
        }
      }
    }
  }
  

}