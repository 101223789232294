@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
		 -moz-border-radius: $radius;
			-ms-border-radius: $radius;
					border-radius: $radius;
}

.quality-guarantee{
	.inner.main-visual-sub-inner{
		position: relative;
	}
	.main-visual-sub-img{
		position: absolute;
		right: 80px;
		top: 25px;
	}
	.main-visual-sub-txt{
		position: absolute;
		right: 41px;
		top: 144px;
		width: 189px;
		@include vertical-align-middle(31px);
		@include mincho-s(17);
		text-align: center;
		background: url(/img/quality/bg_main_visual_img.png) left top no-repeat;
		color: #fff;
	}

	/* sec
	---------------------------------- */
	.quality-lead-area{
		border-top: 1px solid #dfdfdf;
		padding: 0;
		background: none;
	}

	.local-tab-area{
		border-top: 1px solid $c-murasaki;
		.local-tab{
			float: left;
			text-align: center;
			margin: 0 auto;
			margin-top: -20px;
			background: #fff;
			border: 4px solid $c-murasaki;
			width: 292px;
			@include mincho-murasaki;
			@include vertical-align-middle(33px);
			font-size: 17px;
			text-align: center;
			a{
				display: inline-block;
				color: $c-murasaki;
				padding-left: 22px;
				background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
				&:hover{
					text-decoration: none;
				}
			}
			&.local-tab-01{
				margin-right: 24px;
				margin-left: 177px;
			}
		}
	}


	/* sec01
	---------------------------------- */
	.main-contens{
		padding: 22px 0;
		.inner-main-contens{
			box-sizing: border-box;
			background: #fff;
			padding: 0;
			border: 3px solid #f2f2f2;
			.inner-main-contens-detail{
				padding: 0 30px;
				.caution{
					text-align: right;
					font-size: 10px;
					margin-bottom: 15px;
				}
			}
		}
		.guarantee-lead{
			font-size: 14px;
			line-height: 1.79;
			height: auto;
		}

		/* 保証のところ
		---------------------------------- */
		.main-contens-list-area{
			.main-contens-list{
				position: relative;
				box-sizing: border-box;
				.border{
					border: 3px solid #f2f2f2;
					border-top: 3px solid $c-murasaki;
					width: 914px;
					padding: 2px 5px;
					margin-left: -3px;
				}

				/* ◯年保証
				---------------------------------- */
				.main-contens-list-txt{
					font-family: $font-mincho;
					font-size: 24px;
					font-weight: bold;
					color: #fff;
					width: 109px;
					height: 85px;
					text-align: center;
					background: url(/img/quality/guarantee/bg_guarantee_icon.png) left top no-repeat;
					line-height: 1.3;
					position: absolute;
					left: 165px;
					top: -5px;
					padding-top: 25px;
				}

				/* 枠
				---------------------------------- */
				.main-contens-list-box{
					overflow: hidden;
					box-sizing: border-box;
					background: #f9f9f7;
					height: 99px;
					.main-contens-list-box-desc{
						width: 401px;
						background: $c-murasaki;
						@include vertical-align-middle(22px);
						position: relative;
						display: inline-block;
						color: #fff;
						text-align: center;
						z-index: 0;
						margin: 16px 0 20px 296px;
						&:after{
							content: "";
							position: absolute;
							bottom: -10px; left: 50%;
							margin-left: -10px;
							display: block;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 10px 10px 0 10px;
							border-color: $c-murasaki transparent transparent transparent;
						}
					}
					.main-contens-list-box-title{
						line-height: 1;
						margin-left: 306px;
						@include mincho-s(14);
						.felt{
							@include mincho-murasaki-s(31);
						}
						.frame{
							//@include mincho-murasaki-s(10);
							//@include vertical-align-middle(14px);
							text-align: center;
							display: inline-block;
							padding: 2px 3px;
							//width: 55px;
							font-size: fz(10);
							color: $c-murasaki;
							font-family: $font;
							border: 1px solid $c-murasaki;
						}
					}
				}
			}
		}
		.caution-area{
      position: relative;
      &:before {
        position: absolute;
        right: 0;
        top: 10px;
        display: inline-block;
        content: "";
        width: 82%;
        height: 1px;
        border-top: 1px solid $c-murasaki;
        z-index: 9500;
      }
			.caution-area-txt{
        position: relative;
        z-index: 9501;
				width: 107px;
				border: 1px solid $c-murasaki;
				padding: 1px;
				margin-top: -10px;
        margin-left: 115px;
				background: #fff;
				span{
					display: block;
					text-align: center;
					color: $c-murasaki;
					font-size: 12px;
					border: 1px solid $c-murasaki;
				}
			}
			.caution-area-box{
				width: 406px;
				margin: 0 auto;
				.caution-area-box-list-area{
					margin-bottom: 23px;
					.caution-area-box-list{
						font-size: 15px;
						margin-bottom: 11px;
						&.last{
							margin-bottom: 0;
						}
						@include vertical-align-middle(25px);
						.num{
							font-size: 18px;
							@include border-radius(12px);
							color: #fff;
							background: $c-murasaki;
							display: inline-block;
							margin-right: 10px;
							width: 25px;
							height: 25px;
							text-align: center;
						}
						.txt{
							display: inline-block;
							font-size: 15px;
							font-weight: bold;
						}
					}
				}
				.caution-area-box-txt{
					width: 401px;
					background: $c-murasaki;
					@include vertical-align-middle(22px);
					position: relative;
					display: inline-block;
					color: #fff;
					text-align: center;
					z-index: 0;
					margin: 0 auto 15px auto;
					font-weight: bold;
					&:after{
						content: "";
						position: absolute;
						bottom: -10px; left: 50%;
						margin-left: -10px;
						display: block;
						width: 0px;
						height: 0px;
						border-style: solid;
						border-width: 10px 10px 0 10px;
						border-color: $c-murasaki transparent transparent transparent;
					}
				}
			}
			.caution-area-box-02{
				position: relative;
				border: 3px solid #f2f2f2;
				height: 109px;
				box-sizing: border-box;

				.border{
					border-top: 3px solid $c-murasaki;
					position: absolute;
					margin-top: -3px;
					height: 109px;
					width: 914px;
					box-sizing: border-box;
					padding: 2px 5px;
					box-sizing: border-box;
					margin-left: -3px;
				}

				/* ◯年保証
				---------------------------------- */
				.caution-area-box-02-icon{
					font-family: $font-mincho;
					font-size: 24px;
					font-weight: bold;
					color: #fff;
					width: 109px;
					height: 85px;
					text-align: center;
					background: url(/img/quality/guarantee/bg_guarantee_icon.png) left top no-repeat;
					line-height: 1.3;
					position: absolute;
					left: 165px;
					top: -5px;
					padding-top: 25px;
				}

				/* 枠
				---------------------------------- */
				.caution-area-box-02-inner{
					overflow: hidden;
					box-sizing: border-box;
					background: #f9f9f7;
					height: 99px;
					.caution-area-box-02-inner-area{
						margin-left: 295px;
						width: 390px;
						.bg{
							background: url(/img/quality/guarantee/bg_type.png) left top no-repeat;
							width: 83px;
							@include vertical-align-middle(23px);
							font-size: 14px;
							font-weight: bold;
							text-align: center;
							display: inline-block;
							color: #fff;
							vertical-align: top;
							margin-top: 13px;
							margin-right: 5px;
						}
						.txt-01{
							font-size: 14px;
							font-weight: bold;
							vertical-align: top;
							margin-right: 13px;
						}
						.txt-02{
							font-size: 35px;
							font-family: $font;
							color: $c-murasaki;
							vertical-align: top;
							margin-right: 7px;
						}
						.frame{
							//@include mincho-murasaki-s(10);
							//@include vertical-align-middle(14px);
							text-align: center;
							display: inline-block;
							padding: 2px 3px;
							//width: 55px;
							font-size: fz(10);
							font-family: $font;
							border: 1px solid $c-murasaki;
							line-height: 1;
							font-weight: bold;
							color: $c-murasaki;
						}
					}
					.caution-area-box-02-inner-top{
						@include vertical-align-middle(50px);
						background: url(/img/quality/guarantee/bg_line.png) left bottom repeat-x;
					}
					.caution-area-box-02-inner-under{
						@include vertical-align-middle(49px);
					}

				}
			}
		}
	}

	/* アンダー15 3つの保証内容
	---------------------------------- */
	.guarantee-contents{
		width: 915px;
		border: 4px solid #dfdfdf;
		padding: 6px;
		margin-bottom: 10px;
		.inner-guarantee-contents{
			//height: 282px;
			background: #fffce4;
			padding: 85px 23px 0;
			position: relative;
		}
		.guarantee-contents-title{
			color: #ff6e6e;
			position: absolute;
			left: 0;
			right: 0;
			top: -19px;
			text-align: center;
			display: inline-block;
			background: url(/img/quality/guarantee/bg_line_marker_01.png) left 67px repeat-x;
			width: 520px;
			margin: 0 auto;
			.txt-01{
				@include mincho-s(41);
				display: inline-block;
			}
			.txt-02{
				font-family: $font;
				font-size: 73px;
				@include border-radius(44px);
				@include vertical-align-middle(89px);
				width: 89px;
				background: #fde521;
				font-weight: bold;
				display: inline-block;
				margin-right: 6px;
				margin-left: 11px;
				text-align: center;
			}
			.txt-03{
				@include mincho-s(31);
				display: inline-block;
			}
		}

		.guarantee-contents-list-area{
			overflow: hidden;
			margin-bottom: 8px;
			.guarantee-contents-list{
				width: 273px;
				min-height: 230px;
				padding-bottom: 15px;
				border-radius: 0 0 6px 6px;
				overflow: hidden;
				background: #fff url(/img/quality/guarantee/bg_contents_01.png) left top no-repeat;
				margin-right: 22px;
				float: left;
				&.last{
					margin-right: 0;
				}
				.txt-01{
					text-align: center;
					color: #ff6e6e;
					font-size: 16px;
					margin-top: 12px;
					margin-bottom: 28px;
					line-height: 1;
					font-weight: bold;
				}
				.txt-02{
					color: #fff;
					@include mincho-s(23);
					margin-bottom: 37px;
					line-height: 1;
					text-align: center;
				}
				.txt-03{
					text-align: center;
					.txt-03-01{
						font-size: 14px;
						font-weight: bold;
						display: block;
						margin-bottom: 3px;
					}
					.txt-03-02{
						@include mincho-s(16);
						color: #ff6e6e;
						background: url(/img/quality/guarantee/bg_line_marker_02.png) left 17px repeat-x;
						display: inline-block;
					}
				}
				&.guarantee-contents-list-01{
					.txt-02{
						span{
							background: url(/img/quality/guarantee/icon_guarantee_contents_01.png) left 2px no-repeat;
							padding-left: 45px;
						}
					}
				}
				&.guarantee-contents-list-02{
					.txt-02{
						span{
							background: url(/img/quality/guarantee/icon_guarantee_contents_02.png) left 5px no-repeat;
							padding-left: 60px;
						}
					}
				}
				&.guarantee-contents-list-03{
					.txt-02{
						span{
							background: url(/img/quality/guarantee/icon_guarantee_contents_03.png) left 2px no-repeat;
							padding-left: 52px;
						}
					}
				}
			}
		}

		.guarantee-contents-clms{
			overflow: hidden;
			.guarantee-contents-clms-left{
				width: 276px;
				float: left;
				.txt-01,.txt-02{
					font-size: 12px;
					line-height: 1.58;
				}
			}
			.guarantee-contents-clms-right{
				width: 565px;
				float: right;
				.txt-03{
					font-size: 12px;
					margin-bottom: 15px;
					line-height: 1.58;
				}
				.txt-04{
					padding: 7px 15px;
					background: #fff;
					font-size: 12px;
					line-height: 1.58;
				}
			}
		}
		.guarantee-contents-char-01{
			position: absolute;
			left: 19px;
			top: 7px;
			width: 113px;
			height: 78px;
		}
		.guarantee-contents-char-02{
			position: absolute;
			right: 40px;
			top: 7px;
			width: 92px;
			height: 78px;
		}
	}
	.guarantee-contents-txt{
		font-size: 14px;
		line-height: 1.21;
		margin: 0 auto 16px;
		width: 887px;
	}
	.guarantee-contents-02{
		margin: 0 auto 16px;
		width: 887px;
		height: 84px;
		border: 3px solid #ff6e6e;
		.txt-01{
			color: #fff;
			@include mincho-s(23);
			@include vertical-align-middle(84px);
			text-align: center;
			width: 272px;
			margin-right: 21px;
			float: left;
			background: url(/img/quality/guarantee/bg_contents_02.png) left top no-repeat;
			span{
				font-size: 30px;
			}
		}
		.txt-02{
			float: left;
			width: 451px;
			.txt-02-01{
				display: block;
				font-size: 14px;
				line-height: 1;
				margin: 8px 0 11px;
				font-weight: bold;
			}
			.txt-02-02{
				display: block;
				line-height: 1;
				margin-bottom: 10px;
				color: #ff6e6e;
				@include mincho-s(23);
			}
			.txt-02-03{
				display: block;
				font-size: 10px;
				line-height: 1;
			}
		}
	}
	.guarantee-contents-02-caution{
		padding: 15px;
		background: #f9f9f7;
		margin-bottom: 28px;
		.guarantee-contents-02-caution-title{
			font-size: 14px;
			font-weight: bold;
			span{
				color: #ff6e6e;
			}
		}
		.guarantee-contents-02-caution-list-area{
			.guarantee-contents-02-caution-list{
				font-size: 14px;
				line-height: 1.7;
				font-weight: normal;
				padding-left: 24px;
			}
		}
	}

	.sec-nav{
		margin-top: 6px;
		.inner {
			position: relative;
			.sec-nav-area{
				float:left;
				left:50%;
				position:relative;
				margin-left: 0;
				padding-left: 0;
			}
			.sec-nav-list{
				float:left;
				left:-50%;
				position:relative;
				margin-right: 84px;
				&.last{
					margin-right: 0;
				}
			}
		}
	}



	.white-belt-balloon{
		border-top: 1px solid #dfdfdf;
		.title{
			background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
		}
	}


}
