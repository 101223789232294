@charset "utf-8";
// frame
.glasses-frame {
  .bg-pattern-01 {
    padding-bottom: 60px;
  }
  .bnr-contents {
    margin: 40px 0;
  }
  .contents__ttl-bg-outer {
    margin: 70px 0;
  }
  .ttl-icon-outer {
    position: relative;
    width: 460px;
    height: 42px;
    margin: 0 auto;
  }
  .ttl-icon {
    position: absolute;
    top: -35px;
    left: 0;
    span {
      margin-left: 50px;
    }
  }
  .contents-list {
    background-image:url(/img/common/gl_footer_contents/bg_border_double.png),url(/img/common/gl_footer_contents/bg_border_double.png);
    background-position: left top, left bottom;
    background-repeat: repeat-x;
    background-color: #fff;
    margin: 30px 0;
    padding: 50px 40px;
  }
  .list-item {
    padding-top: 50px;
    margin-top: 50px;
    border-top: 1px dotted #000;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }
  .list-item__img {
    float: left;
    border-top: 1px solid $c-murasaki;
    border-bottom: 1px solid $c-murasaki;
    border-right: 8px solid $c-murasaki;
    border-left: 8px solid $c-murasaki;
  }
  .list-item__textarea {
    float: right;
  }
  .list-item__title-outer {
    height: 65px;
    line-height: 65px;
    border-top: 1px solid $c-murasaki;
    border-bottom: 1px solid $c-murasaki;
    margin-bottom: 20px;
  }
  .list-item__title {
    font-weight: bold;
    font-size: fz(30);
    font-family: $font-mincho;
    color: $c-murasaki;
    border-left: 4px solid $c-murasaki;
    padding-left: 20px;
  }
  // list-2
  .contents2-list {
    .col-4 {
      border-right: 1px dotted #000;
      margin-top: 25px;
      &:nth-child(3n) {
        border-right: none;
      }
    }
  }
  .list2-item__img {
    text-align: center;
    margin-bottom: 30px;
  }
  .list2-item__title {
    font-weight: bold;
    font-size: fz(23);
    font-family: $font-mincho;
    color: $c-murasaki;
    text-align: center;
  }
  .list2-item__anc {
    border-bottom: 1px dotted #000;
    &.border-none {
      border-bottom: none;
    }
  }
  .list2-item__title,
  .list2-item__anc {
    padding-bottom: 30px;
  }
  .list2-item__text {
    min-height: 10em;
  }
  .contents2-link-title {
    color: $c-murasaki;
    font-size: 33px;
    font-weight: bold;
    font-family: $font-mincho;
    text-align: center;
  }
  .contents2-link {
    .contents2-link-anc-border {
      margin-top: 40px;
      &:before {
        content: "";
        display: block;
        border-bottom: 1px solid $c-murasaki;
        margin-bottom: -30px;
      }
    }
    .btn.btn-05 {
      width: 645px;
      margin: 0 auto;
    }
  }
  // list-3
  .list3-item__title {
    margin-bottom: 20px;
    text-align: center;
    padding-top: 5px;
    border-top: 2px solid $c-murasaki;
    span {
      font-size: fz(22);
      font-family: $font-mincho;
      color: #fff;
    }
  }
  .list3-item__img {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #000;
  }
}
