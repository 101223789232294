.glasses-lightharmony{
  .main-visual{
    width: 980px;
    height: 1367px;
    background: url(/img/glasses/brand/lightharmony/bg_main_visual.png) left top no-repeat;
    margin-bottom: 38px;
    text-align: left;
  }

  /* メインコンテンツ
  ----------------------------*/
  .main-contents{
    padding-top: 678px;
    .main-contents_box{
      position: relative;
      .main-contents_title{
        border-left: 8px solid #af53a1;
        padding-left: 14px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 18px;
        span{
          @include mincho-s(23);
          display: block;
        }
      }
      .main-contents_box_clms_01{
        float: left;
        margin-left: 75px;
      }
      .main-contents_box_clms_02{
        float: left;
      }
    }
    .detail-btn{
      margin-left: 24px;
      a{
        border: 1px solid #e1e1e1;
        background: #fafafa;
      }
    }
    .main-contents_box_01{
      margin-bottom: 137px;
      .main-contents_title{
        margin-top: 83px;
        span.custom{
          margin-top: 5px;
        }
      }
      .main-contents_box_clms_01{
        width: 374px;
      }
      .main-contents_box_clms_02{
        width: 448px;
      }
      .main-contents_img_fig_txt{
        &.main-contents_img_fig_txt-01{
          top: 164px;
          left: 451px;
          width: 95px;
        }
        &.main-contents_img_fig_txt-02{
          top: 204px;
          left: 608px;
          width: 116px;
        }
        &.main-contents_img_fig_txt-03{
          top: 208px;
          left: 788px;
          width: 111px;
        }
      }
    }
    .main-contents_box_02{
      .main-contents_title{
        margin-top: 83px;
        span.custom-01{
          margin-top: 5px;
        }
        span.custom-02{
          font-size: 18px;
          margin-top: 2px;
        }
      }
      .main-contents_box_clms_01{
        width: 422px;
      }
      .main-contents_box_clms_02{
        width: 370px;
        margin-top: -20px;
      }
      .main-contents_img_fig_txt{
        &.main-contents_img_fig_txt-01{
          top: 224px;
          left: 595px;
          width: 81px;
        }
        &.main-contents_img_fig_txt-02{
          top: 255px;
          left: 686px;
          width: 86px;
          span.custom{
            font-weight: bold;
            display: block;
          }
        }
        &.main-contents_img_fig_txt-03{
          top: 255px;
          left: 783px;
          width: 88px;
          span.custom{
            font-weight: bold;
            display: block;
          }
        }
        &.main-contents_img_fig_txt-04{
          top: 82px;
          left: 827px;
          width: 105px;
        }
      }
    }

  }
  .main-contents_img_fig_txt{
    position: absolute;
    font-size: 10px;
    line-height: 1.2;
    color: #8f8f8f;
  }
  .contents-list {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .contents-list__title {
    height: 22px;
    line-height: 30px;
    font-weight: bold;
    background: url(/img/glasses/brand/sportyluxe/icon_circle.png) left center no-repeat;
    padding-left: 26px;
    padding-bottom: 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
  }
  .contents-list__inner__title{
    @include vertical-align-middle(33px);
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #4e3c93;
    margin-bottom: 19px;
    span{
      display: block;
      margin-left: 7px;
      padding-left: 11px;
      background: #afa7cb;
    }
  }
  .contents-list{
    margin-bottom: 50px;
  }
}
