.contact-lens-point {
	padding: 33px 0;
	&__contents {
		border: 1px solid #dcdcdc;
		text-align: center;
		padding-bottom: 49px;
	}
	.caution {
		padding: 24px 33px 12px;
		background: #f8f8f8;
		border-radius: 12px;
		max-width: 705px;
		margin: 20px auto 26px;
		text-align: left;
		box-sizing: border-box;
	}
	small {
		font-size: 12px;
	}
}