@charset "UTF-8";
.area01 {
	.main-visual {
    width: 100%;
    height: 424px;
    background-image: url(/img/shop_list/area01/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
  .anc-01 {
    position: absolute;
    top: 80px;
    left: 170px;
  }
  .anc-02 {
    position: absolute;
    top: 65px;
    left: 325px;
  }
  .anc-03 {
    position: absolute;
    top: 200px;
    left: 250px;
  }
  .anc-04 {
    position: absolute;
    top: 155px;
    left: 400px;
  }
  .anc-05 {
    position: absolute;
    top: 265px;
    left: 260px;
  }
  .anc-06 {
    position: absolute;
    top: 260px;
    left: 420px;
  }
  .anc-07 {
    position: absolute;
    top: 345px;
    left: 248px;
  }
}
