@charset "utf-8";
.glasses-junior {
	.main-visual--junior {
		background: #FFF265 url(/img/glasses/set/junior/bg_main.jpg) center top no-repeat!important;
		//background-image: url(/img/glasses/set/junior/bg_main.jpg) !important;
		//background-size: cover!important;
		.btn {
			background: none;
			font-size: 100%;
		}
		.btn-05 {
			height: 36px;
			line-height: 36px;
			padding: 0 10px;
			margin-right: 10px;
			margin-top: 10px;
			margin-bottom: 10px;
			span {
				display: block;
			}
			&:hover {
				background: #7a6599;
			}
		}
	}
	.bg-dotted {
		background: url(/img/glasses/set/junior/bg_dotted.png) left top repeat;
	}
	.bg-dotted--02 {
		padding-bottom: 40px;
		background: url(/img/glasses/set/junior/bg_dotted_02.png) left top repeat;
	}

	#sec-01, #sec-02 {
		padding-top: 40px;
	}


	.safe-title {
		text-align: center;
	}
	.safe-list--short {
		width: 658px;
		margin: 0 auto;
	}
	.safe-list-item {
		background: #fff;
		padding: 15px;
	}
	.safe-list-item {
		.block {
			margin-top: 5px;
			.row {
				margin-left: -5px;
				margin-right: -5px;
			}
			.col-3 {
				padding: 0 5px;
			}
		}
	}
	.safe-list-title {
		font-size: fz(20);
		font-weight: bold;
		color: $c-murasaki;
		text-align: center;
		margin-bottom: 10px;
	}
	.safe-list-img, .safe-list-text {
		margin-bottom: 10px;
	}
	.safe-list-text {
		min-height: 114px;
	}
	.safe-list-part-num {
		background: url(/img/glasses/set/junior/bg_safe_list_border.png) left center repeat-x;
		span {
			display: block;
			width: 30%;
			margin: 0 auto;
			background: #fff;
		}
	}
	.icon {
		color: $c-murasaki;
		padding-left: 19px;
		background: url(/img/common/icon_circle_01.png) no-repeat left center;
	}
	.warranty {
		background: #fff;
		padding-bottom: 20px;
	}
	.warranty-title {
		text-align: center;
		img {
			width: 100%;
		}
	}
	.warranty-list-outer {
		width: 951px;
		overflow: hidden;
		margin: 20px auto;
	}
	.warranty-list {
		width: 981px;
		overflow: hidden;
		*zoom: 1;
	}
	.warranty-list-item {
		float: left;
		width: 285px;
		border: 6px solid #ef4492;
		margin-right: 30px;
	}
	.warranty-list-item__text-outer {
		width: 285px;
		height: 128px;
	}
	.warranty-list-item__text {
		display: table-cell;
		width: 285px;
		height: 128px;
		vertical-align: middle;
		text-align: center;
		color: #ef4492;
		font-weight: bold;
		span {
			font-size: fz(38);
		}
	}
	.bg-junior-choose {
		height: 376px;
		background: #fff55d url(/img/glasses/set/junior/bg_junior_choose.jpg) center top no-repeat;
		.detail-anc {
			padding-top: 290px;
		}
	}
	.detail-anc {
		width: 395px;
		margin: 0 auto 20px;
	}
	.contents-list__item {
		margin: 15px 0 0;
		.col-4 {
			margin-top: 25px;
			.icon {
				margin-top: 5px;
			}
		}
	}
}
