@charset "utf-8";
/* global */
.contents__ttl-bg-outer {
	&:after {
		display: block;
		content: '';
		background: url(/img/glasses/set/economy/bg_ttl_arrow.png) no-repeat center bottom;
		height: 10px;
	}
}
.contents__ttl-bg {
	background: #4e3c93;
}
.contents__title {
	background-image: url(/img/glasses/set/economy/bg_ttl_01.png), url(/img/glasses/set/economy/bg_ttl_02.png);
	background-position: left top, right top;
	background-repeat: no-repeat;
	text-align: center;
	padding: 10px 0;
	font-size: fz(24);
	font-weight: bold;
	font-family: $font-mincho;
	color: #fff;
}
.contents__caption {
	position: relative;
	text-align: center;
	padding: 40px 0;
}
.contents__caption--is-balloon {
	border-bottom: 1px solid #e1e1e1;
	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		bottom: -9px;
		width: 17px;
		height: 9px;
		background: url(/img/glasses/set/economy/bg_ttl_arrow_02.png) center bottom no-repeat;
	}
}
.contents__caption--is-border {
	&:after {
		content: "";
		display: block;
		height: 8px;
		background: url(/img/glasses/set/casual/bg_contents_caption.png) left bottom repeat-x;
		padding-top: 20px;
	}
}

/* local */
.glasses-economy {
	.main-visual {
		background: url(/img/glasses/set/economy/bg_main_visual.png) left top no-repeat;
		background-size: 100% 100%;
		.col-7 {
			margin-top: 30px;
		}
		.attr {
			font-size: 13px;
			font-weight: bold;
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	.main-visual-inner {
		display: table;
		min-height: 339px;
	}
	.main-visual__img,
	.main-visual__textarea {
		display: table-cell;
		vertical-align: middle;
	}
	.main-visual__img--casual {
		height: 200px;
	}
	.main-visual__title {
		margin-top: 50px;
	}
	.main-visual__title,
	.main-visual__text,
	.main-visual__price {
		color: #4e3c93;
		text-align: left;
	}
	.main-visual__title {
		font-size: fz(40);
		font-family: $font-mincho;
		margin-bottom: 10px;
		margin-top: 25px;
	}
	.main-visual__text {
		margin-bottom: 25px;
	}
	.main-visual__price {
		display: inline-block;
		font-size: 43px;
		font-weight: bold;
		background: url(/img/glasses/set/economy/icon_including_tax.png) left center no-repeat;
		border-bottom: 5px solid $c-murasaki;
		padding-left: 55px;
		span {
			font-size: 30px;
		}
	}
	.contents__box {
		.inner {
			display: table;
		}
		.contents__box-inner {
			display: table-cell;
			height: 455px;
			vertical-align: middle;
		}
	}
	.contents__box--is-bg3 {
		.inner {
			border-bottom: 1px solid #4e3c93;
		}
		.contents__box-textarea {
			width: 465px;
		}
	}
	.contents__imgarea.left {
		margin-left: 100px;
		padding-top: 75px;
		padding-bottom: 75px;
	}
	.contents__box-textarea {
//		width: 465px;
		padding-top: 75px;
	}
	.contents__box-title {
		position: relative;
		color: $c-murasaki;
		font-size: fz(26);
		font-weight: bold;
		font-family: $font-mincho;
		margin-bottom: 15px;
		padding-left: 15px;
		span {
			position: relative;
			border-left: 5px solid #a78dc3;
			margin-left: -15px;
			padding-left: 12px;
		}
		&:before {
			display: block;
			content: "";
			position: absolute;
			top: -5px;
			left: 0;
			height: 100%;
			border-left: 5px solid $c-murasaki;
		}
	}
	.contents__title--din {

	}
	.contents__box-note {
		padding-left: 20px;
		line-height: 2;
	}
	.contents-list {
		overflow: hidden;
		*zoom: 1;
		border-bottom: 3px solid #5a3e80;
		margin-top: 35px;
		&:after {
		  content: "";
		  display: block;
		  width: 90%;
		  float: right;
		  clear: right;
		  border-bottom: 3px solid #bcb1c1;
		  margin-bottom: -3px;
		  padding-bottom: 5px;
		}
	}
	.contents-list__item {
		&:last-child {
			border-bottom: 1px solid #4e3c93;
			padding-bottom: 50px;
		}
	}
	.contents-list__title {

	}
	.contents-list__img {
		margin-top: 13px;
		border: 1px solid #ccc;
		margin-bottom: 10px;
		box-sizing: border-box;
		overflow: hidden;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.row{
		.col-6{
			&.custom-p{
				padding: 75px 14px 0;
			}
		}
	}
	// 回遊リンク
	.contents-other {
		padding: 55px 0;
		.current {
			position: relative;
			span {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				z-index: 1000;
				opacity: 1;
				background: url(/img/glasses/set/btn_other_set_on.png) left top no-repeat;
			}
			img {

			}
		}
	}
	.flat-price-section-box-01 {
		border-top: none;
	}
}
