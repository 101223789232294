/*------------------------------------*\
		Global Mixins
\*------------------------------------*/
@mixin hiddenText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin changeOpacityImg {
	img {
		transition: opacity 0.3s;
	}
	&:hover {
		img {
			opacity: 0.5;
		}
	}
}

@mixin fontBold {
	font-weight: bold;
}

@mixin legacy-pie-clearfix {
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

/*
 *  角丸
*************************/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

/*
 *  フォント
*************************/
@mixin mincho-s($size){
  font-family: $font-mincho;
  font-weight: bold;
  font-size: fz($size);
}
@mixin mincho-murasaki{
  font-family: $font-mincho;
  color: $c-murasaki;
  font-weight: bold;
}
@mixin mincho-murasaki-s($size){
  font-family: $font-mincho;
  color: $c-murasaki;
  font-weight: bold;
  font-size: fz($size);
}




@mixin vertical-align-middle($length){
  height: $length;
  line-height: $length;
}

@mixin box($w,$color){
  border: $w solid $color;
}

@mixin frame-btn($a,$b,$c,$d,$size){
  a{
    width: $a;
    border: $b solid $c;
    display: block;
    text-align: center;
    &:hover{
      text-decoration: none;
    }
    span{
			@include mincho-s($size);
			@include vertical-align-middle($d);
      padding-left: 34px;
    }
  }
}
/*------------------------------------*\
    PC mixins
\*------------------------------------*/

/*------------------------------------*\
    SMP mixins
\*------------------------------------*/
