@charset "utf-8";
/*------------------------------------*\
		Common Layout

		1.ヘッダーナビ
		2.パンくず
		3.ローカルナビ
		4.ページャー
		5.コンテンツ下
			5-1.コンテンツ下：ブランドフレーム取扱店舗
			5-2.コンテンツ下：メガネ履歴
			5-3.コンテンツ下：キャンペーン +  Palette
			5-4.コンテンツ下：店舗案内 & フラッグショップ
			5-5.コンテンツ下：リンク（メガネ・サングラス・コンタクトレンズ・補聴器）
			5-6.コンテンツ下：リンクバナー
			5-7.コンテンツ下：リンク（会社概要・採用情報・店舗物件・お問い合わせ）
\*------------------------------------*/

// =====================================
// 1.ヘッダーナビ
// =====================================
.main-header {
	background: $c-murasaki;
	.inner {
		overflow: hidden;
		background: url(/img/common/bg_page_header.png) no-repeat left top;
		min-height: 91px;
	}
	.page-title {
		text-align: center;
		min-width: 242px;
		float: left;
		font-size: fz(30);
		padding: 36px 0 10px;
		font-family: $font-mincho;
		&.title-s {
			font-size: fz(22);
		}
	}
	.page-nav {
		padding: 59px 0 0;
		float: right;
		li {
			float: left;
			margin-left: 50px;
			padding-left: 10px;
			padding-bottom: 10px;
			background: url(/img/common/icon_arrow_02.png) no-repeat left 6px;
			&:first-child {
				margin-left: 0;
			}
			&.current, &:hover {
				border-bottom: 3px solid #01c1b8;
			}
		}
		a:hover {
			text-decoration: none;
		}
	}
	.page-title, a, p {
		color: #fff;
	}
}


// =====================================
// 2.パンくず
// =====================================
.breadcrumbs {
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	ul {
		overflow: hidden;
	}
	li {
		float: left;
		height: 34px;
		line-height: 34px;
		font-size: fz(11);
		&:after {
			content: '>';
			padding: 0 6px;
		}
		&:last-child:after {
			content: '';
			padding: 0;
		}
	}
	a {
		text-decoration: underline;
	}
}


// =====================================
// 3.ローカルナビ
// =====================================
.local-nav{
	background: #f7f7f7;
	border-top: 1px solid #dfdfdf;
	height: 63px;
	overflow: hidden;
	position: relative;
	ul {
		float: left;
		position: relative;
		left: 50%;
	}
	li {
		position: relative;
		left: -50%;
		float: left;
		font-size: 14px;
		padding-left: 24px;
		margin-right: 41px;
		background: url(/img/common/icon_circle_01_bottom.png) no-repeat left 1px;
		margin-top: 23px;
		&:last-child {
			margin-right: 0;
		}
		a{
			color: #4e3c93;
			padding-bottom: 20px;
			&:hover{
				border-bottom: 3px solid #01c1b8;
				text-decoration: none;
			}
		}
		&.active a{
			border-bottom: 3px solid #01c1b8;
		}
	}
}

// =====================================
// 4.ページャー
// =====================================
.pager{
	text-align: center;
	padding-bottom: 25px;
	.pager-list{
		display: inline-block;
		li{
			display: inline-block;
			height: 40px;
			line-height: 40px;
			background: url(/img/search/line.png) right top no-repeat;
			width: 75px;
			a , span{
				font-size: 55px;
				font-family: $font;
				color: $c-murasaki;
			}
			span {
				color: #000;
			}
			a:hover{
				text-decoration: underline;
			}
			&:last-child{
				background: none;
			}
			&.current{
				a{
					color: #333;
				}
			}
		}
	}
	.left-arrow,
	.right-arrow{
		display: inline-block;
		vertical-align: top;
		height: 40px;
		line-height: 40px;
		img{
			vertical-align: middle;
		}
	}
}


// =====================================
// 	5.コンテンツ下
// =====================================
#common-contents {
	border-top: 1px solid #dfdfdf;
}

/* *****************************
 * 5-1.コンテンツ下：ブランドフレーム取扱店舗
 ****************************** */
.common-brand {
	padding: 32px 0;
	text-align: center;
	h2 {
		margin-bottom: 10px;
	}
	.list-wrapper {
		box-sizing: border-box;
		margin-bottom: 22px;
		border: 1px solid #dbdbdb;
	}
	ul {
		overflow: hidden;
		box-sizing: border-box;
		background: url(/img/home/bg_list_01.png) repeat-y center -1px;
		margin-bottom: -1px;
	}
	li {
		float: left;
		width: 244px;
		height: 99px;
		line-height: 99px;
		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.btn {
		width: 315px;
		margin: 0 auto;
	}
}
/* *****************************
 * 5-2.コンテンツ下：メガネ履歴
 ****************************** */
.common-history {
	overflow: hidden;
	padding: 40px 0;
	.box-history {
		float: left;
		width: 485px;
	}
	.history-item-img {
		border: 1px solid #f0f0f0;
		border-top: none;
		height: 209px;
		padding: 10px;
		line-height: 189px;
		box-sizing: border-box;
		text-align: center;
		img {
			max-width: 70%;
			max-height: 209px;
			height: auto;
		}
	}
	.history-item-cat-01 {
		float: left;
		width: 139px;
		height: 77px;
		border: 1px solid #f0f0f0;
		border-top: none;
		padding: 10px;
		line-height: 57px;
		box-sizing: border-box;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		img {
			max-width: 100%;
			max-height: 100%;
			height: auto;
		}
	}
	.history-item-name {
		float: left;
		border: 1px solid #f0f0f0;
		border-top: none;
		border-left: none;
		padding: 12px 17px;
		width: 485px - 139px;
		height: 77px;
		box-sizing: border-box;
		color: $c-murasaki;
		line-height: 1.1;
	}
	.history-item-cat-02 {
		font-family: $font-mincho;
	}
	.history-item-number {
		@include fontBold;
		font-size: fz(35);
	}
	.box-campaign {
		float: right;
		width: 486px;
	}
	.box-campaign-sub {
		float: left;
		display: none;
	}
	.box-campaign-img {
		border: 1px solid #f0f0f0;
		padding-top: 1px;
		text-align: center;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.common-history-link {
		clear: both;
		background: #f7f7f7;
		height: 40px;
		line-height: 40px;
		border: 1px solid #e2e2e2;
		border-top: none;
		text-align: center;
		a {
			background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
			padding: 3px 0 3px 30px;
			line-height: 17px;
			@include fontBold;
		}
	}
}
/* *****************************
 * 5-3.コンテンツ下：キャンペーン +  Palette
 ****************************** */
.common-campaign {
	border-top: 1px solid #dfdfdf;
	padding: 32px 0 20px;
	.inner {
		overflow: hidden;
		padding: 0 30px;
		box-sizing: content-box;
		text-align: center;
	}
	.left {
		width: 710px;
		float: left;
		ul.slick-slider {
			margin: 6px 0 10px;
		}
		li {
			padding-bottom: 11px;
			img {
				border: 1px solid #dfdfdf;
			}
		}
	}
	.right {
		float: right;
		width: 222px;
		ul {
			margin: 5px 0 10px;
		}
		li {
			border: 1px solid #dfdfdf;
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.btn-03 {
			padding: 7px 24px 7px 8px;
		}
	}
	.slick-dots {
		position: absolute;
		top: 296px;
		left: -23px;
		li {
			margin: 0 0 7px;
			border: none;
			display: block;
			width: 12px;
			height: 12px;
			padding: 0;
			border-radius: 50%;
			background: #fff;
			&.slick-active {
				background: #bcb1c1;
			}
		}
	}
}
/* *****************************
 * 5-4.コンテンツ下：店舗案内 & フラッグショップ
 ****************************** */
.common-store-guide {
	background: #f7f7f7;
	overflow: hidden;
	padding: 38px 0 20px;
	.left .title-01 {
		padding-left: 38px;
		background: url(/img/common/icon_store.png) no-repeat left center;
		@include mincho-s(24);
	}
	.right .title-01 span {
		padding-left: 34px;
		background: url(/img/common/icon_flagship_shop.png) no-repeat left center;
		@include mincho-s(24);
	}
	// 店舗案内
	.common-store-guide-area {
		position: relative;
		width: 525px;
		height: 384px;
		margin-top: -29px;
		background: url(/img/common/img_japan_map.png) no-repeat left top;
		li {
			position: absolute;
			background: #fff;
			border-radius: 4px;
			.btn-01 {
				padding: 7px 8px 7px 30px;
			}
			.btn-03 {
				padding: 7px 30px 7px 8px;
			}
		}
	}
	.common-store-area-01 {  top: 101px;  left: 402px;  }
	.common-store-area-02 {  top: 216px;  left: 361px;}
	.common-store-area-03 {  top: 90px;  left: 174px;  }
	.common-store-area-04 {  top: 175px;  left: 34px;  }
	.common-store-area-05 {  top: 312px;  left: 129px;  }
	.common-store-area-06 {  top: 90px;  left: 36px;  }
	.common-store-area-07 {  top: 338px;  left: 323px;  }
	//フラッグシップショップ
	.common-store-flagship {
		margin-top: 14px;
		width: 426px;
		min-height: 363px;
		padding: 34px 15px 0;
		text-align: center;
		box-sizing: border-box;
		background-image:
			url(/img/common/bg_flagship_shop_01.png),
			url(/img/common/bg_flagship_shop_03.png),
			url(/img/common/bg_flagship_shop_02.png);
		background-repeat:
			no-repeat,
			no-repeat,
			repeat-y;
		background-position:
			left top,
			left bottom,
			left;
		.title-01 {
			margin-bottom: 22px;
		}
		.title-02 {
			background: $c-murasaki;
			color: #fff;
			font-size: fz(14);
			height: 30px;
			line-height: 30px;
			margin-bottom: 14px;
			&:after {
				content: '';
				background: url(/img/common/bg_flagship_shop_04.png) no-repeat center bottom;
				height: 6px;
				display: block;
			}
		}
	}
	.common-store-flagship-shinjuku-list {
		overflow: hidden;
		text-align: left;
		margin-bottom: 14px;
		li {
			display: inline-block;
			padding-left: 24px;
			background: url(/img/common/icon_circle_01.png) no-repeat left center;
			@include fontBold;
			background-size: 17px;
			line-height: 20px;
			margin-left: 20px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.common-store-flagship-others-list {
		overflow: hidden;
		border-top: 1px solid #dfdfdf;
		li {
			float: left;
			padding: 0 24px;
			box-sizing: border-box;
			min-width: 110px;
			text-align: left;
			background: url(/img/common/icon_circle_01.png) no-repeat left center;
			@include fontBold;
			background-size: 17px;
			line-height: 20px;
			margin-top: 18px;
		}
	}
}
/* *****************************
 * 5-5.コンテンツ下：リンク（メガネ・サングラス・コンタクトレンズ・補聴器）
 ****************************** */
.common-nav {
	box-sizing: border-box;
	min-height: 194px;
	border-top: 2px solid #dfdfdf;
	.inner {
		border-top: 2px solid #4e2f76;
		position: relative;
		margin-top: -2px;
		padding-top: 22px;
		overflow: hidden;
	}
	dl {
		box-sizing: border-box;
		float: left;
		width: 25%;
		dt, dd {
			padding-left: 32px;
			margin-bottom: 1em;
		}
		dt {
			@include fontBold;
			font-size: fz(14);
			background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
			background-size: 17px;
			line-height: 17px;
		}
		dd {
			font-size: fz(12);
			background: url(/img/common/icon_arrow_01.png) no-repeat 18px center;
		}
	}
}
/* *****************************
 * 5-6.コンテンツ下：リンクバナー
 ****************************** */
.common-bnr {
	border-top: 1px solid #dfdfdf;
	ul {
		box-sizing: border-box;
		border-left: 1px solid #dfdfdf;
		border-right: 1px solid #dfdfdf;
		overflow: hidden;
		text-align: center;
	}
	li {
		padding: 6px 5px 7px;
		display: inline-block;
		border-left: 1px solid #dfdfdf;
	}
	a {
		display: block;
		border: 1px solid #dfdfdf;
		@include changeOpacityImg;
	}
}
/* *****************************
 * 5-7.コンテンツ下：リンク（会社概要・採用情報・店舗物件・お問い合わせ）
 ****************************** */
.common-bottom-nav {
	border-top: 1px solid #dfdfdf;
	overflow: hidden;
	ul {
		float: left;
		overflow: hidden;
		padding-left: 9px;
	}
	li {
		font-size: fz(12);
		float: left;
		height: 58px;
		line-height: 58px;
		padding-left: 14px;
		margin-right: 38px;
		background: url(/img/common/icon_arrow_01.png) no-repeat left center;
	}
	.pagetop {
		float: right;
		padding: 5px;
		margin-top: 15px;
		@include changeOpacityImg;
	}
}
#lean_overlay {
	position: fixed; z-index:100;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	background: #000;
	display: none;
}
/* *****************************
 * 5-7.コンテンツ下：SNS LINK
 ****************************** */
.sns-link {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 99999;
	ul {
		text-align: right;
		font-size: 0;
	}
	li {
		display: inline-block;
		border: 3px solid #eae7ec;
		border-bottom: none;
		border-right: 2px solid #eae7ec;
		&:last-child {
			border-right: 3px solid #eae7ec;
		}
	}
	a {
		display: block;
		width: 41px;
		height: 41px;
		@include hiddenText();
		background-image: url(/img/common/btn_sns_01.png);
		background-repeat: no-repeat;
		transition: background 0.3s ease-in-out;
	}
	&__facebook {
		background-position: 0 -41px;
		&:hover {
			background-position: 0 0;
		}
	}
	&__twitter {
		background-position: -43px -41px;
		&:hover {
			background-position: -43px 0;
		}
	}
}