.multi_drive_clms{
  width: 770px;
  margin: 0 auto 62px auto;
  .title{
    width: 360px;
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    @include vertical-align-middle(32px);
    background: $c-murasaki;
    font-family: font;
    z-index: 0;
    margin-bottom: 16px;
    &:after{
      content: "";
      position: absolute;
      bottom: -10px; left: 50%;
      margin-left: -10px;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 11px 8px 0 8px;
      border-color: $c-murasaki transparent transparent transparent;
    }
  }
}


/* マルチドライブ カスタム
------------------------------------------*/
.multi_drive.select-clms{
  .select-clms-left{
    width: 342px;
    margin-left: 5px;
    .select-sub-img-01{
      margin-bottom: 25px;
    }
    .fig-desc-lists{
      margin-left: 39px;
      .fig-desc-list{
        padding-left: 40px;
        margin-bottom: 15px;
        line-height: 1.4;
        font-size: 12px;
        &.fig-desc-list-01{
          background: url(/img/glasses/lens/select/multi_drive/icon_multi_num_01.gif) left top no-repeat;
        }
        &.fig-desc-list-02{
          background: url(/img/glasses/lens/select/multi_drive/icon_multi_num_02.gif) left top no-repeat;
        }
        &.fig-desc-list-03{
          background: url(/img/glasses/lens/select/multi_drive/icon_multi_num_03.gif) left top no-repeat;
        }
        &.fig-desc-list-04{
          background: url(/img/glasses/lens/select/multi_drive/icon_multi_num_04.gif) left top no-repeat;
        }
      }
    }
  }
  .select-clms-right{
    width: 450px;
  }
}
