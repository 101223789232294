/* =================================
 ブランド一覧ページ
 ================================= */
@import "brand/top";
@import "brand/common";

/* =================================
 スポーティラックスページ
 ================================= */
@import "brand/sportyluxe";

/* =================================
 ライトハーモニーページ
 ================================= */
@import "brand/lightharmony";

/* =================================
 ビサイドページ
 ================================= */
@import "brand/biside";

.contents-list__img-brand {
	padding: 10px;
	vertical-align: middle;
	height: 190px;
	line-height: 190px;
	img {
		max-width: 100%;
		height: auto;
	}
}

/* =================================
 Vintageページ
 ================================= */
@import "brand/vintage";

/* =================================
 取扱店舗
 ================================= */
@import "brand/shop_list";
