@charset "UTF-8";
.sunglasses-top {
	.main-visual {
		background: url(/img/sunglasses/top/bg_main_visual.png) center top no-repeat;
		padding: 77px 0;
	}
	.main-visual__title {
		height: 143px;
		line-height: 143px;
		color: $c-murasaki;
		font-size: fz(40);
		font-weight: bold;
		font-family: $font-mincho;
	}
	.contents {
		width: 981px;
		margin: 0 auto;
	}
	.contents-box {
		padding: 0 25px 38px;
	}
	.contents-box__img {
		min-height: 180px;
		padding-top: 40px;
		margin-bottom: 35px;
		img {
			display: block;
			width: 80%;
			margin: 0 auto;
		}
	}
	.contents-box__title {
		font-size: 24px;
		font-weight: bold;
		color: $c-murasaki;
		border-bottom: 1px solid #dfdfdf;
		margin-bottom: 10px;
		padding-bottom: 5px;
	}
	.contents2 {
		width: 981px;
		margin: 0 auto;
		padding-bottom: 40px;
	}
	.contents2__title {
		text-align: center;
		margin: 0 0 18px;
		padding-top: 50px;
	}
	.contents2-box {
		background-image: url(/img/sunglasses/product/bg_box_top.png),url(/img/sunglasses/product/bg_box_bottom.png),url(/img/sunglasses/product/bg_box_middle.png);
		background-position: left top, left bottom, left top;
		background-repeat: no-repeat, no-repeat, repeat-y;
		background-size: 100%;
	}
	.contents2-box-inner {
		padding: 30px 60px;
	}
	.contenst2-box__caption {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #e1dbf7;
	}
	.contents2-box__item {
		&:nth-child(2n) {
			border-left: 1px solid #dfdfdf;
		}
	}
	.contents2-box__btn {
		text-align: right;
	}

	.contents2-box2__item {
		margin-top: 35px;
		min-height: 463px;
		padding: 34px 25px;
		box-sizing: border-box;
		background-image: url(/img/sunglasses/product/bg_brand_pickup_top2.png),url(/img/sunglasses/product/bg_brand_pickup_bottom2.png),url(/img/sunglasses/product/bg_brand_pickup_middle2.png);
		background-repeat: no-repeat,  no-repeat, repeat-y;
		background-position: left top, left bottom, left;
	}
	.contents2-box2__item-img {
		padding: 50px 0 25px;
		margin: 0 0 25px;
		border-bottom: 1px solid #dfdfdf;
	}
	.contents2-box2__item-text {
		margin-bottom: 15px;
	}
	.contents3 {
		padding-top: 50px;
		.contents__list-item {
			margin-bottom: 25px;
		}
	}
	.contents-brand-pickup {
		background: url(/img/sunglasses/top/bg_brand_pickup_black.png) no-repeat center top;
		width: 991px;
		position: relative;
		margin-left: -1px;
		height: 369px;
		box-sizing: border-box;
		overflow: hidden;
		.left {
			padding: 65px 0 0 40px;
		}
		.right {
			box-sizing: border-box;
			width: 390px;
			padding-right: 40px;
			color: #fff;
			text-align: left;
			.contens-brand-pickup-logo {
				position: relative;
				margin: 0 0 -14px -26px;
			}
			p {
				border-top: 1px solid #fff;
				padding-top: 0.5em;
				margin-bottom: 0.5em;
			}
			a.btn {
				float: right;
			}
		}
	}
}
.sunglasses-brand-list {
	.main-visual {
		font-size: fz(24);
		color: #fff;
		font-family: $font-mincho;
		background: url(/img/sunglasses/brand_list/bg_main_visual.jpg) center top no-repeat;

	}
	.main-visual-img {
		margin-top: -52.5px;
	}
	.ttl-stores-list {
		margin-bottom: 20px;
	}
	// global
	.line-list-head .line-list-head-title.line-list-head-title-02,
	.line-lists .line-list .line-list-item.line-list-item-02 {
		width: 360px;
	}
	.line-lists .line-list .line-list-item {
		font-size: 13px;
	}
	.line-list-head .line-list-head-title.line-list-head-title-03 {
		width: 205px;
	}
	.line-lists .line-list .line-list-item.line-list-item-03 {
		width: 205px;
		font-size: 20px;
	}
	.line-list-head-title.line-list-head-title-04 {
		float: left;
	}
	.line-lists .line-list .line-list-item.line-list-item-04 {
		width: 60px;
	}
	#area-01 {
		overflow: hidden;
		padding-bottom: 15px;
		margin-bottom: 25px;
		position: relative;
		min-height: 40px;
		background: url(/img/glasses/brand/shop_list/bnr_01.png) no-repeat left bottom;
		.logo {
			float: left;
		}
		.btn {
			position: absolute;
			right: 0;
			bottom: 15px;
		}
	}
	.shop-section-01 {
		padding-top: 55px;
	}
}
/* =================================
 プロショップ
 ================================= */
@import "sunglasses/pro_shop";

/* =================================
 クアッド
 ================================= */
@import "sunglasses/quad";

/* =================================
 機能と効果
 ================================= */
@import "sunglasses/functional_effect";

/* =================================
歴史
 ================================= */
@import "sunglasses/history";
