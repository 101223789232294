@charset "utf-8";
/*------------------------------------*\
    タイトル
\*------------------------------------*/
// 鍵カッコ: グレー
.title-type-01 {
  @include mincho-murasaki-s(24);
  line-height: 1;
  text-align: center;
  margin-bottom: 21px;
  &:before{
    content: url(/img/common/bg_quotation_left.gif);
    position: relative;
    top: 9px;
    margin-right: 17px;
  }
  &:after{
    content: url(/img/common/bg_quotation_right.gif);
    position: relative;
    top: 9px;
    margin-left: 17px;
  }
}
// 鍵カッコ: 紫
.title-type-02 {
  &:before {
    content: url("/img/home/bg_title_left_01.png");
    margin-right: 17px;
  }
  &:after {
    content: url("/img/home/bg_title_right_01.png");
    margin-left: 17px;
  }
}
.title-02 {
  font-size: fz(24);
  font-family: $font;
}
.title-04 {
  color: #bbb1c1;
  font-size: fz(24);
  font-family: $font-mincho;
  background: url(/img/common/bg_title_02.png) no-repeat left bottom;
  padding-bottom: 6px;
  margin-bottom: 39px;
}
.title-05{
	display: inline-block;
	&:before{
		display: inline-block;
		content: url(/img/glasses/brand/top/bg_kakko_left.png);
		padding-right: 25px;
	}
	&:after{
		display: inline-block;
		content: url(/img/glasses/brand/top/bg_kakko_right.png);
		padding-left: 25px;
	}
}
.title-06 {
  background: #453288 url(/img/common/bg_title_05.png) no-repeat right top;
  padding: 16px;
  @include mincho-s(20);
  color: #fff;
}
