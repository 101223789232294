.contact-lens-clinic {
	.box-01 {
		.inner {
			height: 220px;
			background-image: url(/img/contact_lens/bg_05.png),url(/img/contact_lens/clinic/bg_01.png);
			background-repeat: repeat-x, no-repeat;
			background-position: left top, 730px center;
		}
		h2 {
			@include mincho-murasaki-s(24);
			padding: 36px 0;
		}
		p {
			font-weight: bold;
		}
	}
	.box-02 {
		padding: 50px 0 10px;
		border-top: 1px solid $c-murasaki;
		border-bottom: 1px solid $c-murasaki;
		ul {
			overflow: hidden;
		}
		li {
			float: left;
			margin-bottom: 20px;
			&:nth-child(2n+1) {
				margin-right: 20px;
			}
		}
	}

	.box-03 {
		margin-bottom: 60px;
		h3 {
			@include mincho-murasaki-s(24);
			padding: 20px 0 8px;
		}
		.contents__caption {
			padding-bottom: 15px;
		}
		.sec-nav {
			margin-bottom: 20px;
			&-area {
				margin-left: 0;
			}
		}
		.area{
			margin-bottom: 60px;
			&:last-child{
				margin-bottom: 0;
			}
			&-head{
				height: 50px;
				line-height: 50px;
				font-size: 20px;
				font-family: $font-mincho;
				color: #fff;
				font-weight: bold;
				padding-left: 17px;
				background: #4e3c93 url(/img/quality/clinic_store/bg_area_head.png) left top no-repeat;
				margin-bottom: 40px;
				@extend .border-box;
			}
			&-list{
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
				li{
					margin-top: 37px;
					&:first-child{
						margin-top: 0;
					}
				}
				.area-list-store-name{
					font-size: 14px;
					color: $c-murasaki;
					font-weight: bold;
					float: left;
				}
				.area-list-store-contact{
					float: right;
					font-size: 14px;
					color: $c-murasaki;
					font-weight: bold;
					span{
						display: inline-block;
						margin-left: 10px;
					}
				}
			}

			.area-list-head-wrap{
				margin-bottom: 16px;
			}
			.timetable{
				border: 1px solid #eee;
				th{
					width: 139px;
					background: #bbb1c1;
					color: #fff;
					text-align: center;
					vertical-align: middle;
					height: 24px;
					border-right: 1px solid #eee;
				}
				td{
					height: 60px;
					text-align: center;
					vertical-align: middle;
					border-right: 1px solid #eee;
				}
			}
		}
	}

	// モーダル
	.btn-consul,.clinic-consul-body{
		display: none;
	}
}
