.quality-top{
	$path: '/img/quality/top/';
	.main-visual{
		height: 340px;
		background: url(#{$path}main_visual_img.jpg) center top no-repeat;
		.main-visual-title {
			padding-left: 594px;
			padding-top: 0;
			.txt-01{
				display: block;
				@include mincho-murasaki;
				font-size: 32px;
				line-height: 1;
				margin-bottom: 10px;
			}
			.txt-02, .txt-03{
				display: block;
				line-height: 1.67;
				font-family: $font-mincho;
				font-weight: bold;
				font-size: 17px;
				color: #4e4e4e;
			}
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
	/* sec01
	---------------------------------- */
	.quality-lead-area{
		border-top: 1px solid #dfdfdf;
		.quality-lead{
			text-align: center;
			margin-bottom: 20px;
			span{
				background: url(#{$path}bg_dot.png) 27px top no-repeat;
				padding: 10px 0;
				border-bottom: 2px solid #c7e1bd;
			}
		}
		.quality-lead-02{
			text-align: center;
			font-size: 14px;
			line-height: 1.7;
		}
	}

	/* sec02
	---------------------------------- */
	.sec-02{
		.inner{
			background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
			padding: 63px 0 42px;
			.quality-menu{
				width: 870px;
				margin: 0 auto;
				padding-bottom: 61px;
				background: url(#{$path}bg_kakko_bottom.png) left bottom no-repeat;
				.quality-menu-title{
					background: url(#{$path}bg_kakko_top.png) left bottom no-repeat;
					margin-bottom: 40px;
					padding-bottom: 2px;
					text-align: center;
					span{
						font-size: 28px;
						@include mincho-murasaki;
						line-height: 1;
						background: #fff;
						display: inline-block;
						padding: 0 10px
					}
				}
				.under-menu-list{
					text-align: center;
					a{
						display: block;
					}
					.under-menu-list-item{
						display: inline-block;
						width: 167px;
						margin-right: 46px;
						&.last{
							margin-right: 0;
						}
						.under-menu-list-item-img{
							display: block;
							width: 114px;
							margin: 0 auto;
							margin-bottom: 10px;
						}
						.under-menu-list-item-title{
							display: inline-block;
							text-align: center;
							padding-left: 22px;
							@include mincho-murasaki;
							font-size: 18px;
							background: url(/img/common/icon_circle_01.png) left 5px no-repeat;
						}
					}
				}
			}
		}
	}


	/* sec03
	---------------------------------- */
	.sec-03{
		padding: 27px 0 62px;
		.inner{
			.sec-03-box{
				background: #fff;
				border: 3px solid #f2f2f2;
				width: 980px - 6px;
				position: relative;
				.sec-03-box-left{
					border-right: 1px dotted #e9e9e9;
					border-top: 4px solid $c-murasaki;
					background: url(#{$path}bg_sec_03.png) left top repeat-y;
					text-align: center;
					width: 286px;
					float: left;
					.quality-content-icon{
						width: 129px;
					}
					.quality-content-name{
						width: 189px;
						@include vertical-align-middle(31px);
						@include mincho-s(16);
						color: #fff;
						background: url(#{$path}bg_content_name.png) left top no-repeat;
					}
					.detail-btn{
						text-align: center;
						a{
							display: block;
							width: 206px;
							border: 3px solid $c-murasaki;
							margin: 0 auto;
							span{
								padding-right: 0;
								padding-left: 22px;
								@include mincho-murasaki;
								background: url(/img/common/icon_circle_01.png) left 7px no-repeat;
								display: inline-block;
							}
						}
					}
				}
				.sec-03-box-right{
					width: 566px;
					float: right;
					margin-right: 40px;
					.quality-content-title{
						font-size: 24px;
						line-height: 1.67;
						padding-bottom: 12px;
						@include mincho-murasaki;
						background: url(#{$path}bg_line.png) left bottom no-repeat;
						margin-bottom: 33px;
					}
					.quality-content-desc{
						font-size: 14px;
					}
				}

				.quality-content-img{
					position: absolute;
					top: -11px;
					right: -14px;
					width: 165px;
					border: 3px solid $c-murasaki;
					padding: 4px;
					background: #fff;
				}

				&.sec-03-box-01{
					height: 323px;
					.sec-03-box-left{
						height: 323px - 4px;
						.quality-content-icon{
							margin: 48px auto 21px;
						}
						.quality-content-name{
							margin: 0 auto 28px;
						}
					}
					.sec-03-box-right{
						.quality-content-title{
							margin-top: 65px;
						}
						.quality-content-desc{
							font-size: 14px;
						}
					}
				}

				&.sec-03-box-02{
					height: 402px;
					.sec-03-box-left{
						height: 402px - 4px;
						.quality-content-icon{
							margin: 82px auto 21px;
						}
						.quality-content-name{
							margin: 0 auto 67px;
						}
					}
					.sec-03-box-right{
						.quality-content-title{
							margin-top: 39px;
						}
						.quality-content-desc{
							margin-bottom: 25px;
						}
						.zen-shikai-area{
							width: 584px;
							height: 116px;
							background: url(#{$path}bg_zen_shikai.jpg) left top no-repeat;
							.zen-shikai{
								float: left;
								margin: 10px 23px 0 15px;
							}
							.zen-shikai-desc{
								font-size: 14px;
								line-height: 1.6;
								margin-top: 35px;
								width: 256px;
								float: left;
							}
						}
					}
				}

				&.sec-03-box-03{
					height: 323px;
					.sec-03-box-left{
						height: 323px - 4px;
						.quality-content-icon{
							margin: 48px auto 21px;
						}
						.quality-content-name{
							margin: 0 auto 28px;
						}
					}
					.sec-03-box-right{
						.quality-content-title{
							margin-top: 65px;
						}
						.quality-content-desc{
							font-size: 14px;
						}
					}
				}

				&.sec-03-box-04{
					height: 341px;
					.sec-03-box-left{
						height: 341px - 4px;
						.quality-content-icon{
							margin: 52px auto 21px;
						}
						.quality-content-name{
							margin: 0 auto 46px;
						}
					}
					.sec-03-box-right{
						.quality-content-title{
							margin-top: 38px;
						}
						.quality-content-desc{
							font-size: 14px;
						}
						.link-area{
							width: 584px;
							height: 65px;
							background: #dbd0e6;
							.detail-btn{
								width: 180px;
								float: left;
								a{
									display: block;
									width: 180px;
									text-align: center;
									padding-left: 0;
									span{
										padding-right: 0;
										padding-left: 22px;
										@include mincho-murasaki;
										background: url(/img/common/icon_circle_01.png) left 7px no-repeat;
										display: inline-block;
									}
								}
								&.detail-btn-01{
									margin: 15px 15px 0 107px;
								}
								&.detail-btn-02{
									margin: 15px 0 0 0;
								}
							}
						}
					}
				}

			}
		}
	}



	/* sec04
	---------------------------------- */
	.main-contens{
		padding: 66px 0 68px 0;
		background: $c-murasaki;
		font-size: 0;
		text-align: center;
		.inner-main-contens{
			width: 980px;
			margin: 0 auto;
		}
		.main-contens-box{
			display: inline-block;
			width: 299px;
			margin-right: 41px;
			margin-bottom: 35px;
			font-size: 13px; // base
			&:nth-child(3n) {
				margin-right: 0;
			}
			.main-contens-title{
				@include mincho-s(18);
				text-align: center;
				color: #fff;
				line-height: 1;
				padding-bottom: 7px;
				background: url(/img/quality/after/bg_kakko.png) left bottom no-repeat;
				margin-bottom: 13px;
				span{
					display: inline-block;
					background: $c-murasaki;
					padding: 0 10px;
				}
			}
			.main-contens-img{
				border: 5px solid #fff;
				margin-bottom: 15px;
			}
			.main-contens-txt{
				font-size: 14px;
				line-height: 1.7;
				color: #fff;
				width: 271px;
				margin: 0 auto 15px;
			}
			.main-contens-btn{
				border: 4px solid #fff;
				margin: 0 auto;
				@include mincho-s(16);
				width: 287px;
				@include vertical-align-middle(32px);
				text-align: center;
				a{
					display: block;
					color: #fff;
					padding-left: 22px;
					background: url(/img/quality/icon_circle_quality_banner.gif) 70px 8px no-repeat;
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
	}


	/* sec05
	---------------------------------- */
	.sec-05{
		height: 457px;
		background: url(#{$path}bg_store.jpg) center top no-repeat;
		overflow: hidden;
		.inner-box{
			width: 625px;
			height: 206px;
			background: url(#{$path}bg_store_box.png) center top no-repeat;
			margin: 142px auto 0;
			overflow: hidden;
			.inner-box-title{
				@include mincho-s(30);
				color: #fff;
				line-height: 1;
				margin: 36px 0 38px;
				text-align: center;
			}
			.inner-box-desc{
				font-size: 14px;
				line-height: 1;
				text-align: center;
				margin-bottom: 24px;
				color: #fff;
			}
		}
		.main-contens-btn{
			border: 4px solid #fff;
			margin: 0 auto;
			@include mincho-s(16);
			width: 287px;
			@include vertical-align-middle(32px);
			text-align: center;
			a{
				display: block;
				color: #fff;
				padding-left: 22px;
				background: url(/img/quality/icon_circle_quality_banner.gif) 70px 8px no-repeat;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}


	/* sec06
	---------------------------------- */
	.sec-06{
		.inner-white-belt-balloon {
			padding: 10px 0;
		}
		.sec-06-txt{
			font-size: 14px;
			line-height: 1;
			text-align: center;
			margin-bottom: 20px;
		}
		.sec-06-img{
			width: 897px;
			height: 172px;
			border: 3px solid $c-murasaki;
			padding: 5px;
			background: #fff;
			margin: 0 auto 15px;
		}
		.detail-btn{
			display: block;
			text-align: center;
			margin-bottom: 45px;
			a{
				display: inline-block;
				width: 206px;
				border: 3px solid $c-murasaki;
				span{
					padding-right: 0;
					padding-left: 22px;
					@include mincho-murasaki;
					background: url(/img/common/icon_circle_01.png) left 7px no-repeat;
					display: inline-block;
				}
			}
		}
	}


	/* sec07
	---------------------------------- */
	.sec-07{
		height: 277px;
		background: url(#{$path}bg_sec_07.png) center top no-repeat;
		overflow: hidden;
		margin-bottom: 38px;
		.sec-07-txt{
			float: left;
			width: 224px;
			font-size: 46px;
			line-height: 1.37;
			color: #fff;
			font-family: $font;
			margin-left: 140px;
			margin-top: 55px;
		}
		.sec-07-right-area{
			float: right;
			width: 380px;
			text-align: center;
			margin-right: 79px;
			.sec-07-right-txt{
				@include mincho-s(16);
				color: #fff;
				line-height: 2;
				margin-bottom: 23px;
				margin-top: 62px;
			}
			.sec-07-right-txt-02{
				border: 4px solid #fff;
				margin: 0 auto;
				@include mincho-s(15);
				width: 287px;
				@include vertical-align-middle(32px);
				text-align: center;
				a{
					display: block;
					color: #fff;
					padding-left: 24px;
					background: url(/img/quality/icon_circle_quality_banner.gif) 17px 8px no-repeat;
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
	}

	.white-belt-balloon{
		border-top: 1px solid #dfdfdf;
		.inner-white-belt-balloon{
			border-top: 2px solid $c-murasaki;
		}
		.inner{
			.title{
				background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
			}
		}
	}
	.white-belt-balloon .inner .white-ballon-parts, .white-belt-balloon .inner-white-belt-balloon .white-ballon-parts {
		position: relative;
		line-height: 1;
		left: 50%;
		top: -2px;
		margin-left: -6px;
	}

}
