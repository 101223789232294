/* エターナルスキンコート カスタム
------------------------------------------*/
.skincoat.select-clms{
  .select-clms-left{
    width: 359px;
  }
  .select-clms-right{
    width: 450px;
  }
}
