.quality-store{

	/* スライダー部分
	---------------------------------- */
	.slider-area {
		position: relative;
		width: 100%;
		height: 589px;
		margin: 0 auto;
		overflow: hidden;
	}
	.bx-wrapper {
		max-width: none;
		min-width: 1280px;
	}
	#bx-slider {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
		}
	}
	.slider-txt-area {
		position: absolute;
		top: 200px;
		left: 150px;
		width: 396px;
		height: 179px;
		background: url(/img/quality/store/bg_slide_img_01_box.png) left top no-repeat;
		overflow: hidden;
		z-index: 9999;
		.slider-list-title {
			text-align: center;
			line-height: 1;
			@include mincho-s(31);
			color: #fff;
			margin: 36px 0 32px;
		}
		.slider-list-lead{
			font-size: 14px;
			line-height: 1.79;
			color: #fff;
			text-align: center;
		}
	}
	.slider-txt-01{
		position: absolute;
		right: 3px;
		bottom: 17px;
		font-size: 10px;
		color: #fff;
	}


	.bx-wrapper {
		position: static;
	}
	.bx-wrapper .bx-pager {
		bottom: 30px;
		z-index: 9999;
	}

	/* sec01
	---------------------------------- */
	.quality-lead-area{
		.inner{
			.quality-lead{
				text-align: center;
				line-height: 1;
				margin-bottom: 34px;
				span{
					border-bottom: 2px solid #c7e1bd;
				}
			}
		}
	}
	.idea-list-area{
		height: 51px;
		padding-left: 29px;
		background: url(/img/quality/store/bg_idea_list.png) left top no-repeat;
		margin-bottom: 33px;
		.idea-list{
			width: 279px;
			display: inline-block;
			margin-right: 44px;
			&.last{
				margin-right: 0;
			}
		}
		.idea-list-num{
			width: 25px;
			float: left;
			color: #fff;
			font-family: $font;
			font-size: 28px;
			margin-left: 25px;
			@include vertical-align-middle(51px);
			display: block;
		}
		.idea-list-txt{
			color: #fff;
			font-family: $font;
			font-size: 28px;
			@include vertical-align-middle(51px);
			display: block;
		}
		.idea-list.idea-list-01{
			.idea-list-num{
				margin-right: 56px;
			}
		}
		.idea-list.idea-list-02{
			.idea-list-num{
				margin-right: 16px;
			}
		}
		.idea-list.idea-list-03{
			.idea-list-num{
				margin-right: 48px;
			}
		}
	}
	.quality-lead-title{
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 1.7;
		text-align: center;
	}

	/* sec02
	---------------------------------- */

	.main-contens-store{
		padding: 22px 0 28px 0;
		overflow: hidden;
		.main-contens-store-clms-left{
			float: left;
			width: 465px;
			box-sizing: border-box;
			position: relative;
			.main-contens-store-title{
				position: absolute;
				width: 154px;
				box-sizing: border-box;
				background: #fff;
				border: 2px solid $c-murasaki;
				padding: 1px;
				margin: 0 auto;
				top: -11px;
				left: 0;
				right: 0;
				span{
					border: 1px solid $c-murasaki;
					box-sizing: border-box;
					display: block;
					@include vertical-align-middle(26px);
					@include mincho-murasaki-s(18);
					text-align: center;
				}
			}
		}
		.main-contens-store-clms-right{
			float: right;
			width: 466px;
			margin-right: 15px;
			font-size: 14px;
			line-height: 1.7;
		}

	}

}
