
.inner.lens-faq{
  padding: 42px 0;

	.lens-faq-inner {
		padding: 24px 42px;
		border: 2px solid #dfdfdf;
		background: #fff;
	}

}

.lens-faq-lists{
  border-top: 1px solid #eae7ec;
  .lens-faq-list{
    border-bottom: 1px solid #eae7ec;
    .btn-accordion{
      padding: 10px 0;
      cursor: pointer;
      background: #fff;
      &:hover{
        background: #f5f5f5;
      }
      &.active{
        background: #f5f5f5;
      }
      span{
        @include mincho-murasaki-s(18);
        display: block;
        background: url(/img/common/icon_circle_01.png) left 50% no-repeat;
        padding-left: 26px;
        padding-right: 26px;
        margin-left: 10px;
      }
    }
    .contents-accordion{
      font-size: 14px;
      line-height: 1.79;
      border-top: 1px solid #eae7ec;
      padding: 20px 10px 20px 35px;
      .anc {
        color: #453288;
        text-decoration: underline;
      }
    }
  }
}
