/* UV-W ブロック カスタム
------------------------------------------*/
.uv_w_block.select-clms{
  .select-clms-left{
    width: 400px;
    .select-sub-img-01{
      margin-bottom: 15px;
    }
    .select-sub-img-02{
      img{
        vertical-align: top;
      }
    }
  }
  .select-clms-right{
    width: 450px;
  }
}
