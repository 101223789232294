@charset "UTF-8";
.area04 {
	.main-visual {
    height: 424px;
    background-image: url(/img/shop_list/area04/bg_main_visual.png);
    background-repeat: no-repeat;
    background-position: center top;
    .inner {
      height: 100%;
      overflow: hidden;
    }
  }
  .anc-01 {
    position: absolute;
    top: 80px;
    left: 170px;
  }
  .anc-02 {
    position: absolute;
    top: 65px;
    left: 325px;
  }
  .anc-03 {
    position: absolute;
    top: 170px;
    left: 210px;
  }
  .anc-04 {
    position: absolute;
    top: 205px;
    left: 300px;
  }
  .anc-05 {
    position: absolute;
    top: 260px;
    left: 330px;
  }
  .anc-06 {
    position: absolute;
    top: 320px;
    left: 330px;
  }
  .anc-07 {
    position: absolute;
    top: 335px;
    left: 178px;
  }
  .anc-08 {
    position: absolute;
    bottom: 130px;
    left: 216px;
  }
  .anc-09 {
    position: absolute;
    bottom: 160px;
    left: 95px;
  }
}
