@charset "utf-8";
.glasses-type {
  .title {
    font-size: fz(30);
    font-weight: bold;
    font-family: $font-mincho;
    text-align: center;
    border-bottom: 1px solid $c-murasaki;
    color: $c-murasaki;
    margin: 40px 0 20px;
  }
  .text {
    font-weight: bold;
    text-align: center;
    font-size: fz(14);
  }
  .btn-list {
    margin-bottom: 40px;
  }
  .bg-pattern-01 {
    padding: 50px 0;
  }
  .contents-list {
    background-image: url(/img/common/gl_footer_contents/bg_border_double.png);
    background-position: left top;
    background-repeat: repeat-x;
    background-color: #fff;
    margin: 30px 0;
    padding: 30px 40px;
  }
  .list-item {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid $c-murasaki;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  .list-item__title {
    position: relative;
    font-size: fz(30);
    font-weight: bold;
    font-family: $font-mincho;
    color: $c-murasaki;
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #c9c9c9;
    padding-left: 80px;
    &:before {
      width: 120px;
      height: 117px;
      position: absolute;
      top: -25px;
      left: -55px;
    }

  }
  .list-item__title--01 {
    &:before {
      content: url(/img/glasses/type/ttl_list_01.png);
    }
  }
  .list-item__title--02 {
    &:before {
      content: url(/img/glasses/type/ttl_list_02.png);
    }
  }
  .list-item__title--03 {
    &:before {
      content: url(/img/glasses/type/ttl_list_03.png);
    }
  }
  .list-item__img {
    float: left;
    border: 1px solid #c9c9c9;
  }
  .list-item__textarea {
    float: right;
  }
  .note-outer {
    border: 1px solid $c-murasaki;
    padding: 3px;
    margin-bottom: 10px;
  }
  .list-item__text{
    font-size: fz(14);
  }
  .note {
    height: 30px;
    line-height: 30px;
    background: $c-murasaki;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: fz(17);
  }
}

/* =================================
 単焦点レンズ
 ================================= */
@import "type/type_single";
