@charset "UTF-8";
.shop-top {
  padding-top: 25px;
  h1 {
    text-align: left;
    margin: 0 0 0 39px;
    background: url(/img/shop_list/top/icon_01.png) no-repeat left center;
    padding-left: 42px;
    color: $c-murasaki;
    font-family: $font-mincho;
    font-size: fz(24);
    line-height: 24px;
  }
  .inner {
    position: relative;
    height: 430px;
    background: url(/img/shop_list/top/pic_japan.png) no-repeat 301px 27px;
  }
  .map {
    width: 100%;
    position: relative;
    li {
      position: absolute;
    }
    #list01 {
      top: 107px;
      left: 642px;
    }
    #list02 {
      top: 225px;
      left: 600px;
    }
    #list03 {
      top: 118px;
      left: 414px;
    }
    #list04 {
      top: 200px;
      left: 274px;
    }
    #list05 {
      top: 323px;
      left: 369px;
    }
    #list06 {
      top: 117px;
      left: 224px;
    }
    #list07 {
      top: 354px;
      left: 563px;
    }
    a {
      padding: 8px 8px 8px 26px;
      border-radius: 3px;
      background: #fff url(/img/common/icon_circle_01.png) no-repeat 4px center;
    }
  }

  .main-contents {
    background: url(/img/shop_list/bg_02.png) repeat-x left top;

    h2 {
      height: 89px;
      text-align: center;
      background: url(/img/shop_list/bg_01.png) repeat-x left top;
      margin-bottom: 21px;
    }
    .list {
      overflow: hidden;
      position: relative;
      background: #f7f7f7;
      ul {
        overflow: hidden;
        padding-bottom: 21px;
        position: relative;
        left: 50%;
        li {
          float: left;
          margin-right: 38px;
          background: url(/img/common/icon_circle_01.png) no-repeat left center;
          position: relative;
          left: -50%;
          &:last-child {
            margin-right: 0;
          }
          &:first-child {
            margin-right: 13px;
          }

        }
      }
    }
  }
  .middle-contents {
    background: #fff;
  }
}

.shop-single {
  border-top: 1px solid #dfdfdf;
  padding: 30px 0 0;
  overflow: hidden;
  font-size: fz(15);
}

.shop-single-contents {
  padding: 0 50px 0 60px;
  .left, .right {
    margin-bottom: 30px;
  }
  .left {
    width: 410px;
  }
  .right {
    width: 440px;
  }
  .clearfix {
    overflow: hidden;
  }
  .flagshipshop {
    color: $c-murasaki;
    font-size: fz(14);
    font-family: $font-mincho;
    background: url(/img/shop/single/icon_flagshipment.png) no-repeat left center;
    height: 21px;
    line-height: 21px;
    position: relative;
    margin-left: -40px;
    padding-left: 40px;
  }
  .shop-name-wrapper {
    overflow: hidden;
    background: url(/img/shop/single/bnr_01.png) no-repeat left bottom;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
  .shop-name {
    color: $c-murasaki;
    font-size: fz(36);
    font-family: $font-mincho;
    float: left;
  }
  .link-language {
    overflow: hidden;
    border: 1px solid #eee;
    padding: 2px;
    width: 126px;
    margin-top: 38px;
    margin-left: 279px;
    li {
      width: 62px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
      float: left;
      &:first-child {
        margin-right: 2px;
      }
    }
    li span, li a {
      display: block;
      width: 100%;
      height: 100%;
    }
    li span, li a:hover {
      color: #fff;
      background: $c-murasaki;
    }
  }

  .shop-item {
    overflow: hidden;
    float: left;
    padding-left: 10px;
    margin-bottom: 15px;
    li {
      float: left;
      margin-right: 10px;
    }
  }
  .shop-option {
    float: right;
    margin-bottom: 15px;
    li {
      margin-bottom: 6px;
    }
  }
  .shop-sub-option {
    clear: both;
    overflow: hidden;
    margin-bottom: 15px;
    li {
      float: left;
      height: 19px;
      line-height: 19px;
      padding: 0 10px;
      border: 1px solid #dfdfdf;
      margin: 0 5px 5px 0;
      font-size: 13px;
    }
  }
  .shop-tax {
    border: 1px solid #dfdfdf;
    padding: 10px;
    margin-bottom: 15px;
    float: left;
    ul {
      overflow: hidden;
      li {
        float: left;
        margin-right: 15px;
        line-height: 34px;
        font-size: fz(14);
        &:last-child {
          margin: 0;
        }
        img {
          height: 34px;
          width: auto;
        }
      }
    }
  }
  .shop-cards {
    clear: left;
    margin-bottom: 30px;
    p {
      color: $c-murasaki;
      font-size: fz(14);
      font-family: $font-mincho;
    }
    ul {
      overflow: hidden;
    }
    li {
      float: left;
      border: 1px solid #dfdfdf;
      border-left: none;
      &:first-child {
        border: 1px solid #dfdfdf;
      }
    }
  }
  .shop-information {
    background: #f7f7f7;
    overflow: hidden;
    margin-bottom: 30px;
    .left {
      width: 430px;
      margin-bottom: 0;
    }
    .right {
      margin-bottom: 0;
    }
    .shop-address {
      overflow: hidden;
      background: #f7f7f7;
      box-sizing: border-box;
      padding: 6px 19px;
      .left {
        width: 265px;
      }
      .right {
        width: 115px;
        font-size: 13px;
        padding-top: 10px;
        text-align: right;
      }
    }
  }

  .shop-dl-01, .shop-dl-02, .shop-dl-03 {
    clear: both;
    padding: 10px;
    dt {
      padding-left: 19px;
      float: left;
      width: 4em;
      text-align: center;
      color: $c-murasaki;
      font-size: fz(12);
    }
    dd {
      margin-left: 95px;
    }
  }
  .shop-dl-01, .shop-dl-03 {
    background: #fdfdfd;
    dt {
      text-align: left;
    }
  }
  .shop-img {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .shop-google-map, .shop-google-map-parking {
    height: 280px;
    width: 440px;
  }
  .shop-contact-lens {
    clear: both;
    margin-bottom: 35px;
    p {
      color: $c-murasaki;
      font-size: fz(14);
      font-family: $font-mincho;
      margin-bottom: 6px;
    }
    ul {
      font-size: fz(10);
      li {
        margin-top: 4px;
      }
    }
    table {
      width: 100%;
    }
    thead {
      background: #bbb1c1;

    }
    th, td {
      border-right: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      width: 14.2857%;
    }
    th {
      height: 24px;
      color: #fff;
      @include fontBold;
    }
    td {
      font-size: fz(12);
      border-bottom: 1px solid #dfdfdf;
      height: 60px;
      text-align: center;
    }
  }
  .shop-brands {
    margin-bottom: 15px;
    p {
      color: $c-murasaki;
      font-size: fz(14);
      font-family: $font-mincho;
    }
    ul {
      overflow: hidden;
      border-left: 1px solid #dfdfdf;
      padding-top: 1px;
    }
    li {
      float: left;
      box-sizing: border-box;
      width: 16.66666666%;
      height: 57px;
      border-top: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
      position: relative;
      margin-top: -1px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 35px;
        box-sizing: border-box;
        padding: 10px;
      }
      img {
        vertical-align: middle;
        max-width: 80%;
        max-height: 80%;
        height: auto;
        width: auto;
      }
      text-align: center;
    }
  }
}

.shop-introduce {
  padding-bottom: 40px;
  h2 {
    font-size: fz(24);
    font-family: $font-mincho;
    text-align: center;
    padding: 25px 0;
    span {
      &:before {
        content: url(/img/shop/single/bg_title_left.png);
        padding-right: 10px;
      }
      &:after {
        content: url(/img/shop/single/bg_title_right.png);
        padding-left: 10px;
      }
    }
  }
  .inner {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .shop-single-contents-intro {
    overflow: hidden;
    margin-bottom: 30px;
    h2 {
      text-align: left;
    }
  }
  .shop-text {
    float: left;
    &.is-img {
      width: 285px;
    }
  }
  .shop-text-title {
    font-size: fz(16);
    margin-bottom: 6px;
    @include fontBold();
  }
  .shop-photo {
    float: right;
    overflow: hidden;

    li {
      float: left;
      margin-left: 3px;
      width: 280px;
      height: 280px * 150px / 230px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .shop-staff {
    clear: both;
    overflow: hidden;
    margin-bottom: 30px;
    p {
      font-size: fz(14);
      margin-left: 205px;
    }
  }
  .shop-staff-img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    float: left;
    img {
      border-radius: 50%;
      width: 100%;
      height: auto;
    }
  }
  .shop-staff-title {
    color: $c-murasaki;
    @include fontBold;
    font-size: fz(15);
  }
  .shop-staff-comment {
    margin-top: 1em;
  }
  .shop-staff-name {
    text-align: right;
    br {
      display: none;
      &:before {
        display: inline-block;
        content: "　";
      }
    }
  }
  .shop-feature {
    h3 {
      color: $c-murasaki;
      font-size: fz(24);
      font-family: $font-mincho;
      padding-bottom: 10px;
      margin-bottom: 35px;
      background: url(/img/shop/single/bnr_02.png) no-repeat left bottom;
    }
    h4 {
      background: url(/img/shop/bg_shop_feature_title.png) repeat-x left center;
      > span {
        padding-right: 10px;
        background: #fff;
        display: inline-block;
      }
    }
  }
  .shop-feature-contents {
    padding: 0 60px;
  }
  .shop-floor {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .shop-floor-title {
    font-family: $font-mincho;
    color: $c-murasaki;
    font-size: fz(24);
    display: table-cell;
    vertical-align: middle;
  }
  .shop-floor-contents {
    overflow: hidden;
    padding-top: 10px;
    margin-bottom: 30px;
  }
  .shop-floor-img,
  .shop-floor-sub-contents {
    float: left;
  }
  .shop-floor-img {
    box-sizing: border-box;
    max-width: 250px;
    padding: 0 20px 10px 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .shop-floor-sub-contents {
    &.is-img {
      width: 500px;
    }
    h5 {
      font-size: fz(15);
      margin-bottom: 10px;
      @include fontBold;
    }
  }
  .shop-floor-brand {
    border: 3px solid #dfdfdf;
    padding: 10px;
    margin-bottom: 30px;
    h5 {
      font-family: $font-mincho;
      color: $c-murasaki;
      font-size: fz(18);
      margin-bottom: 6px;
    }
    ul {
      overflow: hidden;
      margin-bottom: 20px;
      padding-left: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      li {
        float: left;
        font-size: fz(14);
        padding: 0 20px 0 15px;
        background: url(/img/common/icon_arrow_01.png) no-repeat left center;
      }
    }
  }
}

/* セカンドナビ
------------------------------------------*/
.sec-nav {
  background: #f7f7f7;
  border-top: 1px solid #dfdfdf;
  height: auto;
  padding-bottom: 23px;
  .sec-nav-area {
    margin-left: 10px;
    .sec-nav-list {
      float: left;
      font-size: 14px;
      padding-left: 24px;
      margin-right: 41px;
      background: url(/img/common/icon_circle_01_bottom.png) no-repeat left 1px;
      margin-top: 23px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #4e3c93;
        padding-bottom: 20px;
        &:hover {
          border-bottom: 3px solid #01c1b8;
          text-decoration: none;
        }
      }
      &.active a {
        border-bottom: 3px solid #01c1b8;
      }
    }
  }
  &.contact-lens-shop {
    .sec-nav-area {
      margin-left: 57px;
    }
    .sec-nav-list {
      margin-right: 53px;
    }
  }
}

.shop-section-01 {
  padding-top: 17px;
}

.title-02 {
  &.contact-lens-shop {
    padding-bottom: 8px;
    margin-bottom: 23px;
  }
}

/* 取扱店一覧
------------------------------------------*/
.type-lists {
  .type-list {
    text-align: right;
    .type-list-name {
      background: url(/img/contact_lens/icn_my_small.gif) left top no-repeat;
      padding-left: 30px;
      font-size: 14px;
      display: inline-block;
    }
    .type-list-link {
      color: $c-murasaki;
      display: block;
      background: url(/img/common/icon_external_link_01.png) right top no-repeat;
      padding-right: 21px;
    }
  }
}

/* ラインリスト
------------------------------------------*/
$w_01: 165px;
$w_02: 452px;
$w_03: 235px;
$w_04: 104px;

/* ヘッド */
.line-list-head {
  border-bottom: 1px solid $c-murasaki;
  padding-bottom: 5px;
  .line-list-head-title {
    line-height: 1;
    font-size: 14px;
    @include fontBold;
    color: $c-murasaki;
    float: left;
    &.line-list-head-title-01 {
      width: $w_01;
      margin-left: 2px;
    }
    &.line-list-head-title-02 {
      width: $w_02;
    }
    &.line-list-head-title-03 {
      width: $w_03;
    }
  }
}

/* ラインリスト
------------------------------------------*/
.line-lists {
  .line-list {
    border-bottom: 1px solid #d9d9d9;
    &.last {
      border-bottom: none;
    }
    &:hover {
      background: #f5f5f5;
    }
    a {
      display: block;
      &:hover {
        text-decoration: none;
        background: url(/img/common/bg_line_arrow.gif) right top repeat-y;
      }
    }
    .arrow {
      background: url(/img/common/line_arrow_off.png) 966px 50% no-repeat;
      &:hover {
        background: url(/img/common/line_arrow_on.png) 966px 50% no-repeat;
      }
    }
    .line-list-item {
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      height: 60px;
      padding: 0 10px;
      line-height: 1.3;
      &.line-list-item-01 {
        width: $w_01 - 12px;
        @include fontBold;
        padding-left: 12px;

      }
      &.line-list-item-02 {
        width: $w_02;
      }
      &.line-list-item-03 {
        width: $w_03;
        @include fontBold;
        font-size: 26px;
      }
      &.line-list-item-04 {
        width: $w_04;
      }
    }
  }
}

// 店舗一覧 TOP
.shop-list-top {
  .list-outer {

  }
  .list {
    display: table;
    width: 585px;
    margin: 0 auto;
    overflow: hidden;
    .list-item {
      display: table-cell;
      height: 70px;
      vertical-align: middle;
    }
  }
  .list-outer--2 {
    padding: 20px 0;
    background: #f7f7f7;
  }
  .list-outer--3 {
    width: 875px;
    margin: 0 auto;
    padding: 20px 0;
  }
  .baloon {
    color: #fff;
    font-weight: bold;
    background: #453287;
    padding: 5px 10px;
  }
  .icon04 {
    background: url(/img/shop_list/top/icon_04.png) right center no-repeat;
    padding-right: 39px;
    background-size: 22px;
  }
  .icon05 {
    background: url(/img/shop_list/top/icon_05.png) right center no-repeat;
    padding-right: 39px;
    background-size: 22px;
  }
  .btn-08 {
    background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
  }
}

// 店舗一覧
.shop {
  .icon-title {
    display: inline-block;
    font-size: fz(24);
    font-family: $font-mincho;
    color: $c-murasaki;
    background: url(/img/shop_list/area01/bg_main_visual_title.png) left center no-repeat;
    padding-left: 44px;
  }
  .main-visual__title {
    position: absolute;
    top: 35px;
    left: 15px;
  }
  .main-visual__img {
    text-align: right;
    margin-top: 20px;
  }
  .sec-nav {
    border-top: none;
  }
  // global
  .line-list-head .line-list-head-title.line-list-head-title-02,
  .line-lists .line-list .line-list-item.line-list-item-02 {
    width: 360px;
  }
  .line-lists .line-list .line-list-item.line-list-item-01 {
    font-size: 15px;
  }
  .line-lists .line-list .line-list-item {
    font-size: 13px;
  }
  .line-list-head .line-list-head-title.line-list-head-title-03 {
    width: 205px;
  }
  .line-lists .line-list .line-list-item.line-list-item-03 {
    width: 205px;
    font-size: 20px;
  }
  .line-list-head-title.line-list-head-title-04 {
    float: left;
  }
  .line-lists .line-list .line-list-item.line-list-item-04 {
    width: 60px;
  }
  .list-contact-icon {
    font-size: 12px;
    display: table-cell;
    padding: 20px 0;
    li {
      display: inline-block;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .btn-friend-shop {
    height: 62px;
    line-height: 62px;
    background: #f7f7f7;
    border-top: 1px solid $c-murasaki;
    border-bottom: 1px solid #d9d9d9;
    padding-left: 10px;
    .btn-03 {
      background: #f7f7f7 url(/img/common/icon_external_link_01.png) no-repeat 97% center;
    }
  }
}

/* =================================
 店舗の下層
 ================================= */
// マップとボタンの位置調整変数
$map-pos-val: 300px;
@mixin pos($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  @if $left   != '' {
    left: $left;
  }
  @if $top    != '' {
    top: $top;
  }
  @if $right  != '' {
    right: $right;
  }
  @if $bottom != '' {
    bottom: $bottom;
  }
}

.btn-map {
  padding: 7px 8px 7px 30px;
  background: #fafafa url(/img/shop_list/area01/icon_map_jp.png) no-repeat left center;
  background-size: auto;
  @include pos('', '', 10px, 16px);
}

.btn-other {
  padding: 7px 8px;
  @include pos('', '', 10px, 365px);
}

@import "shop/area01";
@import "shop/area02";
@import "shop/area03";
@import "shop/area04";
@import "shop/area05";
@import "shop/area06";
/* =================================
 店舗物件募集
 ================================= */
@import "shop/recruitment";
