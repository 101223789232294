@charset "UTF-8";
/* 体験談
------------------------------------------*/
.exp-section-01{
	margin-bottom: 37px;
	.exp-thum{
		width: 132px;
		margin-right: 16px;
		float: left;
	}
	.exp-info{
		width: 302px;
		float: left;
		margin-top: 11px;
		.exp-txt{
			padding-left: 28px;
			font-size: 16px;
			font-weight: bold;
			line-height: 1.31;
			background: url(/img/hearing_aid/icn_exp_check.gif) left top no-repeat;
			span{
				border-bottom: 2px solid #01c1b7;
			}
		}
	}
	.right{
		.exp-thum{
			margin-left: 33px;
		}

	}
}

/* セクション2
------------------------------------------*/
.exp-section-02{
	border-bottom: 1px solid #e1dbf7;
	padding: 25px 0 35px;
	position: relative;
	.exp-section-txt-01{
		@include mincho-murasaki;
		font-size: 22px;
		margin: 0 0 30px 145px;
	}
	.exp-section-txt-02{
		@include mincho-murasaki;
		@include vertical-align-middle(71px);
		font-size: 28px;
		border: 9px solid #01c1b7;
		text-align: center;
	}
	.exp-arrow{
		position: absolute;
		top: -25px;
		left: 444px;
	}
}

/* セクション3
------------------------------------------*/
.exp-section-03{
	.exp-section-txt-01{
		width: 448px;
		font-size: 14px;
		line-height: 1.79;
	}
	.exp-section-img-01{
		width: 461px;
		margin-right: 6px;
	}
	.exp-section-lists{
		width: 398px;
		.exp-section-list{
			margin-bottom: 34px;
			.exp-section-list-head{
				font-size: 18px;
				color: $c-murasaki;
				font-weight: bold;
				line-height: 1;
				margin-bottom: 4px;
			}
			.exp-section-list-desc{
				font-size: 13px;
				line-height: 1.83;
			}
		}
	}
	.exp-section-img-02{
		width: 470px;
	}
}
