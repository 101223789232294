@charset "utf-8";
/*------------------------------------*\
		Footer
\*------------------------------------*/

#footer {
	border-top: 1px solid #dfdfdf;
	padding-bottom: 20px;
	overflow: hidden;
}
.footer-logo {
	padding-top: 21px;
	float: left;
	a {
		@include changeOpacityImg;
	}
}
.footer-nav {
	margin-bottom: 7px;
	ul {
		overflow: hidden;
		padding: 30px 6px 0 0;
	}
	li {
		float: left;
		font-size: fz(12);
		margin-left: 29px;
		padding-left: 9px;
		background: url(/img/common/icon_arrow_01.png) no-repeat left center;
	}
}
.footer-copyright {
	float: right;
	font-family: $font;
	font-size: fz(12);
	@include fontBold;
}
