@charset "utf-8";
/*------------------------------------*\
		Header
\*------------------------------------*/

#header {
	border-top: 2px solid $c-murasaki;
	padding: 10px 0 0;
	overflow: hidden;
	&.zenshikai-header-sub {
		border-top: 2px solid #58afa2;
	}
}
.header-nav {
	float: right;
	overflow: hidden;
	margin-bottom: 10px;
	li {
		float: left;
		font-size: fz(12);
		border-left: 1px solid #dfdfdf;
		padding-left: 9px;
		margin-left: 9px;
		@include fontBold;
		&:first-child {
			border: none;
		}
	}
}
.header-search {
	clear: right;
	float: right;
	margin-left: 20px;
	border-bottom: 1px solid #000;
	input{
		border:none;
		text-decoration: none;
		background-color:#fff;
		cursor:pointer;
		padding:0;
		-webkit-appearance: button;
		*overflow: visible;
	}
	input[type=text] {

	}
	input[type=submit] {
		width: 16px;
		height: 16px;
		background: url(/img/common/icon_search.png) left top no-repeat;
	}
}
.header-main {
	clear: right;
}
.header-logo {
	width: 240px;
	text-align: center;
	float: left;
	height: 82px;
	vertical-align: middle;
}
.header-page-head {
	height: 55px;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	padding-left: 121px;
	h1 {
		font-family: $font-mincho;
		color: $c-murasaki;
		font-size: fz(24);
		@include fontBold;
		line-height: 1.2;
		text-align: left;
	}
	ul {
		margin-top: 10px;
		overflow: hidden;
		li {
			float: left;
			font-size: fz(14);
			@include fontBold;
			background: #fff url(/img/common/icon_circle_01.png) no-repeat 0 center;
			background-size: 17px;
			padding-left: 22px;
			margin-right: 60px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	a:hover {
		text-decoration: none;
		color: #7a6599;
	}
}
.header-page-head-inner {
	border-left: 3px solid $c-murasaki;
	padding-left: 11px;
}
