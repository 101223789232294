
/* タイトル部分
------------------------------------------*/
.select-sec-01{
  padding-top: 45px;
  padding-bottom: 54px;
  border-top: 1px solid #dfdfdf;
  .inner{
    background: #fff;
    position: relative;
    padding: 46px 61px 40px;
  }
  .select-icon{
    position: absolute;
    top: -1px;
    left: -18px;
    width: 112px;
    height: 112px;
  }
  .sub-title{
    @include mincho-murasaki;
    font-size: 23px;
    line-height: 1;
    background: url(/img/glasses/bg_choice_line.gif) left bottom repeat-x;
    margin: 0 0 32px 0;
    padding: 0 0 30px 10px;
    position: relative;
  }
  .title{
    @include mincho-murasaki;
    font-size: 35px;
    line-height: 1;
    margin-bottom: 36px;
  }
}


/* タイトル以下のレイアウト
------------------------------------------*/
.select-clms{
  .select-clms-left{
    float: left;
  }
  .select-clms-right{
    float: right;
    .select-clms-title{
      color: $c-murasaki;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 14px;
    }
    .select-clms-txt{
      line-height: 1.79;
      font-size: 14px;
    }
    .select-price-area{
      margin-top: 24px;
      border-top: 1px solid $c-murasaki;
      padding-top: 20px;
      text-align: right;
      .item-name{
        display: inline-block;
        @include mincho-murasaki;
        vertical-align: top;
        font-size: 14px;
        //margin-top: 15px;
        font-weight: bold;
        line-height: 1.5;
      }
      .price-area{
        display: inline-block;
        color: $c-murasaki;
        line-height: 1.2;
        margin-left: 40px;
        vertical-align: top;
        text-align: left;
        .price{
          font-weight: bold;
          font-size: 35px;
            span {
                font-size: 17px;
            }
        }
        .sup{
          font-size: 12px;
          display: block;
        }
      }
    }
  }
}
/* =================================
 レンズトップページ
 ================================= */
@import "select/index";
/* =================================
 近近両用レンズページ
 ================================= */
@import "select/dual_lens_01";

/* =================================
 中近両用レンズページ
 ================================= */
@import "select/dual_lens_02";

/* =================================
 ブルー光線カットレンズページ
 ================================= */
@import "select/blue_light";

/* =================================
 マルチドライブページ
 ================================= */
@import "select/multi_drive";

/* =================================
 サングラスページ
 ================================= */
@import "select/sunglasses";

/* =================================
 サポートレンズページ
 ================================= */
@import "select/support_lens";

/* =================================
 UV-W ブロックページ
 ================================= */
@import "select/uv_w_block";

/* =================================
 エターナルスキンコートページ
 ================================= */
@import "select/skincoat";

/* =================================
 ルティーナページ
 ================================= */
@import "select/lutina";
