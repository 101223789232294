@charset "utf-8";
/*------------------------------------*\
    icon
\*------------------------------------*/

// ビュレット
.icn-01 {
  &--01{
    display: inline-block;
    padding-left: 23px;
    background: url(/img/common/icon_circle_01.png) left center no-repeat;
  }
  &--02{
    display: inline-block;
    padding-left: 23px;
    background: url(/img/common/icon_circle_01_bottom.png) left center no-repeat;
  }
  &--03{
    display: inline-block;
    padding-left: 23px;
    background: url(/img/common/icon_circle_01_left.png) left center no-repeat;
  }
}

//
.icn-02 {
  display: inline-block;
  background: url(/img/smp/common/icon_pickup.png) left center no-repeat;
  padding-left: 31px;
}
.icn-03 {
  display: inline-block;
  background: url(/img/smp/common/icon_brand.png) left center no-repeat;
  background-size: contain;
  padding-left: 31px;
}
.icn-04 {
  display: inline-block;
  background: url(/img/smp/common/icon_megane.png) left center no-repeat;
  background-size: 40px 12px;
  padding-left: 50px;
}
.icn-05 {
  display: inline-block;
  background: url(/img/smp/common/icon_arrow_under.png) right center no-repeat;
  background-size: 18px 12px;
}
.icn-06 {
  display: inline-block;
  background: url(/img/smp/common/icon_blank.png) right center no-repeat;
  background-size: 20px 17px;
}
.icn-07 {
  display: inline-block;
  background: url(/img/smp/common/icon_arrow.png) left center no-repeat;
  background-size: 5px 9px;
  padding-left: 10px;
}
.icn-08 {
  display: inline-block;
  background: url(/img/smp/common/icon_store.png) left center no-repeat;
  background-size: 23px;
  padding-left: 30px;
}

// ふきだし
.icn-09 {
  display: inline-block;
  background: url(/img/common/icn_think.png) left center no-repeat;
  padding-left: 47px;
}

// トーク
.icn-10 {
  display: inline-block;
  background: url(/img/hearing_aid/icn_voice.png) left center no-repeat;
  background-size: contain;
  padding-left: 53px;
}

// ブランドリスト タイトル横のアイコン
.icn-11 {
  display: inline-block;
  background: url(/img/common/icon_brand_list.png) left center no-repeat;
  background-size: contain;
  padding-left: 27px;
}

// ブランドリスト タイトル横のアイコン
.icn-star {
  display: inline-block;
  background: url(/img/common/icon_star.png) left center no-repeat;
  padding-left: 40px;
}

// 緑右向き矢印
.icon--is-arrow-02 {
  display: inline-block;
  background: url(/img/smp/common/icon_arrow_02.png) left center no-repeat;
  background-size: 15px 12.5px;
  padding-left: 25px;
}

// お問い合わせ　電話
.icn-contact-phone {
  display: inline-block;
  background: url(/img/common/icon_contact_phone.png) left center no-repeat;
  padding-left: 40px;
}

// お問い合わせ　メール
.icn-contact-mail {
  display: inline-block;
  background: url(/img/common/icon_contact_mail.png) left center no-repeat;
  padding-left: 40px;
}

// コメント
.icn-comment {
  display: inline-block;
  background: url(/img/common/icon_comment_01.png) left center no-repeat;
  padding-left: 55px;
}
