@charset "UTF-8";

.page-404 {
	.header-logo{
		margin: 44px 0 20px 0;
	}
	.header-main{
		overflow: hidden;
		position: relative;
		.txt{
			font-size: 15px;
			color: $c-murasaki;
			position: absolute;
			right: 0;
			bottom: 7px;
			line-height: 1;
			font-family: $font;
		}
	}
	.main{
		background-image: url(/img/common/bg_line_01.png),url(/img/common/bg_line_02.png);
		background-position: top left, bottom left;
		background-repeat: repeat-x,repeat-x;
		padding: 60px 0;
		overflow: hidden;
		.clms{
			.clms-left{
				float: left;
				width: 470px;
				.txt-01{
					font-size: 26px;
					color: $c-murasaki;
					font-family: $font;
					line-height: 1;
					margin-bottom: 25px;
					text-align: center;
				}
				.txt-02{
					font-size: 30px;
					color: $c-murasaki;
					line-height: 1;
					margin-bottom: 18px;
				}
				.txt-03{
					font-size: 14px;
					line-height: 1.7;
					margin-bottom: 23px;
				}
				.link-box{
					overflow: hidden;
					padding: 25px 0;
					border-top: 1px solid #453287;
					border-bottom: 1px solid #453287;
					ul{
						overflow: hidden;
						li{
							margin-bottom: 15px;
							a{
								display: inline-block;
								padding-left: 21px;
								background: url(/img/common/icon_circle_01.png) no-repeat left center;
								font-weight: bold;
								font-size: 14px;
							}
							&.link-02,
							&.link-03,
							&.link-04,
							&.link-05,{
								float: left;
								margin-right: 36px;
							}
							&.link-05{
								margin-right: 0;
							}
							&.link-06{
								margin-bottom: 0;
							}
						}
					}
				}
				.header-search{
					margin: 27px auto 0;
					width: 163px;
					float: none;
				}
			}
			.clms-right{
				float: right;
				width: 470px;
			}
		}
	}
	.footer-copyright{
		color: $c-murasaki;
		font-weight: normal;
		margin-top: 9px;
	}
}
