@charset "utf-8";
.glasses-reading{
  .main-visual-sub {
    .mb20 {
      color: #453288;
      font-family: $font-mincho;
      font-size: fz(12);
    }
  }
  // common
  .balloon-01{
    .title{
      height: 80px;
      padding-top: 26px;
      box-sizing: border-box;
    }
  }

  .sec-01{
    background: url(/img/glasses/reading_glass/bg_sec01.png) center 80px no-repeat;
    height: 388px;
  }
  .sec-02{
    .txt{
      font-size: fz(14);
      line-height: 1.7;
      margin: 10px auto 40px auto;
    }
  }
  .sec-03{
    height: 460px;
    .balloon-01{
      border-top: 1px dotted #ededed;
    }
    .frame-box{
      margin-top: 23px;
      background-image: url(/img/glasses/reading_glass/frame01_top.png),url(/img/glasses/reading_glass/frame01_bottom.png);
      background-position: left top, left bottom;
      background-repeat: no-repeat,no-repeat;
      padding: 15px 0;
      .frame-box-inner{
        background-image: url(/img/glasses/reading_glass/frame01_middle_left.png),url(/img/glasses/reading_glass/frame01_middle_right.png);
        background-position: left top, right top;
        background-repeat: repeat-y,repeat-y;
        padding: 19px 40px;
        background-color: #fff;
      }
    }
    .clms{
      overflow: hidden;
      .clms-left{
        float: left;
        width: 496px;
        padding-left: 25px;
        border-left: 5px solid #453288;
        font-size: fz(14);
        margin-top: 38px;
      }
      .clms-right{
        float: right;
        width: 324px;
        border: 5px solid #453288;
      }
    }
  }
  .sec-04{
    .row{
      text-align: center;
    }
    .col-6{
      padding: 0;
      &:first-child{
        background: url(/img/glasses/reading_glass/line_04.png) right top repeat-y;
      }
    }
    .block{
      width: 890px;
      margin-top: 24px;
      margin-bottom: 110px;
      .title{
        width: 425px;
        margin: 0 auto;
        background: #453288 url(/img/glasses/reading_glass/line_01.png) left center repeat-x;
        span{
          @include vertical-align-middle(45px);
          @include mincho-s(18px);
          color: #fff;
          padding: 0 10px;
          background: #453288;
        }
      }
      .content{
        width: 425px;
        height: 534px;
        margin: 0 auto;
        box-sizing: border-box;
        border-left: 1px solid #453288;
        border-right: 1px solid #453288;
        background: url(/img/common/bg_line_02.png) left bottom repeat-x;
        overflow: hidden;
        .img{
          margin-top: 1px;
          border-top: 1px solid #453288;
          padding-top: 22px;
          img{
            border: 3px solid #453288;
          }
        }
        .txt{
          width: 370px;
          margin: 0 auto;
          text-align: left;
          font-size: fz(14);
          line-height: 1.7;
          margin-top: 20px;
        }
      }
    }
  }
  .sec-05{
    border-top: 4px solid $c-murasaki;
    .sec-05-inner{
      background: url(/img/glasses/reading_glass/bg_sec05.png) left top repeat;
      margin-top: 1px;
    }
    .sec-05-title {
      padding: 22px 0;
      top: -40px;
    }
    .balloon-03{
      &:before{
        content: "";
        position: absolute;
        bottom: -17px;
        left: 50%;
        margin-left: -14px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 17px 14px 0;
        border-color: #c6dfbf transparent transparent transparent;
        z-index: 20;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -23px;
        left: 50%;
        margin-left: -20px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 23px 20px 0;
        border-color: $c-murasaki transparent transparent transparent;
        z-index: 10;
      }
    }
    .frame-box{
      background-image: url(/img/glasses/reading_glass/frame02_top.png),url(/img/glasses/reading_glass/frame02_bottom.png);
      background-position: left top, left bottom;
      background-repeat: no-repeat,no-repeat;
      padding: 16px 0;
      .frame-box-inner{
        background-image: url(/img/glasses/reading_glass/frame02_middle_left.png),url(/img/glasses/reading_glass/frame02_middle_right.png);
        background-position: left top, right top;
        background-repeat: repeat-y,repeat-y;
        background-color: #fff;
      }
      .sec-05-list-area{
        width: 980px;
        .sec-05-list{
          text-align: center;
          @include mincho-murasaki-s(24);
          @include vertical-align-middle(70px);
          border-right: 1px solid $c-murasaki;
          &:first-child {
            padding: 0 0 0 20px;
          }
          &:last-child{
            border-right: none;
            padding: 0;
          }
        }
      }
    }
    .kakko{
      text-align: center;
      margin-top: 28px;
      margin-bottom: 47px;
      .sec-05-title-kakko{
        display: inline-block;
        @include mincho-s(36px);
        color: #fff;
        &:before{
         position: relative;
         top: -12px;
         display: inline-block;
         content: url(/img/glasses/reading_glass/bg_kakko_left.png);
         padding-right: 0;
         margin-right: 20px;
        }
        &:after{
         position: relative;
         top: -12px;
         display: inline-block;
         content: url(/img/glasses/reading_glass/bg_kakko_right.png);
         padding-left: 0;
         margin-left: 20px;
        }
      }
    }
    .sec-05-box-att {
      font-size: 139%;
      line-height: 1.9;
      color: #fff;
      width: 898px;
      font-weight: bold;
      margin: 40px auto;
      font-family: $font-mincho;
    }
    .clms{
      width: 898px;
      margin: 0 auto 39px;
      overflow: hidden;
    }
    .clms-left{
      float: left;
      width: 280px;
      box-sizing: border-box;
      border: 1px solid #fff;
      background: $c-murasaki;
      padding: 4px;
    }
    .clms-right{
      float: right;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 15px;
      font-size: fz(14);
      line-height: 1.9;
      color: #fff;
      width: 578px;
      font-weight: bold;
      background: $c-murasaki;
    }
    .sec-05-box{
      background: #fff;
      padding: 2px;
      width: 898px;
      margin: 0 auto 36px;
      .sec-05-box-inner{
        border: 1px solid $c-murasaki;
        padding: 2px;
        text-align: center;
      }
      .sec-05-box-title{
        @include vertical-align-middle(47px);
        @include mincho-murasaki-s(30px);
        background: #c6dfbf;
      }
      .sec-05-box-txt{
        color: $c-murasaki;
        font-family: $font-mincho;
        display: inline-block;
        font-size: 29px;
        margin-bottom: 10px;
        .txt-01{
          font-size: 36px;
          background: url(/img/glasses/reading_glass/line_02.png) left bottom repeat-x;
          padding-bottom: 6px;
        }
        .txt-02{
          font-size: 60px;
        }
      }
    }
    .sec-05-bnr{
      width: 898px;
      margin: 0 auto 53px;
      box-sizing: border-box;
      border: 2px solid #fff;
      background: url(/img/glasses/reading_glass/bg_img_01.png) 7px 6px no-repeat;
      text-align: center;
      .detail-btn{
        a{
          width: 410px;
          @include vertical-align-middle(60px);
          @include mincho-murasaki-s(27px);
          display: inline-block;
          margin: 6px 0;
        }
        span{
          background-position: right center;
          padding-left: 16px;
          padding-right: 23px;
        }
      }
    }
    .sec-05-point{
      .row{
        width: 898px;
        margin: 0 auto;
        overflow: visible;
      }
      .col-6{
        padding: 0;
        &:first-child{
          background: url(/img/glasses/reading_glass/line_05.png) right top repeat-y;
        }
      }
      .sec-05-point-list{
        background: #fff;
        padding: 3px;
        width: 430px;
        float: left;
        &.sec-05-point-list-02{
          float: right;
        }
      }
      .title{
        background: $c-murasaki;
        @include vertical-align-middle(40px);
        @include mincho-s(27px);
        color: #fff;
        padding-left: 76px;
        position: relative;
        span{
          position: absolute;
          width: 88px;
          display: inline-block;
          @include vertical-align-middle(88px);
          background: url(/img/glasses/reading_glass/bg_num.png) left top no-repeat;
          top: -29px;
          margin-left: 9px;
          text-align: center;
          color: $c-murasaki;
          font-size: 40px;
        }
      }
      .txt{
        height: 109px;
        box-sizing: border-box;
        padding: 15px;
        font-size: fz(14);
        line-height: 1.7;
      }
    }
    .shape{
      clear: both;
      padding-top: 50px;
      display: table;
      text-align: center;
      white-space: nowrap;
      &:after,
      &:before {
          content: '';
          display: table-cell;
          width: 50%;
          background: #fff;
          background-clip: padding;
      }
      &:after {
          border-left: 1em solid transparent;
      }
      &:before {
          border-right: 1em solid transparent;
      }
    }
  }
  .sec-06{
    text-align: center;
    .txt{
      @include mincho-murasaki-s(31px);
      margin-top: 49px;
    }
    .txt-02{
      @include mincho-murasaki-s(43px);
      margin-top: 20px;
      margin-bottom: 70px;
      display: inline-block;
      background: url(/img/glasses/reading_glass/line_03.png) left bottom repeat-x;
      span{
        background: url(/img/glasses/reading_glass/dot.png) 15px top no-repeat;
        padding-top: 15px;
      }
    }
  }
}
