@charset "UTF-8";
.f-14{font-size: 14px!important;}
.f-15{font-size: 15px!important;}
.f-16{font-size: 16px!important;}
.f-20{font-size: 20px!important;}
.mb12{margin-bottom: 12px!important;}
.mb15{margin-bottom: 15px!important;}
.mb16{margin-bottom: 16px!important;}
.mb17{margin-bottom: 17px!important;}
.mb20{margin-bottom: 20px!important;}
.mb25{margin-bottom: 25px!important;}
.mb35{margin-bottom: 35px!important;}
.mb40{margin-bottom: 40px!important;}
.mb48{margin-bottom: 48px!important;}
.mb55{margin-bottom: 55px!important;}
.mb58{margin-bottom: 58px!important;}
.mb60{margin-bottom: 60px!important;}
.mb65{margin-bottom: 65px!important;}
.mb90{margin-bottom: 90px!important;}
.lh1{line-height: 1!important;}

.clm-2{width: $inner-width / 2;}
.inner-950{
	width: 950px;
	margin: 0 auto;
	.clm-2{width: 950px / 2;}
}

.title-02{
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	font-family: $font-mincho;
	&.title-02-purple{
		color: $c-murasaki;
		background: url(/img/common/bg_title_02.png) left bottom no-repeat;
		padding-bottom: 17px;
	}
}

.main-recruit {
	.inner {
		.title-04 {
			margin-top: 40px;
		}
	}
	.main-header {
		.inner {
			position: relative;
		}
		.page-nav {
			position: absolute;
			top: 0;
			right: 0;
			li {
				margin-left: 40px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.breadcrumbs.recruit_bread {
		margin-bottom: 60px;
	}
}

/* 採用情報ナビゲーション
------------------------------------------*/
.main-header .page-nav li.recruit-nav-02{
	margin-left: 41px;
}
.main-header .page-nav li.recruit-nav-03{
	margin-left: 67px;
}
.main-header .page-nav li.recruit-nav-04{
	margin-left: 59px;
}
.main-header .page-nav li.recruit-nav-05{
	margin-left: 58px;
}
.main-header .page-nav li.recruit-nav-06{
	margin-left: 57px;
}
.main-header .page-nav li.recruit-nav-07{
	margin-left: 53px;
}


/* 採用情報ボタン
------------------------------------------*/
.main-recruit {
	.list-inline-01 {
		overflow: hidden;
		text-align: center;
		.list-recruit-btn{
			float: left;
			margin-right: 39px;
			width: 300px;
			&:last-child {
				margin-right: 0;
			}
			a{
				display: block;
				background: url(/img/recruit/btn_recruit.png) left top no-repeat;
				font-family: $font-mincho;
				color: #fff;
				width: 300px;
				height: 100px;
				&:hover{
					text-decoration: none;
				}
				span{
					display: block;
					padding-right: 44px;
					font-size: 23px;
					line-height: 100px;
					background-image: url(/img/recruit/icn_arrow_recruit_btn.png);
					background-repeat: no-repeat;
				}
			}
			&.list-recruit-btn-01 a span{
				background-position: 203px 37px;
			}
			&.list-recruit-btn-02 a span{
				background-position: 215px 37px;
			}
			&.list-recruit-btn-03 a span{
				line-height: 1.15;
				background-position: 204px 37px;
				padding-top: 23px;
			}
		}
	}
}

/* 募集要項
------------------------------------------*/
.bg-pattern-05-inner-recruit{
	padding: 25px 20px;
	.recruit-contact{
		text-align: center;
		font-size: 14px;
		line-height: 2.21;
		&.recruit-contact-title{
			font-family: $font-mincho;
			color: $c-murasaki;
			font-size: 20px;
			background: url(/img/recruit/dot_recruit.gif) bottom left repeat-x;
			line-height: 1;
			padding-bottom: 18px;
			margin-bottom: 13px;
		}
		.recruit-contact-info-01{
			margin-right: 15px;
		}
		.recruit-contact-info-02{
			margin-right: 66px;
		}
		.recruit-contact-tel{
			font-size: 28px;
			font-weight: bold;
			background: url(/img/recruit/icn_free_dial.png) top left no-repeat;
			padding-left: 54px;
			line-height: 1.5;
		}
		.recruit-contact-tel-sub{
			line-height: 1.5;
		  vertical-align: top;
		  display: inline-block;
		  margin-top: 13px;
		}
	}
}

/* 和真のめざすもの
------------------------------------------*/
.recruit-target{
	.target-title{
		background: #fff;
		border-top: 2px solid #dfdfdf;
		border-bottom: 1px solid #e1e1e1;
		position: relative;
		margin-bottom: 30px;
		.inner{
			text-align: center;
			font-size: 36px;
			color: $c-murasaki;
			font-family: $font-mincho;
			border-top: 2px solid $c-murasaki;
			margin-top: -2px;
			display: block;
			line-height: 1;
			padding: 20px 0 24px 0;
		}
		.recruit_arrow_bottom{
			position: absolute;
			left: 50%;
			bottom: -13px;
			z-index: 100;
		}
	}
	.target-desc{
		line-height: 1.92;
		margin-bottom: 57px;
		font-size: 14px;
	}
	.target-nav{
		width: 980px;
		margin: 0 auto;
		overflow: hidden;
		margin-bottom: 150px;
	}
	.target-nav-list{
		float: left;
		width: 220px;
		margin-right: 30px;
		&.last{
			margin-right: 0;
		}
	}
	.target-nav-list-title{
		color: $c-murasaki;
		font-family: $font-mincho;
		font-size: 20px;
		line-height: 1;
		margin-bottom: 13px;
	}
	.target-nav-list-thum{
		width: 220px;
		border: 1px solid #dcdcdc;
		margin-bottom: 12px;
		img{
			width: 100%;
			height: auto;
		}
	}
	.target-nav-list-detail-btn{
		border: 1px solid #c1c1c1;
		background: #f1f1f1;
		a{
			width: 197px;
			height: 45px;
			line-height: 45px;
			padding-left: 23px;
			background: url(/img/common/icon_circle_01.png) 46px 14px no-repeat;
			display: block;
			color: #000;
			font-family: $font-mincho;
			font-weight: bold;
			font-size: 15px;
			&:hover{
				text-decoration: none;
			}
		}
	}
}


/* ==================================
 和真のめざすもの
================================== */
.recruit_bread{
	border-bottom: 1px solid #e0e0e0;
}
.main-contents-recruit-tab{
	background: #f7f7f7;
	border-top: 1px solid #eee;
	padding-top: 78px;
}
.recruit-tab-area{
	position: relative;
	background: #fff;
	border-top: 1px solid #eee;
	margin-bottom: 40px;
	//タブ部分
	.recruit-tab-area-inner{
		position: absolute;
		z-index: 100;
		top: -60px;
		width: 100%;
	}
	.recruit-tab{
		width: 980px;
		margin: 0 auto;
		.recruit-tab-list{
			margin-right: 19px;
			float: left;
			width: 213px;
			text-align: center;
			border-top: 1px solid #eee;
			border-left: 1px solid #eee;
			border-right: 1px solid #eee;
			margin-top: 16px;
			background: #fff;
			&:hover{
				margin-top: 0;
			}
			a{
				background: #c8bdcd;
				color: #fff;
				font-size: 18px;
				width: 100%;
				height: 43px;
				line-height: 43px;
				display: block;
				font-family: $font-mincho;
				&:hover{
					text-decoration: none;
					height: 59px;
					line-height: 59px;
					background: #fff;
					color: $c-murasaki;
					span{
						width: 180px;
						border-top: 2px solid $c-murasaki;
						display: block;
						margin: 0 auto;
					}
				}
			}
			&.current{
				margin-top: 0;
				background: #fff;
				a{
					background: #fff;
					color: $c-murasaki;
					height: 59px;
					line-height: 59px;
					font-family: $font-mincho;
					&:hover{
						text-decoration: none;
					}
					span{
						width: 180px;
						border-top: 2px solid $c-murasaki;
						display: block;
						margin: 0 auto;
					}
				}
			}
			&.last{
				margin-right: 0;
			}
		}
	}
	.recruit-inner{
		width: 980px;
		margin: 0 auto;
		padding-top: 57px;
		&.inner-01{
			background: url(/img/recruit/calligraphy_shin.png) 667px 120px no-repeat;
		}
		&.inner-02{
			background: url(/img/recruit/bg_recruit_02.jpg) right top no-repeat;
		}
		&.inner-03{
			background: url(/img/recruit/bg_recruit_03.jpg) right top no-repeat;
		}
		&.inner-04{
			background: url(/img/recruit/bg_recruit_04.jpg) right top no-repeat;
		}
	}
}

.main-contents-title{
	font-family: $font-mincho;
	color: $c-murasaki;
	font-size: 32px;
	line-height: 1;
	margin-bottom: 60px;
}
.main-contents-title-sub{
	margin-left: 22px;
	font-size: 20px;
	color: #a399c7;
	display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}
.mission-list{
	font-family: $font-mincho;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 50px;
	li {
		margin-bottom: 5px;
	}
}
.green-txt{
	font-size: 20px;
	color: #01c1b8;
	font-weight: bold;
	line-height: 1;
}

.txt-desc-01{
	line-height: 1.85;
	color: #666;
	font-size: 14px;
}

/* ==================================
 採用情報共通
================================== */
.next-prev-box{
	overflow: hidden;
	margin: 114px 0 0 0;
	.prev{
		float: left;
		border: 1px solid #e1e1e1;
		a{
			padding: 0 26px 0 33px;
			height: 35px;
			line-height: 35px;
			background: #fafafa;
			font-family: $font-mincho;
			display: block;
			font-size: 14px;
			color: #000;
			&:before{
				content: url(/img/recruit/icon_circle_01_re.png);
				margin-right: 13px;
				position: relative;
				top: 4px;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
	.next{
		float: right;
		border: 1px solid #e1e1e1;
		a{
			padding: 0 33px 0 26px;
			height: 35px;
			line-height: 35px;
			background: #fafafa;
			font-family: $font-mincho;
			display: block;
			font-size: 14px;
			color: #000;
			&:after{
				content: url(/img/common/icon_circle_01.png);
				margin-left: 13px;
				position: relative;
				top: 4px;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
}
/* 左側の縦棒
------------------------------------------*/
.detail-inner{
	border-left: 2px solid #aaa;
	padding-left: 62px;
	margin-bottom: 80px;
}


/* ==================================
 和真の戦略
================================== */

/* 国内最大級　和真新宿メガネタウン
------------------------------------------*/
.title-17{
	font-size: 17px;
	font-weight: bold;
	line-height: 1;
	&.purple-02{
		color: #593687;
	}
}

.box-type-01{
	border: 1px solid #c7c1ca;
	padding-top: 19px;
	.part{
		width: 189px;
		height: 34px;
		background: #4e3c94;
		position: relative;
		margin-left: -16px;
		float: left;
		margin-right: 20px;
		p{
			font-size: 16px;
			font-weight: bold;
			line-height: 34px;
			color: #fff;
			text-align: center;
		}
		&:after{
			content: url(/img/recruit/icn_trivia_arrow.png);
			position: absolute;
			top: 9px;
			right: -11px;
		}
	}
	.title{
		float: left;
		color: #4e3c94;
		font-size: 18px;
		font-family: $font-mincho;
		font-weight: bold;
		line-height: 34px;
	}
	.desc{
		clear: both;
		padding: 28px 20px 30px 20px;
		font-size: 14px;
		color: #303030;
		line-height: 1.7;
	}
	.detail{
		font-weight: bold;
		font-size: 14px;
		color: $c-murasaki;
		text-align: right;
		display: block;
		margin-top: 12px;
	}
}

/* 和真戦略
------------------------------------------*/
.strategy-list-title-area{
	position: relative;
	height: 109px;
	margin-bottom: 31px;
	.strategy-list-title{
		font-size: 26px;
		font-weight: bold;
		color: $c-murasaki;
		font-family: $font-mincho;
		line-height: 1;
		margin-bottom: 10px;
		padding: 18px 0 12px 129px;
		border-bottom: 1px solid #959595;
	}
	.strategy-list-sub-title{
		padding: 0 0 0 151px;
		font-size: 20px;
		font-weight: bold;
		line-height: 1;
		color: #333;
	}
	.strategy-list-part{
		position: absolute;
		left: 0;
		top: 0;
		background: url(/img/recruit/bg_strategy-list-part.png) left top no-repeat;
		width: 109px;
		padding-right: 30px;
		height: 109px;
		text-align: center;
		.strategy-list-part-txt{
			color: #fff;
			font-family: $font-mincho;
			font-size: 16px;
			line-height: 1;
			margin-top: 31px;
			.strategy-list-part-num{
				font-size: 38px;
				display: block;
				margin-top: 10px;
			}
		}
	}
}

/* トリビア
------------------------------------------*/
.trivia-01-left{
	width: 440px;
	float: left;
	margin-right: 23px;
}
.trivia-01-right{
	width: 515px;
	float: left;
	.history-store-list{
		width: 162px;
		float: left;
		margin-right: 10px;
		&.last{
			margin-right: 0;
		}
	}
	.store-thum{
		margin-bottom: 3px;
	}
	.store-desc{
		line-height: 1.8;
		font-size: 12px;
	}
}

/* お店紹介リンク
------------------------------------------*/
.store-intro{
	margin-bottom: 50px;
	.store-intro-list{
		font-size: 17px;
		color: #593687;
		line-height: 2.18;
		padding-left: 25px;
		background: url(/img/common/icon_circle_01.png) left 10px no-repeat;
		font-weight: bold;
		a{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
			&:after{
				content: url(/img/common/icon_external_link_01.png);
			}
		}
	}
}


/* オリジナルポイント
------------------------------------------*/
.frame-01{
	background: url(/img/recruit/frame_01_top.png) left top no-repeat;
	padding-top: 12px;
	margin: 0 auto;
	width: 943px;
	.frame-01-bottom{
		background: url(/img/recruit/frame_01_bottom.png) left bottom no-repeat;
		padding-bottom: 12px;
		.frame-01-inner{
			background: url(/img/recruit/frame_01_middle.png) left top repeat-y;
		}
	}
}

.frame-02{
	background: url(/img/recruit/frame_02_top.png) left top no-repeat;
	padding-top: 12px;
	width: 406px;
	.frame-02-bottom{
		background: url(/img/recruit/frame_02_bottom.png) left bottom no-repeat;
		padding-bottom: 12px;
		.frame-02-inner{
			background: url(/img/recruit/frame_02_middle.png) left top repeat-y;
		}
	}
}
.original-point{
	text-align: center;
	line-height: 1;
	padding: 25px 0;
	.original-point-list{
		display: inline-block;
		font-family: $font-mincho;
		color: $c-murasaki;
		margin-right: 85px;
		font-size: 26px;
		.point-num{
			font-size: 40px;
		}
		.point-txt{
			display: inline-block;
			vertical-align: top;
		  padding: 7px 0 0 5px;
		}
		&.last{
			margin-right: 0;
		}
	}
}

/* ==================================
 和真での仕事
================================== */
.collabo-intro{
	width: 538px;
}
.collabo{
	padding: 4px 0;
	.collabo-list{
		font-family: $font-mincho;
		color: $c-murasaki;
		line-height: 1.7;
		text-align: center;
		.collabo-num{
			font-size: 30px;
		}
		.collabo-txt{
			font-size: 23px;
			display: inline-block;
			vertical-align: top;
		  padding: 6px 0 0 5px;
		}
		&.last{
			font-size: 18px;
			color: #666;
		}
	}
}

/* ==================================
 和真の社員サポート
================================== */
.recruit-support-area-btn{
	margin-bottom: 36px;
	.support-btn-list{
		float: left;
		width: 209px;
		margin-right: 29px;
		a{
			width: 184px;
			padding-left: 25px;
			height: 41px;
			line-height: 41px;
			display: block;
			span{
				font-size: 18px;
				font-weight: bold;
			}
			&:hover{
				text-decoration: none;
			}
		}
		&.support-btn-list-01{
			border: 1px solid #c7c1dc;
			background: rgba(78,60,148,0.1);
			filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#114e3c94,EndColorStr=#114e3c94);/*IE8以下用*/
			a{
				background: url(/img/recruit/icn_support_btn_arrow_01.png) 181px 19px no-repeat;
				span{
					color: #4e3c94;
				}
				&:before{
					content: url(/img/recruit/icn_support_btn_01.png);
					position: relative;
					top: 1px;
					margin-right: 10px;
				}
			}
		}
		&.support-btn-list-02{
			border: 1px solid #ffc9c9;
			background: rgba(255,110,110,0.1);
			filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#11ff6e6e,EndColorStr=#11ff6e6e);/*IE8以下用*/
			a{
				width: 189px;
				padding-left: 20px;
				background: url(/img/recruit/icn_support_btn_arrow_02.png) 181px 19px no-repeat;
				span{
					color: #ff6e6e;
				}
				&:before{
					content: url(/img/recruit/icn_support_btn_02.png);
					position: relative;
					top: 4px;
					margin-right: 10px;
				}
			}
		}
		&.support-btn-list-03{
			border: 1px solid #f1eec4;
			background: rgba(217,207,95,0.1);
			filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#11d9cf5f,EndColorStr=#11d9cf5f);/*IE8以下用*/
			a{

				background: url(/img/recruit/icn_support_btn_arrow_03.png) 181px 19px no-repeat;
				span{
					color: #ccc141;
				}
				&:before{
					content: url(/img/recruit/icn_support_btn_03.png);
					position: relative;
					top: 7px;
					margin-right: 10px;
				}
			}
		}
		&.last{
			margin-right: 0;
		}
	}
}

.support-inner{
	width: 850px;
	float: right;
}
.support-list{
	margin-bottom: 70px;
}
.support-list-title-area{
	position: relative;
	height: 90px;
	margin-bottom: 35px;
	.support-list-title{
		margin-left: 18px;
		padding: 28px 0 16px 85px;
		width: 747px;
		line-height: 1;
		font-size: 20px;
		font-family: $font-mincho;
		font-weight: bold;
		&.support-list-title-01{
			color: #593687;
			background: url(/img/recruit/line_purple.gif) left bottom no-repeat;
		}
		&.support-list-title-02{
			color: #ff6e6e;
			background: url(/img/recruit/line_pink.gif) left bottom no-repeat;
		}
		&.support-list-title-03{
			color: #d9cf5f;
			background: url(/img/recruit/line_yellow.gif) left bottom no-repeat;
		}
	}
	.support-list-part{
		position: absolute;
		width: 90px;
		top: 0;
		left: 0;
	}
}

.support-sub{
	border-left: 1px solid #707070;
	padding-left: 14px;
	.support-sub-list{
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 10px;
		&.last{
			margin-bottom: 0;
		}
	}
}

.training-course{
	width: 556px;
	margin: 0 auto 77px auto;
	.training-course-list{
		width: 250px;
		height: 150px;
		float: left;
		margin-right: 56px;
		&.training-course-01{
			background: url(/img/recruit/training_manage.png) left top no-repeat;
		}
		&.training-course-02{
			background: url(/img/recruit/training_professional.png) left top no-repeat;
		}
		&.last{
			margin-right: 0;
		}
		.genre{
			display: block;
			margin-top: 80px;
			width: 227px;
			line-height: 1;
			margin: 80px auto 7px auto;
			background: #a69dc9;
			text-align: center;
			color: #fff;
			font-size: 18px;
			font-weight: bold;
			padding: 5px 0;
		}
		.course{
			display: block;
			text-align: center;
			color: #8376b4;
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.policy-txt{
	font-size: 24px;
	font-family: $font-mincho;
	font-weight: bold;
	color: #333;
	line-height: 1;
	margin-bottom: 25px;
}

.table-recruit {
	width: 700px; border: 1px solid #555;
	border-collapse: collapse;
	th, td {
		border: 1px solid #555;
		background: #fff;
		vertical-align:middle;
		padding: 20px 10px;
	}
	th {
		width: 100px;
	}
}

