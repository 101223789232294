@charset "utf-8";
.glasses-type-single {
  .page-nav-outer {
    border: 2px solid $c-murasaki;
    padding: 3px;
    margin: 50px 0;
  }
  .type-page-nav {
    border: 1px solid $c-murasaki;
    padding: 7px 0;
    overflow: hidden;
  }
  .page-nav-item {
    float: left;
    width: 313px;
    padding-right: 6px;
    margin-left: 5px;
    border-right: 1px dotted $c-murasaki;
    box-sizing: border-box;
    &:last-child {
      padding-right: 0;
      border-right: none;
    }
    span {
      display: table-cell;
      height: 91px;
      vertical-align: middle;
      font-size: fz(24);
      font-weight: bold;
      font-family: $font-mincho;
      color: $c-murasaki;
      padding-left: 32px;
      background: url(/img/common/icon_circle_01.png) left center no-repeat;
    }
  }
  .page-nav-item.is-active {
    background: $c-murasaki;
    span {
      color: #fff;
    }
  }
  .page-nav-item__icon-01,.page-nav-item__icon-02,.page-nav-item__icon-03 {
    display: table;
    width: 100%;
    height: 100%;
    margin-left: 5px;
  }
  .page-nav-item__icon-01 {
    background: url(/img/glasses/type/single/btn_link_01.png) right center no-repeat;;
  }
  .page-nav-item__icon-02 {
    background: url(/img/glasses/type/single/btn_link_02.png) right center no-repeat;;
  }
  .page-nav-item__icon-03 {
    background: url(/img/glasses/type/single/btn_link_03.png) right center no-repeat;;
  }
  //contents
  .bg-pattern-01 {
    padding-bottom: 50px;
  }
  .contents-lead {
    text-align: center;
    font-weight: bold;
    padding: 40px 0 0;
    font-size: fz(14);
  }
  .btn-list-outer {
    width: 878px;
    margin: 40px auto;
    .btn-lists{
      height: 47px;
    }
    .btn {
      padding: 11px 0;
      text-align: center;
      @include mincho-s(15px);
      color: #333;
      span{
        display: inline-block;
        background: url(/img/common/icon_circle_01.png) no-repeat left center;
        padding-left: 21px;
      }
    }
    .btn-06 {
      width: 210px;
      box-sizing: border-box;
      background: #fafafa;
      border-radius: 0;
    }
    .last{
      padding: 6px 0 1px;
      line-height: 1.4;
      font-size: 14px;
      text-align: left;
      padding-left: 5px;
    }
  }
  .btn-list-outer--single{
    width: 835px;
    .btn--single{
      width: 256px;
    }
  }
  .btn-list-outer--special{
    width: 835px;
    .btn--special{
      width: 256px;
    }
  }
  .contents-list__item-inner {
    width: 900px;
    margin: 40px auto;
    .txt{
      font-size: fz(14);
    }
  }
  .contents-list__item {
    background: #fff;
    margin-bottom: 50px;
    padding-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .contents-list__item-textarea {
    background: #f9f9f7;
    padding: 18px 25px;
  }
  .contents-list__item-title {
    margin-bottom: 15px;
  }

  .dummy {
    text-align: center;
    margin: 50px 0;
  }
}
