/* CCP カスタム
------------------------------------------*/
.ccp.special-clms{
  .special-clms-left{
    width: 360px;
    margin-left: 1px;
  }
  .special-clms-right{
    width: 450px;
    .item-name-01{
      margin-top: 10px;
    }
    .special-clms-txt.type-list{
      padding-left: 1em;
      text-indent: -1em;
    }
    .price.custom{
      padding-right: 1em;
    }
  }
}

.special-inner-sec-02{
  .left{
    width: 354px;
  }
  .right{
    width: 469px;
    margin-right: 2px;
    .special-img{
      width: 220px;
      float: left;
      &.special-img-01{
        margin-right: 29px;
      }
    }
  }
}

.special-inner-sec-03{
  .left{
    width: 637px;
  }
  .right{
    width: 182px;
    margin-right: 17px;
    .special-img{
      &.special-img-03{
        margin-top: 11px;
      }
    }
  }
}

.special-inner-sec-04{
  .left{
    width: 355px;
    .special-lists{
      width: 313px;
    }
  }
  .right{
    width: 465px;
    margin-right: 3px;
  }
}