@charset "UTF-8";
/* =================================
	店舗物件募集
================================= */
.recruitment {
	.heading-title{
		background: url(/img/privacy/purple_belt.png) left top no-repeat;
		font-size: 18px;
		color: #fff;
		width: 980px;
		box-sizing: border-box;
		padding-left: 10px;
		@include vertical-align-middle(50px);
		margin-bottom: 15px;
	}
	.box{
		overflow: hidden;
		@include box(1px,#dfdfdf);
		padding: 20px;
		.title{
			font-weight: bold;
			margin-bottom: 7px;
			font-size: 14px;
		}
		.txt{
			font-size: 14px;
		}
	}
	.clms{
		overflow: hidden;
		width: 980px;
		margin-bottom: 50px;
		.clms-left{
			float: left;
			width: 230px;
		}
		.clms-right{
			float: right;
			width: 710px;
			span{
				font-size: 12px;
				display: block;
			}
		}
	}
	.desc{
		font-size: 14px;
		line-height: 1.7;
	}
	.terms-list-01{
		li{
			text-indent: -1em;
			padding-left: 1em;
			font-size: 14px;
		}
	}
	.terms-list-02{
		dt{
			font-size: 16px;
			font-weight: bold;
		}
		dd{
			font-size: 14px;
		}
	}
}