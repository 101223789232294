.contactlens-care {
	.sp-show {
		display: none;
	}
	.inner {
		width: 880px;
	}
	.type-main-title {
		width: 100%;
		margin-top: 40px;
	}
	.box-corner {
		text-align: center;
		h3 {
			@include mincho-s(18);
			margin-bottom: 10px;
		}
		span {
			@include mincho-murasaki-s(18);
			border-bottom: 3px solid #01c1b8;
		}
		p {
			text-align: left;
			margin-bottom: 40px;
		}
	}
	.title-01 {
		@include mincho-s(18);
		margin: 60px 0 30px;
	}
	.title-02 {
		margin-top: 45px;
		text-align: center;
		@include mincho-murasaki-s(22);
		span {
			padding-left: 39px;
			background: url(/img/contact_lens/care/icon_contact-lens-care_01.png) no-repeat left top;
			border-bottom: 2px solid #453288;
		}
	}
	.title-06 {
		background: #453288 url(/img/common/bg_title_05.png) no-repeat right top;
		padding: 16px;
		font-family: Roboto Condensed,A1 Mincho,"ヒラギノ明朝 ProN W6",HiraMinProN-W6,"HG明朝E","ＭＳ Ｐ明朝",MS PMincho,"MS 明朝",serif;
		font-weight: 700;
		font-size: 154%;
		color: #fff;
	}
	.flow-box .flow-right-box.flow-right-box-02 {
		width: 405px;
	}
	.item {
		margin-bottom: 30px;
		&-title {
			font-size: 18px;
			margin-bottom: 8px;
			line-height: 18px;
			span {
				font-size: 15px;
			}
		}
		&-text {
			min-height: 9em;
		}
	}
	.attention {
		margin-top: 30px;
		border: 1px solid #f00;
		position: relative;
		padding: 23px;
		dt {
			position: absolute;
			top: -0.7em;
			left: 23px;
			background: #fff;
			color: #f00;
			padding: 0 5px;
		}
		dd {
			text-indent: -1em;
			margin-left: 1em;
         }
	}
}