@charset "utf-8";
/**
 *こどものメガネの選び方
 */
.glasses-children {
	.main-visual {
		height: 213px;
		color: $c-murasaki;
		font-family: $font-mincho;
		background: #fff265;
		text-align: left;
		h1 {
			font-size: fz(18);
			margin-top: 10px;
		}
		p {
			font-size: fz(20);
			padding-top: 20px;
		}
	}
	.list-children-contents {
		background: #fff;
		box-sizing: border-box;
		.list-children-contents-item {
			overflow: hidden;
			padding: 20px 45px;
			&:nth-child(2n) {
				.img-area {
					float: right;
				}
				.text-area {
					float: left;
				}
			}
			&:nth-child(2n+1) {
				.img-area {
					float: left;
				}
				.text-area {
					float: right;
				}
			}
			&.bg-pattern-01 {
				text-align: center;
				div {
					width: 515px;
					float: none;
					margin: 0 auto;
				}
				.btn {
					margin: 0;
				}
			}

			.text-area {
				width: 55%;
				img {
					float: none;
				}
			}
			.img-area {
				width: 45%;
				text-align: center;
			}
			h3 {
				color: $c-murasaki;
				font-size: fz(20);
				font-family: $font-mincho;
				background: url(/img/glasses/children/icon_children_01.png) no-repeat left 5px,
				url(/img/glasses/children/bg_children_01.png) no-repeat left bottom;
				padding: 0 0 11px 40px;
				margin-top: 22px;
				margin-bottom: 15px;
			}
			p {
				font-size: fz(15);
				line-height: 24px;
				margin-bottom: 10px;
			}
			/* ul {
				background: transparent;
				li {
					padding: 0;
				}
			} */
			.btn {
				margin-bottom: 10px;
			}
		}
	}
	.list-children-contents li {
		p.frame {
			@include mincho-murasaki-s(22);
			height: 63px;
			line-height: 63px;
			background: url(/img/glasses/children/lens/bg_frame.png) left top no-repeat;
			background-size: 100% 100%;
			text-align: center;
			box-sizing: border-box;
			.txt-01{
				font-size: 14px;
				padding: 0px 7px;
			}
			.txt-02{
				font-family: $font-mincho;
				font-size: 36px;
			}
		}

		.recommended {
			overflow: hidden;
			color: $c-murasaki;
			font-family: $font-mincho;
			dt {
				font-size: 14px;
				margin-top: 8px;
				float: left;
			}
			dd {
				margin-left: 238px;
			}
			.txt-01{
				padding: 0px 7px;
				font-size: 14px;
			}
			.txt-02{
				font-family: $font;
				font-size: 20px;
				font-weight: bold;
			}
		}

	}

	.jaggy-box{
		.jaggy-top{
			background: url(/img/glasses/children/bg_jaggy_top.png) left top repeat-x;
			padding-top: 1px;
		}
		.jaggy-left{
			background: url(/img/glasses/children/bg_jaggy_side.png) left top repeat-y;
			padding-left: 1px;
		}
		.jaggy-right{
			background: url(/img/glasses/children/bg_jaggy_side.png) right top repeat-y;
			padding-right: 1px;
		}
		.jaggy-bottom{
			background: url(/img/glasses/children/bg_jaggy_top.png) left bottom repeat-x;
			padding-bottom: 1px;
		}
	}

}


/**
 * こどものメガネの選び方 | トップページ
 */
@import "children/top";



/**
 * こどものメガネの選び方 | レンズ編
 */
.glasses-children-lens {
	.main-visual {
		background: #fff265 url(/img/glasses/children/lens/bg_main_01.png) no-repeat center top;
		p {
			padding-top: 53px;
		}
		h1 {
			font-size: fz(40);
		}
	}
	.list-children-contents {
		background: transparent;
		.list-children-contents-item {
			background: #fff;
			margin-bottom: 18px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
/**
 * こどものメガネの選び方 | フレーム編
 */
.glasses-children-frame {
	.main-visual {
		background: #fff265 url(/img/glasses/children/frame/bg_main_01.png) no-repeat center top;
		p {
			padding-top: 53px;
		}
		h1 {
			font-size: fz(40);
		}
	}
	.list-children-contents {
		background: transparent;
		.list-children-contents-item {
			background: #fff;
			margin-bottom: 18px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
/**
 * こどものメガネの選び方 | 和真の考える理想のこどもメガネとは？
 */
.glasses-children-ideal {
	.main-visual {
		background: #fff265 url(/img/glasses/children/ideal/bg_main_01.png) no-repeat center top;
		h1 {
			line-height: 213px;
			font-size: fz(26);
		}
	}
	.lead {
		text-align: center;
		font-size: fz(26);
		line-height: 46px;
		margin-bottom: 25px;
		color: $c-murasaki;
		font-family: $font-mincho;
	}
	.list-children-contents{
		li {
			&:nth-child(2) {
				//overflow: visible;
				img {
					width: 100%;
					//margin-top: -74px;
				}
			}
			&:nth-child(2n+1) {
				background: #fcfcfc;
			}
			ul {
				li {
					font-size: fz(14);
					line-height: 1.85;
					text-indent: -1.5em;
					padding-left: 1.5em;
					margin-bottom: 28px;
					&:nth-child(2n+1) {
						background: none;
					}
					&:before {
						content: "●";
						color: #453288;
						margin-right: .5em;
					}
				}
			}
		}
	}
}

/**
 * こどものメガネの選び方 | 安心メガネ保証
 */
 @import "children/guarantee";


/**
 * こどものメガネの選び方 | よくあるご質問
 */
.glasses-children-faq{
	.main-visual {
		background: #fff265 url(/img/glasses/children/guarantee/bg_main_01.png) no-repeat center top;
		h1 {
			line-height: 213px;
			font-size: fz(26);
		}
	}
	.inner.lens-faq{
		background: #fff;
		padding: 24px 120px;
	}
}

/**
 * こどものメガネの選び方 | ジュニアメガネセット
 */
.contents-junior-link {
	padding: 50px 0;
	.btn {
		width: 180px;
		display: block;
		margin: 0 auto;
	}

	.btn-junior {
		display: block;
		width: 515px;
		height: 80px;
		margin-bottom: 30px;
		padding: 5px;
		border: 2px solid #dbdbdb;

		line-height: 66px;
		box-sizing: border-box;
		font-family: $font-mincho;
		font-size: fz(24);
		color: $c-murasaki;
		span {
			display: block;
			width: 100%;
			height: 100%;
			padding-left: 170px;
			box-sizing: border-box;
			background: #f5f4f6 url(/img/glasses/children/icon_children_02.png) 93px center no-repeat;
		}
	}
}
