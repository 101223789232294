.glasses-brand-top{
	/* メインビジュアル
	---------------------------------*/
	.main-visual{
		height: 429px;
		background: url(/img/glasses/brand/top/bg_main_visual.png) center top no-repeat;
		margin: 0 auto;
		.txt{
			width: 340px;
      color: #fff;
			@include mincho-s(40px);
			padding: 104px 0 86px 636px;
		}
		.frame-btn{
			margin-left: 636px;
			@include frame-btn(331px,4px,#fff,53px,18px);
			span{
				color: #fff;
			}
		}
	}
	/* --- /メインビジュアル --------------------------------- */
	.main-conents{
		.bg-pattern-02{
			padding: 40px 0 80px;
			/* sec01
			---------------------------------*/
			.sec01{
				text-align: center;
				.title-type-01 {
					span{
						color: #000;
					}
				}
				.img{
					width: 977px;
					margin: 0 auto 10px;
				}
				.frame-btn{
					@include frame-btn(365px,5px,$c-murasaki,51px,18px);
					a{
						margin: 0 auto 20px;
						background: #fff;
						span{
							color: $c-murasaki;
						}
					}
				}
			}


			/* --- /sec01 --------------------------------- */


			/* sec02
			---------------------------------*/
			.sec02{
				overflow: hidden;
			}


			/* sec03
			---------------------------------*/

			.sec03{
				overflow: hidden;


				.title-area{
					text-align: center;
					.txt{
						@include mincho-murasaki-s(24px);
						display: inline-block;
						position: relative;
						padding-bottom: 8px;
						padding-left: 37px;
						background: url(/img/glasses/brand/top/bg_title_icon.png) left 2px no-repeat;
						&:before{
							position: absolute;
							content: url(/img/glasses/brand/top/bg_kakko_left.png);
							bottom: 0;
							left: -108px;
						}
						&:after{
							position: absolute;
							content: url(/img/glasses/brand/top/bg_kakko_right.png);
							bottom: 0;
							right: -108px;
						}
						span{
							color: $c-murasaki;
						}
					}
				}


				.list-area{
					overflow: hidden;
					margin-bottom: 49px;
					.list{
						border: 1px solid #d1d1d1;
						float: left;
						width: 314px;
						margin: 0 16px 32px 0;
						background: #fff;
						.img{
							@include vertical-align-middle(119px);
							text-align: center;
							img{
								vertical-align: middle;
								max-width: 80%;
								max-height: 80%;
								width: auto;
								height: auto;
							}
						}
						.list-btn{
							a{
								background: #fafafa;
								display: block;
								border-top: 1px solid #d1d1d1;
								text-align: center;
								span{
									@include vertical-align-middle(33px);
									@include mincho-s(14px);
									padding-left: 33px;
									display: inline-block;
									background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
								}
							}
						}
						&:nth-child(3n){
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}
