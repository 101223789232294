.quality-lowvision{
  /* sec01
  ---------------------------------- */
  .quality-lead-area{
    border-top: 1px solid #dfdfdf;
  }

  /* sec02
  ---------------------------------- */
  .main-contens{
    padding: 40px 0 25px 0;
  
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
    }
    .main-contens-clms{
      width: 475px;
      height: 235px;
      position: relative;
      border: 3px solid #d3c6e1;
      box-sizing: border-box;
      padding: 48px 41px;
      background: #fff;
      .main-contens-clms-title{
        font-size: 28px;
        @include mincho-murasaki;
        padding-bottom: 28px;
        width: 265px;
        margin-bottom: 15px;
        line-height: 1;
        border-bottom: 1px solid #a78ec3;
      }
      .main-contens-img{
        position: absolute;
        right: 21px;
        top: -21px;
      }
    }
    .main-contens-clms-left{
      float: left;
    }
    .main-contens-clms-right{
      float: right;
    }
  }

  .main-contens-colorview{
    padding: 38px 0 59px;
    .inner-main-contens-colorview{
      width: 952px;
    }
    .main-contens-colorview-clms-left{
      width: 523px;
      float: left;
    }
    .main-contens-colorview-clms-right{
      width: auto;
      float: right;
    }
    .main-contens-colorview-lead{
      width: 338px;
      height: 31px;
      @include vertical-align-middle(31px);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #fff;
      background: url(/img/quality/after/lowvision/bg_sec_02_img.png) left top no-repeat;
      margin-bottom: 26px;
    }
    .main-contens-colorview-title{
      @include mincho-murasaki;
      font-size: 30px;
      line-height: 1;
      margin-bottom: 20px;
    }
    .main-contens-txt{
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 32px;
    }
    .main-contens-colorview-detail-btn{
      background: #fff;
      margin-left: 138px;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }




  .sec-03.shop-list-area{
    position: relative;
    margin-bottom: 80px;
    .inner-shop-list{
      border-bottom: 1px solid $c-murasaki;
    }
    .area-name{
      width: 961px;
      background: url(/img/quality/after/bg_purple_belt.png) left top no-repeat;
      margin-bottom: 45px;
      @include mincho-murasaki;
      @include vertical-align-middle(50px);
      font-size: 20px;
      color: #fff;
      padding-left: 19px;
    }
    
    .shoplist-area-to-top-btn{
      margin: 0 auto;
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      background: #fff;
      border: 4px solid $c-murasaki;
      font-family: $font-mincho;
      font-weight: bold;
      width: 252px;
      @include vertical-align-middle(33px);
      font-size: 16px;
      text-align: center;
      a{
        display: inline-block;
        color: $c-murasaki;
        padding-left: 22px;
        background: url(/img/common/icon_circle_01.png) left 8px no-repeat;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .white-belt-balloon{
    border-top: 1px solid #dfdfdf;
    .inner{
      .title{
        background: url(/img/glasses/bg_choice_line.gif) left top repeat-x;
      }
    }
  }
  

}