@charset "utf-8";
/*------------------------------------*\
    吹き出し
\*------------------------------------*/

// base

.balloon {
  @include mincho-murasaki-s(24);
  text-align: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff transparent transparent transparent;
    z-index: 20;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e7e7e7 transparent transparent transparent;
    z-index: 10;
  }
}

// 白吹き出し（全体）
.balloon-01{
  @include mincho-murasaki-s(24);
  text-align: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -9px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff transparent transparent transparent;
    z-index: 20;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e7e7e7 transparent transparent transparent;
    z-index: 10;
  }
}

// 吹き出し: 紫
.balloon-02{
  @include mincho-s(24);
  @include vertical-align-middle(59px);
  color: #fff;
  text-align: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #e1e1e1;
  background-image: url(/img/common/balloon_02_left.png), url(/img/common/balloon_02_right.png);
  background-position: left top, right top;
  background-color: #4e3c93;
  background-repeat: no-repeat,no-repeat;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #4e3c93 transparent transparent transparent;
    z-index: 10;
  }
}

// フチあり吹き出し
.balloon-03{
  @include mincho-murasaki-s(30);
  text-align: center;
  border: 6px solid $c-murasaki;
  background: #c6dfbf;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -9px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff transparent transparent transparent;
    z-index: 20;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e7e7e7 transparent transparent transparent;
    z-index: 10;
  }
}

// .innerに 紫のボーダートップ
.balloon-04{
  border-top: 2px solid #ededed;
  line-height: 1.6;
  .inner{
    display: block;
    margin-top: -2px;
    border-top: 2px solid #4e3c93;
    padding: 20px 0;
  }
  .desc{
    font-size: 14px;
    font-weight: bold;
    color: #000;
    line-height: 1;
    display: block;
    margin-top: 11px;
  }
}
