@charset "UTF-8";

.contact-lens {
	.pc-mode{
		display: block;
	}
	.sp-mode{
		display: none;
	}
	.main-visual {
		background: url(/img/contact_lens/bg_main_01.png) 50% top no-repeat;
	}
	.main-visual-title {
		color: $c-murasaki;
	}
	/* コンタクトレンズ一覧
	------------------------------------------*/
	.contact-lens-top-section-01 {
		padding-top: 34px;
		border-top: 1px solid #dfdfdf;
	}
	.contact-lens-top-box-01 {
		width: 980px;
		height: 270px;
		background: url(/img/contact_lens/bg_contact_lens_box_01.jpg) left top no-repeat;
		padding-top: 27px;
		padding-bottom: 34px;
		.head {
			@include mincho-s(20);
			text-align: right;
			margin-right: 25px;
			line-height: 1;
			margin-bottom: 19px;
		}
		.contact-lens-type-list-area {
			float: right;
			width: 503px;
			border: 1px solid #cbcbcb;
			background: #fff;
			margin-right: 20px;
			.type-list {
				width: 251px;
				float: left;
				border-bottom: 1px solid #cbcbcb;
				@include vertical-align-middle(53px);
				font-size: 14px;
				&.last {
					margin-bottom: 0;
				}
				&.type-list-a {
					border-right: 1px solid #cbcbcb;
				}
				a {
					padding-left: 38px;
					background: url(/img/common/icon_circle_01.png) 11px 50% no-repeat;
					display: block;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	.contact-lens-top-section-02 {
		padding-top: 15px;
		border-top: 1px solid #dfdfdf;
		.contact-lens-top-box-02 {
			width: 977px;
			background: #f5f4f6;
			border: 2px solid #dfdfdf;
			margin-bottom: 32px;
			.detail-inner {
				width: auto;
				height: 165px;
				border: 6px solid #fff;
				background: url(/img/contact_lens/bg_contact_lens_box_02.png) 452px top no-repeat;
				margin-bottom: 0;
				padding-left: 0;
				.head {
					@include mincho-murasaki-s(24);
					background: url(/img/contact_lens/icon_arrow_contact_lens.gif) left 4px no-repeat;
					line-height: 1;
					margin: 69px 0 23px 7px;
					padding: 2px 0 2px 23px;
				}
				.detail-btn {
					position: static;
					margin-left: 22px;
					display: inline-block;
					a {
						display: block;
					}
				}
			}
		}
		.type-box-a {
			margin-bottom: 37px;
		}
		.type-box-a-list {
			.type-box-a-title {
				background: url(/img/contact_lens/bg_type_box_b.gif) left 12px no-repeat;
			}
		}
	}
	.contact-lens-top-box-03 {
		margin-bottom: 32px;
		padding: 10px 6px 6px;
		border: 2px solid #dfdfdf;
		h3 {
			@include mincho-murasaki-s(24);
			padding-left: 28px;
			background: #fff url(/img/contact_lens/bg_type_box_b.gif) left 4px no-repeat;
			line-height: 1;
			margin-left: -6px;
			margin-bottom: 10px;

		}
		&-inner {
			position: relative;
			background: #f5f4f6;
			padding: 20px 0 0 510px;
			min-height: 127px;
			img {
				position: absolute;
				bottom: 0;
				left: 0;
			}
			p {
				margin-bottom: 35px;
			}
		}
	}

	/* 2016/02/25 追記 */
	.contact-lens-top{
		.cf:after {
		  content: ".";
		  display: block;
		  clear: both;
		  height: 0;
		  visibility: hidden;
		}
		.cf {min-height: 1px;}
		* html .cf {
		  height: 1px;
		  /*¥*//*/
		  height: auto;
		  overflow: hidden;
		  /**/
		}
		.border-box{
		  -moz-box-sizing: border-box;
		  -webkit-box-sizing: border-box;
		  box-sizing: border-box;
		}
		.main-visual{
		    .main-visual-txt-wrap{
		        display: table-cell;
		        height: 340px;
		        vertical-align: middle;
		    }
		    .main-visual-title-02{
		        color: #453288;
		        font-family: $font-mincho;
		        font-weight: bold;
		        font-size: 308%;
		        padding-left: 42px;
		    }
		    .main-visual-sub-02{
		        font-family: $font-mincho;
		        font-weight: bold;
		        font-size: 20px;
		        color: #333;
		        padding-left: 42px;
		    }
		}
		.box-01 {
	    border: 4px solid #f2f2f2;
	    border-top: none;
	    padding-bottom: 20px;
	    background: #fff url("/img/contact_lens/inspection/bg_01.png") repeat-x left top;
	    text-align: center;
	    .contents__caption-outer{
	    	margin-bottom: 23px;
	    }
	    .contents__caption{
	    	color: #453288;
	    	font-family: $font-mincho;
	    	font-weight: bold;
	    	font-size: 30px;
	    	padding: 20px 0 15px;
	    }
		}
		.easy-txt-01{
			font-family: $font-mincho;
			font-weight: bold;
			font-size: 24px;
			color: #333;
			line-height: 1;
			text-align: center;
			margin-bottom: 15px;
			&:before{
				content: url(/img/common/bg_quotation_left.gif);
		    position: relative;
		    top: 9px;
		    margin-right: 17px;
			}
			&:after{
				content: url(/img/common/bg_quotation_right.gif);
				position: relative;
				top: 9px;
				margin-left: 17px;
			}
		}
		.easy-txt-02{
			font-family: $font-mincho;
			font-weight: bold;
			font-size: 22px;
			color: $c-murasaki;
			text-align: center;
			margin: 15px 0 28px;
		}
		.frame-btn a {
	    width: 365px;
	    border: 5px solid #453288;
	    display: block;
	    text-align: center;
	    margin: 0 auto 20px;
	    background: #fff;
	    span{
	    	font-family: $font-mincho;
  	    font-weight: bold;
  	    font-size: 139%;
  	    height: 51px;
  	    line-height: 51px;
  	    padding-left: 34px;
  	    color: #453288;
	    }
		}
		.box-list-wrap{
			padding: 30px 0;
			.box-list{
				font-size: 0;
				text-align: center;
				&-item{
					display: inline-block;
					margin-left: 9px;
					width: 238px;
					&:first-child{
						margin-left: 0;
					}
				}
			}
		}

		.shop-clinic-list-wrap{
			padding-top: 47px;
			background: #fff;
			text-align: center;
			.shop-clinic-head{
				font-family: $font-mincho;
				font-weight: bold;
				color: $c-murasaki;
				font-size: 24px;
				padding-left: 61px;
				background: url(/img/contact_lens/index/icon_shop_clinic_head.png) left center no-repeat;
				position: relative;
				display: inline-block;
				&:before{
					content: url(/img/common/bg_quotation_left.gif);
			    position: absolute;
			    top: 9px;
			    left: -103px;
				}
				&:after{
					content: url(/img/common/bg_quotation_right.gif);
					position: absolute;
					top: 9px;
					right: -103px;

				}
			}
			.shop-clinic-desc{
				margin-bottom: 6px;
			}
			.shop-list-wrap{
				padding: 40px 0 30px;
			}
			.shop-clinic-list{
				margin-bottom: 30px;
			}
			.shop-clinic-list-item{
				width: 230px;
				@extend .border-box;
				&.shop-clinic-list-item-l{
					float: left;
					margin-left: 20px;
				}
				&.shop-clinic-list-item-r{
					float: right;
					&:last-child {
						clear: right;
					}
				}
				&:first-child{
					margin-left: 0;
				}
				&.shop-clinic-list-item-a{
					margin-top: 12px;
				}
				.shop-clinic-list-item-head{
					font-family: $font-mincho;
					font-weight: bold;
					color: $c-murasaki;
					font-size: 18px;
					text-align: center;
					border: 1px solid #dfdfdf;
					background: #fff;
					line-height: 1;
					padding: 8px 0;
					margin-bottom: 18px;
					@extend .border-box;
				}
				.shop-pref-list{
					&-item{
						border-bottom: 1px solid #dfdfdf;
						padding: 10px 3px 5px 0;
				    text-align: right;
				    font-size: 15px;
						&:first-child{
							padding-top: 0;
						}
					}
				}
			}
		}

		.shop-clinic-list-btn-wrap{
			background: #fff;
			border: 1px solid #dfdfdf;
			width: 352px;
			margin: 0 auto;
			-o-border-radius: 3px;
			-ms-border-radius: 3px;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			padding: 20px 0;
			background: #fff;
			display: block;
			text-align: center;
			@extend .border-box;
			a{
		    width: 270px;
		    border: 1px solid #dfdfdf;
		    display: block;
		    background: #fff;
		    margin: 0 auto 7px;
		    @extend .border-box;
		    span{
		    	font-family: $font-mincho;
	  	    font-weight: bold;
	  	    color: #453288;
	  	    font-size: 15px;
	  	    height: 33px;
	        line-height: 33px;
	        padding-left: 33px;
	        display: inline-block;
	        background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
		    }
			}
		}

		.contact-lens-top-section-02{
			padding: 30px 0 66px;
			.contact-lens-top-box-02{
				margin-bottom: 20px;
				&.contact-lens-top-box-custom-01,&.contact-lens-top-box-custom-02,&.contact-lens-top-box-custom-03{
					.detail-inner{
						.head{
							background: url(/img/contact_lens/bg_type_box_b.png) left 4px no-repeat;
							margin-left: 0;
							padding-left: 35px;
						}
					}
				}
				&.contact-lens-top-box-custom-02{
					background: #d7f3ff;
					.detail-inner{
						position: relative;
						background: url(/img/contact_lens/index/bg_checkup.png) 509px 15px no-repeat;
						.head{
							margin: 60px 0 23px 0;
							background: url(/img/contact_lens/bg_type_box_b.png) left 18px no-repeat;
						}
						.detail-btn{
							position: absolute;
							top: 68px;
							right: 22px;
						}
					}
				}
				&.contact-lens-top-box-custom-03{
					background: url(/img/contact_lens/index/bg_recommend.png) left top no-repeat;
					margin-bottom: 0;
					.detail-inner{
						background: none;
						.head{
							margin: 69px 0 21px 7px;
						}
					}
				}
			}
			.contact-lens-top-box-03{
				.detail-btn{
					a{
						display: inline-block;
					}
				}
			}
			.contact-lens-top-box-03,.type-box-a{
				margin-bottom: 20px;
			}
		}
	}

}

.contact-lens-flow {
	.main-header .page-title {
		font-size: 178%;
	}
}
