@charset "utf-8";
.sunglasses-func-and-effect {
	.main-visual {
		height: 306px;
		background: url(/img/sunglasses/functional_effect/bg_main_visual.jpg) center top no-repeat;
		padding-top: 31px;
		&-tag {
			position: relative;
			color: #fff;
			font-weight: bold;
			background: #4e3c93;
			margin-left: 42px;
			padding: 5px 20px;
			display: inline-block;
			&:after{
				content: "";
				position: absolute;
				bottom: -10px; left: 50%;
				margin-left: -10px;
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 10px 0 10px;
				border-color: #4e3c93 transparent transparent transparent;
			}
		}
		&-title {
			height: auto;
			color: $c-murasaki;
			font-size: 45px;
			font-weight: bold;
			font-family: $font-mincho;
			margin-bottom: 20px;
			padding: 0 0 15px 42px;
			text-align: left;
			span {
				border-bottom: 6px solid #a78dc3;
			}
		}
		&-sub-title {
			width: 610px;
			font-size: fz(12);
			font-weight: bold;
			text-align: left;
			padding-left: 42px;
		}
	}

	.ancarea {
		text-align: left;
		padding: 8px 0;
		background-color: rgba(255,255,255,0.6);
		margin-left: 42px;
		margin-top: 20px;
		box-sizing: border-box;
		.col-6 {
			line-height: 2;
			&:nth-child(2n) {
				margin-left: -30px;
			}
		}

		a {
			color: #4e3c93;
			font-size: 17px;
			background: url(/img/common/icon_circle_01.png) no-repeat 7px center;
		}
	}
	.title-outer {
		margin-top: 30px;
		position: relative;
		padding: 20px 0 18px;
		background: url(/img/sunglasses/functional_effect/bg_fukidasi.png) center top repeat-y;
		&:after{
			content: "";
			position: absolute;
			bottom: -10px; left: 50%;
			margin-left: -10px;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #4e3c93 transparent transparent transparent;
		}
	}
	.title-tag {
		position: relative;
		width: 435px;
		color: #453287;
		font-weight: bold;
		font-size: 19px;
		text-align: center;
		background: #fff;
		margin: 0 auto 10px;
		padding: 0 15px;
		&:after{
			content: "";
			position: absolute;
			bottom: -10px; left: 50%;
			margin-left: -10px;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #fff transparent transparent transparent;
		}
	}
	.title {
		color: #fff;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
	}
	.title-note {
		color: #fff;
		font-weight: bold;
		text-align: center;
	}
	// list
	.list {
		overflow: hidden;
		margin-bottom: 40px;
		.list-item {
			float: left;
			&:before {
				display: inline-block;
				content: url(/img/sunglasses/functional_effect/icon_arrow.png);
				width: 24px;
				height: 31px;
				margin-left: -16px;
			}
			&:first-child {
				&:before {
					display: none;
				}
			}
		}
		.list-item-2 {
			float: left;
		}
		.list-item-3 {
			float: left;
		}
	}
	.list-nav {
    margin-top: 20px;
    .smp-hide {
      display: block!important;
    }
	}

	.sub-title {
		color: #4e3c93;
		font-weight: bold;
		font-size: 19px;
		border-bottom: 3px solid #4e3c93;
		&:after {
			content:"";
			display: block;
			width: 80%;
			border-bottom: 3px solid #bcb1c1;
			margin-bottom: -3px;
			margin-left: 20%;
		}
	}
	.bt {
		border-top: 3px solid #4e3c93;
		padding-top: 50px;
	}
	.detail-btn {
		a {
			padding-top: 7px;
			padding-bottom: 7px;
			border: 1px solid #e1e1e1;
			background: rgba(237,237,237,0.29);
			filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#33ededed,EndColorStr=#33ededed); /*IE8以下用*/
		}
	}
	.contents-03-01 {
		border: 1px solid #dfdfdf;
		margin: 0 auto 40px;
		width: 954px;
	}
	.contents-03-01-inner {
		padding: 19px;
	}
	.contents-03-01-inner .cf:first-child {
		border-bottom: 1px solid #4e3c93;
	}
	.contents-03-01-inner .left {
		width: 60.632497%;
	}
	.contents-03-01-inner .right {
		width: 35.768811%;
	}
	.contents-03-01-inner h4 {
		font-size: 23px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.contents-03-01-inner li {
		margin-bottom: 1em;
		background: url("/img/common/bg_list_01.png") left 4px no-repeat;
		padding-left: 13px;
	}
}
