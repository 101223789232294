.glasses-children-top{


	/* メインビジュアル
	----------------------------------- */

	.main-visual{
		height: 313px;
		background: #FFF265 url(/img/glasses/children/top/main_01.jpg) center top no-repeat;
		p{
			margin-top:0;
			img{
				display: block;
				margin: 0 auto;
			}
		}
	}

	/* // メインビジュアル ----------------------------------- */



	/* メインコンテンツ
	----------------------------------- */

	.main-conents{


		.sec-01{
			padding: 40px 0;
		}
		.main-conents-title{
			@include mincho-murasaki-s(36px);
			background: url(/img/glasses/children/top/bg_title_line.png) left bottom no-repeat;
			width: 459px;
			margin: 0 auto 36px;
			span{
				background: url(/img/glasses/children/top/icon_glasses.png) left top no-repeat;
				padding-left: 56px;
			}
		}


		.main-conents-lead{
			font-size: 14px;
			font-weight: bold;
			line-height: 1.6;
			margin-bottom: 27px;
			text-align: center;
		}


		.question{
			width: 742px;
			margin: 0 auto 30px;
			.question-item{
				float: left;
				width: 200px;
				height: 200px;
				text-align: center;
				overflow: hidden;
				.head{
					color: #fff;
					font-family: $font;
					font-size: 30px;
					line-height: 1;
					margin: 30px 0 15px;
				}
				.question-desc{
					@include mincho-s(18px);
					color: #fff;
					line-height: 25px;
				}
				&.question-item-01{
					background: url(/img/glasses/children/top/bg_question_01.png) left top no-repeat;
					margin-right: 71px;
				}
				&.question-item-02{
					background: url(/img/glasses/children/top/bg_question_02.png) left top no-repeat;
					margin-right: 71px;
				}
				&.question-item-03{
					background: url(/img/glasses/children/top/bg_question_03.png) left top no-repeat;
				}
			}
		}
		.purple-frame-btn{
			margin: 0 auto;
			text-align: center;
			a{
				background: #fff;
				@include mincho-murasaki-s(20);
				border: 5px solid $c-murasaki;

				width: 618px;
				height: 53px;
				line-height: 53px;
				display: inline-block;
				transition: all 0.3s;
				&:hover {
					background: $c-murasaki;
					color: #fff;
				}

			}
		}
		.sec-02{
			.sec-02-jaggy-inner{
				background: url(/img/glasses/children/top/bg_jaggy.png) left top repeat-x;
				padding-top: 12px;
				.inner.jaggy-box{
					width: 988px;
				}
				.jaggy-box-area.jaggy-box-area-01{
					background: #fff;
					padding: 10px;
					.sec-02-header{
						height: 150px;
						position: relative;
						margin-bottom: 46px;
						&:after{
						  content: "";
						  position: absolute;
						  bottom: -20px; left: 50%;
						  margin-left: -20px;
						  display: block;
						  width: 0px;
						  height: 0px;
						  border-style: solid;
						  border-width: 20px 20px 0 20px;
						}
						.num{
							float: left;
							font-size: 69px;
							font-family: $font;
							line-height: 1;
							margin: 20px 0 0 18px;
							color: #fff;
						}
						.title{
							float: left;
							margin: 20px 0 0 53px;
							@include mincho-s(26px);
							@include vertical-align-middle(110px);
							color: #fff;
							padding-left: 139px;
							background: url(/img/glasses/children/top/title_img_01.png) left top no-repeat;
						}
					}



					/* Q1エリア
					----------------------------------- */
					.q1{
						.sec-02-header{
							$color: #ee8d9a;
							background: $color;
							&:after{
							  border-color: $color transparent transparent transparent;
							}
							.title{
								background: url(/img/glasses/children/top/title_img_01.png) left top no-repeat;
							}
						}
						.contents-inner{
							position: relative;
						}
						.char{
							position: absolute;
							bottom: -35px;
							right: 15px;
						}
					}

					/* // Q1エリア ----------------------------------- */



					/* Q2エリア
					----------------------------------- */
					.q2{
						.sec-02-header{
							$color: #c3d300;
							background: $color;
							&:after{
							  border-color: $color transparent transparent transparent;
							}
						}
						.title{
							background: url(/img/glasses/children/top/title_img_02.png) left top no-repeat;
						}
						.sec-02-header-02-box{
							background: #f7f7f7;
							overflow: hidden;
							.jaggy-box-area-04{
								overflow: hidden;
								padding: 23px 44px 35px;
								.box-title{
									@include mincho-murasaki-s(20px);
									text-align: center;
									display: block;
									position: relative;
									margin-bottom: 17px;
									&:before{
										position: absolute;
										content: url(/img/glasses/children/top/bg_dot_line.png);
										left: 0;
										top: 0;
									}
									&:after{
										position: absolute;
										content: url(/img/glasses/children/top/bg_dot_line.png);
										right: 0;
										top: 0;
									}
								}
								.purple-frame-btn{
									@include frame-btn(294px,5px,$c-murasaki,55px,20px);
									background: #fff;
									a {
										span {
											font-size: 20px;
										}
									}
								}
							}
						}
					}
					/* // Q2エリア ----------------------------------- */



					/* Q3エリア
					----------------------------------- */
					.q3{
						.sec-02-header{
							$color: #b085b7;
							background: $color;
							&:after{
							  border-color: $color transparent transparent transparent;
							}
							.title{
								background: url(/img/glasses/children/top/title_img_03.png) left top no-repeat;
							}
						}
						.q3-box{
							width: 726px;
							height: 145px;
							border: 2px solid #dfdfdf;
							padding: 6px;
							background: #fff;
						}
						.q3-box-top{
							background: #f5f5f5;
							@include vertical-align-middle(84px);
							@include mincho-s(32px);
							color: #ef4492;
							padding-left: 30px;
						}
						.q3-box-bottom{
							background: #ef4492;
							height: 61px;
							position: relative;
							.txt{
								color: #fff;
								font-size: 16px;
								line-height: 24px;
								font-weight: bold;
								padding-left: 30px;
								padding-top: 6px;
							}
							.detail-btn{
								position: absolute;
								right: 5px;
								bottom: 5px;
							  margin-left: 24px;
							  a{
							    border: 1px solid #e1e1e1;
									padding: 7px 0;
							    background: #fafafa;
							  }
							}
							.char{
								position: absolute;
								top: -79px;
								right: 7px;
							}
						}
					}
					/* // Q3エリア ----------------------------------- */

					.contents-inner{
						width: 742px;
						margin: 0 auto 76px;
						.sec-02-txt-01{
							margin-bottom: 15px;
							font-size: 14px;
							line-height: 1.7;
						}
						.sec-02-box-01.jaggy-box{
							width: 740px;
							margin-bottom: 18px;
							background: #f7f7f7;
							.jaggy-box-area.jaggy-box-area-02{
								padding: 24px 28px;
							}
							.sec-02-box-01-txt{
								color: #592f2a;
								font-weight: bold;
								font-size: 16px;
								line-height: 28px;
								span{
									background: url(/img/quality/guarantee/bg_line_marker_02.png) left 17px repeat-x;
									display: inline-block;
								}
							}
						}
						.sec-02-box-02.jaggy-box{
							width: 740px;
							background: #ffebed;
							.jaggy-box-area.jaggy-box-area-03{
								padding: 24px 28px;
							}
							.sec-02-box-02-txt{
								font-size: 14px;
								font-weight: bold;
								color: #e60012;
								line-height: 23px;
							}
						}
					}
				}
			}
		}


		/* フラッグエリア
		----------------------------------- */

		.flag-area{
			padding: 36px 26px 19px;
			.flag{
				width: 892px;
				height: 330px;
				box-sizing: border-box;
				background: url(/img/glasses/children/top/bg_flag.png) left top no-repeat;
				padding: 61px 0 0 86px;
				font-weight: bold;
				.txt {
					span {
						line-height: 2;
						color: $c-murasaki;
						font-size: 21px;
						background: url(/img/quality/guarantee/bg_line_marker_02.png) left bottom repeat-x;
					}
				}
			}
			.caution{
				@include box(1px,#333);
				padding: 29px 27px;
				background: #fff;
				.title{
					font-size: 15px;
					font-weight: bold;
					line-height: 1;
					padding-bottom: 10px;
					border-bottom: 1px dotted #c9c9c9;
					margin-bottom: 3px;
				}
				.txt{
					font-size: 14px;
					line-height: 23px;
					span{
						display: block;
						line-height: 1.1;
					}
				}

			}
		}

		/* // フラッグエリア ----------------------------------- */

		/* 無料点検実施中
		----------------------------------- */

		.sec-check{
			background: #fff;
			padding: 37px 25px;
			overflow: hidden;
			.icon{
				color: #fff;
				@include mincho-s(24px);
				text-align: center;
				line-height: 1.2;
				padding-top: 66px;
				width: 192px;
				height: 191px;
				background: url(/img/glasses/children/top/bg_circle.png) left top no-repeat;
				margin-right: 26px;
				box-sizing: border-box;
			}

			.main{
				width: 700px;
				.title{
					margin-bottom: 22px;
					margin-top: 13px;
					.title-01,.title-02{
						@include mincho-s(26px);
						color: #d40f2a;
						position: relative;
					}
					.title-01{
						padding-right: 62px;
						@include vertical-align-middle(48px);
						display: inline-block;
						background: url(/img/glasses/children/top/icon_01.png) right top no-repeat;
						margin-right: 29px;
					}
					.title-02{
						padding-right: 62px;
						display: inline-block;
						@include vertical-align-middle(48px);
						margin-left: 18px;
						background: url(/img/glasses/children/top/icon_03.png) right top no-repeat;
					}
				}
				.sub{
					@include mincho-s(22px);
					line-height: 1;
					text-align: center;
					margin-bottom: 18px;
				}
				.txt{
					font-size: 12px;
					line-height: 1.7;
				}
			}




		}

		/* // 無���点検実施中 ----------------------------------- */
	}
}
